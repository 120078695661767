import React, { memo, useContext, useEffect, useState } from 'react'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import Divider from '@mui/material/Divider'

import ColorDivider from '../ColorDivider'
import NumberInput from '../NumberInput'
import { scrollToTop } from '../../../Helper'
import ContributionHeading from '../ContributionHeading'
import SelectDeselectButtonGroup from '../useSelectDeselect'
import {
  calculateBenefitsContribution,
  calculateFSAAndRetirementContribution,
  getBenefitOptions,
  replaceWhitespaceWithUnderscore
} from '../helpers/Benefits'
import { type Adults } from '../../AdultsInterface'
import type { ContributionLimits, FormValues } from '../../../FormValuesInterfaces'
import type { BenefitData, FSABenefitProps } from '../../../EmployeeCalculatorInterface'

import './styles.sass'

type ContributionLimitsKey = keyof ContributionLimits

interface BenefitProps extends FSABenefitProps {
  componentName: string
  benefitKey: string
}

const BenefitsContributionsDetails: React.FC<BenefitProps> = memo((props) => {
  const {
    formValuesContext,
    benefitDataCompany,
    setValues,
    setDisabled,
    componentName,
    benefitKey
  } = props

  const formValues: FormValues = useContext(formValuesContext)
  const employeeType = formValues?.adults[0]?.employee?.company_name ?? ''
  const [error, setError] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const benefitName = replaceWhitespaceWithUnderscore(componentName)
  const { providerOptions } = getBenefitOptions(employeeType, benefitName, benefitDataCompany)

  const annualContributionLimit = (formValues.benefitsAnnualContributionLimit)?.[`${benefitKey}Limit` as ContributionLimitsKey]?.[0]

  const benefitTypeKey = `${benefitKey}BenefitData` as keyof Adults

  const extractBenefitType = (): BenefitData | undefined => {
    return (formValues?.adults[0]?.[benefitTypeKey] as BenefitData | undefined) ?? undefined
  }

  const extractedType = extractBenefitType()

  const [benefitData, setBenefitData] = useState({
    provider: extractedType?.provider !== ''
      ? extractedType?.provider as string
      : replaceWhitespaceWithUnderscore(providerOptions[0]),
    name: extractedType?.name as string,
    amount: extractedType?.amount as number,
    coverage: extractedType?.coverage as string,
    contribution: extractedType?.contribution as string,
    annualContributionLimit: annualContributionLimit || '0',
    isSelected: extractedType?.isSelected
  })

  useEffect(() => {
    setDisabled(error)
  }, [error])

  useEffect(() => {
    setBenefitData((prevData) => ({
      ...prevData,
      amount: calculateFSAAndRetirementContribution(prevData?.coverage, formValues?.adults[0]?.payFrequency as string),
      contribution: calculateBenefitsContribution(prevData?.provider)
    }))
  }, [benefitData.coverage])

  scrollToTop()

  const handleProviderChange = (_event: React.MouseEvent<HTMLElement>, newProvider: string): void => {
    setBenefitData((prevData) => ({ ...prevData, provider: newProvider, isSelected: false }))
  }

  return (
    <div className={`fsa-container`}>
      <div className='data-container'>
        <div className='page-header'>
          <b>{componentName}</b>
          <p>Choose the plan that best fits your goals and estimated budget. Be sure to reach out to your HR contact with questions.</p>
        </div>

        <ColorDivider />

        <div className='page-body'>
          <b>Providers</b>

          <ToggleButtonGroup
            color='secondary'
            value={benefitData.provider}
            exclusive
            onChange={handleProviderChange}
            aria-label='Platform'
          >
            {providerOptions.map((option) => (
              <ToggleButton
                key={option}
                value={replaceWhitespaceWithUnderscore(option)}
                className='provider-option'
              >
                {option}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>

          <div className='benefit-data'>
            <div className='montly-data'>
              <b>${benefitData.amount}</b>
              <h3>PRE-TAX CONTRIBUTION PER PAY PERIOD</h3>
            </div>

            <Divider variant='middle' />

            <h2>Employer Match</h2>
            <ContributionHeading
              benefitData={{
                ...benefitData,
                isSelected: typeof benefitData.isSelected === 'boolean' ? benefitData.isSelected : Boolean(benefitData.isSelected)
              }}
              benefitType='limit'
              isPension={componentName.toLowerCase() === 'pension'}
            />

            <Divider variant='middle' />

            <div className='benefit-input-container'>
              <div className='info-text'>
                <h2>Select your Annual Contribution Amount</h2>
                <p>MAXIMUM CONTRIBUTION IS ${parseFloat(annualContributionLimit).toFixed(2)}</p>
              </div>

              <div className={`options ${errorMessage !== '' ? 'error' : ''}`}>
                <NumberInput
                  value={benefitData?.coverage}
                  setValue={(newValue: string) => {
                    setBenefitData((prevData) => ({ ...prevData, coverage: newValue }))
                  }}
                  setError={setErrorMessage}
                  setIsError={setError}
                  annualContributionStartrangeValue={0}
                  annualContributionEndrangeValue={parseFloat(annualContributionLimit)}
                  label='$'
                  setSelected={(newValue: boolean) => {
                    setBenefitData((prevData) => ({ ...prevData, isSelected: newValue }))
                  }}
                />
              </div>

              {errorMessage && <div className='error-text'>{errorMessage}</div>}
            </div>

            <div className='selected-button'>
              <SelectDeselectButtonGroup
                formValuesContext={formValuesContext}
                setValues={setValues}
                benefitData={benefitData}
                setIsSelected={(newValue) => { setBenefitData((prevData) => ({ ...prevData, isSelected: newValue })) }}
                error={false}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
})

export default BenefitsContributionsDetails
