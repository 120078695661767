import React from 'react'
import '../component_styles/ArrowBoxLink.sass'
import Link from './Link'

interface ArrowBoxLinkProps {
  text: string
  href: string
}

const ArrowBoxLink: React.FC<ArrowBoxLinkProps> = (props) => {
  return (
    <div className="arrow-box-link">
      <Link
        text={props.text}
        href={props.href}
      />
    </div>
  )
}

export default ArrowBoxLink
export type { ArrowBoxLinkProps }
