export const validate = (event: React.ChangeEvent<HTMLInputElement> | any, field: string) => {
  const errors: any = {}

  if (field === 'title') {
    if (['', undefined].includes(event?.target?.value)) {
      errors.title = 'Title is Required'
    } else {
      errors.title = false
    }
  }

  if (field === 'bgColor') {
    if (['', null].includes(event?.value)) {
      errors.bgColor = 'Color is Required'
    } else {
      errors.bgColor = false
    }
  }

  if (field === 'category') {
    if (['', null].includes(event?.value)) {
      errors.category = 'Category is Required'
    } else {
      errors.category = false
    }
  }

  return errors
}
