import React, { memo } from 'react'
import Button from '@mui/material/Button'

import './styles.sass'

interface ButtonProps {
  label: string
  width: string
  buttonClick: VoidFunction
  disabled?: boolean
}

const PurpleButton: React.FC<ButtonProps> = memo((props) => {
  const { label, width, disabled, buttonClick } = props

  return (
    <Button
      variant='contained'
      className={`purple-button ${disabled ? 'purple-button-disabled' : ''}`}
      style={{ width: width }}
      disabled={disabled}
      onClick={buttonClick}
      type='submit'
    >
      {label}
    </Button>
  )
})

export default PurpleButton
