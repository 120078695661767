import React, { useState, useCallback } from 'react'
import I18n from '../config/I18n'
import Link from './Link'
import TextField from './TextField'
import '../component_styles/SnapshotCard.sass'

interface SnapshotDetailedCardProps {
  name: string
  date: string
  snapshotUrl: string
  totalIncomeValue: number
  totalIncomeIncrement: string
  netIncomeValue: number
  netIncomeIncrement: string
  seekerSnapshotId: number
  errors?: string
  linkClassName?: string
}

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD'
})

const SnapshotDetailedCard: React.FC<SnapshotDetailedCardProps> = (props) => {
  const { errors, date, snapshotUrl, totalIncomeValue, totalIncomeIncrement, netIncomeValue, netIncomeIncrement, seekerSnapshotId } = props
  const [editing, setEditing] = useState(false)
  const [name, setName] = useState(props.name)
  const [oldName, setOldName] = useState(props.name)

  const startEditing = useCallback((ev:any) => {
    ev.preventDefault()
    setEditing(true)
    setOldName(name)
  }, [])

  const save = useCallback((ev:any) => {
    ev.preventDefault()

    $.ajax({
      method: 'POST',
      async: false,
      url: `/${I18n.locale}/seeker_snapshots/${seekerSnapshotId}/update_name`,
      headers: {
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
      },
      data: {
        name: name
      }
    })
    setEditing(false)
  }, [name])

  const cancel = useCallback((ev:any) => {
    ev.preventDefault()
    setName(oldName)
    setEditing(false)
  }, [oldName])

  const setNewName = (value:string) => {
    return setName(value)
  }

  return (
    <div className="snapshot-card detailed">
      <div className="snapshot-details-container clearfix">
        <div className="snapshot-details">
          { !editing &&
            <h2 className="no-margin-bottom">{ name }</h2>
          }
          { editing &&
            <TextField
              type="text"
              name="name"
              placeholder={ I18n.t('snapshot.name') }
              isRequired={ true }
              autoFocus={ false }
              value={ name }
              onChange={ setNewName }
              inputClassName="no-margin-bottom"
              maxLength={ 16 }
              minLength={ 3 }
              errors={ errors }
            />
          }
          <p className="date">{ I18n.t('snapshot.created_on', { date: date }) }</p>
        </div>
        { !editing &&
          <a href="#" className="green caps edit-url" onClick={ startEditing }>
            { I18n.t("snapshot.edit_name_line1") }
            <br></br>
            { I18n.t("snapshot.edit_name_line2") }
          </a>
        }
        { editing &&
          <a href="#" className="red caps edit-url" onClick={ cancel }>
            { I18n.t("forms.cancel") }
          </a>
        }
      </div>

      <div className="income-display clearfix nudge-down-25">
        <h4 className="no-margin-bottom">{ I18n.t("snapshot.total_household_income") }</h4>
        <p className={ totalIncomeValue < 0 ? "red" : "green" }>{ formatter.format(totalIncomeValue) }/{ totalIncomeIncrement }</p>
      </div>
      <div className="income-display clearfix">
        <h4 className="no-margin-bottom">{ I18n.t("snapshot.estimated_net_income") }</h4>
        <p className={ netIncomeValue < 0 ? "red" : "green" }>{ formatter.format(netIncomeValue) }/{ netIncomeIncrement }</p>
      </div>

      { !editing &&
        <Link
          text={ I18n.t("snapshot.view_snapshot") }
          href={ snapshotUrl }
          className={ `btn center nudge-down-50 ${props.linkClassName}` }
        />
      }
      { editing &&
        <Link
          text={ I18n.t("snapshot.save_name") }
          href="#"
          onClick={ save }
          className={ `btn center nudge-down-50 ${props.linkClassName}` }
        />
      }
    </div>
  )
}

export default SnapshotDetailedCard
export type { SnapshotDetailedCardProps }
