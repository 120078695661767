import React, { useState, useEffect } from 'react'
import { TextField, Checkbox, FormControlLabel } from '@material-ui/core'
import { Editor } from '@tinymce/tinymce-react'
import { LoadingButton } from '@mui/lab'
import { ToastContainer } from 'react-toastify'
import Select from 'react-select'

import { CategoryTypeProps } from './Category'
import {
  checkPresenceOfImgNText,
  convertCategoriesToOptions,
  getSelectRelatedTopics,
  getImage,
  getQuickPeekCardTier,
  getSelectedCategory,
  getSelectedColor,
  tinymceInitSettings
} from '../Helper'
import { createNewQuickPeek, updateQuickPeek } from '../api/quickPeek'
import { DROPDOWN_COLOR_OPTIONS, DROPDOWN_COLOR_STYLES, QUICK_PEEK_TIERS } from '../Constants'
import { QuickPeekCardProps } from './QuickPeekCard'
import { validate } from '../formValidations/quickPeekEditor'
import ImageSelector from './ImageSelector'

import '../component_styles/QuickPeekEditor.sass'
import 'react-toastify/dist/ReactToastify.css'

interface QuickPeekEditorProps {
  categories: CategoryTypeProps[]
  quickPeekCard: QuickPeekCardProps
  selectedRelatedTopics: object[]
  relatedTopics: QuickPeekCardProps[]
  quickPeekImages: ImageProps[]
}

interface ImageProps {
  id: number
  link: string
  alt_text: string
}

interface SelectOptions {
  id?: string | number
  label?: string | number
  tag?: string | number
  value?: string | number
  __isNew__?: string | number
  link: string | number
}

const QuickPeekEditor: React.FC<QuickPeekEditorProps> = (props) => {
  const { categories, quickPeekCard, selectedRelatedTopics, quickPeekImages } = props

  const [title, setTitle] = useState(quickPeekCard?.title ?? '')
  const [subHeading, setsubHeading] = useState(quickPeekCard?.sub_heading ?? '')
  const [bgColor, setBgColor] = useState(quickPeekCard?.background_color ?? '')
  const [tier, setTier] = useState(getQuickPeekCardTier(quickPeekCard))
  const [text, setText] = useState(quickPeekCard?.content)
  const [relatedTopics, setRelatedTopics] = useState(selectedRelatedTopics)
  const [quickPeekImage, setQuickPeekImage] = useState<string | number | undefined>()
  const [image, setImage] = useState(quickPeekCard?.image_url ?? '')
  const [imageAltText, setAltText] = useState<string | number | undefined>('')
  const [intro, setIntro] = useState(quickPeekCard?.intro ?? '')
  const [helpFromAPerson, setHelpFromAPerson] = useState(quickPeekCard?.help_from_a_person ?? '')
  const [ctaHeading, setCtaHeading] = useState(quickPeekCard?.cta_heading ?? '')
  const [buttonText, setButtonText] = useState(quickPeekCard?.cta_button_text ?? '')
  const [ctaText, setCtaText] = useState(quickPeekCard?.cta_text ?? '')
  const [ctaLink, setCtaLink] = useState(quickPeekCard?.cta_link ?? '')
  const [errors, setErrors] = useState({ title: false, bgColor: false, category: false })
  const [category, setCategory] = useState(quickPeekCard?.category_id ?? '')
  const [published, setPublished] = useState(quickPeekCard?.publish_status === 'published' ? true : false)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setQuickPeekImage(getImage(quickPeekImages, quickPeekCard.image_id)?.link)
  }, [])

  const cantSendRequest = () => {
    if (title.length > 0 && bgColor.length > 0 && category.toString().length > 0 && checkPresenceOfImgNText(imageAltText, quickPeekImage)) {
      return false
    }
    return true
  }

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement> | any, field: string) => {
    setErrors({ ...errors, ...validate(e, field) })

    if (field === 'title') setTitle(e?.target?.value)
    if (field === 'showText') setsubHeading(e?.target?.value)
    if (field === 'bgColor') setBgColor(e?.value)
    if (field === 'category') setCategory(e?.value)
  }

  const handleCreate = () => {
    const data = {
      title: title,
      sub_heading: subHeading,
      background_color: bgColor,
      publish_status: published ? 'published' : 'unpublished',
      tier: tier.value,
      content: text,
      category_id: category ?? 0,
      related_topics: getSelectRelatedTopics(relatedTopics),
      intro: intro,
      help_from_a_person: helpFromAPerson,
      cta_heading: ctaHeading,
      cta_text: ctaText,
      cta_button_text: buttonText,
      cta_link: ctaLink,
      image
    }
    setIsLoading(true)

    if (quickPeekCard?.title)
      updateQuickPeek(quickPeekCard?.id, data, image, imageAltText, setIsLoading)
    else
      createNewQuickPeek(data, image, imageAltText, setIsLoading)
  }

  const getRelatedTopics = () => {
    let relatedQPs: QuickPeekCardProps[] = []
    if (tier.value == 1) {
      props.relatedTopics?.forEach((topic: QuickPeekCardProps) => topic.tier == 2 ? relatedQPs.push(topic) : '')
    } else if (tier.value == 2) {
      props.relatedTopics?.forEach((topic: QuickPeekCardProps) => topic.tier == 3 ? relatedQPs.push(topic) : '')
    } else {
      props.relatedTopics?.forEach((topic: QuickPeekCardProps) => topic.tier == 1 ? relatedQPs.push(topic) : '')
    }
    return relatedQPs
  }

  return (
    <div className='text-editor-main content'>
      <h2>{`${quickPeekCard?.title ? 'Update' : 'Create'} Quick Peek Card`}</h2>
      <ToastContainer
        position='top-right'
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className='text-fields'>
        <TextField
          name='title'
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleOnChange(e, 'title')}
          required
          margin='dense'
          label='Title'
          error={errors.title}
          variant='outlined'
          value={title}
        />

        <TextField
          name='sub-heading'
          margin='dense'
          label='Sub Heading'
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleOnChange(e, 'showText')}
          variant='outlined'
          value={subHeading}
        />

        <div style={{ margin: '6px 0 0' }}>
          <Select
            className='basic-single'
            classNamePrefix='select'
            name='bgColor'
            placeholder={'Select Color'}
            options={DROPDOWN_COLOR_OPTIONS}
            value={getSelectedColor(bgColor)}
            styles={DROPDOWN_COLOR_STYLES}
            onChange={(e: React.ChangeEvent<HTMLInputElement> | any) => handleOnChange(e, 'bgColor')}
          />
          {errors.bgColor && <p className='error-msg'>{errors.bgColor}</p>}
        </div>

        <Select
          className='basic-single'
          classNamePrefix='select'
          placeholder={'Select Tier'}
          isSearchable={false}
          defaultValue={QUICK_PEEK_TIERS[2]}
          name='tier'
          value={tier}
          options={QUICK_PEEK_TIERS}
          onChange={(e: React.ChangeEvent<HTMLInputElement> | any) => setTier(e)}
        />

        <>
          <Select
            className='basic-single'
            classNamePrefix='select'
            placeholder={'Select Category'}
            isSearchable={true}
            value={getSelectedCategory(category, categories)}
            options={convertCategoriesToOptions(props.categories)}
            onChange={(e: React.ChangeEvent<HTMLInputElement> | any) => handleOnChange(e, 'category')}
          />
          {errors.category && <p className='error-msg'>{errors.category}</p>}
        </>

        <Select
          className='basic-single'
          classNamePrefix='select'
          isClearable={true}
          placeholder={'Select Related Topics'}
          isSearchable={true}
          isMulti={true}
          name='relatedTopics'
          value={relatedTopics}
          options={getRelatedTopics()}
          onChange={(e: React.ChangeEvent<HTMLInputElement> | any) => setRelatedTopics(e)}
        />
      </div>

      <FormControlLabel
        control={
          <Checkbox
            name='isPublished'
            checked={published}
            onChange={(e: React.ChangeEvent<HTMLInputElement> | any) => setPublished(e.target.checked)}
          />
        }
        label='I Want To Publish This Quick Peek!'
      />
      <Editor
        value={text}
        init={{ ...tinymceInitSettings }}
        onEditorChange={(text: string) => setText(text)}
      />

      {tier.value != 1 &&
        <div className='intro-help-section'>
          <p><b>Intro for this Quick peek</b></p>
          <Editor
            value={intro}
            init={{ ...tinymceInitSettings, height: '300px' }}
            onEditorChange={(text: string) => setIntro(text)}
          />

        </div>
      }

      <div className='intro-help-section'>
        <p><b>Help from a person details</b></p>
        <Editor
          value={helpFromAPerson}
          init={{ ...tinymceInitSettings, height: '300px' }}
          onEditorChange={(text: string) => setHelpFromAPerson(text)}
        />
      </div>

      <div className='cta-section'>
        <p><b>CTA Details</b></p>
        <TextField
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setCtaHeading(e.target.value)}
          required
          margin="dense"
          label='CTA Heading'
          variant='outlined'
          value={ctaHeading}
        />
        <Editor
          value={ctaText}
          init={{ ...tinymceInitSettings, height: '300px' }}
          onEditorChange={(text: string) => setCtaText(text)}
        />

        <TextField
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setButtonText(e.target.value)}
          required
          margin="dense"
          label='Button Text'
          variant='outlined'
          value={buttonText}
        />
        <TextField
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setCtaLink(e.target.value)}
          required
          margin="dense"
          label='CTA Link'
          variant='outlined'
          value={ctaLink}
        />
      </div>

      <ImageSelector
        allImages={quickPeekImages}
        displayText={'Quick Peek Image'}
        imageAltText={imageAltText}
        imageId={quickPeekCard?.image_id}
        image={quickPeekImage}
        setAltText={setAltText}
        setURLImage={setQuickPeekImage}
        setImage={setImage}
      />
      <div className={`button-div ${cantSendRequest() || isLoading ? 'is-disabled' : ''}`}>
        <LoadingButton
          disabled={cantSendRequest()}
          loading={isLoading}
          onClick={handleCreate}
          variant='contained'
        >
          {quickPeekCard?.title ? 'Update Quick Peek' : 'Create Quick Peek'}
        </LoadingButton>
      </div>
    </div>
  )
}

export default QuickPeekEditor
export type { QuickPeekEditorProps, SelectOptions, ImageProps }
