import React, { memo, useContext, useEffect, useState } from 'react'

import OutlinedButton from '../OutlinedButton'
import PurpleButton from '../PurpleButton'
import { addThousandsSeparator, scrollToTop, splitAndCapitalize } from '../../../Helper'
import { type StepProps } from '../../../EmployeeCalculatorInterface'
import { type FormValues } from '../../../FormValuesInterfaces'
import { DURATION_MULTIPLIER, PARSLEY_EMAIL, WEEKS_IN_YEAR } from '../../../Constants'

import './styles.sass'

const ConfirmationJobInfo: React.FC<StepProps> = memo((props) => {
  const { formValuesContext, setValues, handleNext } = props
  const formValues: FormValues = useContext(formValuesContext)
  const [isIncorrectInfo, setIncorrectInfo] = useState(false)
  const [annualPay, setAnnualPay] = useState(0)
  const [hourlyPay, setHourlyPay] = useState(0)
  const [pay, setPay] = useState(0)

  const handleIncorrectButtonClick = (): void => { setIncorrectInfo(true) }

  const handleConfirmButtonClick = (): void => {
    handleNext()

    const formValuesUpdated = {
      ...formValues,
      currentWage: {
        0: {
          0: pay
        }
      },
      hourlyPay,
      currentPayscale: {
        0: {
          0: formValues?.adults?.[0]?.employee?.pay_frequency as string
        }
      },
      currentWorkweek: {
        0: {
          0: formValues?.adults?.[0]?.employee?.scheduled_hours as number
        }
      },
      overtimeAvailable: {
        0: {
          0: formValues?.adults?.[0]?.employee?.ot_available ? 'yes' : 'no'
        }
      },
      tipsAvailable: {
        0: {
          0: formValues?.adults?.[0]?.employee?.tips_available ? 'yes' : 'no'
        }
      },
      adults: {
        ...formValues?.adults,
        0: {
          ...formValues?.adults?.[0],
          currentJobs: [
            {
              ...formValues?.adults?.[0].currentJobs?.[0],
              currentWage: pay,
              currentPayscale: formValues?.adults?.[0]?.employee?.pay_frequency,
              currentWorkweek: formValues?.isInitialRender ? formValues?.adults?.[0]?.employee?.scheduled_hours : formValues?.adults[0]?.currentJobs?.[0]?.currentWorkweek,
              overtimeAvailable: formValues?.adults?.[0]?.employee?.ot_available ? 'yes' : 'no',
              tipsAvailable: formValues?.adults?.[0]?.employee?.tips_available ? 'yes' : 'no'
            }
          ],
          payFrequency: formValues?.adults?.[0]?.employee?.pay_frequency
        }
      }
    }

    setValues(formValuesUpdated)
  }

  const calculateAnnualPay = (wage: number, wagePeriod: string, weekhours: number): number => {
    switch (wagePeriod) {
      case 'year':
        return wage
      case 'hour':
        return wage * WEEKS_IN_YEAR * weekhours
      default:
        return 0
    }
  }

  const calculateHourlyPay = (wage: number, wagePeriod: string, weekhours: number): number => {
    switch (wagePeriod) {
      case 'year':
        return wage / (WEEKS_IN_YEAR * weekhours)
      case 'hour':
        return wage
      default:
        return 0
    }
  }

  const calculatePayBasedOnMultiplier = (annualPay: number, multiplier: number | undefined): number => {
    return multiplier !== undefined ? annualPay / multiplier : 0
  }

  const wageRateConversion = (): void => {
    const wagePeriod = formValues.adults[0].employee?.wage_period
    const payFrequency = formValues?.adults[0]?.employee?.pay_frequency
    const multiplier = DURATION_MULTIPLIER[payFrequency as string]
    const weekhours = formValues?.adults[0]?.employee?.scheduled_hours ?? 0
    const wage = formValues?.isInitialRender
      ? formValues?.adults[0]?.employee?.wage
      : formValues?.adults[0]?.employee?.scheduled_hours !== formValues?.adults[0]?.currentJobs?.[0]?.currentWorkweek
        ? formValues?.adults[0]?.currentJobs?.[0]?.currentAnnualWage
        : formValues?.adults[0]?.employee?.wage ?? 0

    const calculatedAnnualPay = calculateAnnualPay(wage ?? 0, wagePeriod as string, weekhours)
    const calculatedHourlyPay = calculateHourlyPay(wage ?? 0, wagePeriod as string, weekhours)
    const calculatedPay = calculatePayBasedOnMultiplier(calculatedAnnualPay, multiplier)

    setAnnualPay(parseFloat(calculatedAnnualPay.toFixed(2)))
    setHourlyPay(parseFloat(calculatedHourlyPay.toFixed(2)))
    setPay(parseFloat(calculatedPay.toFixed(2)) || 0)
  }

  const payFrequency = formValues?.adults[0]?.employee?.pay_frequency ?? ''
  const formattedPay = `$${addThousandsSeparator(pay.toString())}`

  useEffect(() => {
    wageRateConversion()
  }, [annualPay, hourlyPay, formValues?.adults[0]?.employee?.wage_period, formValues?.adults[0]?.employee?.pay_frequency])

  scrollToTop()

  return (
    <div className='landing-page'>
      <div className='page-header'>
        <b>Welcome to the {splitAndCapitalize(formValues?.adults[0]?.employee?.company_name as string)}<br /><br />Please review and confirm your job details</b>
        <p>
          Before you get started with Parsley, we&apos;d like to make sure we&apos;re prepared to show you accurate information about your role, pay, and benefits options.
        </p>
      </div>

      <div className='data-modal'>
        <p>Role</p>
        <b>{formValues?.adults[0]?.employee?.job_title}</b>
        <p>Annual Pay</p>
        <b>
          ${(addThousandsSeparator(annualPay.toString()))}
          <span>
            {payFrequency === 'biweekly' || payFrequency === 'bimonthly'
              ? `(${formattedPay} ${payFrequency})`
              : `(${formattedPay} per ${payFrequency})`}
          </span>
        </b>
        <p>Hourly Pay</p>
        <b>${hourlyPay}</b>
      </div>

      {isIncorrectInfo &&
        <h1>Looks like we got something wrong. Please contact <a href='mailto:{PARSLEY_EMAIL}'>{PARSLEY_EMAIL}</a>.</h1>
      }

      <div className='landing-button'>
        <PurpleButton label='Confirm' width='50%' buttonClick={handleConfirmButtonClick} />
        <OutlinedButton label='Incorrect' width='50%' btnClick={handleIncorrectButtonClick} />
      </div>
    </div>
  )
})

ConfirmationJobInfo.displayName = 'ConfirmationJobInfo'

export default ConfirmationJobInfo
