import React, { useEffect, useState } from 'react'
import I18n from '../config/I18n'
import GrossHouseholdIncomeDisplay from './GrossHouseholdIncomeDisplay'
import BenefitsOutcomeCardCollection from './BenefitsOutcomeCardCollection'
import ButtonMultipleChoiceInput from './ButtonMultipleChoiceInput'
import { BenefitsOutcomeCardProps } from './BenefitsOutcomeCard'
import IncomeOverview from './IncomeOverview'
import { currencyFormatter, incrementFromType, valueForIncrement } from '../Helper'
import '../component_styles/SnapshotShow.sass'

interface SnapshotShowProps {
  grossHouseholdYearly: number
  incrementType: 'yearly'|'monthly'|'weekly'
  incomeYearly: number
  expensesYearly: number
  outcomeCards: BenefitsOutcomeCardProps[]
  name: string
  date: string
}

const SnapshotShow: React.FC<SnapshotShowProps> = (props) => {
  const { grossHouseholdYearly, outcomeCards, incomeYearly, expensesYearly } = props
  const [incrementType, setIncrementType] = useState<'yearly'|'monthly'|'weekly'>(props.incrementType)
  const [increment, setIncrement] = useState<'yr'|'mo'|'wk'>('yr')
  const [grossHouseholdValue, setGrossHouseholdValue] = useState(grossHouseholdYearly)
  const [incomeValue, setIncomeValue] = useState(incomeYearly)
  const [expensesValue, setExpensesValue] = useState(expensesYearly)
  const [surplusValue, setSurplusValue] = useState(0)
  useEffect(() => {
    setIncrement(incrementFromType(incrementType))
    setGrossHouseholdValue(valueForIncrement(incrementType, grossHouseholdYearly))
    setIncomeValue(valueForIncrement(incrementType, incomeYearly))
    setExpensesValue(valueForIncrement(incrementType, expensesYearly))
  }, [incrementType, grossHouseholdYearly, incomeYearly, expensesYearly])

  useEffect(() => {
    setSurplusValue(incomeValue - expensesValue)
  }, [incomeValue, expensesValue])

  const options = [
    {
      text: "Weekly",
      value: "weekly",
      id: "weekly",
      name: "increment_type"
    }, {
      text: "Monthly",
      value: "monthly",
      id: "monthly",
      name: "increment_type"
    }, {
      text: "Annual",
      value: "yearly",
      id: "yearly",
      name: "increment_type"
    }
  ]
  const onInputChange = (ev: any) => {
    setIncrementType(ev.target.value)
    return true
  }
  return (
    <div className="snapshot-show">
      <h2 className="no-margin-bottom" >{props.name}</h2>
      <p className="date-created">{ I18n.t('snapshot.created_on', { date: props.date }) }</p>

      <GrossHouseholdIncomeDisplay
        amount={ grossHouseholdValue }
        increment={ increment }
      />
      <hr className="orange-hr nudge-down-25" />
      <BenefitsOutcomeCardCollection
        cards={ outcomeCards }
        incrementType={ incrementType }
      />
      <div className="finance-container">
        <div className="center">{ I18n.t('snapshot.estimated_net_income') }</div>
        <div className="net-income">{ currencyFormatter.format(surplusValue) }</div>
        <ButtonMultipleChoiceInput
          name="incomeType"
          label=""
          disabled={ false }
          options={ options }
          onInputChange={ onInputChange }
          selected={ "yearly" }
        />
        <div className="finance-description">{ I18n.t('snapshot.financial_description') }</div>
        <h3>{ I18n.t('snapshot.financial_overview') }</h3>
        <IncomeOverview
          incomeAfterTaxesYearly={ incomeYearly }
          expensesYearly={ expensesYearly }
          incrementType={ incrementType }
        />
      </div>
    </div>
  )
}

export default SnapshotShow
export type { SnapshotShowProps }
