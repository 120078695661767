import React from 'react'
import '../component_styles/Link.sass'

interface LinkProps {
  text?: string
  href?: string
  method?: string
  className?: string
  confirmMessage?: string
  onClick?: (ev:any) => void
  children?: JSX.Element
}

const Link: React.FC<LinkProps> = (props) => {
  const urlMethod = props.method ? props.method : "get"

  function determineLinkClassNames() {
    const classes = ["link"]

    if (props.className) {
      classes.push(props.className)
    }

    return classes.join(" ")
  }

  return (
    <a href={ props.href } onClick={ props.onClick } data-method={ urlMethod } data-confirm={ props.confirmMessage } className={ determineLinkClassNames() }>
      { props.text }
      { props.children }
    </a>
  )
}

export default Link
export type { LinkProps }
