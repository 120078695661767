import React, { memo } from 'react'
import Modal from '@mui/material/Modal'
import { Button } from '@material-ui/core'

import './styles.sass'

interface PinCodeInfoProps {
  isOpen: boolean
  setIsOpen: (value: boolean) => void
  incorrectPinMessage: boolean
}

const PinCodeInfoModal: React.FC<PinCodeInfoProps> = memo((props) => {
  const { isOpen, setIsOpen, incorrectPinMessage } = props

  const handleButtonClick = (): void => { setIsOpen(false) }

  return (
    <Modal open={isOpen} className='pin-code-modal-background'>
      <div className='pin-code-modal-box'>
        {incorrectPinMessage &&
          <p>We cannot log you in without the correct pin number.</p>}
        <p>Contact <a href='#'>support@yourparsley.com</a> to access your account.</p>

        <div className='pin-code-modal-button'>
          <Button variant='outlined' className='pin-code-outlined-button' onClick={handleButtonClick}> Close </Button>
        </div>
      </div>
    </Modal>
  )
})

export default PinCodeInfoModal
