import React, { memo, useMemo } from 'react'
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow
} from '@material-ui/core'

import {
  MonthYearToggleButton,
  RenderDefaultRow,
  RenderRow,
  RenderRowWithHeading,
  RenderTableHead,
  SectionVisibilityType
} from '../../../interfaces'
import { CTCPrograms, Program, Result, TaxCredits } from '../../../OutputInterfaces'

interface Props {
  result: Result
  monthYearToggleButton: MonthYearToggleButton
  renderRow: RenderRow
  renderDefaultRow: RenderDefaultRow
  toggleTableBody: (value: string) => void
  tableVisibility: SectionVisibilityType
  renderRowsWithHeading: RenderRowWithHeading
  renderTableHead: RenderTableHead
  sectionsVisibility: SectionVisibilityType
}

const TotalAnnualTaxCreditsSection: React.FC<Props> = memo((props) => {
  const {
    result,
    monthYearToggleButton,
    renderDefaultRow,
    tableVisibility,
    renderRowsWithHeading,
    renderTableHead,
    sectionsVisibility
  } = props

  const renderTaxCreditsPrograms = useMemo(() => (tax: CTCPrograms, key: number) => {
    if (tax.ctc_inner_programs) {
      return tax.ctc_inner_programs.programs.map(
        (innerPrograms: Program, key: number) =>
          tableVisibility.childTaxCredit &&
          renderDefaultRow('totalAnnualTaxCreditCalculations', innerPrograms, key, '50px')
      )
    } else {
      return renderRowsWithHeading('childTaxCredit', 'totalAnnualTaxCreditCalculations', tax as Program, key)
    }
  }, [renderDefaultRow, renderRowsWithHeading ])

  const renderTaxSection = useMemo(() => (taxCreditsProgram: TaxCredits, programIndex: number) => {
    if (taxCreditsProgram.ctc_programs) {
      return taxCreditsProgram.ctc_programs.programs.map(
        (taxes: CTCPrograms , key: number) => renderTaxCreditsPrograms(taxes, key)
      )
    } else {
      return renderDefaultRow('totalAnnualTaxCreditCalculations', taxCreditsProgram as Program, programIndex)
    }
  },[renderTaxCreditsPrograms, renderDefaultRow])

  return (
    <>
      <div className='d-flex justify-content-end'>
        {monthYearToggleButton('totalAnnualTaxCreditCalculations')}
      </div>

      <TableContainer component={Paper}>
        <Table>
          {renderTableHead(
            'sectionTotalAnnualTaxCredits',
            'totalAnnualTaxCreditCalculations',
            'Total Annual Tax Refunds',
            result?.output?.tax_credits?.programs_sum
          )}

          {sectionsVisibility['sectionTotalAnnualTaxCredits'] && (
            <TableBody>
              <TableRow>
                <TableCell colSpan={2}>
                  Most tax credits are issued annually after you file your taxes. There are a few exceptions which we will note if they apply to you.
                  Premium Tax Credits for Marketplace Health Insurance are deducted from your premium cost so not applied here.
                </TableCell>
              </TableRow>

              {result?.output?.tax_credits?.programs.map(
                (taxCredits: TaxCredits, programIndex: number) =>
                  renderTaxSection(taxCredits, programIndex)
              )}
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </>
  )
})

export default TotalAnnualTaxCreditsSection
