import React, { useContext, useState, useEffect, forwardRef, useImperativeHandle, type ChangeEvent } from 'react'
import Select from 'react-select'
import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core'

import { type FormValues } from '../../../FormValuesInterfaces'
import BeforeEstimatedTax from '../BeforeAfterEstimatedTax'
import ColorDivider from '../ColorDivider'
import NumberInput from '../NumberInput'
import PurpleButton from '../PurpleButton'
import { convertAndRound, scrollToTop } from '../../../Helper'
import { calculatePay } from '../../../api/employeeTaxes'
import {
  AVERAGE_WORK_HOURS_PER_WEEK,
  DURATION_MULTIPLIER,
  TIPS_DURATION_OPTIONS,
  WEEKS_IN_YEAR
} from '../../../Constants'

import './styles.sass'

interface EarningsProps {
  formValuesContext: React.Context<FormValues>
  setValues: React.Dispatch<FormValues>
  handleNext: () => void
  handleBack: () => void
  isBeforeTaxes: boolean
  setIsBeforeTaxes: (value: boolean) => void
  setIsChanged: (value: boolean) => void
}

const Earnings = forwardRef((props: EarningsProps, ref) => {
  const {
    formValuesContext,
    setValues,
    handleNext,
    handleBack,
    isBeforeTaxes,
    setIsBeforeTaxes,
    setIsChanged
  } = props
  const formValues: FormValues = useContext(formValuesContext)
  const [hours, setHours] = useState(formValues?.adults[0]?.currentJobs?.[0]?.currentWorkweek)
  const [tip, setTip] = useState(formValues?.adults[0]?.currentJobs?.[0]?.tipsAmount)
  const [tipType, setTipType] = useState(formValues?.adults[0]?.currentJobs?.[0]?.tipsPayscale)
  const [otAvailable, setOtAvailable] = useState(false)
  const [earningType, setEarningType] = useState(formValues?.adults[0]?.employee?.wage_period)
  const hoursRange = useState(formValues?.adults[0]?.employee?.hours_range)
  const otRange = useState(formValues?.adults[0]?.employee?.ot_hours_range)
  const employeeChange = useState(formValues?.adults[0]?.employee?.employee_change)
  const [beforeTaxValue, setBeforeTaxValue] = useState(formValues?.adults[0]?.beforeTaxAmount)
  const [currentAnnualWage, setCurrentAnnualWage] = useState(formValues?.adults[0]?.currentJobs?.[0]?.currentAnnualWage ?? 0)
  const [hoursError, setHoursError] = useState('')
  const [otError, setOTError] = useState('')
  const [error, setError] = useState(false)
  const [pay, setPay] = useState(0)
  const wagesOTType = 'week'
  const [wagesOTWorkweek, setWagesOTWorkweek] = useState(formValues?.adults[0]?.currentJobs?.[0]?.overtimeWorkweek)

  const calculatePayBasedOnMultiplier = (annualPay: number, multiplier: number | undefined): number => {
    return multiplier !== undefined ? annualPay / multiplier : 0
  }

  useEffect(() => {
    if (formValues?.isInitialRender) {
      const initialHours = formValues?.adults[0]?.employee?.scheduled_hours.toString() ?? '0'
      setHours(Number(initialHours))
    } else {
      setHours(formValues?.adults[0]?.currentJobs?.[0]?.currentWorkweek as number)
    }
  }, [formValues])

  useEffect(() => {
    const hourlyPay = formValues?.hourlyPay
    const newCurrentAnnualWage = hourlyPay * WEEKS_IN_YEAR * Number(hours)
    setCurrentAnnualWage(newCurrentAnnualWage)
    setPay(calculatePayBasedOnMultiplier(newCurrentAnnualWage, DURATION_MULTIPLIER[formValues?.adults[0]?.employee?.pay_frequency as string]))
  }, [hours, formValues?.hourlyPay, formValues?.adults[0]?.employee?.pay_frequency])

  const getData = async (formValues: FormValues): Promise<void> => {
    const response = await calculatePay(formValues)

    setBeforeTaxValue(convertAndRound(response?.before_estimated_tax as number))

    const formValuesUpdated = {
      ...formValues,
      isInitialRender: false,
      adults: {
        ...formValues.adults,
        0: {
          ...formValues.adults[0],
          beforeTaxAmount: convertAndRound(response?.before_estimated_tax as number),
          currentJobs: [
            {
              ...formValues?.adults?.[0].currentJobs?.[0],
              currentWage: pay
            }
          ]
        }
      }
    }

    setValues(formValuesUpdated)
    setIsBeforeTaxes(!isBeforeTaxes)
  }

  useEffect(() => {
    setOtAvailable(Number(hours) === AVERAGE_WORK_HOURS_PER_WEEK)
    setError(hoursError !== '' || otError !== '')
  }, [hours, wagesOTWorkweek, tip, tipType])

  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setEarningType(event.target.value)
  }

  useImperativeHandle(ref, () => ({
    handleButtonClick() {
      const formValuesUpdated = {
        ...formValues,
        currentWorkweek: {
          0: {
            0: hours
          }
        },
        currentAnnualWage: {
          0: {
            0: currentAnnualWage
          }
        },
        overtimeAvailable: {
          0: {
            0: formValues?.adults?.[0]?.employee?.ot_available ? 'yes' : 'no'
          }
        },
        overtimeWage: {
          0: {
            0: 0
          }
        },
        overtimePayscale: {
          0: {
            0: wagesOTType
          }
        },
        overtimeWorkweek: {
          0: {
            0: wagesOTWorkweek
          }
        },
        tipsAvailable: {
          0: {
            0: formValues?.adults?.[0]?.employee?.tips_available ? 'yes' : 'no'
          }
        },
        tipsAmount: {
          0: {
            0: tip
          }
        },
        tipsPayscale: {
          0: {
            0: tipType
          }
        },
        adults: {
          ...formValues?.adults,
          0: {
            ...formValues?.adults?.[0],
            jobs: 1,
            currentJobs: [
              {
                ...formValues?.adults?.[0]?.currentJobs?.[0],
                currentWorkweek: hours,
                currentAnnualWage,
                overtimePayscale: wagesOTType,
                overtimeWorkweek: wagesOTWorkweek,
                tipsAmount: tip,
                tipsPayscale: tipType,
                currentWage: pay
              }
            ]
          }
        },
        isInitialRender: false
      }

      setValues(formValuesUpdated as FormValues)
    }
  }))

  const handleButtonClick = (): void => {
    const formValuesUpdated = {
      ...formValues,
      currentWorkweek: {
        0: {
          0: Number(hours)
        }
      },
      tipsAvailable: {
        0: {
          0: formValues?.adults?.[0]?.employee?.tips_available ? 'yes' : 'no'
        }
      },
      tipsAmount: {
        0: {
          0: tip as number
        }
      },
      tipsPayscale: {
        0: {
          0: tipType as string
        }
      },
      adults: {
        ...formValues?.adults,
        0: {
          ...formValues?.adults?.[0],
          jobs: 1,
          currentJobs: [
            {
              ...formValues?.adults?.[0]?.currentJobs?.[0],
              currentWorkweek: hours as number,
              currentAnnualWage,
              overtimeWage: 0,
              overtimePayscale: wagesOTType,
              overtimeWorkweek: wagesOTWorkweek as number,
              tipsAmount: tip as number,
              tipsPayscale: tipType as string,
              currentWage: pay
            }
          ]
        }
      }
    }

    setValues(formValuesUpdated as FormValues)
    void getData(formValuesUpdated)
  }

  const getSelectdValue = (
    value: string,
    options: Array<{ label: string, value: string }>
  ): { label: string, value: string } | undefined => {
    return options.find((option) => option.value === value)
  }

  const renderHoursAndOTInput = (
    heading: string,
    valueAvailable: boolean,
    value: string,
    canEmployeeChange: boolean,
    setValue: (value: string) => void
  ): React.ReactNode => {
    if (valueAvailable) {
      return (
        <>
          <b>{heading}?</b>
          <div className='hours-input'>
            <NumberInput
              value={value}
              label='Enter Hours'
              setValue={setValue}
              disabled={!canEmployeeChange}
            />

            <Select
              classNamePrefix='select'
              placeholder='Week'
              isSearchable={false}
              menuPlacement='top'
            />
          </div>
        </>
      )
    }
    return null
  }

  const validateHours = (value: string, starting: number, ending: number): string => {
    const numericValue = parseFloat(value)

    if (numericValue < starting || numericValue > ending) {
      setError(true)
      return `Value must be between ${starting} and ${ending}.`
    }

    return ''
  }

  const handleHoursChange = (value: string): void => {
    const error = validateHours(value, hoursRange[0]?.[0] as number, hoursRange[0]?.[1] as number)
    setHours(Number(value))
    setHoursError(error)
    setIsChanged(true)
  }

  const handleOTChange = (value: string): void => {
    const error = validateHours(value, 1, otRange[0]?.[1] as number - (otRange[0]?.[0] as number) + 1)
    setWagesOTWorkweek(Number(value))
    setOTError(error)
    setIsChanged(true)
  }

  scrollToTop()

  return (
    <div className='earning-page-container'>
      <div className='page-header'>
        <b>Let&apos;s get started</b>
        <p>
          First, let&apos;s calculate your take-home pay so you have an idea of what to expect each pay-period.
        </p>
      </div>

      <ColorDivider />

      <div className='page-body'>
        <b>Earnings type</b>

        <RadioGroup className='custom-radio-group' value={earningType} onChange={handleChange}>
          <div className='radio-button'>
            <FormControlLabel
              value='hour'
              control={<Radio />}
              label='Hourly'
              disabled={earningType === 'year'}
            />
          </div>

          <div className='radio-button'>
            <FormControlLabel
              value='year'
              control={<Radio />}
              label='Salary'
              disabled={earningType === 'hour'}
            />
          </div>
        </RadioGroup>

        <div className='earning-info-container'>
          <div className='earning-info-data'>
            {renderHoursAndOTInput(
              'How many hours per week',
              true,
              String(hours),
              employeeChange[0] as boolean,
              handleHoursChange
            )}

            {hoursError !== '' && <div className='error-text'>{hoursError}</div>}

            {formValues?.adults[0]?.currentJobs?.[0]?.tipsAvailable === 'yes' && (
              <>
                <b>Wages earned as tips:</b>
                <div className='hours-input'>
                  <NumberInput
                    value={String(tip)}
                    setValue={(value: string) => {
                      setTip(Number(value))
                      setIsChanged(true)
                    }}
                    label='$'
                  />
                  <Select
                    classNamePrefix='select'
                    placeholder='Duration'
                    onChange={(e) => {
                      setTipType(e?.value ?? '')
                      setIsChanged(true)
                    }}
                    isSearchable={false}
                    options={TIPS_DURATION_OPTIONS}
                    value={
                      tipType
                        ? getSelectdValue(tipType, TIPS_DURATION_OPTIONS)
                        : TIPS_DURATION_OPTIONS[0]
                    }
                  />
                </div>
              </>
            )}

            {renderHoursAndOTInput(
              'How many overtime hours per week',
              formValues?.adults[0]?.currentJobs?.[0]?.overtimeAvailable === 'yes',
              String(wagesOTWorkweek),
              otAvailable,
              handleOTChange
            )}

            {otError !== '' && <div className='error-text'>{otError}</div>}
          </div>
        </div>

        <div className='confirm-button'>
          <PurpleButton
            label='Continue'
            width='100%'
            buttonClick={handleButtonClick}
            disabled={error}
          />
          <BeforeEstimatedTax
            handleNext={handleNext}
            handleBack={handleBack}
            isOpen={isBeforeTaxes}
            setIsOpen={setIsBeforeTaxes}
            position='Before'
            value={beforeTaxValue as number}
          />
        </div>
      </div>
    </div>
  )
})

Earnings.displayName = 'Earnings'

export default Earnings
