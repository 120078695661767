import React, { memo } from 'react'
import Cookies from 'js-cookie'
import Modal from '@mui/material/Modal'
import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'
import { Button } from '@material-ui/core'

import { NPS_SURVEY_COOKIES_EXPIRAY_DAYS } from '../../../Constants'
import congratulationLogo from '../../../../assets/images/NPSSurveylogo.png'

import './styles.sass'

interface NPSModalProps {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  npsRating: number
  setCookieCreated30DaysAgo?: (isOpen: boolean) => void
}

const SurveyCompletion: React.FC<NPSModalProps> = memo((props) => {
  const { isOpen, setIsOpen, npsRating, setCookieCreated30DaysAgo } = props

  const handleClose = (): void => { setIsOpen(false) }

  const handleOKButtonClick = (): void => {
    Cookies.set('parsleyNPSFeedback', npsRating.toString(), { expires: NPS_SURVEY_COOKIES_EXPIRAY_DAYS })
    if (setCookieCreated30DaysAgo) {
      setCookieCreated30DaysAgo(false)
    }
    handleClose()
  }

  return (
    <Modal open={isOpen} className='survey-modal-background'>
      <div className='survey-modal-box'>
        <div className='survey-modal-close-button'>
          <IconButton disableRipple onClick={handleClose}>
            <CloseIcon fontSize='large' />
          </IconButton>
        </div>

        <img src={congratulationLogo} alt='Congratulation B2B Logo' className='logo-image' />
        <h1>Thank you!</h1>
        <b>We’re on a mission to make the best financial calculator ever. Your feedback is truly appreciated!</b>

        <div className='survey-modal-button'>
          <Button variant='contained' className='purple-button' onClick={handleOKButtonClick}> Close </Button>
        </div>
      </div>
    </Modal>
  )
})

export default SurveyCompletion
