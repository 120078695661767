import React, { type FC, useState, useEffect } from 'react'
import { Checkbox, FormControlLabel } from '@material-ui/core/'
import Select from 'react-select'

import { convertObjectToArray, getSelectdValue, haveChildUnderOne } from '../../../../Helper'
import { AGES_IN_MONTHS } from '../../../../Constants'
import './styles.sass'

const _ = require('lodash')

interface WICProps {
  contextValues: any
  setContextValues: Function
}

const WIC: FC<WICProps> = ({ contextValues, setContextValues }) => {
  const [breastfeeding, setBreastfeeding] = useState(contextValues?.breastfeeding ?? false)
  const [children, setChildren] = useState(contextValues?.children)

  useEffect(() => {
    handleSave()
  }, [breastfeeding])

  const handleSave = () => {
    setContextValues({
      ...contextValues,
      breastfeeding: breastfeeding
    })
  }

  const handleChangeInAge = (value: number, index: number) => {
    setContextValues(_.set(contextValues, `children[${index}].ageInMonths`, value))
    setChildren({ ...children, [index]: { ...children[index], ageInMonths: value } })
  }

  const resetAgeInMonths = (index: number) => {
    if (children[index]?.hasOwnProperty('ageInMonths')) {
      const newChildren = { ...children }
      delete newChildren[index].ageInMonths
      setChildren(newChildren)
      setContextValues(_.unset(contextValues, `children[${index}].ageInMonths`))
    }
  }

  const renderChildAgeInMonths = (index: number) => {
    const isChildAgeZero = children[index]?.age == 0

    return (
      <div key={index}>
        {!isChildAgeZero && resetAgeInMonths(index)}
        {
          <div className='wic-input'>
            <p className={`question-heading ${!isChildAgeZero && 'disabled-text'}`}>{children[index]?.name ?? `Child ${index + 1}`}'s age in months:</p>
            <Select
              className='select-age-in-months'
              id={`ageInMonths-${index}`}
              isDisabled={!isChildAgeZero}
              onChange={(event: any) => handleChangeInAge(event.value, index)}
              options={AGES_IN_MONTHS}
              value={getSelectdValue(children[index]?.ageInMonths, AGES_IN_MONTHS)}
            />
          </div>
        }
      </div>
    )
  }

  return (
    <div className='woman-infant-care'>
      {
        <FormControlLabel
          control={<Checkbox checked={breastfeeding ?? false} />}
          disabled={!haveChildUnderOne(contextValues?.children)}
          label={'To help better estimate WIC benefits, please check if anyone in your household is breastfeeding infant children: '}
          labelPlacement='start'
          onChange={(e: any) => setBreastfeeding(e.target.checked)}
        />
      }

      {convertObjectToArray(contextValues.childrenCount).map((index: number) => renderChildAgeInMonths(index))}
    </div>
  )
}

export default WIC
