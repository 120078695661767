import type { Context, Dispatch, SetStateAction } from 'react'

import { type FormValues } from './FormValuesInterfaces'

export interface employeeValues {
  id: number
  wage: number
  wage_period: string
  benefits_available: string[]
  pay_frequency: string
  eligible_for_all_benefits: boolean
  tips_available: boolean
  ot_available: boolean
  employee_change: boolean
  scheduled_hours: number
  hours_range: number[]
  ot_pay_rate: number
  ot_hours_range: number[]
  job_title: string
  company_name: string
}

export interface BenefitData {
  [key: string]: string | number | boolean | undefined
  name: string | undefined
  amount: number | undefined
  provider: string | undefined
  coverage: string | undefined
  contribution: number | string | undefined
  annualContributionLimit?: string | undefined
  type?: string | undefined
  isSelected: boolean | undefined
}

export interface BenefitDataCompanyType {
  providerOptions: string[]
  typeOptions: string[]
}

interface DentalBenefit {
  dentalBenefitData: BenefitData
}

interface HealthBenefit {
  healthBenefitData: BenefitData
}

interface VisionBenefit {
  visionBenefitData: BenefitData
}

interface medicalBenefit {
  medicalBenefitData: BenefitData
}

interface pensionBenefit {
  pensionBenefitData: BenefitData
}

interface TSA403bBenefit {
  TSA403bBenefitData: BenefitData
}

interface dependentCareBenefit {
  dependentCareBenefitData: BenefitData
}

export interface BenefitDataCompany {
  '403(b)': BenefitDataCompanyType
  dental: BenefitDataCompanyType
  dependent_care_flexible_spending_account: BenefitDataCompanyType
  health: BenefitDataCompanyType
  health_flexible_spending_account: BenefitDataCompanyType
  pension: BenefitDataCompanyType
  vision: BenefitDataCompanyType
}

export const dentalData: DentalBenefit = {
  dentalBenefitData: {
    name: 'dentalBenefitData',
    amount: 0,
    provider: '',
    type: '',
    coverage: 'single',
    contribution: '',
    isSelected: false
  }
}

export const healthcareData: HealthBenefit = {
  healthBenefitData: {
    name: 'healthBenefitData',
    amount: 0,
    provider: '',
    type: '',
    coverage: 'single',
    contribution: '',
    isSelected: false
  }
}

export const visionData: VisionBenefit = {
  visionBenefitData: {
    name: 'visionBenefitData',
    amount: 0,
    provider: '',
    coverage: 'single',
    contribution: '',
    isSelected: false
  }
}

export const medicalData: medicalBenefit = {
  medicalBenefitData: {
    name: 'medicalBenefitData',
    amount: 0,
    provider: '',
    coverage: '',
    contribution: '',
    annualContributionLimit: '',
    isSelected: false
  }
}

export const dependentCareData: dependentCareBenefit = {
  dependentCareBenefitData: {
    name: 'dependentCareBenefitData',
    amount: 0,
    provider: '',
    coverage: '',
    contribution: '',
    annualContributionLimit: '',
    isSelected: false
  }
}

export const pensionData: pensionBenefit = {
  pensionBenefitData: {
    name: 'pensionBenefitData',
    amount: 0,
    provider: '',
    coverage: '',
    contribution: '',
    annualContributionLimit: '',
    isSelected: false
  }
}

export const TSA403bData: TSA403bBenefit = {
  TSA403bBenefitData: {
    name: 'TSA403bBenefitData',
    amount: 0,
    provider: '',
    coverage: '',
    contribution: '',
    annualContributionLimit: '',
    isSelected: false
  }
}

export interface AdminStepProps {
  adminValuesContext: Context<FormValues>
  setValues: Dispatch<FormValues>
  handleItemSelection?: (name: string, id: number, description: string) => void
  selectedItem?:{ name: string; id: number } 
  handleNextClick: () => void
}

export interface StepProps {
  formValuesContext: Context<FormValues>
  setValues: Dispatch<FormValues>
  handleNext: () => void
  handleBackButton?: (() => void) | undefined
  handleBack?: () => void
  setIsChanged?: (value: boolean) => void
}

export interface BenefitProps {
  formValuesContext: Context<FormValues>
  benefitDataCompany?: BenefitDataCompany
  setValues: Dispatch<FormValues>
}

export interface FSABenefitProps {
  formValuesContext: Context<FormValues>
  benefitDataCompany?: BenefitDataCompany
  setValues: Dispatch<FormValues>
  setDisabled: Dispatch<SetStateAction<boolean>>
}

export interface BenefitDetailsProps {
  title: string
  benefitType: string
  calculateAmount: (provider: string, type: string, coverage: string) => { amount: number, contribution: string }
  formValuesContext: Context<FormValues>
  benefitDataCompany?: BenefitDataCompany
  setValues: Dispatch<FormValues>
}
