import React, { type Dispatch, type FocusEvent, type SetStateAction } from 'react'
import { type NumberFormatValues, NumericFormat } from 'react-number-format'

import '../../component_styles/BenefitsCliff.sass'

interface Props {
  value: number | string
  setValue?: any
  setIsChanged?: Dispatch<SetStateAction<boolean>>
  onChange?: (value: number | string) => void
  label?: string
  disabled?: boolean
  className?: string
  maxLimit?: number
  type?: 'text' | 'tel' | 'password'
}

const InputField = ({ value, setValue, setIsChanged, onChange, label, disabled, className, maxLimit, type }: Props): JSX.Element => {
  const handleFocus = (e: FocusEvent<HTMLInputElement>): void => {
    Number(e.target.value) === 0 && (onChange?.('') ?? setValue?.(''))
    setIsChanged?.(true)
  }

  const handleBlur = (e: FocusEvent<HTMLInputElement>): void => {
    e.target.value === '' && (onChange?.(0) ?? setValue?.(0))
    setIsChanged?.(true)
  }

  const handleChange = (values: NumberFormatValues): void => onChange?.(values.floatValue as number) ?? setValue?.(values.floatValue as number)

  const isValueAllowed = (values: NumberFormatValues): boolean => {
    const { floatValue } = values

    if (floatValue === undefined) {
      return true
    }

    const maxValue = maxLimit ?? Infinity
    return floatValue <= maxValue
  }

  return (
    <div className='inputfield'>
      <NumericFormat
        value={value}
        onValueChange={(values) => { handleChange(values) }}
        onFocus={handleFocus}
        onBlur={handleBlur}
        placeholder={label}
        disabled={disabled}
        className={className ? className + ' inputfield-input' : 'inputfield-input'}
        decimalScale={10}
        allowNegative={false}
        decimalSeparator='.'
        isAllowed={isValueAllowed}
        type={type ?? 'text'}
      />
    </div>
  )
}

export default InputField
