import React, { useState, useEffect, useRef } from 'react'
import * as d3 from 'd3'

interface BarChartProps {
  width?: number
  height?: number
  chartId: string
  rawData: Bar[]
  margin?: MarginProps
}

interface Bar {
  color: string
  amount: number
}

interface MarginProps {
  top: number
  right: number
  bottom: number
  left: number
}

const BarChart: React.FC<BarChartProps> = (props) => {
  const [originalWidth, setOriginalWidth] = useState(props.width || 800);
  const [width, setWidth] = useState(originalWidth);
  const [height, setHeight] = useState(props.height || 500);
  const [bars, setBars] = useState<Bar[]>(props.rawData);
  const parentDiv = useRef<HTMLInputElement>(null);

  const margin = props.margin || { top: 5, right: 50, bottom: 20, left: 50 };
  const graphHeight = height - (margin.top + margin.bottom);
  const transform = 'translate(' + margin.left + ',' + margin.top + ')';

  const barWidth = 30
  const barMargin = 10

  const yScale:any = d3.scaleLinear()
    .domain([0,d3.max(bars,function(d:any){
      return d.amount;
    })])
    .range([graphHeight, 0]);

  function handleResize() {
    if (parentDiv.current) {
      const parentWidth = parentDiv.current.clientWidth;
      if (parentWidth < originalWidth) {
        setWidth(parentWidth);
      } else {
        setWidth(originalWidth);
      }
    }
  }

  useEffect(() => {
    setBars(props.rawData);
  }, [props.rawData]);

  useEffect(() => {
    if (props.height) {
      setHeight(props.height);
    }
  }, [props.height]);

  useEffect(() => {
    if (props.width) {
      setOriginalWidth(props.width);
      setWidth(props.width);
    }
  }, [props.width]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize();
  });

  return (
    <div ref={ parentDiv } className="bar-chart" style={ {height: `${height}`} }>
      <svg id={props.chartId} width={width} height={height}>
        <g transform={transform}>
          { bars.map((bar: any, index: number) => {
              return (
                <rect x={index * (barWidth + barMargin)} width={barWidth} y={yScale(bar.amount)} height={graphHeight - yScale(bar.amount)} fill={bar.color} key={index}></rect>
              )
            })
          }
        </g>
      </svg>
    </div>
  )
}

export default BarChart
export type { BarChartProps }
