import React, { useState } from 'react'
import Label from './Label'
import '../component_styles/TextField.sass'

interface TextFieldProps {
  name: string
  value: string
  placeholder: string
  label?: string
  errors?: string
  isRequired: boolean
  isEncouraged?: boolean
  disabled?: boolean
  autoFocus: boolean
  autoComplete?: string
  type: string
  inputClassName?: string
  onChange?: (value: string) => void
  maxLength?: number
  minLength?: number
}

const TextField: React.FC<TextFieldProps> = (props) => {
  const { onChange, inputClassName, label, isRequired, maxLength, minLength, isEncouraged, disabled, ...options } = props
  const [value, setValue] = useState(props.value)
  const [characterCount, setCharacterCount] = useState(value ? value.length : 0)

  function handleValueChange (e: any): void {
    setValue(e.target.value)
    setCharacterCount(e.target.value.length)

    if (onChange) {
      onChange(e.target.value)
    }
  }

  let realInputClassName = inputClassName
  if (props.errors) {
    realInputClassName += ' error'
  }

  let currentCountClass = ''

  if (minLength && maxLength) {
    if (characterCount < minLength) {
      currentCountClass = 'grey'
    } else if (characterCount < maxLength) {
      currentCountClass = 'green'
    } else if (characterCount >= maxLength) {
      currentCountClass = 'red'
    }
  }

  return (
    <div className='input'>
      { label &&
        <Label
          label={ label }
          hasError={ !!props.errors }
          isRequired={ isRequired }
          isEncouraged={ isEncouraged }
          className={ props.disabled ? 'disabled' : '' }
        />
      }
      <input
        { ...options }
        value={ value }
        onChange={ handleValueChange }
        className={ realInputClassName }
        minLength={ minLength }
        maxLength={ maxLength }
        disabled={disabled}
      />
      { props.errors && characterCount < (minLength ?? 20) &&
        <div className='error-message'>{ props.errors }</div>
      }
      { maxLength && minLength && !disabled &&
        <div className='character-counter'>
          <span className={ `current-count-${currentCountClass}` }>
            { characterCount }/{ maxLength }
          </span>
        </div>
      }
    </div>
  )
}

export default TextField
export type { TextFieldProps }
