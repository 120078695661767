import React, { useState, useEffect } from 'react'
import I18n from '../config/I18n'
import BarChart from './BarChart'
import { currencyFormatter, incrementFromType, valueForIncrement } from '../Helper'
import '../component_styles/IncomeOverview.sass'

interface IncomeOverviewProps {
  incomeAfterTaxesYearly: number
  expensesYearly: number
  incrementType: 'yearly'|'monthly'|'weekly'
}

const IncomeOverview: React.FC<IncomeOverviewProps> = (props) => {
  const { incomeAfterTaxesYearly, expensesYearly, incrementType } = props
  const [increment, setIncrement] = useState('yr')
  const [incomeAfterTaxesValue, setIncomeAfterTaxesValue] = useState(incomeAfterTaxesYearly)
  const [expensesValue, setExpensesValue] = useState(expensesYearly)
  useEffect(() => {
    setIncrement(incrementFromType(incrementType))
    setIncomeAfterTaxesValue(valueForIncrement(incrementType, incomeAfterTaxesYearly))
    setExpensesValue(valueForIncrement(incrementType, expensesYearly))
  }, [incrementType])

  const total = incomeAfterTaxesValue - expensesValue

  return (
    <div className="income-overview">
      <div className="float-left">
        <h4 className="income-header">{ I18n.t('income_overview.income_after_taxes') }</h4>
        <div className="amount-increment-container income-blue">
          { currencyFormatter.format(incomeAfterTaxesValue) }
          <span className="increment">/{ increment }</span>
        </div>
        <h4 className="income-header">{ I18n.t('income_overview.out_of_pocket_expenses') }</h4>
        <div className="amount-increment-container">
          { currencyFormatter.format(expensesValue) }
          <span className="increment">/{ increment }</span>
        </div>
        { (total < 0) &&
          <>
            <h4 className="income-header">
              { I18n.t(`income_overview.${incrementType}_deficit`) }
            </h4>
            <div className="amount-increment-container">
              <i className="fas fa-arrow-circle-down red" />
              <span className="red"> { currencyFormatter.format(total) }</span>
            </div>
          </>
        }
        { (total >= 0) &&
          <>
            <h4 className="income-header">
              { I18n.t(`income_overview.${incrementType}_surplus`) }
            </h4>
            <div className="amount-increment-container">
              <i className="fas fa-arrow-circle-up green" />
              <span className="green"> { currencyFormatter.format(total) }</span>
            </div>
          </>
        }
      </div>
      <div className="income-bar-char float-right">
        <BarChart
          chartId='income-overview'
          height={ 220 }
          width={ 90 }
          margin={{
            top: 20,
            bottom: 0,
            left: 10,
            right: 10
          }}
          rawData={ [{
            color: '#2457F0', // $blue
            amount: incomeAfterTaxesValue
          }, {
            color: '#000000', // $black
            amount: expensesValue
          }] }
        />
      </div>
    </div>
  )
}

export default IncomeOverview
export type { IncomeOverviewProps }
