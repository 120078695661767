import React, { FC, useEffect } from 'react'
import { Button } from '@material-ui/core'
import CreatableSelect from 'react-select/creatable'

import { convertToOptions, getImage, validatFileSize } from '../../Helper'
import { ImageProps, SelectOptions } from '../QuickPeekEditor'

import './styles.sass'

interface ImageSelectorProps {
  allImages: ImageProps[]
  displayText: string
  imageAltText: string | number | undefined
  imageId: string | number | undefined
  image: string | number | undefined
  setAltText: Function
  setURLImage: Function
  setImage: Function
}

const ImageSelector: FC<ImageSelectorProps> = (props) => {
  const { allImages, imageId, displayText, image, setImage, imageAltText, setAltText, setURLImage } = props

  useEffect(() => {
    setAltText(getImage(allImages, imageId)?.alt_text ?? '')
  }, [])

  const handleImageChange = (event: SelectOptions | any) => {
    if (event?.__isNew__) {
      setAltText(event?.value)
      setURLImage(null)
      setImage(null)
    } else if (event == null) {
      setURLImage(null)
      setAltText(null)
    } else {
      setURLImage(event?.link)
      setAltText(event?.label)
    }
  }

  const isNull = (text: string | number | undefined) => text == null

  const handleRemoveAttachment = () => {
    setURLImage('')
    setImage('')
    setAltText(null)
  }

  const getValue = () => {
    if (imageAltText != undefined && imageAltText?.toString().length != 0)
      return ({ label: imageAltText, value: imageAltText })
    else if (isNull(imageAltText))
      return ({ label: 'Select/Create ALT Text', value: 'Select/Create ALT Text' })
    else
      return ({
        label: getImage(allImages, imageId)?.alt_text ?? 'Select/Create ALT Text',
        value: getImage(allImages, imageId)?.id ?? 'Select/Create ALT Text'
      })
  }

  return (
    <div className='image-selector'>
      <div className='attachment-div'>
        <div className='show-attachment'>
          {!image && displayText}
          {image &&
            <img
              src={image.toString()}
              alt={getImage(allImages, imageId)?.alt_text}
              width='100'
              height='100'
            />
          }
        </div>
        <div className='buttons-div'>
          <div className={`${isNull(imageAltText) || imageAltText == '' ? 'is-disabled' : ''}`}>
            {!image &&
              <label>Browse File
                <input
                  id={`file-upload-${1}`}
                  hidden
                  type='file'
                  name='file'
                  onChange={(e: React.ChangeEvent<HTMLInputElement> | any) => {
                    if (validatFileSize(e)) {
                      setURLImage(URL.createObjectURL(e.target.files[0]));
                      setImage(e.target.files[0])
                    }
                  }}
                />
              </label>}
          </div>
          <div className={`${isNull(imageAltText) ? 'is-disabled' : ''}`}>
            {image && (
              <Button
                disabled={isNull(imageAltText)}
                variant='contained'
                onClick={handleRemoveAttachment}
              >
                Remove Attachment
              </Button>
            )}
          </div>
        </div>
        <CreatableSelect
          className='createable-select'
          classNamePrefix='select'
          isClearable
          placeholder={'Select Image'}
          value={getValue()}
          onChange={handleImageChange}
          options={convertToOptions(allImages)}
        />
      </div>
      {imageAltText && imageAltText?.toString().length > 0 && !image &&
        < p className='error-message'>Upload File or Select from Alt Text Dropdown</p>
      }
    </div >
  )
}

export default ImageSelector
export type { ImageSelectorProps }
