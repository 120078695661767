import React, { useEffect, useRef } from 'react'
import * as d3 from 'd3'

interface GridProps {
  gridHeight: number
  grid: any
  gridType: string
}

const Grid: React.FC<GridProps> = (props) => {
  const ref = useRef(null);
  const translate = "translate(0,"+(props.gridHeight)+")";

  useEffect(() => {
    if (props.grid) {
      d3.select(ref.current).call(props.grid);
    }
  }, [props.grid]);

  return (
    <g ref={ ref }
       className="grid"
       transform={ props.gridType=='x' ? translate : "" }>
    </g>
  )
}

export default Grid
export type { GridProps }
