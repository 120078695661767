import { useState, useEffect } from 'react'

export default function GlobalState(initialValue) {
    this.value = initialValue
    this.subscribers = []

    this.getValue = function () {
        return this.value
    }

    this.setValue = function (newState) {
        if (this.getValue() === newState) {
            return
        }

        this.value = newState
        this.subscribers.forEach(subscriber => {
            subscriber(this.value)
        })
    }

    this.subscribe = function (itemToSubscribe) {
        if (this.subscribers.indexOf(itemToSubscribe) > -1) {
            return
        }
        this.subscribers.push(itemToSubscribe)
    }

    this.unsubscribe = function (itemToUnsubscribe) {
        this.subscribers = this.subscribers.filter(
            subscriber => subscriber !== itemToUnsubscribe
        )
    }
}

export function useGlobalState(globalState) {
    const [, setLocalState] = useState()
    const state = globalState.getValue()

    function reRender(newState) {
        setLocalState(newState)
    }

    useEffect(() => {
        globalState.subscribe(reRender)

        return () => {
            globalState.unsubscribe(reRender)
        }
    })

    function setGlobalState(newState) {
        globalState.setValue(newState)
    }

    return [state, setGlobalState]
}
