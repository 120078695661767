import React, { memo } from 'react'

import { type FSABenefitProps } from '../../../EmployeeCalculatorInterface'
import BenefitsContributionsDetails from '../BenefitsContributionsDetails'

const MedicalFSA: React.FC<FSABenefitProps> = memo((props) => {
  return (
    <BenefitsContributionsDetails
      {...props}
      componentName='Health Flexible Spending Account'
      benefitKey='medical'
    />
  )
})

export default MedicalFSA
