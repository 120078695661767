import React, { memo, useState } from 'react'
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography
} from '@mui/material'
import {
  Delete as DeleteIcon,
  ModeEdit as ModeEditIcon,
  InsertDriveFile as InsertDriveFileIcon,
  NoteAdd as NoteAddIcon
} from '@mui/icons-material'

import { type AdminScenariosData } from '../../AdminInterface'
import { type FormValues } from '../../../FormValuesInterfaces'
import { showScenario } from '../../../api/scenario'
import { scrollToTop } from '../../../Helper'
import { showErrorToast } from '../../NotificationToast'

import './styles.sass'

interface Item {
  id: number
  name: string
  description: string
  url: string
  key?: string
}

interface CaseItemManagementProps {
  title: string
  itemName: string
  itemNamePlural: string
  formValues: FormValues
  items: Item[]
  setValues: (values: FormValues) => void
  handleItemSelection?: (name: string, id: number, description: string) => void
  selectedItem?: { name: string, id: number } | null
  createScenario: (name: string, file: File, description?: string) => void
  handleItemDelete: (id: number) => Promise<void>
  UpdateScenario: (id: number, item_key: string, name: string, file: File, description?: string) => Promise<void>
}

const CaseItemManagement: React.FC<CaseItemManagementProps> = memo((props) => {
  const { title, selectedItem, items, formValues, createScenario, handleItemSelection, setValues, handleItemDelete, UpdateScenario } = props
  const [loading, setLoading] = useState(false)
  const [resquestProcessing, setRequestProcessing] = useState(false)
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false)
  const [deleteItemId, setDeleteItemId] = useState(0)

  const openDeleteConfirmation = (id: React.SetStateAction<number>): void => {
    setDeleteConfirmationOpen(true)
    setDeleteItemId(id)
  }

  const closeDeleteConfirmation = (): void => {
    setDeleteConfirmationOpen(false)
    setDeleteItemId(0)
  }

  const handleFileUpload = (): void => {
    const fileInput = document.createElement('input')
    fileInput.type = 'file'
    fileInput.accept = '.csv'
    fileInput.addEventListener('change', async (event) => {
      const files = (event.target as HTMLInputElement).files
      if (files && files.length > 0) {
        const file = files[0]
        const fileName = file.name.replace(/\.[^/.]+$/, '')
        setLoading(true)
        try {
          await createScenario(fileName, file, '')
        } finally {
          setLoading(false)
        }
      }
    })
    fileInput.click()
  }

  const handleDeleteItem = (id: number): void => {
    openDeleteConfirmation(id)
  }

  const handleOpenItem = async (itemName: string, id: number, description: string, item_key: string): Promise<void> => {
    try {
      setRequestProcessing(true)
      const uploadResponse = await showScenario(id.toString(), item_key)
      const updatedScenariosData: Record<string, AdminScenariosData> = {}

      Object.entries(uploadResponse.data).forEach(([scenarioKey, scenarioData]) => {
        updatedScenariosData[scenarioKey] = scenarioData as AdminScenariosData
      })

      const updatedFormValues = {
        ...formValues,
        adminScenariosData: updatedScenariosData,
      } as unknown as FormValues

      setValues(updatedFormValues)

      if (handleItemSelection) {
        handleItemSelection(itemName, id, description)
      }
    } catch (error) {
      showErrorToast(`Error in handleOpenItem: ${(error as any).message}`)
    }
  }

  const handleEditItem = (item: Item): void => {
    const fileInput = document.createElement('input')
    fileInput.type = 'file'
    fileInput.accept = '.csv'
    fileInput.addEventListener('change', async (event: any) => {
      const files = (event.target as HTMLInputElement).files
      if (files && files.length > 0) {
        const file = files[0]
        const fileName = file.name.replace(/\.[^/.]+$/, '')
        if (files && files.length > 0) {
          const file = files[0]
          setRequestProcessing(true)
          try {
            await UpdateScenario(item.id, item.key || '', fileName, file, '')
          } finally {
            setRequestProcessing(false)
          }
        }
      }
    })
    fileInput.click()
  }

  const handleDeleteConfirm = async () => {
    try {
      closeDeleteConfirmation()
      setRequestProcessing(true)
      await handleItemDelete(deleteItemId)
    } finally {
      setRequestProcessing(false)
    }
  }

  const DeleteConfirmationModal = () => {
    return (
      <Dialog
        open={deleteConfirmationOpen}
        onClose={closeDeleteConfirmation}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            Are you sure you want to delete this {title.toLowerCase()}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDeleteConfirmation} color='primary'>
            Cancel
          </Button>
          <Button onClick={handleDeleteConfirm} style={{ backgroundColor: '#4E49B7', color: '#fff' }} autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  scrollToTop()

  return (
    <div className='dashboardContainer'>
      <div className='headerWrapper'>
        <div className='dashboardHeader'>
          <Button
            variant='contained'
            className='itemButton'
            onClick={handleFileUpload}
          >
            <NoteAddIcon className='addItemIcon' />CREATE NEW {title.toUpperCase()}
          </Button>
        </div>
        {selectedItem?.name && <b style={{ fontWeight: '100', marginLeft: '12px' }}>Folder: {selectedItem.name}</b>}
      </div>
      <div className='wrapper'>
        {resquestProcessing && (
          <div className='circlularLoader'>
            <CircularProgress />
          </div>
        )}
        {items.length > 0 ? (
          <div className='itemListContainer'>
            <TableContainer>
              <Table>
                <TableBody>
                  {items.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        <Typography variant='body1' onClick={() => { handleOpenItem(item.name, item.id, item.description, item.key || '') }}>
                          <Box className='itemName'>
                            <InsertDriveFileIcon />
                            <Box className='itemText'>{item.name}</Box>
                          </Box>
                        </Typography>
                      </TableCell>
                      <TableCell align='right'>
                        <DeleteIcon className='deleteIcon' onClick={() => { handleDeleteItem(item.id) }} />
                        <ModeEditIcon className='editIcon' onClick={() => { handleEditItem(item) }} />
                      </TableCell>
                    </TableRow>
                  ))}
                  {loading && (
                    <TableRow>
                      <TableCell colSpan={2}>
                        <Skeleton sx={{ height: '40px' }} animation='wave' />
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        ) : (
          <b className='noItemsMessage'> No cases found </b>
        )}
      </div>
      {DeleteConfirmationModal()}
    </div>
  )
})

export default CaseItemManagement
