import React from 'react'

import I18n from '../config/I18n'
import { goToURL } from '../Helper'

import '../component_styles/ExploreCard.sass'
import { MAX_OUTER_WIDTH } from '../Constants'

interface ExploreCardProps {
  title: string
  description: string
  tagList: string[]
  imageUrl: string
  imageDescription: string
  url: string
  backgroundColor: string
  content: string
  date: string
}

const ExploreCard: React.FC<ExploreCardProps> = (props) => {
  const { title, description, tagList, imageUrl, imageDescription, url } = props

  const goToExploreCard = () => goToURL(url)

  const goToExploreCardTag = (tag: string) => goToURL(`/${I18n.locale}/explore-card?tag=${tag}`)

  return (
    <div className='card explore-card'>
      <div>
        {tagList &&
          <div className='tags'>
            {tagList.map((tag, index) =>
              <span
                key={`tag-${index}`}
                className='content-tag'
                onClick={() => goToExploreCardTag(tag)}
              >
                {tag}
              </span>
            )}
          </div>
        }
        <div className='explore-card-container' onClick={goToExploreCard}>
          <div className='title two-lines'>{title}</div>
          {outerWidth < MAX_OUTER_WIDTH && <img src={imageUrl} title={imageDescription} alt={imageDescription} />}
          <div className='description two-lines'>{description}</div>
          <div className='read-more'>Read More</div>
        </div>
      </div>
      <div>
        {outerWidth > MAX_OUTER_WIDTH && <img src={imageUrl} title={imageDescription} alt={imageDescription} />}
      </div>
    </div>
  )
}

export default ExploreCard
export type { ExploreCardProps }
