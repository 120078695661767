import React, { useEffect, useRef } from 'react'
import * as d3 from 'd3'

interface AxisProps {
  axisHeight: number
  axis: any
  axisType: string
}

const Axis: React.FC<AxisProps> = (props) => {
  const ref = useRef(null);
  const translate = "translate(0,"+(props.axisHeight)+")";

  useEffect(() => {
    if (props.axis) {
      d3.select(ref.current).call(props.axis);
    }
  }, [props.axis]);

  return (
    <g ref={ ref }
       className="axis"
       transform={ props.axisType=='x' ? translate : "" }>
    </g>
  )
}

export default Axis
export type { AxisProps }
