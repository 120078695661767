import React, { useEffect } from 'react'
import { useGlobalState } from '../GlobalState'
import I18n from '../config/I18n'
import { NoteCardProps } from './NoteCard'
import '../component_styles/NoteCard.sass'

interface NoteCardShowProps {
  note: NoteCardProps
}

const NoteCardShow: React.FC<NoteCardShowProps> = (props) => {
  const { note } = props
  const realProgramName = note.programName || note.programOther
  const realOrganizationName = note.organizationName || note.organizationOther
  const [, setHeaderState] = useGlobalState(window.OC.globals.headerState)

  useEffect(() => {
    setHeaderState({backUrl: `/${I18n.locale}/notes`, editUrl: `/${I18n.locale}/notes/${note.id}/edit`, ...window.OC.globals.headerState})
  }, [])

  return (
    <div className="content without-header text-only">
      <div className={ `note-show note-card-${note.color}-theme-color` }>
        <h1 className="show-page-title">{ note.title }</h1>

        { (note.date || note.time) &&
          <div className="datetime-container">
            { note.date &&
              <span>{ note.date }</span>
            }
            { note.date && note.time &&
              <i className="fas fa-circle" />
            }
            { note.time &&
              <span>{ note.time }</span>
            }
          </div>
        }

        { (note.contactName || note.contactMethod) &&
          <div className="contact-container">
            { note.contactMethod &&
              <span className={ `contact-icon ${note.contactMethod}` } />
            }
            { note.contactName &&
              <span>{ note.contactName }</span>
            }
          </div>
        }

        { (realProgramName || realOrganizationName) &&
            <div className="names-container">
              {
                realProgramName &&
                  <div className="program-name">{ realProgramName }</div>
              }
              {
                (realOrganizationName && realProgramName) &&
                  <div className="organization-name">{ realOrganizationName }</div>
              }
              {
                (realOrganizationName && !realProgramName) &&
                  <div className="program-name">{ realOrganizationName }</div>
              }
            </div>
        }

        <div className="hashtag" onClick={ () => location.href = `/${I18n.locale}/notes?hashtag=${note.hashtag.replace("#", "")}` }>
          { note.hashtag }
        </div>

        <p>{ note.body }</p>
      </div>
    </div>
  )
}

export default NoteCardShow
export type { NoteCardShowProps }
