import { Button, Divider } from '@mui/material'
import React, { useContext, useEffect, useState, type FC } from 'react'

import { GENDERS, MARTIAL_STATUSES, RELATIONSHIPS, YES_NO } from '../../../Constants'
import { getNames } from '../../../Helper'
import DividingColorLines from '../../DividingColorLines'
import InputQuestion from '../../InputQuestion'
import MultiSelectInput from '../../MultiSelectInput'
import SelectInput from '../../SelectInput'

import './styles.sass'

interface AdultDetailsProps {
  setValues: Function
  formValuesContext: any
  setStep: Function
}

const AdultDetails: FC<AdultDetailsProps> = ({ formValuesContext, setStep, setValues }) => {
  const { basicInfo }: any = useContext(formValuesContext)

  const [canSubmit, setCanSubmit] = useState(false)
  const [adults, setAdults] = useState(basicInfo?.adults ?? {})
  const [adultErrors, setAdultErrors] = useState<any>({})
  const [isPregnent, setIsPregnent] = useState(basicInfo?.isPregnent ?? '')
  const [pregentAdults, setPregnentAdults] = useState(basicInfo?.pregentAdults ?? {})
  const totalRequiredInputs = basicInfo?.adultsCount * 5 + (isPregnent == 'Yes' ? 1 + Object.keys(pregentAdults).length : +1)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const checkRequiredFilled = () => {
    let total = Object.keys(adults).reduce((curr, next) => curr + Object.values(adults[next]).length, 0)
    if (isPregnent == 'Yes' && Object.keys(pregentAdults).length != 0) {
      total += 1 + Object.keys(pregentAdults).length
    } else if (isPregnent == 'No') {
      total += 1
    }

    return totalRequiredInputs == total
  }

  const handleNext = () => {
    setCanSubmit(true)

    if (checkRequiredFilled()) {
      setStep('CHILDREN')
      setValues({
        basicInfo: {
          ...basicInfo,
          adults,
          isPregnent,
          pregentAdults
        }
      })
      window.scrollTo(0, 0)
    }
  }

  const renderInputs = (adultNumber: number, totalAdults: number) => {
    return (
      <div className='content d-flex flex-column questions'>
        <h2>{`Household adult information: ${adultNumber} of ${totalAdults ?? 1}`}</h2>
        <InputQuestion
          type='text'
          label='Name of adult'
          value={adults[adultNumber]?.name ?? ''}
          onChangeHandler={(e: React.ChangeEvent<HTMLInputElement>) => { setAdults({ ...adults, [adultNumber]: { ...adults[adultNumber], name: e.target.value } }) }}
          showErrorMsg={adultErrors[adultNumber]?.name || canSubmit && (adults[adultNumber]?.name == undefined || adults[adultNumber]?.name == '')}
          errorMsg={'Please provide name'}
          onBlurHandler={() => {
            setAdultErrors({ ...adultErrors, [adultNumber]: { ...adultErrors[adultNumber], name: adults[adultNumber]?.name == undefined || adults[adultNumber]?.name == '' } })
          }}
        />

        <InputQuestion
          type='number'
          label='Age of adult'
          value={adults[adultNumber]?.age ?? ''}
          onChangeHandler={(e: React.ChangeEvent<HTMLInputElement>) => { setAdults({ ...adults, [adultNumber]: { ...adults[adultNumber], age: e.target.value } }) }}
          showErrorMsg={adultErrors[adultNumber]?.age || canSubmit && (adults[adultNumber]?.age == undefined || adults[adultNumber]?.age == '' || adults[adultNumber]?.age < 18 || adults[adultNumber]?.age > 80)}
          errorMsg={'Age is required & should be 18 or older'}
          onBlurHandler={() => {
            setAdultErrors({ ...adultErrors, [adultNumber]: { ...adultErrors[adultNumber], age: adults[adultNumber]?.age == undefined || adults[adultNumber]?.age == '' || adults[adultNumber]?.age < 18 || adults[adultNumber]?.age > 80 } })
          }}
        />

        <SelectInput
          options={GENDERS}
          placeholderProp={'Gender'}
          value={adults[adultNumber]?.gender}
          setValue={(input: string) => { setAdults({ ...adults, [adultNumber]: { ...adults[adultNumber], gender: input } }) }}
          showErrorMsg={canSubmit && adults[adultNumber]?.gender == undefined}
          errorMsg={'Select a gender'}
        />

        <SelectInput
          options={RELATIONSHIPS}
          placeholderProp={'Relationship'}
          value={adults[adultNumber]?.relationship}
          setValue={(input: string) => { setAdults({ ...adults, [adultNumber]: { ...adults[adultNumber], relationship: input } }) }}
          showErrorMsg={canSubmit && adults[adultNumber]?.relationship == undefined}
          errorMsg={'Select a relationship'}
        />

        <SelectInput
          options={MARTIAL_STATUSES}
          placeholderProp={'Martial Status'}
          value={adults[adultNumber]?.martialStatus}
          setValue={(input: string) => { setAdults({ ...adults, [adultNumber]: { ...adults[adultNumber], martialStatus: input } }) }}
          showErrorMsg={canSubmit && adults[adultNumber]?.martialStatus == undefined}
          errorMsg={'Select a martial status'}
        />
      </div>
    )
  }

  return (
    <div className='adult-details-input'>
      <div className='d-flex flex-column header-input'>
        <h2 className='adult-household'>Adults in household</h2>
        <p className='adult-paragraphh'>Enter information about the adults in your household</p>
      </div>
      <DividingColorLines />
      {Array(basicInfo?.adultsCount).fill(0).map((_adult: number, index: number) =>
        renderInputs(index, basicInfo?.adultsCount))
      }
      <div className='content'>
        <Divider />
        <SelectInput
          options={YES_NO}
          placeholderProp={'Is anyone in your household pregnant?'}
          value={isPregnent}
          setValue={setIsPregnent}
          showErrorMsg={canSubmit && isPregnent == ''}
          errorMsg={'Select an option'}
        />
        {isPregnent == 'Yes' && <MultiSelectInput
          options={getNames(adults) ?? []}
          placeholderProp={'Select who is pregnent?'}
          value={pregentAdults}
          setValue={setPregnentAdults}
          showErrorMsg={canSubmit && Object.keys(pregentAdults).length == 0}
          errorMsg={'Select an option'}
        />}
        <Button variant='outlined' onClick={handleNext}>Continue</Button>
      </div>
    </div >
  )
}

export default AdultDetails
