import React, { useState } from 'react'
import RadioInput from './RadioInput'
import '../component_styles/Dropdown.sass'

interface RadioInputGroupProps {
  options?: [string, string | number][] | string[]
  name: string
  selectedId?: string | number
  onChange?: (id: string) => void
}

const RadioInputGroup: React.FC<RadioInputGroupProps> = (props) => {
  const [selectedId, setSelectedId] = useState(props.selectedId)

  function handleOnChange(ev: any) {
    setSelectedId(ev.target.value)

    if (props.onChange) {
      props.onChange(ev)
    }
  }

  return (
    <>
      { props.options && props.options.map(([text, id]:any, index:number) =>
        <RadioInput
          key={ `radio-${index}` }
          name={ props.name }
          label={ text }
          id={ id }
          selectedId={ selectedId }
          onChange={ handleOnChange }
        />
      ) }
    </>
  )
}

export default RadioInputGroup
export type { RadioInputGroupProps }
