import React, { useState, useContext, useEffect } from 'react'
import { Button, LinearProgress } from '@mui/material'

import { getPercentageProgress, getFilledInput, scrollToTop } from '../../Helper'
import {
  YES_NO,
  BLUR_QUESTIONS,
  NUMBER_OF_CHILDREN,
  NUMBER_OF_ADULT
} from '../../Constants'
import InputOptions from '../InputOptions'
import InputSelect from '../InputSelect'

interface HouseholdProps {
  wiCounties: String[]
  setStep: Function
  step: number
  setValues: Function
  formValuesContext: any
}

const TOTAL_INPUT_QUESTIONS = 4

const Household: React.FC<HouseholdProps> = (props) => {
  const { wiCounties, setStep, setValues, formValuesContext } = props
  const formValues: any = useContext(formValuesContext)
  const { basicInfo } = formValues
  const [qsNumber, setQSNumber] = useState(basicInfo ? Object.keys(basicInfo).length : 0)
  const [blur, setBlur] = useState<{ [x: number]: boolean }>(basicInfo ? getFilledInput() : BLUR_QUESTIONS)
  const [county, setCounty] = useState(basicInfo?.county ?? 'Dane')
  const [planToMove, setPlanToMove] = useState(basicInfo?.planToMove ?? false)
  const [adultMembers, setAdultMembers] = useState<any>(basicInfo?.adultsCount ?? 1)
  const [childMembers, setChildMembers] = useState<any>(basicInfo?.childrenCount ?? 1)

  useEffect(() => {
    basicInfo?.adults && setBlur(Array(TOTAL_INPUT_QUESTIONS).fill(true))
  }, [])

  const handleNext = (num: number) => {
    setBlur({ ...blur, [num + 1]: true })
    setQSNumber(num + 1)
  }

  const continueToNextPage = () => {
    setStep('ADULT')
    setValues({
      ...formValues,
      basicInfo: {
        ...formValues.basicInfo,
        adultsCount: adultMembers,
        childrenCount: childMembers,
        county: county,
        planToMove: planToMove,
      }
    })
  }

  scrollToTop()

  return (
    <>
      <LinearProgress variant='determinate' value={getPercentageProgress(qsNumber, TOTAL_INPUT_QUESTIONS)} />
      <div className='content'>
        <h2>Household Information</h2>
        <InputSelect
          blur={blur}
          handleNext={handleNext}
          heading={'What County (or Tribe for WHEAP) do you live in?'}
          number={0}
          options={wiCounties}
          value={county}
          setValue={setCounty}
          qsNumber={qsNumber}
          setQSNumber={setQSNumber}
        />
        <InputOptions
          blur={blur}
          handleNext={handleNext}
          number={1}
          options={YES_NO}
          questionHeading={'Do you plan to keep living in Wisconsin?'}
          value={planToMove}
          setValue={setPlanToMove}
        />
        <InputSelect
          blur={blur}
          handleNext={handleNext}
          heading={'Number of family members that are above 18:'}
          number={2}
          options={NUMBER_OF_ADULT}
          value={adultMembers}
          setValue={setAdultMembers}
          qsNumber={qsNumber}
          setQSNumber={setQSNumber}
        />
        <InputSelect
          blur={blur}
          handleNext={handleNext}
          heading={'Number of family members that are under 18:'}
          number={3}
          options={NUMBER_OF_CHILDREN}
          value={childMembers}
          setValue={setChildMembers}
          qsNumber={qsNumber}
          setQSNumber={setQSNumber}
        />

        {(qsNumber >= TOTAL_INPUT_QUESTIONS) &&
          <div className='continue-button'>
            <Button variant='outlined' onClick={continueToNextPage}>Continue</Button>
          </div>
        }
      </div>
      <div className='frs-footer'>
        <div className='footer-chip'>
          <p>{qsNumber + 1}/{TOTAL_INPUT_QUESTIONS}</p>
        </div>
        <p onClick={() => window.scrollBy(0, 200)}>Save Snapshot</p>
      </div>
    </>
  )
}

export default Household
export type { HouseholdProps }
