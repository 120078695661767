import { toast } from 'react-toastify'

import { apiClient } from './apiClient'

import I18n from '../config/I18n'

interface NpsFeedbackResponse {
  status: string
  data: {
    id: number
    message: string
  }
}

interface NpsFeedbackData {
  rating: number
  employee_id: string | number | undefined
  user_id: number | undefined
}

interface RequestNpsFeedbackResponse {
  ask_nps_feedback: boolean
}

export const postNpsFeedbacks = async (npsData: NpsFeedbackData): Promise<NpsFeedbackResponse> => {
  const response = await apiClient
    .post(`/${I18n.locale as string}/nps_feedbacks`, { nps_feedbacks: npsData })
    .then(({ data }) => {
      return data
    })
    .catch(({ response }) => {
      toast.error(response?.data?.error)
    })

  return response
}

export const postRequestNpsFeedback = async (
  employee_id?: string | number,
  user_id?: string | number
): Promise<RequestNpsFeedbackResponse> => {
  const response = await apiClient
    .post(`/${I18n.locale as string}/nps_feedbacks/request_nps_feedback`, {
      employee_id,
      user_id
    })
    .then(({ data }) => {
      return data
    })
    .catch(({ response }) => {
      toast.error(response?.data?.error)
    })

  return response
}
