import React from 'react'
import '../component_styles/Button.sass'

interface ButtonProps {
  text: string,
  disable?: boolean,
  className?: string
}

const Button: React.FC<ButtonProps> = (props) =>
{
  return (
    <button className={`btn ${props.disable ? "disable" : ""} ${props.className}`}>
      { props.text }
    </button>
  )
}

export default Button
export type { ButtonProps }
