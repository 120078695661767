import axios from 'axios'

export const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') || ''
}

export const apiClient = axios.create({
  baseURL: window.location.origin,
  headers: headers
})
