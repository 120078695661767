import React, { type ChangeEvent, memo, useState, type Dispatch, type SetStateAction } from 'react'
import { Button, CircularProgress } from '@material-ui/core'
import { ToastContainer } from 'react-toastify'

import FilePreviewModal from '../FilePreviewModal'
import { type EmployeeData } from '../EmployeeList'

import 'react-toastify/dist/ReactToastify.css'
import './styles.sass'
import { uploadEmployerBenefitsCSV } from '../../../api/benefits'
import { uploadEmployeesCSV } from '../../../api/employer'

interface Props {
  setEmployees: Dispatch<SetStateAction<EmployeeData[]>>
}

const FileUploader: React.FC<Props> = memo(({ setEmployees }) => {
  const [filePreview, setFilePreview] = useState<string | null>(null)
  const [uploadedfile, setUploadedFile] = useState<File | null>(null)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isBenefitFile, setIsBenefitFile] = useState(false)
  const [uploading, setUploading] = useState(false)
  const id = window.location.pathname.split('/').pop()

  const handleFileUpload = (event: ChangeEvent<HTMLInputElement>): void => {
    const currentFile = event.target.files?.[0]

    if (currentFile) {
      const reader = new FileReader()

      reader.onload = () => {
        const fileContent = reader.result as string
        setFilePreview(fileContent)
        setUploadedFile(currentFile)
        openModal()
        event.target.value = ''
      }

      reader.readAsText(currentFile)
    }
  }

  const openModal = (): void => { setIsModalOpen(true) }

  const closeModal = (): void => {
    setFilePreview(null)
    setUploadedFile(null)
    setIsModalOpen(false)
  }

  const processCSV = (): void => {
    if (uploadedfile && isBenefitFile) {
      setUploading(true)

      setTimeout(() => {
        uploadEmployerBenefitsCSV(uploadedfile, id as string)
        setUploading(false)
      }, 1000)
    } else if (uploadedfile) {
      setTimeout(() => {
        uploadEmployeesCSV(uploadedfile, id as string, setEmployees)
        setUploading(false)
      }, 1000)
    }

    closeModal()
  }

  return (
    <>
      <div className='input-container'>
        <input
          type='file'
          name='employee_csv_file'
          accept='.csv,.xlsx'
          className='file-input'
          id='employee-csv-file-input'
          onChange={handleFileUpload}
        />

        <input
          type='file'
          name='benefits_csv_file'
          accept='.csv, .xlsx'
          className='file-input'
          id='benefit-csv-file-input'
          onChange={(e) => { setIsBenefitFile(true); handleFileUpload(e) }}
        />

        <label htmlFor='employee-csv-file-input'>
          <Button component='span' className='file-upload-button'>
             Upload Employees
          </Button>
        </label>

        <label htmlFor='benefit-csv-file-input'>
          <Button component='span' className='file-upload-button'>
            Upload Employer Benefits
          </Button>
        </label>
      </div>
      {uploading
        ? <div className='b2b-circular-progress'> <CircularProgress /> </div>
        : <FilePreviewModal
          isOpen={isModalOpen}
          onClose={closeModal}
          filePreview={filePreview}
          onProcessCSV={processCSV}
        />
      }
      <ToastContainer
        position='top-right'
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  )
})

export default FileUploader
