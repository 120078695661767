import React from 'react';
import Label from './Label'

interface RadioInputProps {
  name: string
  label: string
  id: string | number
  selectedId?: string | number
  onChange?: (id: string) => void
}

const RadioInput: React.FC<RadioInputProps> = (props) => {
  const { name, id, onChange, label, selectedId } = props

  function handleOnChange(ev: any) {
    if (onChange) {
      onChange(ev)
    }
  }

  return (
    <div className={ `modal-content-row ${id == selectedId ? "selected" : ''}` }>
      <Label
        label={ label }
        labelClassName={ `label-text for-${id}` }
      >
        <input
          type="radio"
          id={ id.toString() }
          value={ id }
          name={ name }
          onChange={ handleOnChange }
          checked={ (id == selectedId) }
        />
      </Label>
    </div>
  )
}

export default RadioInput
export type { RadioInputProps }
