import { apiClient } from './apiClient'
import I18n from '../config/I18n'
import { showErrorToast, showSuccessToast } from '../components/NotificationToast'
export const createFolder = (name: string): Promise<any> => {
  return new Promise((resolve, reject) => {
    const requestData = { folder: { name } }

    apiClient
      .post(`/${I18n.locale as string}/admin/folders/`, requestData)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        if (error.response && error.response.data && error.response.data.error) {
          showErrorToast(error.response.data.error)
        } else {
          showErrorToast('An error occurred while creating the folder.')
        }
        reject(error)
      })
  })
}

export const getAllFolders = async (page: number = 1): Promise<any> => {
  try {
    const response = await fetch(`/${I18n.locale as string}/admin/folders/?page=${page}&per_page=5`)
    return await response.json()
  } catch (error) {
    showErrorToast('Failed to fetch folders. Please try again.')
    throw error
  }
}

export const deleteFolder = (id: number): Promise<any> => {
  return new Promise((resolve, reject) => {
    apiClient
      .delete(`/${I18n.locale as string}/admin/folders/${id}`)
      .then((response) => {
        showSuccessToast('Folder deleted successfully.')
        resolve(response)
      })
      .catch((error) => {
        if (error.response && error.response.data && error.response.data.error) {
          showErrorToast(error.response.data.error)
        } else {
          showErrorToast('An error occurred while deleting the folder.')
        }
        reject(error)
      })
  })
}

export const updateFolder = (id: number, name: string): Promise<any> => {
  return new Promise((resolve, reject) => {
    const requestData = { folder: { name } }

    apiClient
      .patch(`/${I18n.locale as string}/admin/folders/${id}`, requestData)
      .then((response) => {
        showSuccessToast('Folder updated successfully.')
        resolve(response)
      })
      .catch((error) => {
        if (error.response && error.response.data && error.response.data.error) {
          showErrorToast(error.response.data.error)
        } else {
          showErrorToast('An error occurred while updating the folder.')
        }
        reject(error)
      })
  })
}
