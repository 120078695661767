import React, { memo } from 'react'
import { Divider, Radio, RadioGroup, Checkbox, FormControlLabel } from '@material-ui/core'
import Select from 'react-select'
import InputField from '../../InputField'
import { BENEFIT_DURATION_OPTIONS as DURATION_OPTIONS } from '../../../../Constants'
import './styles.sass';
import { FormValues } from '../../../../FormValuesInterfaces'
interface Props {
  formValues: FormValues
  otherPlanEstimateSource: string
  selectedOtherBenefits: string[]
  setSelectedOtherBenefits: (value: string[]) => void
  setOtherPlanEstimateSource: (value: string) => void
  setIsChanged: (value: boolean) => void
  OtherBenefitsDeductionDetails: { [key: string]: { amount: number; employerContribution: number; frequency: string } }
  setOtherBenefitsDeductionDetails: React.Dispatch<React.SetStateAction<{ [key: string]: { amount: number; employerContribution: number; frequency: string } }>>
}


interface CheckboxGroupProps {
  label: string
  values: {
    main: string
    sub: Record<string, string>
  }
  selectedValues: string[]
  onChange: (value: string) => void
  onAmountChange: (value: string, amount: number) => void
  onEmployerContributionChange: (value: string, contribution: number) => void
  onFrequencyChange: (value: string, frequency: string) => void
  benefitDetails: { [key: string]: { amount: number; employerContribution: number; frequency: string } }
}

const CheckboxGroup: React.FC<CheckboxGroupProps> = ({
  label,
  values,
  selectedValues,
  onChange,
  onAmountChange,
  onEmployerContributionChange,
  onFrequencyChange,
  benefitDetails,
}) => (
  <>
    <div className='checkbox-group-container'>
      <Checkbox
        value={values.main}
        checked={selectedValues.includes(values.main)}
        onChange={() => {
          onChange(values.main);
        }}
      />
      <label className='benefit-heading'>
        <b>{label}</b>
      </label>
      {selectedValues.includes(values.main) && (
        <div className='headings-container'>
          <p>Deducted from my pay</p>
          <p>Contributed by my Employer</p>
          <p>Frequency</p>
        </div>
      )}
    </div>
    {selectedValues.includes(values.main) && (
      <div className='checkbox-group'>
        {Object.entries(values.sub).map(([key, subLabel]) => (
          <div key={key} className='input-container'>
            <FormControlLabel
              key={key}
              control={
                <Checkbox
                  checked={selectedValues.includes(key)}
                  onChange={() => {
                    onChange(key);
                  }}
                  value={key}
                />
              }
              label={subLabel}
            />
            <div className='data-input-container'>
              <InputField
                label='Amount in $'
                value={benefitDetails[key]?.amount || 0}
                setValue={(value: string) => {
                  onAmountChange(key, parseFloat(value))
                }}
                setIsChanged={() => { }}
                type='tel'
                disabled={key === 'healthcare-hra'}
              />
              <InputField
                label='Contributed by my Employer'
                value={benefitDetails[key]?.employerContribution || 0}
                setValue={(value: string) => {
                  onEmployerContributionChange(key, parseFloat(value))
                }}
                setIsChanged={() => { }}
                type='tel'
                disabled={[
                  'dental-plan',
                  'vision-plan',
                  'short-term-disability',
                  'long-term-disability',
                  'life-insurance'
                ].includes(key)}
              />
              <Select
                className='duration-container'
                classNamePrefix='select'
                onChange={(selectedOption) => {
                  if (selectedOption) {
                    onFrequencyChange(key, selectedOption.value)
                  }
                }}
                isSearchable={false}
                options={DURATION_OPTIONS}
                value={
                  DURATION_OPTIONS.find(
                    (option) => option.value === (benefitDetails[key]?.frequency || DURATION_OPTIONS[0].value)
                  ) || DURATION_OPTIONS[0]
                }
              />
            </div>
          </div>
        ))}
      </div>
    )}
  </>
)

const OtherBenefitsDeduction: React.FC<Props> = memo((props) => {
  const {
    otherPlanEstimateSource,
    selectedOtherBenefits,
    setSelectedOtherBenefits,
    setOtherPlanEstimateSource,
    setIsChanged,
    OtherBenefitsDeductionDetails,
    setOtherBenefitsDeductionDetails,
  } = props;

  const handleCheckboxChange = (value: string): void => {
    const updatedSelectedBenefits = selectedOtherBenefits.includes(value)
      ? selectedOtherBenefits.filter((benefit) => benefit !== value)
      : [...selectedOtherBenefits, value]

    setSelectedOtherBenefits(updatedSelectedBenefits);
  }

  const handleAmountChange = (key: string, amount: number): void => {
    setOtherBenefitsDeductionDetails((prevDetails) => ({
      ...prevDetails,
      [key]: {
        ...prevDetails[key],
        amount,
      },
    }))
  }

  const handleEmployerContributionChange = (key: string, contribution: number): void => {
    setOtherBenefitsDeductionDetails((prevDetails) => ({
      ...prevDetails,
      [key]: {
        ...prevDetails[key],
        employerContribution: contribution,
      },
    }))
  }

  const handleFrequencyChange = (key: string, frequency: string): void => {
    setOtherBenefitsDeductionDetails((prevDetails) => ({
      ...prevDetails,
      [key]: {
        ...prevDetails[key],
        frequency,
      },
    }))
  }

  return (
    <div>
      <h3>Other Employer Benefits</h3>
      <p>
        Some employers provide benefits in addition to health care, such as dental, vision, retirement and Flexible Spending Accounts. These may
        or may not take pre-tax funds out of your paycheck.
      </p>
      <b>Select which applies to you:</b>
      <RadioGroup
        onChange={({ target: { value } }) => {
          setOtherPlanEstimateSource(value)
          setIsChanged(true);
          value === 'user-not-entered' && setOtherBenefitsDeductionDetails({})
        }}
        value={otherPlanEstimateSource}
      >
        <FormControlLabel
          value='calc_estimate'
          control={<Radio checked={otherPlanEstimateSource === 'calc_estimate'} />}
          label='My employer provides other benefits that I participate in.'
        />
        <FormControlLabel
          value='user-not-entered'
          control={<Radio checked={otherPlanEstimateSource === 'user-not-entered'} />}
          label='My employer does not deduct other benefits from my paycheck or provide other benefits free of charge.'
        />
      </RadioGroup>

      <Divider />
      {['user-entered', 'calc_estimate'].includes(otherPlanEstimateSource) && (
        <>
          <p>Enter the amount taken from your paycheck, if any. Enter the employer’s contribution if applicable.</p>
          <b>I have the following benefits from my employer:</b>
          <CheckboxGroup
            label='Dental and Vision Insurance'
            values={{
              main: 'dental-vision-insurance',
              sub: {
                'dental-plan': 'Dental Plan',
                'vision-plan': 'Vision Plan',
              },
            }}
            selectedValues={selectedOtherBenefits}
            onChange={handleCheckboxChange}
            onAmountChange={handleAmountChange}
            onEmployerContributionChange={handleEmployerContributionChange}
            onFrequencyChange={handleFrequencyChange}
            benefitDetails={OtherBenefitsDeductionDetails}
          />

          <CheckboxGroup
            label='Flex Spending Accounts'
            values={{
              main: 'flex-spending-accounts',
              sub: {
                'healthcare-fsa': 'Healthcare FSA',
                'dependentcare-fsa': 'Dependent Care FSA',
              },
            }}
            selectedValues={selectedOtherBenefits}
            onChange={handleCheckboxChange}
            onAmountChange={handleAmountChange}
            onEmployerContributionChange={handleEmployerContributionChange}
            onFrequencyChange={handleFrequencyChange}
            benefitDetails={OtherBenefitsDeductionDetails}
          />

          <CheckboxGroup
            label='Healthcare Reimbursement & Savings Accounts'
            values={{
              main: 'healthcare-savings-accounts',
              sub: {
                'healthcare-hra': 'Healthcare HRA',
                'healthcare-hsa': 'Healthcare HSA',
              },
            }}
            selectedValues={selectedOtherBenefits}
            onChange={handleCheckboxChange}
            onAmountChange={handleAmountChange}
            onEmployerContributionChange={handleEmployerContributionChange}
            onFrequencyChange={handleFrequencyChange}
            benefitDetails={OtherBenefitsDeductionDetails}
          />

          <CheckboxGroup
            label='Disability and Life Insurance'
            values={{
              main: 'disability-life-insurance',
              sub: {
                'short-term-disability': 'Short-term Disability Insurance',
                'long-term-disability': 'Long-term Disability Insurance',
                'life-insurance': 'Life Insurance',
              },
            }}
            selectedValues={selectedOtherBenefits}
            onChange={handleCheckboxChange}
            onAmountChange={handleAmountChange}
            onEmployerContributionChange={handleEmployerContributionChange}
            onFrequencyChange={handleFrequencyChange}
            benefitDetails={OtherBenefitsDeductionDetails}
          />

          <CheckboxGroup
            label='Retirement Accounts'
            values={{
              main: 'retirement-accounts',
              sub: {
                'pre-tax-retirement': 'Pre-tax Retirement Accounts (401k, 403b, etc.)',
                pension: 'Pension',
                other: 'Other',
              },
            }}
            selectedValues={selectedOtherBenefits}
            onChange={handleCheckboxChange}
            onAmountChange={handleAmountChange}
            onEmployerContributionChange={handleEmployerContributionChange}
            onFrequencyChange={handleFrequencyChange}
            benefitDetails={OtherBenefitsDeductionDetails}
          />

          <Divider />
        </>
      )}
    </div>
  )
})

export default OtherBenefitsDeduction
