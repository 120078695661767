import { toast } from 'react-toastify'

import { apiClient } from './apiClient'

import I18n from '../config/I18n'

import { type FormValues } from '../FormValuesInterfaces'

interface PayResponse {
  before_estimated_tax: number
}

interface PayAfterTaxResponse {
  pay_after_tax: number
}

interface TakeHomePayAfterBenefitsResponse {
  take_home_pay: number
  annual_take_home_pay: number
}

const calculate = async <T>(url: string, formValues: FormValues): Promise<T | undefined> => {
  const response = await apiClient
    .post(`/${I18n.locale as string}/employee_taxes${url}`, { employee_data: { ...formValues } })
    .then(({ data }) => {
      return data?.output
    })
    .catch(({ response }) => {
      toast.error(response?.data?.error)
    })

  return response
}

export const calculatePay = async (formValues: FormValues): Promise<PayResponse | undefined> => {
  return await calculate('', formValues)
}

export const calculatePayAfterTaxes = async (formValues: FormValues): Promise<PayAfterTaxResponse | undefined> => {
  return await calculate('/pay_after_taxes', formValues)
}

export const calculateTakeHomePayAfterBenefits = async (formValues: FormValues): Promise<TakeHomePayAfterBenefitsResponse | undefined> => {
  return await calculate('/take_home_pay_after_benefits', formValues)
}
