import React, { useState } from 'react'
import Label from './Label'
import '../component_styles/CheckBox.sass'

interface CheckBoxProps {
  name: string,
  value: string,
  label: string,
  checked: boolean
}

const CheckBox: React.FC<CheckBoxProps> = (props) => {
  const { label, name, value, checked, ...options } = props
  const [isChecked, setIsChecked] = useState(checked || false)

  function onChange(e: any) {
    setIsChecked(e.target.checked)
  }

  return (
    <div className="checkbox-container">
      <input type="hidden" name={ name } value="0" />
      <Label
        label={ label }
        className={ isChecked ? "checked" : "" }
      >
        <input
          { ...options }
          type="checkbox"
          name={ name }
          value={ value }
          onChange={ onChange }
          checked={ isChecked }
        />
      </Label>
    </div>
  )
}

export default CheckBox
export type { CheckBoxProps }
