import { ArrowForwardIos } from '@material-ui/icons'
import React, { useEffect, useState } from 'react'

import { ALL_POPULAR_RECENT, MAX_OUTER_WIDTH } from '../Constants'
import { goToURL, timesAgo } from '../Helper'
import I18n from '../config/I18n'
import { type CategoryTypeProps } from './Category'
import CategorySlider from './CategorySlider'
import ColoredBackgroundHeader from './ColoredBackgroundHeader'
import DividingColorLines from './DividingColorLines'
import ExploreRelatedTopics from './ExploreRelatedTopics'
import { type QuickPeekCardProps } from './QuickPeekCard'

import '../component_styles/CategoriesShow.sass'

interface CategoriesShowProps {
  cards: QuickPeekCardProps[]
  category: CategoryTypeProps
  carouselCards: QuickPeekCardProps[]
  tags: string[]
}

const CategoriesShow: React.FC<CategoriesShowProps> = ({ cards, carouselCards, category }) => {
  const [allCategories, setAllCategories] = useState(cards)
  const [seeMore, setSeeMore] = useState(false)
  const [tierOneCards, setTierOneCards] = useState<QuickPeekCardProps[]>([])
  const [tierTwoCards, setTierTwoCards] = useState<QuickPeekCardProps[]>([])

  useEffect(() => {
    setTierOneCards(filterQuickPeeksByTier(1))
    setTierTwoCards(filterQuickPeeksByTier(2))
  }, [allCategories])

  const filterQuickPeeksByTier = (tier: number): QuickPeekCardProps[] => {
    return allCategories.filter((card: QuickPeekCardProps) => card?.tier === tier)
  }

  const renderTierTwo = (card: QuickPeekCardProps, index: number): JSX.Element | undefined => {
    if (index >= 5) {
      !seeMore && setSeeMore(true)
    } else {
      return (
        <div
          className='topic'
          key={index}
          onClick={() => goToURL(`/${I18n.locale}/quick-peek/${card.id}`)}
        >
          <h3>{card.title}</h3>
          <p>{timesAgo(card.created_at)}</p>
        </div>
      )
    }
  }

  const renderTierOne = (tierOneCard: QuickPeekCardProps, index: number): JSX.Element => (
    <div
      className='content-div'
      key={index}
      onClick={() => goToURL(`/${I18n.locale}/quick-peek/${tierOneCard.id}`)}
    >
      <h3>{tierOneCard.title}</h3>
      <p className='two-lines'>{tierOneCard.sub_heading}</p>
      <p className='times-ago'>{timesAgo(tierOneCard.created_at)}</p>
    </div>
  )

  return (
    <div className='quick-peek-card-show'>
      {category.image_url &&
        <div className='header-image'>
          <img src={category.image_url} alt={category.image_alt_text} />
        </div>
      }
      {!category.image_url &&
        <ColoredBackgroundHeader
          title={category.title}
          headerBackgroundColor={category.color}
          dot={true}
        />
      }
      <DividingColorLines />
      {carouselCards.length > 0
        ? (
          <>
            <div className={`${outerWidth > MAX_OUTER_WIDTH ? 'slider-category' : 'content'}`}>
              {ALL_POPULAR_RECENT.length > 0 &&
                <CategorySlider categories={ALL_POPULAR_RECENT} cards={cards} setAllCategories={setAllCategories} />}
            </div>
            <div className={`card-div ${outerWidth > MAX_OUTER_WIDTH ? 'content' : ''}`} >
              <h1>{category.title}</h1>
              {tierOneCards.length > 0
                ? tierOneCards.map((card: QuickPeekCardProps, index: number) => renderTierOne(card, index))
                : <p>No article related to tier 1 </p>
              }
            </div>

            <div className='related-topics content'>
              <h1>
              Understanding {category.title}
              </h1>
              {tierTwoCards.length > 0
                ? tierTwoCards.map((card: QuickPeekCardProps, index: number) => renderTierTwo(card, index))
                : <p>No article related to tier 2 </p>
              }
            </div>

            <div className='content'>
              {seeMore &&
              <div
                className='cta-button '
                onClick={() => goToURL(`/${I18n.locale}/categories/${category.id}/show_all_quick_peeks`)}
              >
                <span>Explore More topics</span>
                <ArrowForwardIos />
              </div>
              }
              <ExploreRelatedTopics cards={carouselCards} />
            </div>
          </>
          )
        : (<div className='card-div' >
          <h3 className='content'>No articles currently available, but we’re glad to know you’re interested. Check back soon!</h3>
        </div>)
      }
    </div>
  )
}

export default CategoriesShow
export type { CategoriesShowProps }
