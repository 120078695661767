import React, { useState } from 'react'
import {
  Button,
  MenuItem,
  Paper,
  Typography,
  FormControl,
  InputLabel,
  Select,
  SelectChangeEvent,
} from '@mui/material'
import './styles.sass'

interface Props {
  selectedItem: string
  caseDescription: string
  handleNextClick: () => void
}

const VisualizationConfigurator: React.FC<Props> = ({
  selectedItem,
  caseDescription,
  handleNextClick,
}) => {
  const [visualizationName] = useState<string>(selectedItem)
  const [description] = useState<string>(caseDescription)
  const [yAxisLabel, setYAxisLabel] = useState<string>('Income after Expenses')
  const [xAxisIncrement, setXAxisIncrement] = useState<string>('')

  const axisOptions: string[] = [
    'Gross Income Monthly',
    'Gross Income Annual',
    'Hours Worked Job 1',
    'Hours Worked Job 2',
    'Children’s Ages',
    'Partner Gross Income Monthly',
    'Partner Gross Income Annually',
  ]

  const handleCreateVisualization = () => {
    handleNextClick()
  }

  return (
    <>
      <div className='dashboardContainer'>
        <div className='dashboardHeader'>
        </div>
        <Paper className='paper'>
          <Typography variant='h6' component='h3'>
            Exact Sciences
          </Typography>

          <Paper variant='outlined' className='paperOutlined'>
            {visualizationName}
          </Paper>

          <Paper variant='outlined' className='paperOutlined'>
            {description}
          </Paper>

          <FormControl fullWidth margin='normal' className='formControl'>
            <InputLabel>Y Axis </InputLabel>
            <Select
              value={yAxisLabel}
              onChange={(e: SelectChangeEvent<string>) => setYAxisLabel(e.target.value)}
            >
              <MenuItem value='Income after Expenses'>Income after Expenses</MenuItem>
            </Select>
          </FormControl>

          <FormControl fullWidth margin='normal' className='formControl'>
            <InputLabel>X Axis </InputLabel>
            <Select
              value={xAxisIncrement}
              onChange={(e: SelectChangeEvent<string>) => setXAxisIncrement(e.target.value)}
            >
              {axisOptions.map((option, index) => (
                <MenuItem key={index} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Button
            variant='contained'
            color='primary'
            className='button'
            onClick={handleCreateVisualization}
          >
            CREATE VISUALIZATION
          </Button>
        </Paper>
      </div>
    </>
  )
}

export default VisualizationConfigurator
