import React, { memo } from 'react'
import { Box, Button, Modal, Typography } from '@mui/material'

import './styles.sass'

interface FilePreviewModalProps {
  isOpen: boolean
  onClose: () => void
  filePreview: string | null
  onProcessCSV: () => void
}

const FilePreviewModal: React.FC<FilePreviewModalProps> = memo((props) => {
  const { isOpen, onClose, filePreview, onProcessCSV } = props

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Box className='file-preview-modal'>
        <Typography variant='h6' component='h2' className='modal-title'>
          File Preview
        </Typography>

        <pre className='file-content'>{filePreview}</pre>
        <Box className='button-container'>
          <Button size='small' variant='outlined' color='error' onClick={onClose} className='modal-button'>
            Cancel
          </Button>

          <Button size='small' variant='contained' color='primary' onClick={onProcessCSV} className='modal-button'>
            Process CSV
          </Button>
        </Box>
      </Box>
    </Modal>
  )
})

export default FilePreviewModal
