import React, { FC } from 'react'
import { ArrowForwardIos } from '@material-ui/icons'

import starImage from '../../../assets/images/Congrats_Star.png'

interface CongratsProps {
  completedSection: string
  nextIntro: string
  upNext: string
  goToNext: Function
  signedIn: Boolean
}

const Congrats: FC<CongratsProps> = ({ completedSection, nextIntro, upNext, goToNext, signedIn }) => (
  <div className='congrats-container content'>
    <p className='completed-heading'>{`${completedSection}: `}
      <span className='footer-color'>Done</span>
    </p>
    <img src={starImage} />

    <div className='next-intro-heading'>
      <p className='section-heading'>Section Complete</p>
      <p className='intro'>{nextIntro}</p>
      <p className='up-next'>Up Next: {upNext}</p>
    </div>

    <div className='keep-going' onClick={() => goToNext(signedIn ? 1 : 'SIGN_UP_USER_PAGE')}>
      <p>{'Keep going'}</p>
      <ArrowForwardIos className='fa-sm' />
    </div>
  </div>
)

export default Congrats
