import Cookies from 'js-cookie'

import { NPS_SURVEY_COOKIES_EXPIRAY_DAYS, SECONDS_IN_A_DAY } from '../../../Constants'

export const cookieExists = (cookieName: string): boolean => {
  return Cookies.get(cookieName) !== undefined
}

export const cookieHasExpired = (cookieName: string): boolean => {
  const cookieCreatedDateString = Cookies.get(`${cookieName}_created`)
  if (!cookieCreatedDateString) {
    return true
  }

  const cookieCreatedDate = new Date(cookieCreatedDateString).getTime()
  if (isNaN(cookieCreatedDate)) {
    return true
  }

  const currentDate = new Date().getTime()
  const differenceInMilliseconds = currentDate - cookieCreatedDate
  const daysDifference = differenceInMilliseconds / SECONDS_IN_A_DAY
  return daysDifference > NPS_SURVEY_COOKIES_EXPIRAY_DAYS
}
