import React, { useState } from 'react'
import { Box, Card, CardContent, Checkbox, Divider, FormControlLabel } from '@material-ui/core'

import { type Result } from '../../../OutputInterfaces'
import { type GetYearlyOrMonthlyAmount, type MonthYearToggleButton } from '../../../interfaces'
import { OOP_MEDICAL_EXPENSES_QP } from '../../../Constants'

interface Props {
  result: Result
  monthYearToggleButton: MonthYearToggleButton
  getMonthlyOrYearlyAmount: GetYearlyOrMonthlyAmount
}

const FinancialSnapshot: React.FC<Props> = (props) => {
  const { result, monthYearToggleButton, getMonthlyOrYearlyAmount } = props
  const [taxCredits, setTaxCredits] = useState(false)

  const totalIncome = taxCredits
    ? result?.output?.income?.programs_sum + result?.output?.tax_credits?.programs_sum
    : result?.output?.income?.programs_sum

  const expenses = result?.output?.expenses?.programs_sum || 0
  const financialDifference = Number(totalIncome) - expenses

  const handleTaxCreditsChange = (): void => {
    setTaxCredits(prevState => !prevState)
  }

  const renderAmount = (label: string, amount: number, color: string): JSX.Element => {
    return (
      <Box className='render-amount'>
        <span>{label}:</span>
        <span style={{ color }}>
          ${getMonthlyOrYearlyAmount(amount, 'financialSnapshotCalculations')}
        </span>
      </Box>
    )
  }

  return (
    <Card>
      <div className='d-flex justify-space-between align-items-center card-header'>
        <p className='financial-snapshot-text'>Financial Snapshot</p>

        <div className='d-flex flex-column align-items-end'>
          {monthYearToggleButton('financialSnapshotCalculations')}
          <FormControlLabel
            control={<Checkbox checked={taxCredits} size='small' onChange={handleTaxCreditsChange} />}
            label='View with Annual Tax Refunds'
          />
        </div>
      </div>

      <Divider className='divider' />

      <CardContent className='card-content'>
        {renderAmount('Income after All Deductions', Number(totalIncome), '')}
        {renderAmount('Out of Pocket Expenses', result?.output?.expenses?.programs_sum, '')}
        {renderAmount(financialDifference > 0 ? 'Surplus' : 'Deficit', financialDifference, financialDifference > 0 ? 'green' : 'red')}
      </CardContent>

      <Divider />

      <p className='card-note'>
        <b>Review your expenses carefully: </b>
        They may have been adjusted due to changes in your public or private benefits, and please note that expenses will also vary depending
        on how much medical care you receive. Review your <a href={`/quick-peek/${OOP_MEDICAL_EXPENSES_QP}`} className='link' target='blank'>out-of-pocket medical expenses</a> to
        ensure you are planning for copays and deductibles.
      </p>
    </Card>
  )
}

export default FinancialSnapshot
