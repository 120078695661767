import React, { memo, useMemo } from 'react'
import ReactHtmlParser from 'react-html-parser'
import { Paper, Table, TableBody, TableCell, TableContainer, TableRow } from '@material-ui/core'

import { ROW_KEY_MARKETPLACE_PREMIUMS } from '../../../Constants'
import { type FormValues } from '../../../FormValuesInterfaces'
import {
  type Expense,
  type MarketPlaceInsurance,
  type MarketplaceInsuranceInnerPrograms,
  type OutOfPocketExpenses,
  type Program,
  type ProgramWithSum,
  type Result
} from '../../../OutputInterfaces'
import {
  type GetBenefitEligibility,
  type GetYearlyOrMonthlyAmount,
  type MonthYearToggleButton,
  type RenderRow,
  type RenderRowWithHeading,
  type RenderTableHead,
  type SectionVisibilityType
} from '../../../interfaces'

interface Props {
  formValues: FormValues
  result: Result
  monthYearToggleButton: MonthYearToggleButton
  tableVisibility: SectionVisibilityType
  renderRow: RenderRow
  getBenefitEligibility: GetBenefitEligibility
  toggleTableBody: (value: string) => void
  getMonthlyOrYearlyAmount: GetYearlyOrMonthlyAmount
  renderRowsWithHeading: RenderRowWithHeading
  renderTableHead: RenderTableHead
  sectionsVisibility: SectionVisibilityType
}

const MonthlyExpenses: React.FC<Props> = memo((props) => {
  const {
    result,
    monthYearToggleButton,
    tableVisibility,
    toggleTableBody,
    renderRow,
    getBenefitEligibility,
    getMonthlyOrYearlyAmount,
    renderRowsWithHeading,
    renderTableHead,
    sectionsVisibility
  } = props

  const renderMarketPlaceInsuranceRow = useMemo(() => (
    monthYearToggleValue: string,
    program: Program,
    programIndex: number,
    paddings?: string
  ) => {
    const { title, key, current_annual } = program
    const benefitEligibility = getBenefitEligibility(current_annual, monthYearToggleValue)

    return (
      <React.Fragment key={programIndex}>
        <TableRow key={key}>
          <TableCell component='th' scope='row' style={{ paddingLeft: paddings }}>
            {title ?? '--'}
          </TableCell>

          <TableCell align='right'>{benefitEligibility}</TableCell>
        </TableRow>

        <TableRow>
          <TableCell colSpan={2} style={{ paddingLeft: paddings }}>
            {ROW_KEY_MARKETPLACE_PREMIUMS === key && <p><i>{ReactHtmlParser(result?.output?.marketplace_healthcare_note)}</i></p>}
            {ROW_KEY_MARKETPLACE_PREMIUMS === key && <p><i>{ReactHtmlParser(result?.output?.badgercare_eligibility_marketplace_notes)}</i></p>}
            {ROW_KEY_MARKETPLACE_PREMIUMS === key && <p><i>{result?.output?.marketplace_healthcare_default_note}</i></p>}
          </TableCell>
        </TableRow>
      </React.Fragment>
    )
  }, [getBenefitEligibility])

  const renderOppRows = useMemo(() => (
    monthYearToggleValue: string,
    program: Program,
    programIndex: number,
    paddings?: string
  ) => {
    const { title, key, current_annual } = program
    const benefitEligibility = getBenefitEligibility(current_annual, monthYearToggleValue)

    const renderTitle = (title: string, key: string): JSX.Element => {
      const isBadgerCareDeductibles = key === 'badger_care_deductibles'
      const formattedTitle = isBadgerCareDeductibles ? (<i>{title ?? '--'}</i>) : (title ?? '--')

      const formattedBenefitEligibility = isBadgerCareDeductibles ? `(${benefitEligibility})` : benefitEligibility

      return (
        <>
          <TableCell component='th' scope='row' style={{ paddingLeft: paddings }}>
            {formattedTitle}
          </TableCell>

          <TableCell align='right'>{formattedBenefitEligibility}</TableCell>
        </>
      )
    }

    return (
      <React.Fragment key={programIndex}>
        <TableRow key={key}>{renderTitle(title, key)}</TableRow>
      </React.Fragment>
    )
  }, [getBenefitEligibility])

  const renderOutofpocketExpenses = useMemo(() => (expenses: OutOfPocketExpenses[]) => {
    const renderMarketPlaceInsuranceRows = (insurancePrograms: MarketPlaceInsurance, key: number): (false | JSX.Element)[] => {
      return insurancePrograms.programs.map((mpInsurance: MarketplaceInsuranceInnerPrograms) => {
        if (mpInsurance.market_place_insurance_inner_programs) {
          return (
            tableVisibility.marketPlaceInsurance &&
            renderMarketPlaceInsuranceRow(
              'monthlyExpensesCalculations',
              mpInsurance.market_place_insurance_inner_programs.programs,
              key,
              '100px'
            )
          )
        } else {
          return renderRowsWithHeading(
            'marketPlaceInsurance',
            'monthlyExpensesCalculations',
            mpInsurance as Program,
            key,
            '80px'
          )
        }
      })
    }

    return (
      <>
        {expenses.map((expense: OutOfPocketExpenses, key: number) => {
          if (expense?.market_place_insurance) {
            return renderMarketPlaceInsuranceRows(expense?.market_place_insurance, key)
          } else {
            return renderOppRows('monthlyExpensesCalculations', expense as Program, key, '80px')
          }
        })}
      </>
    )
  }, [tableVisibility.marketPlaceInsurance, renderMarketPlaceInsuranceRow, renderRowsWithHeading, renderOppRows])

  const renderTotalhealthExpenses = useMemo(() => (expenses: OutOfPocketExpenses[]) => {
    return (
      <>
        <TableRow>
          <TableCell role="cell" scope="program" style={{ paddingLeft: '50px' }}>
            Total Health Expenses before Expense Accounts applied
          </TableCell>
          <TableCell align='right'>
            $ {getMonthlyOrYearlyAmount(
              result?.output?.expenses?.programs[0]?.out_of_pocket_health_expenses?.programs_sum,
              'monthlyExpensesCalculations'
            )}
          </TableCell>
        </TableRow>
        {expenses.map((expense, key: number) => {
          return renderOppRows('monthlyExpensesCalculations', expense as Program, key, '80px')
        })}
      </>
    )
  }, [tableVisibility.marketPlaceInsurance, renderMarketPlaceInsuranceRow, renderRowsWithHeading, renderOppRows])

  const renderLivingExpenses = useMemo(() => (livingExpenses: ProgramWithSum, programIndex: number) => {
    const note = (
      <>
        <p>Expenses below are based on your inputs or estimates based on local data. They may be further adjusted based on benefits you use.
          <i>Example: $10 in SNAP reduces your out-of-pocket food expenses by $10.</i>
        </p>
        <p>If these estimates do not accurately reflect your expenses, feel free to adjust and recalculate.</p>
      </>
    )

    return renderRow(
      'livingExpenses',
      'monthlyExpensesCalculations',
      'Living Expenses',
      livingExpenses.programs,
      programIndex,
      note,
      livingExpenses.programs_sum,
      '50px'
    )
  }, [renderRow])

  const renderMonthlyExpensesTable = useMemo(() => (

    <>
      {sectionsVisibility.sectionMonthlyExpensis && (
        <>
          <TableCell className='cursor-pointer' onClick={() => { toggleTableBody('outOfPocketHealthCare') }}>
            <b>
              {tableVisibility.outOfPocketHealthCare ? <b>-</b> : <b>+</b>} Out of Pocket Healthcare Costs
            </b>
          </TableCell>

          <TableCell align='right'>
            <b>
              $ {getMonthlyOrYearlyAmount(
                result?.output?.expenses?.programs[0]?.out_of_pocket_health_expenses?.net_sum,
                'monthlyExpensesCalculations'
              )}
            </b>
          </TableCell>

          {tableVisibility.outOfPocketHealthCare && (
            <TableBody>
              {result?.output?.expenses?.programs.map((expenses: Expense) =>
                renderTotalhealthExpenses(expenses.total_health_expenses_accounts.programs)
              )}
              {result?.output?.expenses?.programs.map((expenses: Expense) =>
                renderOutofpocketExpenses(expenses.out_of_pocket_health_expenses.programs)
              )}
              {result?.output?.oop_medical_expense_note &&
                <TableRow>
                  <TableCell colSpan={2} style={{ paddingLeft: '80px' }}>
                    {ReactHtmlParser(result.output.oop_medical_expense_note)}
                  </TableCell>
                </TableRow>
              }
            </TableBody>
          )}

          <TableBody>
            {result?.output?.expenses?.programs.map(
              (expenses: Expense, programIndex: number) =>
                renderLivingExpenses(expenses.living_expenses_sub_fields, programIndex)
            )}
          </TableBody>
        </>
      )}
    </>
  ), [
    sectionsVisibility.sectionMonthlyExpensis,
    tableVisibility.outOfPocketHealthCare,
    toggleTableBody,
    result?.output?.expenses?.programs,
    getMonthlyOrYearlyAmount,
    renderOutofpocketExpenses,
    renderLivingExpenses
  ])
  return (
    <>
      <div className='d-flex justify-content-end'>
        {monthYearToggleButton('monthlyExpensesCalculations')}
      </div>
      <TableContainer component={Paper}>
        <Table>
          {renderTableHead(
            'sectionMonthlyExpensis',
            'monthlyExpensesCalculations',
            'Monthly Expenses',
            result?.output?.expenses?.programs_sum
          )}

          {renderMonthlyExpensesTable}
        </Table>
      </TableContainer>
    </>
  )
})

export default MonthlyExpenses
