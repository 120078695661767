import React, { Context, useContext } from 'react'
import { Button } from '@mui/material'

import BasicCaseDetails from '../BasicCaseDetails'
import { FormValues } from '../../../FormValuesInterfaces'
import handleDownloadCsv from '../../../api/dataDashboardResultsDownload'

import './styles.sass'

interface CaseDataExportPanelProps {
  selectedCase: { name: string, id: number, description: string }
  selectedFolder: { name: string; id: number }
  adminValuesContext: Context<FormValues>
  setSelectedCase: React.Dispatch<
    React.SetStateAction<{ name: string, id: number, description: string }>
  >
  handleNextClick: () => void
}

const CaseDataExportCsv: React.FC<CaseDataExportPanelProps> = ({
  selectedCase,
  adminValuesContext,
  selectedFolder,
  handleNextClick,
  setSelectedCase,
}) => {
  const formValues: FormValues | undefined = adminValuesContext
    ? useContext(adminValuesContext)
    : undefined

  return (
    <div>
      <BasicCaseDetails
        selectedCase={selectedCase}
        adminValuesContext={adminValuesContext}
        selectedFolder={selectedFolder}
        setSelectedCase={setSelectedCase}
      />
      <div className='caseDetailsExportContainer'>
        <div className='buttonGroup'>
          <Button
            variant='contained'
            className='dataExportButton'
            onClick={() => handleDownloadCsv(formValues)}
          >
            Generate CSV
          </Button>
          <Button variant='contained' className='dataExportButton' onClick={handleNextClick}>
            Next
          </Button>
        </div>
      </div>
    </div>
  )
}

export default CaseDataExportCsv
