import React, { FC, useState, useEffect } from 'react'
import { TextField } from '@material-ui/core'
import { LoadingButton } from '@mui/lab'
import { Box, Typography, Modal } from '@mui/material'

import { createGuestUser } from '../../api/benefitCliffs'
import { isValidEmail } from '../../Helper'
import LoginInput from './LoginInput'

import './styles.sass'

interface SignInUserProps {
  step: number | string
  setStep: Function
  formValuesContext: any
}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  p: 4,
}

const SignInUser: FC<SignInUserProps> = ({ formValuesContext, setStep }) => {
  const [email, setEmail] = useState<string>('')
  const [emailError, setEmailError] = useState({ showError: false, errorMsg: '' })
  const [password, setPassword] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)

  const [isOpen, setIsOpen] = useState(false)

  const createUser = () => {
    setIsLoading(true)
    createGuestUser({ email: email }, setPassword, setIsLoading, setEmailError)
  }

  useEffect(() => {
    password != '' && password != 'password' && setModalOpen(true)
  }, [password])

  const copyToClipboard = () => {
    navigator.clipboard.writeText(password)
    setModalOpen(false)
    setIsLoading(false)
    setIsOpen(true)
  }

  const openLoginModal = () => setIsOpen(true)

  const handleEmailInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value)
    setEmailError({ showError: false, errorMsg: '' })
  }

  const skipSignIn = () => setStep(1)

  return (
    <div className='d-flex flex-column content sign-in-user'>
      <h2>You are not Signed In!</h2>
      <p>Please provide your email so that we can create and proceed your account to store your data!</p>
      <TextField
        label='Email'
        margin='dense'
        onChange={handleEmailInput}
        onBlur={() => { !isValidEmail(email) && setEmailError({ showError: true, errorMsg: 'Please enter a valid email address' }) }}
        required
        value={email}
        variant='outlined'
        error={emailError.showError}
        helperText={emailError.errorMsg}
      />
      <div className={`button-div ${isLoading || !isValidEmail(email) ? 'is-disabled' : ''}`}>
        <LoadingButton
          disabled={isLoading || !isValidEmail(email)}
          loading={isLoading}
          onClick={createUser}
          variant='contained'
        >
          Create User
        </LoadingButton>
        <div className='d-flex flex-column'>
          <p className='login-in' onClick={openLoginModal}>
            Already have an account? Sign In
          </p>
          <p className='login-in nudge-down-10' onClick={skipSignIn}>Skip Sign In for now!</p>
        </div>
      </div>

      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
      >
        <Box sx={style}>
          <Typography id='modal-modal-title' variant='h6' component='h2'>
            {`Your password is: ${password}`}
          </Typography>
          <Typography id='modal-modal-description' sx={{ mt: 2 }}>
            Please save it to access your data after sign in.
          </Typography>
          <div style={{ marginTop: '20px', width: '100%', justifyContent: 'center' }} className='d-flex'>
            <LoadingButton onClick={copyToClipboard} variant='contained'>
              {'Copy Password & Next'}
            </LoadingButton>
          </div>
        </Box>
      </Modal>
      <LoginInput
        formValuesContext={formValuesContext}
        email={email}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
    </div >
  )
}

export default SignInUser
