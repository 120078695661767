import React, { useState, useEffect, useRef } from 'react'
import * as d3 from 'd3'

interface GradientChartProps {
  chartId: string
  data: LayerDataProps[]
  width?: number
  height?: number
}

interface LayerDataProps {
  name: string
  values: DataProps[]
}

interface DataProps {
  age: number
  amount: number
  color: string
}

const GradientChart: React.FC<GradientChartProps> = (props) => {
  const [originalWidth] = useState<number>(props.width || 400)
  const [width, setWidth] = useState<number>(originalWidth)
  const [height] = useState<number>(props.height || 600)
  const parentDiv = useRef<HTMLDivElement>(null)

  const margin = { top: 30, right: 50, bottom: 60, left: 50 }
  const graphWidth = width - (margin.left + margin.right)
  const graphHeight = height - (margin.top + margin.bottom)

  const x = d3
    .scaleBand()
    .domain(props.data[0].values.map((d: DataProps) => d.age.toString()))
    .range([0, graphWidth])
    .padding(0.1)

  const y = d3
    .scaleLinear()
    .domain([0, d3.max(props.data.flatMap((d) => d.values), (d: DataProps) => d.amount) || 0])
    .nice()
    .range([graphHeight, 0])

  const handleResize = () => {
    if (parentDiv.current) {
      const parentWidth = parentDiv.current.clientWidth
      if (parentWidth < originalWidth) {
        setWidth(parentWidth)
      } else {
        setWidth(originalWidth)
      }
    }
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    handleResize()
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <div>
      <h1>Gradient Chart</h1>
      <div ref={parentDiv} className='gradient-chart' style={{ height: `${height}px` }}>
        <svg id={props.chartId} width={width} height={height}>
          <defs>
            {props.data.flatMap((layer, i) => (
              <linearGradient id={`gradient-${i}`} x1='0%' y1='0%' x2='0%' y2='100%' key={i}>
                <stop offset='0%' stopColor={layer.values[0].color} />
                <stop offset='100%' stopColor='white' />
              </linearGradient>
            ))}
          </defs>
          <g transform={`translate(${margin.left},${margin.top})`}>
            {props.data.map((layer: LayerDataProps, i: number) => (
              <g key={i}>
                {layer.values.map((d: DataProps, j: number) => (
                  <rect
                    key={j}
                    x={x(d.age.toString())!}
                    y={y(d.amount)}
                    width={x.bandwidth()}
                    height={graphHeight - y(d.amount)}
                    fill={`url(#gradient-${i})`}
                  />
                ))}
              </g>
            ))}
            <g
              transform={`translate(0, ${graphHeight})`}
              ref={(node) => d3.select(node!).call(d3.axisBottom(x))}
            />
            <g ref={(node) => d3.select(node!).call(d3.axisLeft(y))} />
          </g>
        </svg>
      </div>
    </div>
  )
}

export default GradientChart
export type { GradientChartProps }
