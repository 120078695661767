import React, { useState } from 'react'
import 'date-fns'
import Grid from '@material-ui/core/Grid'
import DateFnsUtils from '@date-io/date-fns'
import Label from './Label'
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers'

import '../component_styles/DateTimePicker.sass'

interface DateTimePickerProps {
  label?: string
  name: string
  value?: string
  withDate: boolean
  withTime: boolean
  isRequired?: boolean
  isEncouraged?: boolean
  timeId: string
  dateId: string
  errors?: string
}

const DateTimePicker: React.FC<DateTimePickerProps> = (props) => {
  const { label, name, value, withDate, withTime, dateId, timeId, errors, isRequired, isEncouraged } = props

  const [selectedDate, setSelectedDate] = useState(value ? new Date(value) : null)

  const handleDateChange = (date: any) => {
    setSelectedDate(date)
  }

  return (
    <div className='date-time-picker'>
      { label &&
        <Label
          label={ label }
          hasError={ !!errors }
          isRequired={ isRequired }
          isEncouraged={ isEncouraged }
        />
      }
      <MuiPickersUtilsProvider utils={ DateFnsUtils }>
        <Grid container justify="space-around">
          { withDate &&
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="MM/dd/yyyy"
                margin="normal"
                id={ dateId }
                name={ `${name}[date]` }
                value={ selectedDate }
                onChange={ handleDateChange }
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
          }
          { withTime &&
              <KeyboardTimePicker
                margin="normal"
                id={ timeId }
                name={ `${name}[time]` }
                value={ selectedDate }
                onChange={ handleDateChange }
                KeyboardButtonProps={{
                  'aria-label': 'change time',
                }}
                keyboardIcon={ <i className="far fa-clock" /> }
              />
          }
        </Grid>
      </MuiPickersUtilsProvider>
    </div>
  )
}

export default DateTimePicker
export type { DateTimePickerProps }
