import React from 'react'

import { HamburgerMenu, useHamburgerMenu } from './HamburgerMenu'

import '../component_styles/HamburgerMenuButton.sass'

interface HamburgerMenuButtonProps {
  userIsAdmin: boolean
  userIsGuest: boolean
  userId: number
  className?: string
  sitemapDate?: string
}

const HamburgerMenuButton: React.FC<HamburgerMenuButtonProps> = (props) => {
  const { isShown, toggle } = useHamburgerMenu()

  return (
    <div>
      <div className={`menu-toggle ${props.className}`}>
        <i className={isShown ? "fas fa-times" : "fas fa-bars"} style={{ color: '#4D45BA' }} onClick={toggle} />
      </div>
      <HamburgerMenu
        isShown={isShown}
        userIsAdmin={props.userIsAdmin}
        userIsGuest={props.userIsGuest}
        userId={props.userId}
        sitemapDate={props.sitemapDate}
      >
      </HamburgerMenu>
    </div>
  )
}

export default HamburgerMenuButton
export type { HamburgerMenuButtonProps }
