import I18n from '../config/I18n'
import { apiClient } from './apiClient'

type EmployerData = {
  employer: { pin: string, zip_code: string }
}

export const verifyEmployee = async (
  id: string | number | undefined,
  employeeData: EmployerData,
  setErrorMessage: Function
) => {
  const response = await apiClient
    .post(`/${I18n.locale as string}/employers/${id}/verify_employee`, employeeData)
    .then(({ data }) => {
      return data
    })
    .catch(({ response }) => {
      const error = response?.data?.error
      setErrorMessage(error)
      throw error
    })

  return response
}
