import React, { useState, type FC, useEffect } from 'react'
// import Radio from '@mui/material/Radio'
// import RadioGroup from '@mui/material/RadioGroup'
// import FormControlLabel from '@mui/material/FormControlLabel'
// import FormControl from '@mui/material/FormControl'
// import { Typography } from '@mui/material'

import { type FormValues } from '../../../../FormValuesInterfaces'
import { WI_SHARES_APPROVED_ACTIVITY_REQUIREMENTS_QP } from '../../../../Constants'

import './styles.sass'

interface CCDFProps {
  contextValues: FormValues
  setContextValues: Function
  isBenefitEnrolled: boolean
}

const CCDF: FC<CCDFProps> = ({ contextValues, setContextValues }) => {
  const [isStabilizationPeriod, _setIsStabilizationPeriod] = useState(contextValues?.isCopaymentPeriodStabilizationPeriod ?? false)
  const [isGPO, _setIsGPO] = useState(contextValues?.isCopaymentPeriodGPO ?? false)
  const [isExitPhase, _setIsExitPhase] = useState(contextValues?.isCopaymentPeriodExitPhase ?? false)

  useEffect(() => {
    setContextValues({
      ...contextValues,
      isCopaymentPeriodStabilizationPeriod: isStabilizationPeriod,
      isCopaymentPeriodGPO: isGPO,
      isCopaymentPeriodExitPhase: isExitPhase
    })
  }, [isStabilizationPeriod, isGPO, isExitPhase, setContextValues])

  // const handleRadioChange = (option: string): void => {
  //   setIsStabilizationPeriod(option === 'stabilization')
  //   setIsGPO(option === 'phase_out')
  //   setIsExitPhase(option === 'exit')
  // }

  // const renderLabel = (text: string): JSX.Element => {
  //   const boldText = text.substring(0, text.indexOf(':') + 1)
  //   const remainingText = text.substring(text.indexOf(':') + 1)

  //   return (
  //     <Typography component='span'>
  //       <span style={{ fontWeight: 'bold' }}>{boldText}</span>{remainingText}
  //     </Typography>
  //   )
  // }

  // const getRadioGroupValue = (): string => (
  //   isStabilizationPeriod
  //     ? 'stabilization'
  //     : isGPO
  //       ? 'phase_out'
  //       : isExitPhase
  //         ? 'exit'
  //         : ''
  // )

  return (
    <div className='ccdf-benefit'>
      <p>
      <strong> If you do not add at least 1 hour of work or an approved activity for each parent, you will be ineligible for WI Shares.</strong> 
      </p>
      <p>
      Because travel and sleep times vary, Parsley calculates your eligibility for Wisconsin Shares based on the assumption that all adults in your household meet&nbsp;
      <a href={`https://app.yourparsley.com/en/quick-peek/${WI_SHARES_APPROVED_ACTIVITY_REQUIREMENTS_QP}`} className='link' target='blank'>Approved Activity Requirements</a>&nbsp;for the number of child care hours needed.
      </p>

      {/*
      Removing for production: https://yourparsley.atlassian.net/browse/PAR-603     
      {isBenefitEnrolled &&
        <>
          <h4>Which of these apply to your current copay rate:</h4>
          <FormControl>
            <RadioGroup value={getRadioGroupValue()} onChange={(event) => handleRadioChange(event.target.value)}>
              <FormControlLabel
                value='stabilization'
                control={<Radio />}
                label={renderLabel('Stabilization Period: My copay rate will not increase until my next renewal no matter how much my income increases before then.')}
                onChange={() => { handleRadioChange('stabilization') }}
              />
              <FormControlLabel
                value='phase_out'
                control={<Radio />}
                label={renderLabel('Gradual Phase Out Period (GPO): Any increase in my copay rate is capped at the 200% FPL copay rate until my next renewal.')}
                onChange={() => { handleRadioChange('phase_out') }}
              />
              <FormControlLabel
                value='exit'
                control={<Radio />}
                label={renderLabel('Exit Phase: Any increase in my copay rate takes place immediately and has no cap.')}
                onChange={() => { handleRadioChange('exit') }}
              />
            </RadioGroup>
          </FormControl>
        </>
      } */}
    </div>
  )
}

export default CCDF
