import React from 'react'
import I18n from '../config/I18n'
import '../component_styles/NoteCard.sass'

interface NoteCardProps {
  color: string
  title: string
  date: string
  time: string
  contactMethod: string
  contactName: string
  shortenedBody: string
  body?: string
  hashtag: string
  id: number
  programName?: string
  organizationName?: string
  programOther?: string
  organizationOther: string
  logoUrl?: string
  logoDescription?: string
}

const NoteCard: React.FC<NoteCardProps> = (props) => {
  const { color, title, date, time, contactMethod,
          contactName, shortenedBody, hashtag, id,
          programName, organizationName, organizationOther, programOther, logoUrl, logoDescription } = props
  
  const realProgramName = programName || programOther 
  const realOrganizationName = organizationName || organizationOther 

  return (
    <div className={ `card note-card note-card-${color}-theme-color` }>
      <div className="title-container" onClick={ () => location.href = `/${I18n.locale}/notes/${id}` }>
        <div className="title">{ title }</div>
      </div>
      <div className="content-container">
        <div onClick={ () => location.href = `/${I18n.locale}/notes/${id}` }>
          { (realProgramName || realOrganizationName) &&
              <div className="program-information-column">
                {
                  logoUrl &&
                    <div className="logo" style={{ backgroundImage: `url(${logoUrl})` }} title={ logoDescription }>
                    </div>
                }
                <div className={`names-container ${!logoUrl ? "without-logo" : "" }`}>
                  {
                    realProgramName &&
                      <div className="program-name">{ realProgramName }</div>
                  }
                  {
                    (realOrganizationName && realProgramName) &&
                      <div className="organization-name">{ realOrganizationName }</div>
                  }
                  {
                    (realOrganizationName && !realProgramName) &&
                      <div className="program-name">{ realOrganizationName }</div>
                  }
                </div>
              </div>
          }

          { (date || time) &&
            <div className="datetime-container">
              { date &&
                <span>{ date }</span>
              }
              { date && time &&
                <i className="fas fa-circle" />
              }
              { time &&
                <span>{ time }</span>
              }
            </div>
          }

          { (contactName || contactMethod) &&
            <div className="contact-container">
              { contactMethod &&
                <span className={ `contact-icon ${contactMethod}` } />
              }
              { contactName &&
                <span>{ contactName }</span>
              }
            </div>
          }

          <p>{ shortenedBody }</p>
        </div>

        <div className="hashtag" onClick={ () => location.href = `/${I18n.locale}/notes?hashtag=${hashtag.replace("#", "")}` }>
          { hashtag }
        </div>
      </div>
    </div>
  )
}

export default NoteCard
export type { NoteCardProps }
