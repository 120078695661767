import React from 'react'

interface ErrorMessageProps {
  text: any
}

const ErrorMessage: React.FC<ErrorMessageProps> = (props) => {
  const { text } = props

  return (
    <p style={{ margin: '7px 0 0', color: 'red', fontSize: '13px' }}>{text}</p>
  )
}

export default ErrorMessage
export type { ErrorMessageProps }
