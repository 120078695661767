import React, { Context, memo, useContext } from 'react'
import { Button, List, ListItem, ListItemText } from '@mui/material'

import BasicCaseDetails from '../BasicCaseDetails'
import { FormValues } from '../../../FormValuesInterfaces'

import './styles.sass'
import { AdminScenariosData } from '../../AdminInterface'

interface ScenarioVariablesProps {
  selectedCase: { name: string, id: number, description: string }
  selectedFolder: { name: string; id: number }
  adminValuesContext: Context<FormValues>
  handleNextClick: () => void
}

const ScenarioVariables: React.FC<ScenarioVariablesProps> = memo((props) => {
  const { selectedCase, adminValuesContext, handleNextClick, selectedFolder } = props
  const formValues = useContext(adminValuesContext)

  const scenarioVariables: AdminScenariosData[] = []
  if (formValues) {
    const scenarioKeys = Object.keys(
      formValues.adminScenariosData
    ) as (keyof typeof formValues.adminScenariosData)[]
    if (scenarioKeys.length > 1) {
      for (let i = 1; i < scenarioKeys.length; i++) {
        const key = scenarioKeys[i]
        const scenarioData = formValues.adminScenariosData[key]
        if (typeof scenarioData === 'object' && 'scenario' in scenarioData) {
          scenarioVariables.push(scenarioData as unknown as AdminScenariosData)
        }
      }
    }
  }

  return (
    <div>
      <BasicCaseDetails
        selectedCase={selectedCase}
        adminValuesContext={adminValuesContext}
        selectedFolder={selectedFolder}
      />
      <div className='scenarioVariablesConatiner'>
        <b>View Scenario Variables</b>
        {scenarioVariables.length > 0 ? (
          <List className='list'>
            {scenarioVariables.map((scenario, index) => (
              <ListItem key={index}>
                <ListItemText
                  primary={`Family Size: ${scenario.family_size}, Wages: ${scenario.wages.join(
                    ', '
                  )}, Child Ages: ${scenario.child_ages.join(', ')}`}
                />
              </ListItem>
            ))}
          </List>
        ) : (
          <p>No scenario variables available.</p>
        )}
      </div>
      <Button
        variant='contained'
        className='itemButton'
        sx={{
          marginTop: '10px',
          marginLeft: '26px',
          '&:hover': {
            background: '#4E49B7'
          }
        }}
        onClick={handleNextClick}
      >
        NEXT
      </Button>
    </div>
  )
})

export default ScenarioVariables
