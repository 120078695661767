import { toast } from 'react-toastify'

import { apiClient, headers } from './apiClient'
import { goToURL } from '../Helper'
import I18n from '../config/I18n'


export const createNewQuickPeek = async (
  quickPeekData: object,
  quickPeekImage: object | string,
  altText: string | number | undefined,
  setIsLoading: Function
) => {
  const quickPeekId = await apiClient
    .post(`/${I18n.locale as string}/admin/quick-peek`, quickPeekData)
    .then(({ data }) => {
      toast.success(data?.message)
      return data.id
    })
    .catch(({ response }) => toast.error(response?.data?.message))

  if (quickPeekId) {
    headers['Content-Type'] = 'multipart/form-data'

    apiClient
      .post(`/${I18n.locale as string}/admin/quick-peek/${quickPeekId}/upload_image`,
        { 
          id: quickPeekId,
          quick_peek_image: quickPeekImage,
          alt_text: altText
        }, { headers })
      .catch(() => toast.error('Image Upload Error!'))
  }

  handleRedirect(setIsLoading)
}

export const updateQuickPeek = async (
  id: number,
  quickPeekData: object,
  quickPeekImage: string | undefined,
  altText: string | number | undefined,
  setIsLoading: Function
) => {
  const status: string | number = await apiClient
    .patch(`/${I18n.locale as string}/admin/quick-peek/${id}`, quickPeekData)
    .then(({ data, status }) => {
      toast.success(data?.message)
      return status
    })
    .catch(({ response }) => toast.error(response?.data?.message))

  if (status == 200) {
    headers['Content-Type'] = 'multipart/form-data'
    apiClient
      .post(`/${I18n.locale as string}/admin/quick-peek/${id}/upload_image`,
        { id: id,
          quick_peek_image: quickPeekImage,
          alt_text: altText
        }, { headers })
      .catch(() => toast.error('Image Upload Error!'))
  }

  handleRedirect(setIsLoading)
}

const handleRedirect = (setIsLoading: Function) => {
  setTimeout(() => {
    goToURL(`/${I18n.locale as string}/admin/quick-peek`)
    setIsLoading(false)
  }, 2500)
}
