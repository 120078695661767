import React from 'react'
import { ArrowForwardIos, MenuOutlined } from '@material-ui/icons'

import { goToURL } from '../Helper'
import { QuickPeekCardProps } from './QuickPeekCard'
import ExploreRelatedTopics from './ExploreRelatedTopics'
import I18n from '../config/I18n'

import '../component_styles/QuickPeekTierOne.sass'

interface QuickPeekCardShowProps {
  card: QuickPeekCardProps
  nextCardId: number
  relatedTopics: QuickPeekCardProps[]
}

const QuickPeekCardShow: React.FC<QuickPeekCardShowProps> = ({ card, nextCardId, relatedTopics }) => (
  <div className='quick-peek-tier-one content'>
    <div className='topics-div'>
      <div className='topics'>
        <MenuOutlined />
        <p onClick={() => goToURL(`/${I18n.locale}/categories/${card.category_id}`)}>Topics</p>
      </div>

      {nextCardId ? (
        <div className='next-article'>
          <p onClick={() => goToURL(`/${I18n.locale}/quick-peek/${nextCardId}`)}>Next Article</p>
        </div>
      ) : null}
    </div>

    <div className='content-div'>
      <h2>{card.title}</h2>
      {card?.intro?.length > 0 &&
        <div className='intro-div' dangerouslySetInnerHTML={{ __html: card.intro }}></div>
      }
      <div
        dangerouslySetInnerHTML={{ __html: card.content }}
      />
    </div>

    {card?.help_from_a_person?.length > 0 &&
      <div className='help-from-person' dangerouslySetInnerHTML={{ __html: card.help_from_a_person }}></div>
    }

    {card.cta_heading &&
      <div className='cta-div'>
        <div className='cta-explanation'>
          <div className='dark-colored-div'>
            <div className='light-colored-div'></div>
          </div>
          <h3>{card.cta_heading}</h3>
        </div>
        <p dangerouslySetInnerHTML={{ __html: card.cta_text }}></p>
        <div className='cta-button' onClick={() => goToURL(card.cta_link)}>
          <p>{card.cta_button_text}</p>
          <ArrowForwardIos />
        </div>
      </div>
    }

    {relatedTopics?.length > 0 && <ExploreRelatedTopics cards={relatedTopics} />}
  </div>
)

export default QuickPeekCardShow
export type { QuickPeekCardShowProps }
