import React from 'react'
import { Radio, RadioGroup, FormControlLabel, FormControl } from '@mui/material'

import { capitalizeFirstLetter } from '../../Helper'

import './styles.sass'

interface InputOptionsProps {
  blur: { [x: number]: boolean }
  handleNext: Function
  number: number
  options: string[]
  questionHeading: string
  setValue: Function
  value: boolean
}

const InputOptions: React.FC<InputOptionsProps> = (props) => {
  const { blur, questionHeading, number, handleNext, options, value, setValue } = props

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value)
    handleNext(number)
  }

  return (
    <div className={`input-options ${blur[number] ? '' : 'is-blur no-pointer-event'}`}>
      <p className='question-heading'>{questionHeading}</p>
      <FormControl sx={{ width: '100%' }} size='small'>
        <RadioGroup value={value} >
          {options.map((option: string) =>
            <FormControlLabel
              value={option}
              control={<Radio onChange={handleChange} size='small' />}
              label={capitalizeFirstLetter(option)} />
          )}
        </RadioGroup>
      </FormControl>
    </div >
  )
}

export default InputOptions
export type { InputOptionsProps }
