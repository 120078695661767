import React from 'react'
import '../component_styles/Button.sass'

interface SubmitProps {
  text: string,
  disable?: boolean,
  className?: string
}

const Submit: React.FC<SubmitProps> = (props) => {

  return (
    <input
      type="submit"
      name="commit"
      className={ `btn ${props.disable ? "disable" : ""} ${props.className}` }
      value={ props.text }
      data-disable-with={ props.text } />
  )
}

export default Submit
export type { SubmitProps }
