import React, { useState } from 'react'
import Label from './Label'
import '../component_styles/TextField.sass'

interface TextareaFieldProps {
  name: string
  value: string
  placeholder: string
  label: string
  rows: number
  errors?: string
  isRequired: boolean
  disabled?: boolean
  autoFocus: boolean
  autoComplete: string
  minLength?: number
  maxLength?: number
}

const TextareaField: React.FC<TextareaFieldProps> = (props) => {
  const { label, isRequired, minLength, maxLength, ...options } = props
  const [value, setValue] = useState(props.value)
  const [characterCount, setCharacterCount] = useState(0)

  function handleValueChange(e: any) {
    setValue(e.target.value)
    setCharacterCount(e.target.value.length)
  }

  return (
    <div className="input">
      <Label
        label={ label }
        hasError={ !!props.errors }
        isRequired={ isRequired }
        className={ props.disabled ? 'disabled' : '' }
      />
      <textarea
        { ...options }
        value={ value }
        onChange={ handleValueChange }
        className={ props.errors ? "error" : "" }
        minLength={minLength}
        maxLength={maxLength}
      />
      { props.errors &&
        <div className="error-message">{ props.errors }</div>
      }
      
      {minLength && maxLength &&
      <div className="character-counter">
        <span className={`current-count-${characterCount < minLength? "grey" : characterCount < maxLength ? "green" : "red"}`}>
          {characterCount}/{maxLength}
        </span>
      </div>
      }
    </div>
  )
}

export default TextareaField
export type { TextareaFieldProps }
