import React, { useState } from 'react'

import Dropdown, { DropdownProps } from './Dropdown'
import TextField from './TextField'
import I18n from '../config/I18n'

interface ProgramOrganizationCoordinatedDropdownsProps {
  organizationProps: DropdownProps
  programProps: DropdownProps
  programOther: string | null
  organizationOther: string | null
  programOtherName?: string
  organizationOtherName?: string
}

const ProgramOrganizationCoordinatedDropdowns: React.FC<ProgramOrganizationCoordinatedDropdownsProps> = (props) => {
  const { organizationProps, programProps } = props
  const [programOptions, setProgramOptions] = useState(programProps.options)
  const [organizationSelectedId, setOrganizationSelectedId] = useState(organizationProps.selectedId)
  const [organizationSelectedValue, setOrganizationSelectedValue] = useState(organizationProps.selectedValue)
  const [programOtherSelected, setProgramOtherSelected] = useState(!!props.programOther)
  const [organizationOtherSelected, setOrganizationOtherSelected] = useState(!!props.organizationOther)
  const [programSelectedId, setProgramSelectedId] = useState('')
  const [programSelectedValue, setProgramSelectedValue] = useState('')

  delete (programProps.options)
  delete (organizationProps.selectedId)
  delete (organizationProps.selectedValue)


  const onOrganizationChange = (id: string) => {
    setOrganizationOtherSelected(false)

    const $selectedProgramRadioButton = $('.js-program-fields .selected input')
    let programWasOther = false

    if ($selectedProgramRadioButton != null && $selectedProgramRadioButton.length > 0) {
      programWasOther = ($selectedProgramRadioButton[0].id == 'other')
    }

    if (id) {
      $.ajax({
        method: 'GET',
        url: `/${I18n.locale}/programs/program_dropdown_data?organization_id=${id}`,
        headers: {
          'X-CSRF-Token': document.querySelector(`meta[name='csrf-token']`)?.getAttribute('content')
        },
        success: (programJSON) => {
          if (id == 'other') {
            setOrganizationOtherSelected(true)
          }

          setProgramOptions(programJSON.programs)

          if (programWasOther) {
            setProgramSelectedId('other')
            setProgramSelectedValue('Other')
          }
        }
      })
    }
  }

  const onProgramChange = (id: string) => {
    setProgramOtherSelected(false)

    if (id == 'other') {
      setProgramOtherSelected(true)
    }
    else if (id) {
      $.ajax({
        method: 'GET',
        url: `/${I18n.locale}/organizations/organization_dropdown_data?program_id=${id}`,
        headers: {
          'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
        },
        success: (organizationJSON) => {
          const organizationData = organizationJSON.organizations[0]

          setOrganizationSelectedId(organizationData[1])
          setOrganizationSelectedValue(organizationData[0])
        }
      })
    }
  }
  return (
    <div className='columns'>
      <div className='column-50'>
        <Dropdown
          {...organizationProps}
          onChange={onOrganizationChange}
          options={organizationProps.options}
          selectedId={organizationSelectedId}
          selectedValue={organizationSelectedValue}
        />
        {organizationOtherSelected &&
          <TextField
            name={props.organizationOtherName || ''}
            type='text'
            value={props.organizationOther || ''}
            placeholder={I18n.t('form_fields.organization_name')}
            label={I18n.t('form_fields.organization_other')}
            isRequired={false}
            isEncouraged={false}
            autoFocus={false}
          />
        }
      </div>
      <div className='column-50 js-program-fields'>
        <Dropdown
          {...programProps}
          onChange={onProgramChange}
          options={programOptions}
          selectedId={programSelectedId}
          selectedValue={programSelectedValue}
        />
        {programOtherSelected &&
          <TextField
            name={props.programOtherName || ''}
            type='text'
            value={props.programOther || ''}
            placeholder={I18n.t('form_fields.program_name')}
            label={I18n.t('form_fields.program_other')}
            isRequired={false}
            isEncouraged={false}
            autoFocus={false}
            autoComplete=''
          />
        }
      </div>
    </div>
  )
}

export default ProgramOrganizationCoordinatedDropdowns
export type { ProgramOrganizationCoordinatedDropdownsProps }
