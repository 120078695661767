import React from 'react'
import { Button, TextField } from '@mui/material'

import './styles.sass'

interface InputTextProps {
  blur: { [x: number]: boolean }
  handleNext: Function
  number: number
  questionHeading: String
  setValue: Function
  value: string | number | undefined
  qsNumber: number
  setQSNumber: Function
}

const InputText: React.FC<InputTextProps> = (props) => {
  const { blur, number, handleNext, questionHeading, value, setValue, qsNumber } = props

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setValue((event.target as HTMLInputElement).value)

  return (
    <div className={`input-text ${blur[number] ? '' : 'is-blur no-pointer-event'}`}    >
      <p className='question-heading'>{questionHeading}</p>
      <TextField
        label=''
        size='small'
        value={value}
        onChange={handleChange}
      />
      {qsNumber == number &&
        <Button variant='outlined' fullWidth onClick={() => handleNext(number)}>
          Continue
        </Button>
      }
    </div >
  )
}

export default InputText
export type { InputTextProps }
