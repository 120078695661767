import { toast } from 'react-toastify'
import I18n from '../config/I18n'
import { apiClient } from './apiClient'
import { FormValues } from '../FormValuesInterfaces'

const handleDownloadCsv = (formValues: FormValues | undefined) => {
  apiClient
    .post(
      `/${I18n.locale}/admin/data_dashboard/download_csv`,
      JSON.stringify({ form_values: JSON.stringify(formValues) }),
      {
        responseType: 'blob',
      }
    )
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const a = document.createElement('a')
      a.href = url
      a.download = 'response_data.csv'
      document.body.appendChild(a)
      a.click()
      a.remove()
      window.URL.revokeObjectURL(url)
    })
    .catch((error) => {
      if (error.response && error.response.data && error.response.data.error) {
        toast.error(error.response.data.error)
      } else {
        toast.error('An error occurred while downloading the CSV.')
      }
    })
}

export default handleDownloadCsv
