import React, { useState, useContext, type Context, type Dispatch } from 'react'
import { Checkbox, Divider, FormControlLabel, RadioGroup, Radio, FormControl } from '@material-ui/core'

import Select from 'react-select'

import InputField from './InputField'
import { OOP_MEDICAL_EXPENSES_QP, PRIVATE_PLAN_TYPES } from '../../Constants'
import { scrollToTop, getSelectdValue, anyBlindAdult, anyDisabledAdult, anyBlindChild, anyDisabledChild } from '../../Helper'
import '../../component_styles/BenefitsCliff.sass'
import NavigationButtons from './NavigationButtons'
import { calculateHealthcareAmount, getBenefitOptions } from '../Employee/helpers/Benefits'
import { type BenefitDataCompany } from '../../EmployeeCalculatorInterface'
import { type FormValues } from '../../FormValuesInterfaces'
import OtherBenefitsDeduction from './OutcomeScreen/OtherBenefitsDeduction'

// const _ = require('lodash')

interface StepSixProps {
  formValuesContext: Context<FormValues>
  benefitDataCompany: BenefitDataCompany
  setValues: Dispatch<FormValues>
  handleNext: () => void
  handleBack: () => void
  step: number
}

const StepSix: React.FC<StepSixProps> = ({ formValuesContext, benefitDataCompany, setValues, handleBack, handleNext, step }) => {
  const formValues: any = useContext(formValuesContext)

  const healthCoverage = formValues.adults[0]?.healthBenefitData?.coverage
  const coverageOptions = getBenefitOptions(formValues?.employee?.company_name, 'health', benefitDataCompany).coverageOptions

  const determinePrivatePlanType = () => formValues?.b2bCalculator ? 'employer' : 'individual'
  const determineHlthPlanEstimateSource = () => formValues?.b2bCalculator ? 'user-entered' : 'calc_estimate'
  const determineOtherPlanEstimateSource = () => formValues?.b2bCalculator ? 'user-entered' : ''

  const determineHlthAmtParentM = () => {
    let familyStructure = ''
    let taxFilingStatus = formValues?.adults[0]?.filingStatus

    if (formValues?.b2bCalculator) {
      if ((coverageOptions.length === 2 || coverageOptions.length === 4) && ((taxFilingStatus === 'single' || taxFilingStatus === 'married') && (healthCoverage === 'single' || healthCoverage === 'family' || healthCoverage === 'children'))) {
        familyStructure = 'single'
      } else if ((coverageOptions.length === 2 || coverageOptions.length === 4) && (taxFilingStatus === 'married' && (healthCoverage === 'spouse' || healthCoverage === 'family'))) {
        familyStructure = 'spouse'
      } else if (coverageOptions.length === 2 && (taxFilingStatus === 'married' && healthCoverage === 'family')) {
        familyStructure = 'family'
      }
    }
    return calculateHealthcareAmount(formValues.adults[0]?.healthBenefitData?.provider, formValues.adults[0]?.healthBenefitData?.type, familyStructure)?.amount
  }

  const determineHlthAmtFamilyM = () => {
    let familyStructure = ''
    let taxFilingStatus = formValues?.adults[0]?.filingStatus

    if (formValues?.b2bCalculator) {
      if ((coverageOptions.length === 2 || coverageOptions.length === 4) && ((taxFilingStatus === 'single' || taxFilingStatus === 'married') && healthCoverage === 'single')) {
        familyStructure = 'single'
      } else if ((coverageOptions.length === 2 || coverageOptions.length === 4) && ((taxFilingStatus === 'single' || taxFilingStatus === 'married') && healthCoverage === 'family')) {
        familyStructure = 'family'
      } else if ((coverageOptions.length === 2 || coverageOptions.length === 4) && (taxFilingStatus === 'single' && healthCoverage === 'children')) {
        familyStructure = 'children'
      } else if ((coverageOptions.length === 2 || coverageOptions.length === 4) && (taxFilingStatus === 'married' && healthCoverage === 'spouse')) {
        familyStructure = 'spouse'
      }
    }
    return calculateHealthcareAmount(formValues.adults[0]?.healthBenefitData?.provider, formValues.adults[0]?.healthBenefitData?.type, familyStructure)?.amount
  }

  // const [childContinuousCoverage, setChildContinuousCoverage] = useState(formValues?.childContinuousCoverage ?? false)
  const [privateplanType, setPrivateplanType] = useState(formValues?.privateplanType ?? determinePrivatePlanType())
  // const [parentContinuousCoverage, setParentContinuousCoverage] = useState(formValues?.parentContinuousCoverage ?? {})
  // const [abawdPremiumReduction, setAbawdPremiumReduction] = useState(formValues?.abawdPremiumReduction ?? {})
  const [hlthCostsOopM, setHlthCostsOopM] = useState(formValues?.hlthCostsOopM ?? 0)
  const [disabilityMedicalExpensesMnth, setDisabilityMedicalExpensesMnth] = useState(formValues?.disabilityMedicalExpensesMnth ?? 0)
  const [hlthPlanEstimateSource, setHlthPlanEstimateSource] = useState(formValues?.hlthPlanEstimateSource ?? determineHlthPlanEstimateSource())
  const [otherPlanEstimateSource, setOtherPlanEstimateSource] = useState(formValues?.otherPlanEstimateSource ?? determineOtherPlanEstimateSource())
  const [hlthAmtParentM, setHlthAmtParentM] = useState(formValues?.hlthAmtParentM ?? determineHlthAmtParentM() ?? 0)
  const [hlthAmtFamilyM, setHlthAmtFamilyM] = useState(formValues?.hlthAmtFamilyM ?? determineHlthAmtFamilyM() ?? 0)
  const [isChildHealthInsuranceAvailable, setIsChildHealthInsuranceAvailable] = useState(formValues?.isChildHealthInsuranceAvailable ?? false)
  const [selectedOtherBenefits, setSelectedOtherBenefits] = useState<string[]>(formValues?.selectedOtherBenefits ?? [])
  const [OtherBenefitsDeductionDetails, setOtherBenefitsDeductionDetails] = useState<{ [key: string]: { amount: number; employerContribution: number; frequency: string } }>(
    formValues?.otherBenefitsDeductionDetails ?? {}
  )
  const [isChanged, setIsChanged] = useState(false)
  const saveUpdatedData = (): void => {
    setValues({
      ...formValues,
      // privateplanType,
      hlthPlanEstimateSource,
      otherPlanEstimateSource,
      hlthAmtParentM,
      hlthAmtFamilyM,
      // parentContinuousCoverage,
      selectedOtherBenefits,
      // abawdPremiumReduction,
      hlthCostsOopM,
      disabilityMedicalExpensesMnth,
      isChildHealthInsuranceAvailable,
      otherBenefitsDeductionDetails: OtherBenefitsDeductionDetails,
    })
  }

  const handleSubmit = (): void => {
    handleNext()
    saveUpdatedData()
  }

  /*
  Removing for production: https://yourparsley.atlassian.net/browse/PAR-604
  const renderContinousCoverageCheckboxes = (index: number): JSX.Element => {
    _.get(formValues, ['adults', index, 'parentContinuousCoverage']) === undefined &&
      _.set(formValues, ['adults', index, 'parentContinuousCoverage'], false)

    return (
      <FormControlLabel
        key={index}
        labelPlacement='bottom'
        control={<Checkbox
          checked={parentContinuousCoverage[index] ?? false}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            _.set(formValues, ['adults', index, 'parentContinuousCoverage'], e.target.checked)
            setParentContinuousCoverage({ ...parentContinuousCoverage, [index]: e.target.checked })
            setIsChanged(true)
          }}
        />}
        label={index < 1 ? 'Me' : formValues.adults[index]?.name ?? `Adult ${index}`}
      />
    )
  }

  const renderAbawdPremiumReductions = (index: number): JSX.Element => {
    _.get(formValues, ['adults', index, 'abawdPremiumReduction']) === undefined &&
      _.set(formValues, ['adults', index, 'abawdPremiumReduction'], false)

    return (
      <FormControlLabel
        key={index}
        labelPlacement='bottom'
        control={<Checkbox
          checked={abawdPremiumReduction[index] ?? false}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            _.set(formValues, ['adults', index, 'abawdPremiumReduction'], e.target.checked)
            setAbawdPremiumReduction({ ...abawdPremiumReduction, [index]: e.target.checked })
            setIsChanged(true)
          }}
        />}
        label={index < 1 ? 'Me' : formValues.adults[index]?.name ?? `Adult ${index}`}
      />
    )
  }
  */

  scrollToTop()

  return (
    <>
      <div className='step-six'>
        <h3>Health Insurance Information</h3>

        {formValues.check_hlth && <p className='health-insurance-paragraph'>You have selected Medicaid/BadgerCare for health coverage, please select a type of health coverage to model if you or your family become ineligible for BadgerCare. Parsley determines each family member’s eligibility for BadgerCare and Marketplace Premium Tax Credits. At this time, the calculator assumes that both adults will use Marketplace or an employer plan as selected below, not a combination of both. </p>}
        <div className='health-insurance-type'>
          <div className='health-insurance-info'>
            <b>Type of health insurance coverage</b>
          </div>
          <FormControl>
            <Select
              className='basic-single'
              classNamePrefix='select'
              placeholder={'Child Care Type'}
              isSearchable={false}
              onChange={(e: React.ChangeEvent<HTMLInputElement> | any) => {
                setPrivateplanType(e.value)
                setIsChanged(true)
              }}
              options={PRIVATE_PLAN_TYPES}
              value={getSelectdValue(privateplanType, PRIVATE_PLAN_TYPES)}
            />
          </FormControl>
        </div>
        {
          formValues?.b2bCalculator && <p>Prefills below assume use of employer health plan already selected. You may edit below. Dental, vision and retirement deductions selected will also be applied on the final screen.</p>
        }
        {
          (formValues.childrenCount > 0 && formValues.check_hlth) &&
          <div className='d-flex'>
            <FormControlLabel
              control={<Checkbox checked={isChildHealthInsuranceAvailable ?? false} />}
              label='Please check if health insurance is available through your employer that would cover your children: '
              labelPlacement='start'
              onChange={(_e: React.ChangeEvent<{}>, checked: boolean) => {
                setIsChildHealthInsuranceAvailable(checked)
                setIsChanged(true)
              }}
            />
          </div>
        }

        <Divider />

        <b>Health Insurance Premium</b>
        <RadioGroup
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setHlthPlanEstimateSource(event.target.value)
            setIsChanged(true)
          }}
          value={hlthPlanEstimateSource}
        >
          <div className='amount-input'>
            <FormControlLabel
              value='calc_estimate'
              control={<Radio checked={hlthPlanEstimateSource === 'calc_estimate'} />}
              label='I want the calculator to estimate what my family’s health insurance premiums would be. If you choose this option for an employer plan, estimates are based on average costs of employer plans in your state.)'
              onChange={() => {
                setHlthAmtParentM(0)
                setHlthAmtFamilyM(0)
                setIsChanged(true)
              }}
            />

            <FormControlLabel value='user-entered' control={<Radio checked={hlthPlanEstimateSource === 'user-entered'} />} label='I know what my family’s premium would be. My health insurance premium would be:' />
            {hlthPlanEstimateSource === 'user-entered' &&
              <>
                <div className='health-insurance-type'>
                  <b style={{ marginTop: '20px' }}>For Adults Only: </b>
                  <p>If you are currently paying healthcare premiums for yourself and/or any other adult in your household, enter that here.  Do not include the cost to cover children in this line.</p>
                  <InputField
                    setValue={setHlthAmtParentM}
                    setIsChanged={setIsChanged}
                    label='Amount in $'
                    value={hlthAmtParentM}
                    type='tel'
                  />
                </div>
                {formValues.childrenCount > 0 && (isChildHealthInsuranceAvailable || hlthAmtFamilyM) ?
                  <div className='health-insurance-type'>
                    <b>For Adults Plus Children:</b>
                    <br></br>
                    <p>Enter premiums you pay for the entire household, including children:</p>
                    <InputField
                      disabled={formValues.childrenCount === 0}
                      setValue={setHlthAmtFamilyM}
                      setIsChanged={setIsChanged}
                      label='Amount in $'
                      value={hlthAmtFamilyM}
                      type='tel'
                    />
                  </div> : (<></>)}
              </>
            }
          </div>
        </RadioGroup>

        <Divider />

        <b>Additional medical expenses</b>

        <div className='insurance-amount-input'>
          <p>
            Expected {' '}
            <a href={`/quick-peek/${OOP_MEDICAL_EXPENSES_QP}`} className='link' target='blank'>out-of-pocket medical expenses</a>{' '}
            such as copays and deductibles. (Do not include premium payments):
          </p>
          <InputField
            setValue={setHlthCostsOopM}
            setIsChanged={setIsChanged}
            label='Amount in $'
            value={hlthCostsOopM}
            type='tel'
          />
        </div>

        <Divider />

        <OtherBenefitsDeduction
          formValues={formValues}
          otherPlanEstimateSource={otherPlanEstimateSource}
          selectedOtherBenefits={selectedOtherBenefits}
          setSelectedOtherBenefits={setSelectedOtherBenefits}
          setOtherPlanEstimateSource={setOtherPlanEstimateSource}
          OtherBenefitsDeductionDetails={OtherBenefitsDeductionDetails}
          setOtherBenefitsDeductionDetails={setOtherBenefitsDeductionDetails}
          setIsChanged={setIsChanged}
        />

        {(anyBlindAdult(formValues.adults) || anyDisabledAdult(formValues.adults) ||
          anyBlindChild(formValues.children) || anyDisabledChild(formValues.children)) &&
          <div className='insurance-amount-input'>
            <p>How much of the above out-of-pocket medical expenses are for the Elderly, Blind or disabled family member(s) per month: </p>
            <InputField
              setValue={setDisabilityMedicalExpensesMnth}
              setIsChanged={setIsChanged}
              label='Amount in $'
              value={disabilityMedicalExpensesMnth}
              type='tel'
            />
          </div>
        }

        {/*
        Removing for production: https://yourparsley.atlassian.net/browse/PAR-604
        {formValues.check_hlth &&
          <>
            <b>Please check if anyone in your household using Medicaid/BadgerCare is on Continuous Coverage made available due to COVID 19:</b>
            <div className='d-flex'>
              {formValues?.childrenCount > 0 &&
                <FormControlLabel
                  labelPlacement='bottom'
                  onChange={() => {
                    setChildContinuousCoverage(!childContinuousCoverage)
                    setIsChanged(true)
                  }}
                  control={<Checkbox checked={childContinuousCoverage} />}
                  label='Child(ren)'
                />}
              {Object.values(formValues.adults).map((_adult, index: number) => renderContinousCoverageCheckboxes(index))}
            </div>

            <Divider />

            {formValues.adultsCount > 1 &&
              <>
                <b> Do any adults without dependents(ABAWDs) use Medicaid/BadgerCare and receive a premium discount:</b>
                <div className='d-flex'>
                  {convertObjectToArray(formValues?.adultsCount).map((index: number) => renderAbawdPremiumReductions(index))}
                </div>
              </>}
          </>}
          */}
      </div>

      <NavigationButtons
        handleBack={handleBack}
        handleSubmit={handleSubmit}
        step={step}
        saveUpdatedData={saveUpdatedData}
        isChanged={isChanged}
      />
    </>
  )
}

export default StepSix
export type { StepSixProps }
