import React from 'react'
import I18n from '../config/I18n'
import BenefitsOutcomeCard, {  BenefitsOutcomeCardProps } from './BenefitsOutcomeCard'
import '../component_styles/BenefitsOutcomeCard.sass'
import '../component_styles/BenefitsOutcomeCardCollection.sass'

interface BenefitsOutcomeCardCollectionProps {
  cards: BenefitsOutcomeCardProps[]
  title?: string
  incrementType: 'yearly'|'monthly'|'weekly'
}

const BenefitsOutcomeCardCollection: React.FC<BenefitsOutcomeCardCollectionProps> = (props) => {
  const { cards, incrementType } = props
  const title = props.title || I18n.t("benefits")

  return (
    <div className="content-container">
      <div className="outcome-container">
        <h3>{ title }</h3>
        { cards.map((card, index) => {
            return (
              <div className="outcome-section" key={ `card-${index}` }>
                <BenefitsOutcomeCard
                  { ...card }
                  outcomeIncrementType={ incrementType }
                />
              </div>
            )
          })
        }
      </div>
    </div>
  )
}

export default BenefitsOutcomeCardCollection
export type { BenefitsOutcomeCardCollectionProps }
