import React, { memo } from 'react'

import BenefitDetails from '../BenefitDetails'
import { calculateDentalAmount } from '../helpers/Benefits'
import { type BenefitProps } from '../../../EmployeeCalculatorInterface'

const DentalBenefits: React.FC<BenefitProps> = memo(({ formValuesContext, benefitDataCompany, setValues }) => {
  return (
    <BenefitDetails
      title='Dental Benefits'
      benefitType='dental'
      calculateAmount={calculateDentalAmount}
      formValuesContext={formValuesContext}
      benefitDataCompany={benefitDataCompany}
      setValues={setValues}
    />
  )
})

export default DentalBenefits
