import React, { Context, memo } from 'react'
import { Button } from '@mui/material'

import BasicCaseDetails from '../BasicCaseDetails'
import { FormValues } from '../../../FormValuesInterfaces'

import './styles.sass'

interface ScenarioVariablesProps {
  selectedCase: { name: string, id: number, description: string }
  selectedFolder: { name: string, id: number }
  handleNextClick: () => void
  adminValuesContext: Context<FormValues>
  setSelectedCase: React.Dispatch<
    React.SetStateAction<{ name: string, id: number, description: string }>
  >
}

const CaseOverview: React.FC<ScenarioVariablesProps> = memo((props) => {
  const { selectedCase, handleNextClick, adminValuesContext, selectedFolder, setSelectedCase } =
    props
  return (
    <div>
      <BasicCaseDetails
        selectedCase={selectedCase}
        adminValuesContext={adminValuesContext}
        selectedFolder={selectedFolder}
        setSelectedCase={setSelectedCase}
      />
      <div className='caseOverviewConatiner'>
        <Button variant='text' onClick={handleNextClick} className='variableButton'>
          View Scenario Variables
        </Button>
      </div>
    </div>
  )
})

export default CaseOverview
