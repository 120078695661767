import React, { useState } from 'react'
import ReactDOM from 'react-dom'
import I18n from '../config/I18n'
import HamburgerMenuItem from './HamburgerMenuItem'
import '../component_styles/HamburgerMenu.sass'

interface HamburgerMenuProps {
  isShown: boolean
  userIsAdmin: boolean
  userIsGuest: boolean
  userId: number
  sitemapDate?: string
}

interface UseHamburgerMenuProps {
  isShown: boolean
  toggle: () => void
}

export const useHamburgerMenu = (): UseHamburgerMenuProps => {
  const [isShown, setIsShown] = useState<boolean>(false)
  const toggle = () => { setIsShown(!isShown) }
  return {
    isShown,
    toggle
  }
}

export const HamburgerMenu: React.FC<HamburgerMenuProps> = (props) => {
  const { isShown, userIsAdmin, userIsGuest, userId } = props

  const menu = (
    <>
      <div className='menu-container'>
        <div className={`menu ${isShown ? 'visible' : 'hidden'}`}>
          {userIsAdmin &&
            <>
              <h2>Admin Menu</h2>
              <HamburgerMenuItem
                text={I18n.t('navigation.admin.data_dashboard')}
                href={`/${I18n.locale}/admin/data_dashboard`}
                />
              <HamburgerMenuItem
                text={I18n.t('navigation.admin.import_all')}
                href={`/${I18n.locale}/admin/import_all`}
              />
              <HamburgerMenuItem
                text={I18n.t('navigation.admin.view_users')}
                href={`/${I18n.locale}/users`}
              />
              <HamburgerMenuItem
                text={I18n.t('navigation.admin.view_explore_cards')}
                href={`/${I18n.locale}/admin/explore_cards`}
              />
              <HamburgerMenuItem
                text={I18n.t('navigation.admin.view_quick_peek_cards')}
                href={`/${I18n.locale}/admin/quick-peek`}
              />
              <HamburgerMenuItem
                text={I18n.t('navigation.admin.view_categories')}
                href={`/${I18n.locale}/admin/categories`}
              />
              <HamburgerMenuItem
                text={I18n.t('navigation.admin.view_call_to_actions')}
                href='/admin/call_to_actions'
              />
              <HamburgerMenuItem
                text={I18n.t('navigation.admin.view_hashtags')}
                href='/admin/hashtags'
              />
              <HamburgerMenuItem
                text={I18n.t('navigation.admin.view_organizations')}
                href='/admin/organizations'
              />
              <HamburgerMenuItem
                text={I18n.t('navigation.admin.view_programs')}
                href='/admin/programs'
              />
              <HamburgerMenuItem
                text={I18n.t('navigation.admin.view_statistics')}
                href='/admin/statistics'
              />
              <HamburgerMenuItem
                text={`Sitemap ${props.sitemapDate}`}
                href={`/${I18n.locale}/dashboard/sitemap.xml`}
              />
              <hr></hr>
              <h2>Regular Menu</h2>
            </>
          }
          {userId && !userIsGuest &&
            <>
              <h3>{I18n.t('snapshot.snapshots')}</h3>
              <HamburgerMenuItem
                text={I18n.t('snapshot.my_snapshots')}
                href={`/${I18n.locale}/seeker_snapshots`}
                className='primary-link'
              />
              <HamburgerMenuItem
                text={I18n.t('snapshot.create_new_snapshot')}
                href={'#'}
                className='primary-link'
              />
            </>
          }
          {userId &&
            <>
              <h3>{I18n.t('navigation.notes')}</h3>
              <HamburgerMenuItem
                text={I18n.t('navigation.my_notes')}
                href={`/${I18n.locale}/notes`}
                className='primary-link'
              />
            </>
          }
          <hr></hr>
          <HamburgerMenuItem
            text={I18n.t('navigation.employee_calculator')}
            href={`/${I18n.locale}/employers`}
          />
          <HamburgerMenuItem
            text={I18n.t('navigation.benefits_cliff_calculator')}
            href={`/${I18n.locale}/benefits_cliff_calculators`}
          />
          <HamburgerMenuItem
            text={I18n.t('navigation.help')}
            href='#'
          />
          <HamburgerMenuItem
            text={I18n.t('navigation.about_us')}
            href='#'
          />
          <HamburgerMenuItem
            text={I18n.t('navigation.data_policy')}
            href='#'
          />
          <HamburgerMenuItem
            text={I18n.t('navigation.terms_and_conditions')}
            href='#'
          />
          <hr></hr>
          {userId && userIsGuest &&
            <HamburgerMenuItem
              text={I18n.t('navigation.delete_my_data')}
              href={`/${I18n.locale}/users/${userId}/data_information`}
            />
          }
          {userId && !userIsGuest &&
            <HamburgerMenuItem
              text={I18n.t('navigation.delete_my_account')}
              href={`/${I18n.locale}/users/${userId}/data_information`}
            />
          }
          {userId &&
            <HamburgerMenuItem
              text={I18n.t('navigation.sign_out')}
              href='/users/sign_out'
              urlMethod='delete'
            />
          }
        </div>
      </div>
    </>
  )

  if (isShown) {
    $('.main-content').addClass('with-open-menu')
    return ReactDOM.createPortal(menu, document.body)
  } else {
    $('.main-content').removeClass('with-open-menu')
    return null
  }
}

export default HamburgerMenu
export type { HamburgerMenuProps }
