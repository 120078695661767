import React, { memo } from 'react'
import Modal from '@mui/material/Modal'
import { Button } from '@material-ui/core'

import './styles.sass'

type ZipCodeInfoProps = {
  isOpen: boolean
  setIsOpen: (value: boolean) => void
}

const ZipCodeInfo: React.FC<ZipCodeInfoProps> = memo((props) => {
  const { isOpen, setIsOpen } = props
  
  const handleButtonClick = () => setIsOpen(false)

  return (
    <Modal open={isOpen} className='modal-background'>
      <div className='modal-box'>
        <b>Why do we need your zip code?</b>
        <p>Knowing where you live will help us estimate your possible tax credits and other benefits that may be available to you.</p>

        <div className='modal-button'>
          <Button variant='outlined' className='outlined-button' onClick={handleButtonClick}> Close </Button>
        </div>
      </div>
    </Modal>
  )
})

export default ZipCodeInfo
