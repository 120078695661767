import React, { memo } from 'react'
import Modal from '@mui/material/Modal'
import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'
import { Button, Link } from '@material-ui/core'

import './styles.sass'
import { addThousandsSeparator } from '../../../Helper'

interface TaxModalProps {
  isOpen: boolean
  setIsOpen: (valuestep: boolean) => void
  position: string
  handleNext: () => void
  value: number
  handleBack: () => void
}

const BeforeAfterEstimatedTax: React.FC<TaxModalProps> = memo((props) => {
  const { isOpen, setIsOpen, position, handleNext, handleBack, value } = props

  const handleClose = (): void => { setIsOpen(false) }

  const handleOKButtonClick = (): void => {
    handleNext()
    handleClose()
  }

  const handleLinkClick = (): void => {
    handleBack()
    handleClose()
  }

  return (
    <Modal open={isOpen} className='modal-background'>
      <div className='tax-modal-box'>
        <div className='modal-close-button'>
          <IconButton disableRipple onClick={handleClose}>
            <CloseIcon fontSize='large' />
          </IconButton>
        </div>

        <h3>${(addThousandsSeparator(parseFloat(value.toString()).toFixed(2).toString()))}</h3>
        <p>Estimated <span>{position}</span> Tax per Pay Period </p>
        <Link className='link-text' onClick={handleLinkClick}>Edit Pay Details</Link>

        <div className='modal-option'>
          <b>Next we&apos;ll take a look at your {position === 'After' ? 'benefits' : 'estimated taxes'}.</b>

          <div className='modal-button'>
            <Button variant='contained' className='purple-button' onClick={handleOKButtonClick}> OK </Button>
          </div>
        </div>
      </div>
    </Modal>
  )
})

export default BeforeAfterEstimatedTax
