import React, { useEffect, useState } from 'react'
import { TextField, MenuItem, Select ,FormControl, SelectChangeEvent } from '@mui/material'

import { ALL_POPULAR_RECENT, TIER_OPTIONS } from '../Constants'
import { QuickPeekCardProps } from './QuickPeekCard'
import { timesAgo, goToURL } from '../Helper'
import CategorySlider from './CategorySlider'
import I18n from '../config/I18n'

import '../component_styles/ShowTierQuickPeeks.sass'

const _ = require('lodash')

interface ShowTierQuickPeeksProps {
  cards: QuickPeekCardProps[]
  categoryTags: string[]
}

type OptionsType = {
  value: number | string,
  label: number | string
}

const ShowTierQuickPeeks: React.FC<ShowTierQuickPeeksProps> = ({ cards }) => {
  const [allCategories, setAllCategories] = useState(cards)
  const [searchQP, setSearchQP] = useState('')
  const [tierSort, setTierSort] = useState<number | string>(0)

  useEffect(()=> {
    setAllCategories(_.orderBy(cards, 'tier', 'asc'))
  }, [])

  const renderTier = (card: QuickPeekCardProps, index: number) => (
    <div
      className='topic'
      key={index}
      onClick={() => goToURL(`/${I18n.locale}/quick-peek/${card.id}`)}
    >
      <h3>{card.title}</h3>
      <p>{timesAgo(card.created_at)}</p>
    </div>
  )

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.length >= 0) {
      setSearchQP(event.target.value)
      setAllCategories(cards.filter((card: QuickPeekCardProps) => 
        card.title.toLowerCase().includes(event.target.value.toLowerCase())))
    } else {
      setAllCategories(cards)
    }
  }

  const handleTierSort = (event: SelectChangeEvent) => {
    setTierSort(event.target.value as number | string)
    switch (event.target.value as number | string ) {
      case 0:
        setAllCategories(_.orderBy(cards, 'tier', 'asc'))
        break
      case 1:
        setAllCategories(_.orderBy(cards, 'tier', 'desc'))
        break
      case 2:
        setAllCategories(_.orderBy(cards, 'created_at', 'asc'))
        break
      case 3:
        setAllCategories(_.orderBy(cards, 'created_at', 'desc'))
        break
      case 4:
        setAllCategories(_.orderBy(cards, 'published_at', 'asc'))
        break
      case 5:
        setAllCategories(_.orderBy(cards, 'published_at', 'desc'))
        break
      default:
        setAllCategories(cards)
        break
    }
  }

  return (
    <div className='content show-tier-quick-peeks'>
      <div className='filters'>
        <TextField
          margin='dense'
          onChange={handleSearch}
          placeholder='Search Quick Peek'
          value={searchQP}
          variant='outlined'
        />

        <FormControl size='small'>
          <Select
            onChange={handleTierSort}
            value={tierSort.toString()}
            inputProps={{ 'aria-label': 'Text' }}
          >
            {TIER_OPTIONS.map((option: OptionsType) =>
              <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>)
            }
          </Select>
        </FormControl>
      </div>

      <CategorySlider categories={ALL_POPULAR_RECENT} cards={cards} setAllCategories={setAllCategories} />

      {
        allCategories.length > 0 ?
          allCategories.map((card: QuickPeekCardProps, index: number) => renderTier(card, index))
          :
          <p>No Quick Peek to Show</p>
      }
    </div >
  )
}

export default ShowTierQuickPeeks
export type { ShowTierQuickPeeksProps }
