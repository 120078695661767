import React, { memo, useEffect, useState } from 'react'
import { createFolder, deleteFolder, getAllFolders, updateFolder } from '../../../api/folder'
import { AdminStepProps } from '../../../EmployeeCalculatorInterface'
import FolderItemManagment from './FolderItemManagment'
import 'react-toastify/dist/ReactToastify.css'
import { Pagination } from '@mui/material'
import NotificationToast, { showErrorToast, showSuccessToast } from '../../NotificationToast'
import { ITEMS_PER_PAGE } from '../../../Constants'

interface Item {
  id: number
  name: string
  user_id: number
  created_at: string
  updated_at: string
}

const FolderManagement: React.FC<AdminStepProps> = memo((props) => {
  const { handleItemSelection } = props
  const [folders, setFolders] = useState<Item[]>([])
  const [page, setPage] = useState<number>(1)
  const [totalFolders, setTotalFolders] = useState<number>(0)

  const fetchAllFolders = async () => {
    try {
      const response = await getAllFolders(page)
      setFolders(response.folders)
      setTotalFolders(response.meta.total_count)
    } catch (error) {
      showErrorToast('Error fetching folders')
    }
  }

  useEffect(() => {
    fetchAllFolders()
  }, [page])

  const handleCreateFolder = async (name: string) => {
    try {
      await createFolder(name)
      fetchAllFolders()
      showSuccessToast('Folder created successfully');

    } catch (error) {
      showErrorToast('Error creating folder')
    }
  }

  const handleDeleteFolder = async (id: number): Promise<void> => {
    await deleteFolder(id)
    fetchAllFolders()
  }

  const handleUpdateFolder = async (id: number, name: string): Promise<void> => {
    await updateFolder(id, name)
    fetchAllFolders()
  }

  const handlePageChange = (_: React.ChangeEvent<unknown>, value: number) => setPage(value)
  return (
    <>
      <FolderItemManagment
        title='Folder'
        itemName='folder'
        itemNamePlural='folders'
        items={folders}
        onCreateItem={handleCreateFolder}
        handleItemSelection={handleItemSelection}
        onDeleteItem={handleDeleteFolder}
        onUpdateItem={handleUpdateFolder}
      />
      {folders.length > 0 && (
        <div className="pagination-container">
          <Pagination
            count={Math.ceil(totalFolders / ITEMS_PER_PAGE)}
            page={page}
            onChange={handlePageChange}
            color="primary"
          />
        </div >)}

      <NotificationToast />
    </>
  )
})

export default FolderManagement
