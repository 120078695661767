import { toast } from 'react-toastify'

import { handleRedirect } from '../Helper'
import { apiClient } from './apiClient'
import I18n from '../config/I18n'

export const uploadEmployeesCSV = (file: File, id: string, setEmployees: Function): void => {
  const formData = new FormData()
  formData.append('file', file)
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  }

  apiClient
    .post(`/${I18n.locale as string}/employers/${id}/upload_employees_csv`, formData, config)
    .then((response) => {
      toast.success(response.data?.message)
      setEmployees(response.data?.employees)
    })
    .catch((error) => {
      if (error.response?.data?.error) {
        toast.error(error.response.data.error)
      } else {
        toast.error('An error occurred while processing the Employees CSV.')
      }
    })
}

const handleEmployerAuthentication = async (url: string, data: object, setErrorMessage: React.Dispatch<React.SetStateAction<string[]>>, setUploading: (value: boolean) => void): Promise<void> => {
  const config = {
    headers: {
      Accept: 'application/html'
    }
  }

  setUploading(true)

  apiClient
    .post(url, data, config)
    .then(({ data }) => {
      toast.success(data.message)
      handleRedirect(I18n.locale, () => { }, `employers`)
    })
    .catch(({ response }) => {
      setUploading(false)
      setErrorMessage(response.data.errors)
    })
    .finally(() => {
      setTimeout(() => {
        setUploading(false)
      }, 3000)
    })
}

export const signUpEmployer = async (data: object, setErrorMessage: React.Dispatch<React.SetStateAction<string[]>>, setUploading: (value: boolean) => void): Promise<void> => {
  await handleEmployerAuthentication('/employers', data, setErrorMessage, setUploading)
}

export const signInEmployer = async (data: object, setErrorMessage: React.Dispatch<React.SetStateAction<string[]>>, setUploading: (value: boolean) => void): Promise<void> => {
  await handleEmployerAuthentication('/employers/sign_in', data, setErrorMessage, setUploading)
}
