import React, { FC } from 'react'
import { Button } from '@mui/material'

import Select from 'react-select'

import './styles.sass'

interface QuestionSelectProps {
  blur: { [x: number]: boolean }
  handleNext: Function
  number: number
  questionHeading: String
  setValue: Function
  value: string | number | undefined
  qsNumber: number
  setQSNumber: Function
  options: string[]
}

const QuestionSelect: FC<QuestionSelectProps> = (props) => {
  const { blur, options, questionHeading, number, handleNext, value, setValue, qsNumber } = props

  return (
    <div className={`question-select ${blur[number] ? '' : 'is-blur no-pointer-event'}`}>
      <p className='question-heading'>{questionHeading}</p>
      <Select
        value={value}
        options={options}
        className={'question-select-input'}
        classNamePrefix='select'
        onChange={(event: any) => setValue(event)}
      />

      {qsNumber == number &&
        <Button variant='outlined' fullWidth onClick={() => handleNext(number)}>
          Continue
        </Button>
      }
    </div >
  )
}

export default QuestionSelect
