import { toast } from 'react-toastify'

import { apiClient, headers } from './apiClient'
import { goToURL } from '../Helper'
import I18n from '../config/I18n'

export const createNewCategory = async (
  categoryData: object,
  categoryIcon: string | number | undefined,
  categoryImage: string | number | undefined,
  iconAltText: string | number | undefined,
  imageAltText: string | number | undefined,
  setIsLoading: Function
) => {
  const categoryId = await apiClient
    .post(`/${I18n.locale as string}/admin/categories`, categoryData)
    .then(({ data }) => {
      toast.success(data?.message)
      return data.id
    })
    .catch(({ response }) => toast.error(response?.data?.message))

  if (categoryId) {
    headers['Content-Type'] = 'multipart/form-data'
    const result  = await apiClient
      .post(`/${I18n.locale as string}/admin/categories/${categoryId}/upload_icon`,
        {
          category_icon: categoryIcon || '',
          alt_text: iconAltText,
        }, { headers })
      .catch(() => toast.error('Category Icon Upload Error!'))

    result && apiClient
      .post(`/${I18n.locale as string}/admin/categories/${categoryId}/upload_icon`,
        {
          category_image: categoryImage || '',
          alt_text: imageAltText
        }, { headers })
      .catch(() => toast.error('Category Image Upload Error!'))
  }

  handleRedirect(setIsLoading)
}

export const updateCategory = async (
  id: number,
  categoryData: object,
  categoryIcon: string | number | undefined,
  categoryImage: string | number | undefined,
  iconAltText: string | number | undefined,
  imageAltText: string | number | undefined,
  setIsLoading: Function
) => {
  const status: string | number = await apiClient
    .patch(`/${I18n.locale as string}/admin/categories/${id}`, categoryData)
    .then(({ data, status }) => {
      toast.success(data?.message)
      return status
    })
    .catch(({ response }) => toast.error(response?.data?.message))

  if (status == 200) {
    headers['Content-Type'] = 'multipart/form-data'
    const result  = await apiClient
      .post(`/${I18n.locale as string}/admin/categories/${id}/upload_icon`,
        {
          category_icon: categoryIcon || '',
          alt_text: iconAltText,
        }, { headers })
      .catch(() => toast.error('Category Icon Upload Error!'))

    result && apiClient
      .post(`/${I18n.locale as string}/admin/categories/${id}/upload_icon`,
        {
          category_image: categoryImage || '',
          alt_text: imageAltText
        }, { headers })
      .catch(() => toast.error('Category Image Upload Error!'))
  }

  handleRedirect(setIsLoading)
}

export const deleteCategory = (id: number, setCategories: Function) =>
  apiClient
    .delete(`/${I18n.locale as string}/admin/categories/${id}`)
    .then((response) => {
      setCategories(response?.data?.categories)
      toast.success(response?.data?.message)
    })
    .catch(({ response }) => toast.error(response?.data?.message))

const handleRedirect = (setIsLoading: Function) => {
  setTimeout(() => {
    goToURL(`/${I18n.locale as string}/admin/categories`)
    setIsLoading(false)
  }, 2500)
}
