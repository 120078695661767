import React, { FC, useState } from 'react'
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField
} from '@material-ui/core'
import { ArrowDropUp, ArrowDropDown, Edit, DeleteOutline } from '@mui/icons-material'
import { styled } from '@mui/material/styles'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  tableCellClasses
} from '@mui/material'
import { ToastContainer } from 'react-toastify'

import { CategoryTypeProps } from '../Category'
import { deleteCategory } from '../../api/category'
import { goToURL } from '../../Helper'
import CustomTableFooter from '../CustomTableFooter'
import I18n from '../../config/I18n'

import './styles.sass'
import 'react-toastify/dist/ReactToastify.css'

const _ = require('lodash')

interface CategoryAdminIndexProps {
  categories: CategoryTypeProps[]
}

const CategoryAdminIndex: FC<CategoryAdminIndexProps> = ({ categories }) => {
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(25)
  const [allCategories, setAllCategories] = useState(categories)
  const [modalOpen, setModalOpen] = useState(false)
  const [categoryToDel, setCategoryToDel] = useState<number>()
  const [searchQP, setSearchQP] = useState('')
  const [sortOrder, setSortOrder] = useState<Record<string, boolean>>({
    subheading: false,
    title: false
  })

  const handleOpen = (category: CategoryTypeProps) => {
    setModalOpen(true)
    setCategoryToDel(category.id)
  }

  const handleClose = () => {
    setModalOpen(false)
    setCategoryToDel(undefined)
  }

  const handleDelete = () => {
    setModalOpen(false)
    categoryToDel && deleteCategory(categoryToDel, setAllCategories)
  }

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }))

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }))

  const tableHeaderWithIcons = (title: string) => (
    <div className='title-container'>
      <b>{title}</b>
      <div className='up-down-icons'>
        <ArrowDropUp />
        <ArrowDropDown />
      </div>
    </div>
  )

  const filterCategories = (categories: CategoryTypeProps[], event: React.ChangeEvent<HTMLInputElement>) => {
    const basedOnTitle = categories.filter((category: CategoryTypeProps) =>
      category.title.toLowerCase().includes(event.target.value.toLowerCase()))
    const basedOnSubHeading = categories.filter((category: CategoryTypeProps) =>
      category.sub_heading.toLowerCase().includes(event.target.value.toLowerCase()))

    return [...basedOnTitle, ...basedOnSubHeading]
  }

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQP(event.target.value)
    if (event.target.value.length == 0)
      setAllCategories(categories)
    else
      setAllCategories(_.uniqBy(filterCategories(categories, event), 'id'))
  }

  const sortOn = (category: CategoryTypeProps, dependent: string) => {
    if (dependent == 'title')
      return category.title
    else if (dependent == 'subheading')
      return category.sub_heading
  }

  const changeInObject = (column: string) => {
    const changeInObject = sortOrder
    changeInObject[column] = !(changeInObject[column])
    setSortOrder(changeInObject)
  }

  const sortCategory = (categories: CategoryTypeProps[], dependent: string) =>
    _.sortBy(categories, (category: CategoryTypeProps) => sortOn(category, dependent))

  const handleSort = (column: string) => {
    setAllCategories(sortOrder[column] ? sortCategory(allCategories, column) : sortCategory(allCategories, column).reverse())
    changeInObject(column)
  }

  return (
    <div className='category-admin'>
      <h1>Categories</h1>
      <div className='function-container'>
        <Button
          variant='contained'
          onClick={() => goToURL(`/${I18n.locale}/admin/categories/new`)}
        >
          Create Category
        </Button>

        <TextField
          label='Search Category'
          margin='dense'
          onChange={handleSearch}
          placeholder='Search Category'
          value={searchQP}
          variant='outlined'
        />
      </div>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell onClick={() => handleSort('title')}>
                {tableHeaderWithIcons('Title')}
              </StyledTableCell>
              <StyledTableCell align='center' onClick={() => handleSort('subheading')}>
                {tableHeaderWithIcons('Sub Heading')}
              </StyledTableCell>
              <StyledTableCell align='center'><b>Color</b></StyledTableCell>
              <StyledTableCell align='center'><b>Actions</b></StyledTableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {(rowsPerPage > 0
              ? allCategories?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : allCategories).map((category: CategoryTypeProps) => (
                <StyledTableRow key={category.id} >
                  <StyledTableCell
                    className='link-button'
                    onClick={() => goToURL(`/${I18n.locale}/categories/${category.id}`)}
                  >
                    <b>{category.title}</b>
                  </StyledTableCell>
                  <StyledTableCell align='center'>
                    <b>{category.sub_heading}</b>
                  </StyledTableCell>

                  <StyledTableCell align='center'>
                    <div className={`colored-box ${category.color}-background-color `}></div>
                  </StyledTableCell>

                  <StyledTableCell align='center' className='link-button'>
                    <div className='edit-delete'>
                      <div
                        className='edit'
                        onClick={() => goToURL(`/${I18n.locale}/admin/categories/${category.id}/edit`)}
                      >
                        <Edit />
                      </div>

                      <div
                        className='delete'
                        onClick={() => handleOpen(category)}
                      >
                        <DeleteOutline />
                      </div>
                    </div>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
          </TableBody>
        </Table>
        <CustomTableFooter
          length={allCategories.length}
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
        />
      </TableContainer>
      <ToastContainer
        position='top-right'
        autoClose={2500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Dialog open={modalOpen} onClose={() => setModalOpen(false)}>
        <DialogTitle>Alert!!!</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this category?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleDelete}>Delete</Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default CategoryAdminIndex
