import React, { useState } from 'react'
import { ArrowForwardIos } from '@mui/icons-material'

import I18n from '../config/I18n'

import '../component_styles/CategorySlider.sass'
import { MAX_OUTER_WIDTH } from '../Constants'

const _ = require('lodash')

interface CategorySliderProps {
  categories: string[],
  cards: object[],
  setAllCategories: Function
}

const CategorySlider: React.FC<CategorySliderProps> = ({ categories, cards, setAllCategories }) => {
  const [selectedTag, setSelectedTag] = useState('All')

  const filterCategories = (tag: string) => {
    setSelectedTag(tag)

    switch (tag) {
      case 'All':
        return setAllCategories(cards)
      case 'Most Recent':
        return setAllCategories(_.orderBy(cards, 'created_at', 'desc'))
      case 'Most Popular':
        return setAllCategories(_.orderBy(cards, 'visit_count', 'desc'))
      default:
        return setAllCategories(cards.filter((card: any) => card?.tags?.includes(tag)))
    }
  }

  const showForwardArrow = () => location.href == `${location.origin}/${I18n.locale}/categories`

  const scrollHorizontally = () => {
    const scroll = document.querySelector('.category-slider')
    scroll && scroll.scrollBy(400, 0)
  }

  !categories.includes('All') && categories.splice(0, 0, 'All')

  return (
    <div className='d-flex slider'>
      <div className='category-slider'>
        {
          categories.map((val: string) =>
            <div
              className={`category ${selectedTag == val ? 'selected-tag' : ''}`}
              key={val}
              onClick={() => filterCategories(val)}
            >
              {val.charAt(0).toUpperCase() + val.slice(1)}
            </div>
          )
        }
      </div>
      {
        outerWidth > MAX_OUTER_WIDTH && showForwardArrow() &&
        <div className='category forward-icon' onClick={scrollHorizontally}>
          <ArrowForwardIos />
        </div>
      }
    </div>
  )
}

export default CategorySlider
export type { CategorySliderProps }
