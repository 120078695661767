import React, { useState } from 'react'
import {
  Button,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  type SelectChangeEvent
} from '@mui/material'

import BasicCaseDetails from '../BasicCaseDetails'

import './styles.sass'
import { scrollToBottom } from '../../../Helper'

interface Props {
  selectedItem: { name: string, id: number, description: string }
  selectedFolder: { name: string; id: number }
  handleNextClick: () => void
}

const VisualizationConfigurator: React.FC<Props> = ({
  selectedItem,
  selectedFolder,
  handleNextClick,
}) => {
  const [YAxisIncrement, setYAxisIncrement] = useState<string>('Children’s Ages')

  const [xAxisIncrement, setXAxisIncrement] = useState<string>('Children’s Ages')

  const axisOptions: string[] = [
    'Children’s Ages',
    'Income after expense'
  ]

  scrollToBottom()

  const handleCreateVisualization = (): void => {
    handleNextClick()
  }
  return (
    <div>
      <BasicCaseDetails
        selectedCase={selectedItem}

        selectedFolder={selectedFolder}
      />
      <div className='VisualizationConfigurator'>
        <FormControl fullWidth className='formControl'>
          <InputLabel>Y Axis</InputLabel>
          <Select
            labelId='demo-simple-select-label'
            id='demo-simple-select'
            label='Y Axis'
            value={YAxisIncrement}
            onChange={(e: SelectChangeEvent<string>) => { setYAxisIncrement(e.target.value) }}
          >
            {axisOptions.map((option: string, index: number) => (
              <MenuItem key={index} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl fullWidth className='formControl'>
          <InputLabel>X Axis</InputLabel>
          <Select
            labelId='demo-simple-select-label'
            id='demo-simple-select'
            label='X Axis'
            value={xAxisIncrement}
            onChange={(e: SelectChangeEvent<string>) => { setXAxisIncrement(e.target.value) }}
          >
            {axisOptions.map((option: string, index: number) => (
              <MenuItem key={index} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <Button
        variant='contained'
        sx={{
          marginLeft: '20px', background: '#4E49B7', '&:hover': {
            background: '#4E49B7'
          }
        }}
        className='visualizationButton'

        onClick={handleCreateVisualization}
      >
        CREATE VISUALIZATION
      </Button>
    </div>
  )
}

export default VisualizationConfigurator
