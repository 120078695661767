import React, { useContext, useState } from 'react'
import { Button } from '@mui/material'

import MultiSelectInput from '../../MultiSelectInput'
import SelectInput from '../../SelectInput'
import { YES_NO } from '../../../Constants'
import { getNames } from '../../../Helper'
import Education from './Education'

interface PersonalAndFamilyProps {
  setStep: Function
  step: number
  setValues: Function
  formValuesContext: any
}

const PersonalAndFamily: React.FC<PersonalAndFamilyProps> = ({ formValuesContext }) => {
  const formValues: any = useContext(formValuesContext)

  const [isFederalMember, setIsFederalMember] = useState('')
  const [federalMemberNames, setFederalMemberNames] = useState({})
  const [childOfFederalMember, setChildOfFederalMember] = useState('')
  const [childOfFederalMemberNames, setChildOfFederalMemberNames] = useState({})
  const [activeDuty, setActiveDuty] = useState('')
  const [activeDutyNames, setActiveDutyNames] = useState({})
  const [childOfVeteran, setChildOfVeteran] = useState('')
  const [childOfVeteranNames, setChildOfVeteranNames] = useState({})
  const [drugFelony, setDrugFelony] = useState('')
  const [drugFelonyNames, setDrugFelonyNames] = useState({})
  const [fleeFelony, setFleeFelony] = useState('')
  const [fleeFelonyNames, setFleeFelonyNames] = useState({})
  const [isDisabled, setIsDisabled] = useState('')
  const [disabledNames, setDisabledNames] = useState({})
  const [receivedSSI, setReceivedSSI] = useState('')
  const [receivedSSINames, setReceivedSSINames] = useState({})
  const [receivingSSI, setReceivingSSI] = useState('')
  const [receivingSSINames, setReceivingSSINames] = useState({})
  const [unableToWork, setUnableToWork] = useState('')
  const [unableToWorkNames, setUnableToWorkNames] = useState({})
  const [imigrationStatus, setImigrationStatus] = useState('')
  const [imigrationStatusNames, setImigrationStatusNames] = useState({})

  const [livingSituation, setLivingSituation] = useState('')
  const [livingSituationNames, setLivingSituationNames] = useState({})
  const [courtOrderedKinship, setCourtOrderedKinship] = useState('')
  const [courtOrderedKinshipNames, setCourtOrderedKinshipNames] = useState({})
  const [subsidizedGuardianship, setSubsidizedGuardianship] = useState('')
  const [subsidizedGuardianshipNames, setSubsidizedGuardianshipNames] = useState({})
  const [homelessness, setHomelessness] = useState('')
  const [homelessnessNames, setHomelessnessNames] = useState({})
  const [students, setStudents] = useState({})
  const [canSubmit, setCanSubmit] = useState(false)
  const [studentEducation, setStudentEducation]: any = useState({})

  const { basicInfo } = formValues

  const addObj = (obj: Object) => Object.keys(obj).length > 0 ? 1 : 0

  const checkRequiredFilled = () => {
    let totalRequiredInputs = 14

    if (isFederalMember == 'Yes') totalRequiredInputs += 1
    if (childOfFederalMember == 'Yes') totalRequiredInputs += 1
    if (activeDuty == 'Yes') totalRequiredInputs += 1
    if (childOfVeteran == 'Yes') totalRequiredInputs += 1
    if (drugFelony == 'Yes') totalRequiredInputs += 1
    if (isDisabled == 'Yes') totalRequiredInputs += 1
    if (receivedSSI == 'Yes') totalRequiredInputs += 1
    if (receivingSSI == 'Yes') totalRequiredInputs += 1
    if (unableToWork == 'Yes') totalRequiredInputs += 1
    if (livingSituation == 'Yes') totalRequiredInputs += 1
    if (courtOrderedKinship == 'Yes') totalRequiredInputs += 1
    if (subsidizedGuardianship == 'Yes') totalRequiredInputs += 1
    if (homelessness == 'Yes') totalRequiredInputs += 1

    let total = addObj(federalMemberNames) + addObj(childOfFederalMemberNames) + addObj(activeDutyNames) + addObj(students)
      + addObj(childOfVeteranNames) + addObj(drugFelonyNames) + addObj(fleeFelonyNames) + addObj(disabledNames)
      + addObj(receivedSSINames) + addObj(receivingSSINames) + addObj(unableToWorkNames) + addObj(imigrationStatusNames)
      + addObj(livingSituationNames) + addObj(courtOrderedKinshipNames) + addObj(subsidizedGuardianshipNames) + addObj(homelessnessNames)

    if (isFederalMember != '') total += 1
    if (childOfFederalMember != '') total += 1
    if (activeDuty != '') total += 1
    if (childOfVeteran != '') total += 1
    if (drugFelony != '') total += 1
    if (isDisabled != '') total += 1
    if (receivedSSI != '') total += 1
    if (receivingSSI != '') total += 1
    if (unableToWork != '') total += 1
    if (livingSituation != '') total += 1
    if (courtOrderedKinship != '') total += 1
    if (subsidizedGuardianship != '') total += 1
    if (homelessness != '') total += 1

    return totalRequiredInputs <= total &&
      Object.keys(students).length * 13 ==
      Object.keys(students).length *
      Object.keys(studentEducation).reduce((curr, next) => curr + Object.values(studentEducation[next]).length, 0)
  }

  const handleNext = () => {
    setCanSubmit(true)
    if (checkRequiredFilled()) {
      // setStep('CHILDREN')
      // setValues({
      //   basicInfo: {
      //     ...basicInfo,
      //     adults: studentEducation,
      //     federalMemberNames: federalMemberNames,
      //     childOfFederalMemberNames: childOfFederalMemberNames,
      //     activeDutyNames: activeDutyNames,
      //     childOfVeteranNames: childOfVeteranNames,
      //     drugFelonyNames: drugFelonyNames,
      //     fleeFelonyNames: fleeFelonyNames,
      //     disabledNames: disabledNames,
      //     receivedSSINames: receivedSSINames,
      //     receivingSSINames: receivingSSINames,
      //     unableToWorkNames: unableToWorkNames,
      //     imigrationStatusNames: imigrationStatusNames,
      //     livingSituationNames: livingSituationNames,
      //     courtOrderedKinshipNames: courtOrderedKinshipNames,
      //     subsidizedGuardianshipNames: subsidizedGuardianshipNames,
      //     homelessnessNames: homelessnessNames,
      //   }
      // })
      window.scrollTo(0, 0)
    }
  }

  return (
    <div className='content personal-family'>
      <h4>Is anyone in your household:</h4>
      <SelectInput
        options={YES_NO}
        placeholderProp={'A member of a federally recognized tribe?'}
        value={isFederalMember}
        setValue={setIsFederalMember}
        showErrorMsg={canSubmit && isFederalMember == ''}
        errorMsg={'Select an option'}
      />
      {isFederalMember == 'Yes' && <MultiSelectInput
        options={getNames(basicInfo?.adults).concat(getNames(basicInfo?.children ?? []))}
        placeholderProp={'Which household members are members of a tribe?'}
        value={federalMemberNames}
        setValue={setFederalMemberNames}
        showErrorMsg={canSubmit && Object.keys(federalMemberNames).length == 0}
        errorMsg={'Select an option'}
      />}
      <SelectInput
        options={YES_NO}
        placeholderProp={'A child of a member of a federally recognized tribe?'}
        value={childOfFederalMember}
        setValue={setChildOfFederalMember}
        showErrorMsg={canSubmit && childOfFederalMember == ''}
        errorMsg={'Select an option'}
      />
      {childOfFederalMember == 'Yes' && <MultiSelectInput
        options={getNames(basicInfo?.adults).concat(getNames(basicInfo?.children ?? []))}
        placeholderProp={'Which household members are children or grandchildren of tribe members?'}
        value={childOfFederalMemberNames}
        setValue={setChildOfFederalMemberNames}
        showErrorMsg={canSubmit && Object.keys(childOfFederalMemberNames).length == 0}
        errorMsg={'Select an option'}
      />}

      <SelectInput
        options={YES_NO}
        placeholderProp={'On active duty or an honorably discharged member of the military?'}
        value={activeDuty}
        setValue={setActiveDuty}
        showErrorMsg={canSubmit && activeDuty == ''}
        errorMsg={'Select an option'}
      />
      {activeDuty == 'Yes' && <MultiSelectInput
        options={getNames(basicInfo?.adults ?? [])}
        placeholderProp={'Which family members are on active duty or honorably discharged?'}
        value={activeDutyNames}
        setValue={setActiveDutyNames}
        showErrorMsg={canSubmit && Object.keys(activeDutyNames).length == 0}
        errorMsg={'Select an option'}
      />}
      <SelectInput
        options={YES_NO}
        placeholderProp={'The child, spouse or surviving spouse of an active or honorably discharged member of the military?'}
        value={childOfVeteran}
        setValue={setChildOfVeteran}
        showErrorMsg={canSubmit && childOfVeteran == ''}
        errorMsg={'Select an option'}
      />
      {childOfVeteran == 'Yes' && <MultiSelectInput
        options={getNames(basicInfo?.adults).concat(getNames(basicInfo?.children ?? []))}
        placeholderProp={'Which family members does this apply to?'}
        value={childOfVeteranNames}
        setValue={setChildOfVeteranNames}
        showErrorMsg={canSubmit && Object.keys(childOfVeteranNames).length == 0}
        errorMsg={'Select an option'}
      />}

      <h4>Do any of the following describe anyone in your household?</h4>
      <SelectInput
        options={YES_NO}
        placeholderProp={'Convicted of a drug felony?'}
        value={drugFelony}
        setValue={setDrugFelony}
        showErrorMsg={canSubmit && drugFelony == ''}
        errorMsg={'Select an option'}
      />
      {drugFelony == 'Yes' && <MultiSelectInput
        options={getNames(basicInfo?.adults).concat(getNames(basicInfo?.children ?? []))}
        placeholderProp={'Which family members does this apply to?'}
        value={drugFelonyNames}
        setValue={setDrugFelonyNames}
        showErrorMsg={canSubmit && Object.keys(drugFelonyNames).length == 0}
        errorMsg={'Select an option'}
      />}

      <SelectInput
        options={YES_NO}
        placeholderProp={'Fleeing from a felony, or in violation of probation or parole?'}
        value={fleeFelony}
        setValue={setFleeFelony}
      />
      {fleeFelony == 'Yes' && <MultiSelectInput
        options={getNames(basicInfo?.adults).concat(getNames(basicInfo?.children ?? []))}
        placeholderProp={'Which family members does this apply to?'}
        value={fleeFelonyNames}
        setValue={setFleeFelonyNames}
      />}
      <SelectInput
        options={YES_NO}
        placeholderProp={'Has anyone in your household been determined blind or disabled by the Social Security Administration, Veterans Administration, or Railroad Retirement Board?'}
        value={isDisabled}
        setValue={setIsDisabled}
        showErrorMsg={canSubmit && isDisabled == ''}
        errorMsg={'Select an option'}
      />
      {isDisabled == 'Yes' && <MultiSelectInput
        options={getNames(basicInfo?.adults).concat(getNames(basicInfo?.children ?? []))}
        placeholderProp={'Which family members does this apply to?'}
        value={disabledNames}
        setValue={setDisabledNames}
        showErrorMsg={canSubmit && Object.keys(disabledNames).length == 0}
        errorMsg={'Select an option'}
      />}
      <SelectInput
        options={YES_NO}
        placeholderProp={'Has anyone in your household received SSI in the past?'}
        value={receivedSSI}
        setValue={setReceivedSSI}
        showErrorMsg={canSubmit && receivedSSI == ''}
        errorMsg={'Select an option'}
      />
      {receivedSSI == 'Yes' && <MultiSelectInput
        options={getNames(basicInfo?.adults).concat(getNames(basicInfo?.children ?? []))}
        placeholderProp={'Which family members have received SSI?'}
        value={receivedSSINames}
        setValue={setReceivedSSINames}
        showErrorMsg={canSubmit && Object.keys(receivedSSINames).length == 0}
        errorMsg={'Select an option'}
      />}
      <SelectInput
        options={YES_NO}
        placeholderProp={'Does anyone in your household receive SSI or SSDI benefits now?'}
        value={receivingSSI}
        setValue={setReceivingSSI}
        showErrorMsg={canSubmit && receivingSSI == ''}
        errorMsg={'Select an option'}
      />
      {receivingSSI == 'Yes' && <MultiSelectInput
        options={getNames(basicInfo?.adults).concat(getNames(basicInfo?.children ?? []))}
        placeholderProp={'Which family members are receiving SSI or SSDI now?'}
        value={receivingSSINames}
        setValue={setReceivingSSINames}
        showErrorMsg={canSubmit && Object.keys(receivingSSINames).length == 0}
        errorMsg={'Select an option'}
      />}
      <SelectInput
        options={YES_NO}
        placeholderProp={'Is anyone in your household: Unable to work due to illness or injury or a disability?'}
        value={unableToWork}
        setValue={setUnableToWork}
        showErrorMsg={canSubmit && unableToWork == ''}
        errorMsg={'Select an option'}
      />
      {unableToWork == 'Yes' && <MultiSelectInput
        options={getNames(basicInfo?.adults).concat(getNames(basicInfo?.children ?? []))}
        placeholderProp={'Which family member does this apply to?'}
        value={unableToWorkNames}
        setValue={setUnableToWorkNames}
        showErrorMsg={canSubmit && Object.keys(unableToWorkNames).length == 0}
        errorMsg={'Select an option'}
      />}

      <SelectInput
        options={YES_NO}
        placeholderProp={'Does anyone in your household have an immigration status other than U.S. Citizen?'}
        value={imigrationStatus}
        setValue={setImigrationStatus}
      />
      {imigrationStatus == 'Yes' && <MultiSelectInput
        options={getNames(basicInfo?.adults).concat(getNames(basicInfo?.children ?? []))}
        placeholderProp={'Which family members have an immigration status other than U.S. Citizen?'}
        value={imigrationStatusNames}
        setValue={setImigrationStatusNames}
      />}

      <h4>Education</h4>
      <MultiSelectInput
        value={students}
        setValue={setStudents}
        options={getNames(basicInfo?.adults).concat('None of the above')}
        placeholderProp={'Are any adults in your household enrolled in school or an employment-related training program?'}
        showErrorMsg={canSubmit && Object.keys(students).length == 0}
        errorMsg={'Select an option'}
      />
      {Object.keys(students).length > 0 && Object.keys(students).map((student: any) =>
        student != 'None of the above' && <Education
          student={student}
          formValuesContext={formValuesContext}
          studentEducation={studentEducation}
          setStudentEducation={setStudentEducation}
          canSubmit={canSubmit}
        />
      )}

      <h4>Do the following living situations describe anyone in your household?</h4>
      <SelectInput
        options={YES_NO}
        placeholderProp={'Currently in Foster Care or was in Foster Care on their 18th birthday, even if they did not live with you at that time?'}
        value={livingSituation}
        setValue={setLivingSituation}
        showErrorMsg={canSubmit && livingSituation == ''}
        errorMsg={'Select an option'}
      />
      {livingSituation == 'Yes' && <MultiSelectInput
        options={getNames(basicInfo?.adults).concat(getNames(basicInfo?.children ?? []))}
        placeholderProp={'Which family member does this apply to?'}
        value={livingSituationNames}
        setValue={setLivingSituationNames}
        showErrorMsg={canSubmit && Object.keys(livingSituationNames).length == 0}
        errorMsg={'Select an option'}
      />}
      <SelectInput
        options={YES_NO}
        placeholderProp={'Currently in Court Ordered Kinship on or was on their 18th birthday even if they did not live with you at the time?'}
        value={courtOrderedKinship}
        setValue={setCourtOrderedKinship}
        showErrorMsg={canSubmit && courtOrderedKinship == ''}
        errorMsg={'Select an option'}
      />
      {courtOrderedKinship == 'Yes' && <MultiSelectInput
        options={getNames(basicInfo?.adults).concat(getNames(basicInfo?.children ?? []))}
        placeholderProp={'Which family member does this apply to?'}
        value={courtOrderedKinshipNames}
        setValue={setCourtOrderedKinshipNames}
        showErrorMsg={canSubmit && Object.keys(courtOrderedKinshipNames).length == 0}
        errorMsg={'Select an option'}
      />}
      <SelectInput
        options={YES_NO}
        placeholderProp={'Currently in a Subsidized Guardianship or was on their 18th birthday, even if they did not live with you?'}
        value={subsidizedGuardianship}
        setValue={setSubsidizedGuardianship}
        showErrorMsg={canSubmit && subsidizedGuardianship == ''}
        errorMsg={'Select an option'}
      />
      {subsidizedGuardianship == 'Yes' && <MultiSelectInput
        options={getNames(basicInfo?.adults).concat(getNames(basicInfo?.children ?? []))}
        placeholderProp={'Which family member does this apply to?'}
        value={subsidizedGuardianshipNames}
        setValue={setSubsidizedGuardianshipNames}
        showErrorMsg={canSubmit && Object.keys(subsidizedGuardianshipNames).length == 0}
        errorMsg={'Select an option'}
      />}

      <h4>Has anyone in your household: Experienced homelessness in the past 12 months?</h4>
      <SelectInput
        options={YES_NO}
        placeholderProp={'By homeless we mean not having a long-term place to stay. You could be staying in a shelter, with a friend, or not have a place to stay.'}
        value={homelessness}
        setValue={setHomelessness}
        showErrorMsg={canSubmit && homelessness == ''}
        errorMsg={'Select an option'}
      />
      {homelessness == 'Yes' && <MultiSelectInput
        options={getNames(basicInfo?.adults).concat(getNames(basicInfo?.children ?? []))}
        placeholderProp={'Which family member does this apply to?'}
        value={homelessnessNames}
        setValue={setHomelessnessNames}
        showErrorMsg={canSubmit && Object.keys(homelessnessNames).length == 0}
        errorMsg={'Select an option'}
      />}
      <Button variant='outlined' onClick={handleNext}>Continue</Button>
    </div>
  )
}

export default PersonalAndFamily
