import I18n from '../config/I18n'
import React from 'react'
import '../component_styles/Footer.sass'
import FooterLink from './FooterLink'
import { MAX_OUTER_WIDTH } from '../Constants'

interface FooterProps {
}

const Footer: React.FC<FooterProps> = (_props) => {
  return (
    <div className="footer">
      {outerWidth < MAX_OUTER_WIDTH && <div className="footer-links">
        <FooterLink
          text={I18n.t("footer.home")}
          href={`/${I18n.locale}/dashboard`}
          iconName="fa-home"
        />
        <FooterLink
          text={I18n.t("footer.calculate")}
          href={`/${I18n.locale}/benefits_cliff_calculators`}
          iconName="fa-calculator"
        />
        <FooterLink
          text={I18n.t("footer.explore")}
          href={`/${I18n.locale}/explore-card`}
          iconName="fa-globe"
        />
      </div>}
    </div>
  )
}

export default Footer
export type { FooterProps }
