import React, { memo } from 'react'

import BenefitDetails from '../BenefitDetails'
import { calculateVisionAmount } from '../helpers/Benefits'
import { type BenefitProps } from '../../../EmployeeCalculatorInterface'

const VisionBenefits: React.FC<BenefitProps> = memo(({ formValuesContext, benefitDataCompany, setValues }) => {
  return (
    <BenefitDetails
      title='Vision Benefits'
      benefitType='vision'
      calculateAmount={calculateVisionAmount}
      formValuesContext={formValuesContext}
      benefitDataCompany={benefitDataCompany}
      setValues={setValues}
    />
  )
})

export default VisionBenefits
