import React, { useEffect, useRef } from 'react'

import { useGlobalState } from '../GlobalState'
import I18n from '../config/I18n'
import DateTimePicker, { DateTimePickerProps } from './DateTimePicker'
import Dropdown, { DropdownProps } from './Dropdown'
import RadioInputGroup, { RadioInputGroupProps } from './RadioInputGroup'
import TextField from './TextField'
import Link from './Link'

import '../component_styles/QuestionInput.sass'

interface DefaultProps {
  programName: string
  programDescription: string
  questionText: string
  questionNumber: number
  totalQuestionsCount: number
  backLink?: string
  nextLink?: string
}

// Subset of TextFieldProps
interface IntegerFieldProps {
  name: string
  value: string
  placeholder: string
  label?: string
  errors?: string
  isRequired: boolean
  isEncouraged?: boolean
  disabled?: boolean
  autoFocus: boolean
  autoComplete?: string
  inputClassName?: string
}

// Subset of RadioInputGroupProps
interface BooleanProps {
  name: string
}

type InputProps =
  | { inputType: "datetime"; input: DateTimePickerProps }
  | { inputType: "dropdown"; input: DropdownProps }
  | { inputType: "integer"; input: IntegerFieldProps }
  | { inputType: "radio"; input: RadioInputGroupProps }
  | { inputType: "boolean"; input: BooleanProps }

type QuestionInputProps = DefaultProps & InputProps

const QuestionInput: React.FC<QuestionInputProps> = (props) => {
  const { programName, programDescription, questionText, questionNumber, totalQuestionsCount, backLink, nextLink, inputType, input } = props

  const [, setHeaderState] = useGlobalState(window.OC.globals.headerState)
  const container = useRef<HTMLInputElement>(null);

  const maximumSwipeTime = 400
  const maximumVerticalDistance = 100
  const minimumHorizontalDistance = 100

  const isQuestionFooterVisible = backLink || nextLink || (questionNumber && totalQuestionsCount)
  const isProgramHeaderVisible = isQuestionFooterVisible && programName

  useEffect(() => {
    if (questionNumber && totalQuestionsCount) {
      const progress = questionNumber / totalQuestionsCount * 100
      setHeaderState({ progressPercent: progress, ...window.OC.globals.headerState })
    }
  }, [])

  useEffect(() => {
    if (container.current) {
      let startX = 0
      let startY = 0
      let startTime = 0

      container.current.addEventListener('touchstart', function (ev) {
        const touchObj = ev.changedTouches[0]
        startX = touchObj.pageX
        startY = touchObj.pageY
        startTime = new Date().getTime()
      }, false)

      container.current.addEventListener('touchend', function (ev) {
        const touchObj = ev.changedTouches[0]
        const elapsedTime = new Date().getTime() - startTime
        const distX = touchObj.pageX - startX
        const distY = touchObj.pageY - startY

        if (
          elapsedTime <= maximumSwipeTime &&
          Math.abs(distX) >= minimumHorizontalDistance &&
          Math.abs(distY) <= maximumVerticalDistance
        ) {
          if (distX > 0) {
            onSwipeRight()
          } else {
            onSwipeLeft()
          }
        }
      }, false)
    }
  }, [])

  function onSwipeLeft() {
    if (nextLink) {
      window.location.href = nextLink
    }
  }

  function onSwipeRight() {
    if (backLink) {
      window.location.href = backLink
    }
  }

  function renderInput() {
    switch (inputType) {
      case 'datetime':
        return (
          <DateTimePicker
            {...(input as DateTimePickerProps)}
          />
        )

      case 'dropdown':
        return (
          <>
            <Dropdown
              {...(input as DropdownProps)}
              header={props.questionText}
            />
            <div className="nudge-down-10" />
          </>
        )

      case 'integer':
        return (
          <TextField
            {...(input as IntegerFieldProps)}
            type="number"
          />
        )

      case 'radio':
        return (
          <RadioInputGroup
            {...(input as RadioInputGroupProps)}
          />
        )

      case 'boolean':
        return (
          <RadioInputGroup
            options={[['Yes', 1], ['No', 0]]}
            {...(input as BooleanProps)}
          />
        )

      default:
        return null
    }
  }

  return (
    <div ref={container} className="question-input-container">
      {isProgramHeaderVisible &&
        <>
          <h2>{programName}</h2>
          <p>{programDescription}</p>
        </>
      }
      <div className="orange-hr nudge-down-25"></div>

      <div className="question">
        <h3>{questionText}</h3>
        {renderInput()}
      </div>

      {isQuestionFooterVisible &&
        <div className="question-footer">
          <div className="question-footer-container">
            <div className="back-button">
              {backLink &&
                <Link
                  text={I18n.t("back")}
                  href={backLink}
                />
              }
            </div>

            <div className="counter-container">
              {questionNumber && totalQuestionsCount &&
                <div className="counter">{questionNumber} / {totalQuestionsCount}</div>
              }
            </div>

            <div className="continue-button">
              {nextLink &&
                <Link href={nextLink}>
                  <div>
                    {I18n.t('forms.continue')}
                  </div>
                </Link>
              }
            </div>
          </div>
        </div>
      }
    </div>
  )
}

export default QuestionInput
export type { QuestionInputProps }
