import React, { memo } from 'react'

import { NON_NUMERIC_CHARACTERS } from '../../../Constants'

import './styles.sass'

interface NumberInputProps {
  value: string
  setValue: (value: string) => void
  error?: string
  setError?: (error: string) => void
  setIsError?: (isError: boolean) => void
  annualContributionStartrangeValue?: number
  annualContributionEndrangeValue?: number
  label: string
  disabled?: boolean
  setSelected?: (isSelected: boolean) => void
}

const NumberInput: React.FC<NumberInputProps> = memo((props) => {
  const { value, setValue, setError, setIsError, annualContributionStartrangeValue, annualContributionEndrangeValue, label, disabled, setSelected } = props

  const validateValue = (numberValue: number, annualContributionStartrangeValue?: number, annualContributionEndrangeValue?: number): void => {
    if (annualContributionStartrangeValue !== undefined && !isNaN(annualContributionStartrangeValue) && annualContributionEndrangeValue !== undefined) {
      const errorMessage = `Contribution must be between ${annualContributionStartrangeValue} and ${annualContributionEndrangeValue}.`
      const isError = numberValue > annualContributionEndrangeValue || numberValue < annualContributionStartrangeValue

      if (setError && setIsError) {
        setError(isError ? errorMessage : '')
        setIsError(isError)
      }
    }
  }

  const handleInputChange = (event: { target: { value: string } }): void => {
    if (setSelected) { setSelected(false) }
    const inputValue = event.target.value
    const numericValue = inputValue.replace(NON_NUMERIC_CHARACTERS, '')
    const numberValue = parseInt(numericValue, 10)
    validateValue(numberValue, annualContributionStartrangeValue, annualContributionEndrangeValue)

    setValue?.(numericValue.toString())
  }

  return (
    <div className={`custom-input ${disabled ?? false ? 'disabled' : ''}`}>
      <input
        type='text'
        value={value}
        onChange={handleInputChange}
        placeholder={label}
        disabled={disabled}
      />
    </div>
  )
})

NumberInput.displayName = 'NumberInput'

export default NumberInput
