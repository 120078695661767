import React from 'react'
import { ArrowForwardIos } from '@material-ui/icons'

import { goToURL } from '../Helper'
import { LinkProps } from './Link'
import ColoredBackgroundHeader from './ColoredBackgroundHeader'
import ExploreCard, { ExploreCardProps } from './ExploreCard'
import I18n from '../config/I18n'
import NoteCard, { NoteCardProps } from './NoteCard'

import '../component_styles/GenericCardIndex.sass'

interface DefaultProps {
  withHeader: boolean
  headerLink?: LinkProps
  tag?: string
}

type CardProps =
  | { cardType: 'explore'; cards: ExploreCardProps[] }
  | { cardType: 'note'; cards: NoteCardProps[] }

type GenericCardIndexProps = DefaultProps & CardProps

const GenericCardIndex: React.FC<GenericCardIndexProps> = (props) => {
  const { withHeader, headerLink, tag, cardType, cards } = props

  const components = {
    explore: ExploreCard,
    note: NoteCard
  }

  return (
    <div className='card-index content'>
      {withHeader &&
        <ColoredBackgroundHeader
          title={I18n.t(`${cardType}_cards.title`, { tag: tag || '' })}
          description={tag ? I18n.t(`${cardType}_cards.description_with_resource`, { tag: tag }) : ''}
          headerBackgroundColor='white'
          headerLink={headerLink}
        />
      }
      <div className='cards-display'>
        {cards.map((card: any, index: any) => {
          const CardComponent = components[cardType]
          return (
            <CardComponent
              key={`card-${index}`}
              {...card}
            />
          )
        })
        }
      </div>

      <div
        className='explore-more-topics'
        onClick={() => goToURL(`/${I18n.locale}/categories`)}
      >
        <p>{'See all articles'}</p>
        <ArrowForwardIos className='fa-xs' />
      </div>
    </div>
  )
}

export default GenericCardIndex
export type { GenericCardIndexProps }
