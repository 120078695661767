import { toast } from 'react-toastify'

import { apiClient } from './apiClient'
import I18n from '../config/I18n'

export const uploadEmployerBenefitsCSV = (file: File, id: string): void => {
  const formData = new FormData()
  formData.append('file', file)
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  }

  apiClient
    .post(`/${I18n.locale as string}/employers/${id}/upload_employer_benefits_csv`, formData, config)
    .then((response) => {
      toast.success(response.data?.message)
    })
    .catch((error) => {
      if (error.response?.data?.error) {
        toast.error(error.response.data.error)
      } else {
        toast.error('Failed to upload or read the CSV.')
      }
    })
}
