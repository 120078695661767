export const validate = (event: React.ChangeEvent<HTMLInputElement> | any, field: string) => {
  const errors: any = {}

  if (field === 'category') {
    if (checkEventUndefined(event)) {
      errors.category = 'Category is Required'
    } else {
      errors.category = false
    }
  }

  if (field === 'subHeading') {
    if (checkEventUndefined(event)) {
      errors.subHeading = 'Sub-Heading is Required'
    } else {
      errors.subHeading = false
    }
  }

  if (field === 'selectedTags') {
    if (event?.length < 1) {
      errors.selectedTags = 'Tags are Required'
    } else {
      errors.selectedTags = false
    }
  }

  if (field === 'color') {
    if (['', null].includes(event?.value)) {
      errors.color = 'Color is Required'
    } else {
      errors.color = false
    }
  }

  return errors
}

const checkEventUndefined = (event: React.ChangeEvent<HTMLInputElement>) =>
  ['', undefined].includes(event?.target?.value)
