import React from 'react'
import I18n from '../config/I18n'
import { currencyFormatter } from '../Helper'
import '../component_styles/GrossHouseholdIncomeDisplay.sass'

interface GrossHouseholdIncomeDisplayProps {
  increment: 'yr'|'mo'|'wk'
  amount: number
}

const GrossHouseholdIncomeDisplay: React.FC<GrossHouseholdIncomeDisplayProps> = (props) => {
  const { amount, increment } = props

  return (
    <div className="row">
      <div>
        <h4 className="no-margin">{ I18n.t("snapshot.gross_household_income") }</h4>
        <div className="amount-increment-container">
          { currencyFormatter.format(amount) }
          <span className="increment">/{ increment }</span>
        </div>
      </div>

      <div className="edit-income-container">
        <a
          className="green caps edit-url"
          href={ `/${I18n.locale}/gross_household_income/edit` }
        >
          { I18n.t('snapshot.edit_income_line1') }
          <br />
          { I18n.t('snapshot.edit_income_line2') }
        </a>
      </div>
    </div>
  )
}

export default GrossHouseholdIncomeDisplay
export type { GrossHouseholdIncomeDisplayProps }
