import React, { useState } from 'react'

import { Button } from '@material-ui/core'
import CloseIcon from '@mui/icons-material/Close'
import GppGoodIcon from '@mui/icons-material/GppGood'
import IconButton from '@mui/material/IconButton'
import Modal from '@mui/material/Modal'

const BenefitsCiffCalculatorModal: React.FC = () => {
  const [isOpen, setIsOpen] = useState(true)

  const handleCloseButton = (): void => { setIsOpen(false) }
  const handleButtonClick = (): void => { setIsOpen(false) }

  return (
    <Modal open={isOpen} className='modal-background'>
      <div className='tax-modal-box'>
        <div className='modal-close-button'>
          <IconButton disableRipple onClick={handleCloseButton}>
            <CloseIcon fontSize='large' />
          </IconButton>
        </div>

        <GppGoodIcon className='custom-shiled-icon' />

        <div className='modal-option'>
          <h3>This is confidential and will not be shared with your employer.</h3>

          <div className='modal-button'>
            <Button variant='contained' className='purple-button' onClick={handleButtonClick}>OK</Button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default BenefitsCiffCalculatorModal
