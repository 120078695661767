import React, { type Context, type Dispatch, memo, useContext, useEffect } from 'react'
import Modal from '@mui/material/Modal'
import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'
import { Button, Link } from '@material-ui/core'

import './styles.sass'
import { addThousandsSeparator } from '../../../Helper'
import { type FormValues } from '../../../FormValuesInterfaces'
import { postRequestNpsFeedback } from '../../../api/npsFeedback'

interface TakeHomePayProps {
  isOpen: boolean
  takeHomePayAmount: number
  annualTakeHomePayAmount: number
  setIsOpen: (value: boolean) => void
  handleNext: () => void
  formValuesContext: Context<FormValues>
  setValues: Dispatch<FormValues>
  handleBack?: () => void
  setIsNPSSurveyOpen: (value: boolean) => void
  setAskForFeedback: (value: boolean) => void
  employeeID?: number | string | undefined
}

const TakeHomePay: React.FC<TakeHomePayProps> = memo((props) => {
  const { isOpen, takeHomePayAmount, annualTakeHomePayAmount, setIsOpen, handleNext, formValuesContext, setValues, handleBack, setIsNPSSurveyOpen, employeeID, setAskForFeedback } = props
  const formValues: FormValues = useContext(formValuesContext)

  const saveTakeHomePayValue = (): void => {
    const formValuesUpdated = {
      ...formValues,
      takeHomePayAmount
    }

    setValues(formValuesUpdated)
  }

  useEffect(() => {
    if (isOpen) {
      void askForNPSFeedback()
    }
  }, [isOpen])

  const askForNPSFeedback = async (): Promise<void> => {
    if (employeeID) {
      const response = await postRequestNpsFeedback(employeeID, undefined)
      setAskForFeedback(response?.ask_nps_feedback)
    }
  }

  const handleClose = (): void => { setIsOpen(false) }

  const handleButtonClick = (): void => {
    saveTakeHomePayValue()
    handleClose()
    handleNext()
  }

  const handleButtonClose = (): void => {
    saveTakeHomePayValue()
    handleClose()
    setIsNPSSurveyOpen(true)
  }

  const handleLinkClick = (): void => {
    handleBack?.()
    handleClose()
  }

  return (
    <Modal open={isOpen} className='modal-background'>
      <div className='take-home-pay-modal'>
        <div className='modal-close-button'>
          <IconButton disableRipple onClick={handleClose}>
            <CloseIcon fontSize='large' />
          </IconButton>
        </div>

        <h3>${(addThousandsSeparator(takeHomePayAmount.toString()))}</h3>
        <p>Estimated Pay <span>After Benefits & Taxes</span> Per Pay Period</p>
        <Link className='link-text' onClick={handleLinkClick}>Edit Pay Details</Link>
        <p>Annual Take-home Pay </p>
        <b>${(addThousandsSeparator(annualTakeHomePayAmount.toString()))}</b>

        <div className='tax-modal-option'>
          <b>Would you like to see how your new compensation affects your eligibility for public benefits?</b>

          <div className='modal-button'>
            <Button variant='contained' className='purple-button' onClick={handleButtonClick}> Yes, let’s do it </Button>
            <Button variant='outlined' className='outlined-button' onClick={handleButtonClose}> No thanks </Button>
          </div>
        </div>
      </div>
    </Modal>
  )
})

export default TakeHomePay
