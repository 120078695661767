import React from 'react'
import '../component_styles/SnapshotCard.sass'

interface SnapshotSimplifiedCardProps {
  name: string
  date: string
  snapshotUrl: string
}

const SnapshotSimplifiedCard: React.FC<SnapshotSimplifiedCardProps> = (props) => {
  const { name, date, snapshotUrl } = props

  return (
    <div className="clearfix snapshot-card simplified">
      <div className="float-left snapshot-details">
        <h4 className="no-margin-bottom">{ name }</h4>
        <p className="date">{ date }</p>
      </div>
      <a href={ snapshotUrl } className="float-right">
        <i className="fas fa-angle-right link-icon"></i>
      </a>
    </div>
  )
}

export default SnapshotSimplifiedCard
export type { SnapshotSimplifiedCardProps }
