import React from 'react'
import '../component_styles/QuestionAnswerDisplay.sass'
import QuestionAnswerDisplay, {  QuestionAnswerDisplayProps } from './QuestionAnswerDisplay'

interface QuestionAnswerCollectionDisplayProps {
  questionAnswerPairs: QuestionAnswerDisplayProps[]
}

const QuestionAnswerCollectionDisplay: React.FC<QuestionAnswerCollectionDisplayProps> = (props) => {
  const { questionAnswerPairs } = props

  return (
    <div className="content-container">
      { questionAnswerPairs.map((pair, index) => {
          return(
            <div key={ `pair-${index}` }>
              <QuestionAnswerDisplay
                { ...pair }
                extraClassName="with-border"
              />
            </div>
          )
        })
      }
    </div>
  )
}

export default QuestionAnswerCollectionDisplay
export type { QuestionAnswerCollectionDisplayProps }
