import React from 'react'
import { toast, ToastContainer } from 'react-toastify'

import 'react-toastify/dist/ReactToastify.css'
import { AUTO_CLOSE_DURATION } from '../Constants'

export const showSuccessToast = (message: string): void => {
  toast.success(message, {
    autoClose: AUTO_CLOSE_DURATION,
    hideProgressBar: true,
    pauseOnHover: false,
    draggable: false,
    closeOnClick: true,
  })
}

export const showErrorToast = (message: string): void => {
  toast.error(message, {
    autoClose: AUTO_CLOSE_DURATION,
    hideProgressBar: true,
    pauseOnHover: false,
    draggable: false,
    closeOnClick: true,
  })
}

const NotificationToast: React.FC = () => <ToastContainer />

export default NotificationToast
