const _ = require('lodash')

export const validate = (event: any, field: any, ind: any, prop: any, err: any) => {
  let errors: any = err

  switch (field) {
    case 'adults':
      if (prop == 'name') {
        if (event?.target?.value === '' || event?.target?.value === undefined) {
          _.set(errors, `adults[${ind}].name`, 'Name is Required')
        } else {
          _.set(errors, `adults[${ind}].name`, false)
        }
      } else if (prop == 'age') {
        if (event?.value === '' || event?.value === undefined) {
          _.set(errors, `adults[${ind}].age`, 'Age is Required')
        } else {
          _.set(errors, `adults[${ind}].age`, false)
        }
      } else if (prop == 'gender') {
        if (event?.value === '' || event?.value === undefined) {
          _.set(errors, `adults[${ind}].gender`, 'Gender is Required')
        } else {
          _.set(errors, `adults[${ind}].gender`, false)
        }
      } else if (prop == 'jobs') {
        if (event?.value === '' || event?.value === undefined) {
          _.set(errors, `adults[${ind}].jobs`, 'Current Job is Required')
        } else {
          _.set(errors, `adults[${ind}].jobs`, false)
        }
      }
      break

    case 'children':
      if (prop == 'name') {
        if (event?.target?.value === '' || event?.target?.value === undefined) {
          _.set(errors, `children[${ind}].name`, 'Name is Required')
        } else {
          _.set(errors, `children[${ind}].name`, false)
        }
      } else if (prop == 'age') {
        if (event?.value === '' || event?.value === undefined) {
          _.set(errors, `children[${ind}].age`, 'Age is Required')
        } else {
          _.set(errors, `children[${ind}].age`, false)
        }
      } else if (prop == 'gender') {
        if (event?.value === '' || event?.value === undefined) {
          _.set(errors, `children[${ind}].gender`, 'Gender is Required')
        } else {
          _.set(errors, `children[${ind}].gender`, false)
        }
      } else if (prop === 'parent') {
        if (event?.value === '' || event?.value === undefined) {
          _.set(errors, `children[${ind}].parent`, 'Choose an adult as parent or guardian of this child.')
        } else {
          _.set(errors, `children[${ind}].parent`, false)
        }
      }
      break

    case 'jobs':
      if (event?.value === '' || event?.value === undefined) {
        _.set(errors, `adults[${ind}].jobs`, 'Current Job is Required')
      } else {
        _.set(errors, `adults[${ind}].jobs`, false)
      }
      break
  }

  return errors
}
