import React, { memo } from 'react'
import Button from '@mui/material/Button'

import './styles.sass'

interface ButtonProps {
  label: string
  width: string
  btnClick: (() => void) | undefined
  disabled?: boolean
}

const OutlinedButton: React.FC<ButtonProps> = memo((props) => {
  const { label, width, btnClick, disabled } = props

  return (
    <Button
      variant='outlined'
      className={`outlined-button ${disabled ? 'outlined-button-disabled' : ''} `}
      style={{ width }}
      disabled={disabled}
      onClick={btnClick}
    >
      {label}
    </Button>
  )
})

export default OutlinedButton
