import React, { type ChangeEvent, type FC, memo, useEffect } from 'react'
import { Checkbox, FormControlLabel } from '@mui/material'

import './styles.sass'
import { type FormValues } from '../../../../FormValuesInterfaces'
import ErrorMessage from '../../../ErrorMessage'

const _ = require('lodash')

interface ReducedFareBusPassProps {
  contextValues: FormValues
  setContextValues: Function
  anyAdultEligible: boolean
  setAnyAdultEligible: (value: boolean) => void
}

const ReducedFareBusPass: FC<ReducedFareBusPassProps> = memo(({ contextValues, setContextValues, anyAdultEligible, setAnyAdultEligible }) => {
  const handleSave = (): void => { setContextValues({ ...contextValues }) }

  const handlecheckBusPassEligibilityChange = (event: ChangeEvent<HTMLInputElement>, index: number | string): void => {
    _.set(contextValues, ['adults', index, 'checkBusPassEligibility'], event.target.checked)
    event.target.checked && _.set(contextValues, ['adults', index, 'publicTransport'], true)
    handleSave()
  }

  const rendercheckBusPassEligibilityCheckbox = (index: number): JSX.Element => {
    if (_.get(contextValues, ['adults', index, 'checkBusPassEligibility']) === undefined) {
      _.set(contextValues, ['adults', index, 'checkBusPassEligibility'], false)
    }
    const adult = contextValues.adults[index]
    const name = index < 1 ? 'Myself' : adult?.name || `Adult ${index}`
    return (
      <div className='errors-div' key={index}>
        <FormControlLabel
          label={name}
          labelPlacement='bottom'
          control={
            <Checkbox
              checked={adult?.checkBusPassEligibility || false}
              onChange={(event: ChangeEvent<HTMLInputElement>) => { handlecheckBusPassEligibilityChange(event, index) }}
            />
          }
        />
      </div>
    )
  }

  useEffect(() => {
    const anyAdultEligible = Object.values(contextValues.adults).some((adult: any) => adult.checkBusPassEligibility)
    setAnyAdultEligible(anyAdultEligible)
  }, [contextValues, setAnyAdultEligible])

  return (
    <>
      <div className='bus-pass-benefit'>
        <p>For which household adults would you like to determine bus pass eligibility?</p>
        {Object.keys(contextValues.adults).map((_, key) => rendercheckBusPassEligibilityCheckbox(Number(key)))}
      </div>
      <div className='errors-div'>
        {!anyAdultEligible && <ErrorMessage text={'Please select at least one adult for bus pass eligibility.'} />}
      </div>
    </>
  )
})

export default ReducedFareBusPass
