import React, { memo, useContext, useEffect, useState } from 'react'
import { type AdminStepProps } from '../../../EmployeeCalculatorInterface'
import { type FormValues } from '../../../FormValuesInterfaces'
import CaseItemManagement from '../CaseItemManagement'
import { createScenario, deleteScenario, getScenarios, updateScenario } from '../../../api/scenario'
import { Pagination } from '@mui/material'
import NotificationToast, { showErrorToast, showSuccessToast } from '../../NotificationToast'
import { ITEMS_PER_PAGE } from '../../../Constants'
interface Item {
  id: number
  name: string
  description: string
  url: string
  key?: string
}

const CaseManagement: React.FC<AdminStepProps> = memo((props) => {
  const { adminValuesContext, setValues, selectedItem, handleItemSelection } = props
  const formValues: FormValues = useContext(adminValuesContext)
  const [cases, setCases] = useState<Item[]>([])
  const [page, setPage] = useState<number>(1)
  const [totalCases, setTotalCases] = useState<number>(0)

  const fetchAllScenarios = async () => {
    try {
      const response = await getScenarios(selectedItem?.id || 0, page)
      setCases(response.scenarios)
      setTotalCases(response.meta.total_count)
    } catch (error) {
      showErrorToast('Error fetching cases')
    }
  }

  useEffect(() => {
    fetchAllScenarios()
  }, [page, selectedItem])

  const handleCreateScenario = async (name: string, file: File, description?: string) => {
    try {
      await (selectedItem ? createScenario(selectedItem.id, name, file, description) : Promise.reject(new Error("Selected item is undefined")))
      showSuccessToast('Scenario created successfully')
      await fetchAllScenarios()
    } catch (error) {
      showErrorToast('Error creating scenario')
    }
  }

  const handleDeleteScenario = async (id: number) => {
    try {
      await deleteScenario(id)
      showSuccessToast('Scenario Deleted successfully')
      fetchAllScenarios()
    } catch (error) {
      showErrorToast('Error deleting scenario')
    }
  }

  const HandleUpdateScenario = async (id: number, item_key: string, name: string, file: File, description?: string) => {
    try {
      await updateScenario(id, item_key, name, file, description)
      showSuccessToast('Scenario updated successfully')
      await fetchAllScenarios()
    } catch (error) {
      showErrorToast('Error updating scenario')
    }
  }

  const handlePageChange = (_: React.ChangeEvent<unknown>, value: number) => setPage(value)

  return (
    <>
      <CaseItemManagement
        title='Case'
        itemName='caseName'
        itemNamePlural='caseNames'
        formValues={formValues}
        setValues={setValues}
        items={cases}
        handleItemSelection={handleItemSelection}
        selectedItem={selectedItem}
        createScenario={handleCreateScenario}
        handleItemDelete={handleDeleteScenario}
        UpdateScenario={HandleUpdateScenario}
      />
      {cases.length > 0 && (
        <div className="pagination-container">
          <Pagination
            count={Math.ceil(totalCases / ITEMS_PER_PAGE)}
            page={page}
            onChange={handlePageChange}
            color="primary"
          />
        </div>
      )}
      <NotificationToast />
    </>
  )
})

export default CaseManagement
