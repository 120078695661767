import React, { useState, useContext, type ChangeEvent, Context, Dispatch } from 'react'
import Select from 'react-select'
import {
  Checkbox,
  Divider,
  FormControlLabel,
  RadioGroup,
  Radio
} from '@material-ui/core'

import InputField from './InputField'
import { HEATING_SOURCE_OPTIONS } from '../../Constants'
import { getSelectdValue, anyAdultStudent, scrollToTop, anyDisabledAdult } from '../../Helper'
import '../../component_styles/BenefitsCliff.sass'
import NavigationButtons from './NavigationButtons'
import PhoneBillsInputs from './PhoneBillInputs'
import { type FormValues } from '../../FormValuesInterfaces'
import { type Adults } from '../AdultsInterface'
import ErrorMessage from '../ErrorMessage'

const _ = require('lodash')

interface StepSevenProps {
  formValuesContext: Context<FormValues>
  setValues: Dispatch<FormValues>
  handleNext: () => void
  handleBack: () => void
  step: number
}

const StepSeven: React.FC<StepSevenProps> = (props) => {
  let formValues: any = useContext(props.formValuesContext)

  const [adults, setAdults] = useState(formValues?.adults ?? {})
  const [housingOverride, setHousingOverride] = useState(formValues?.housingOverride ?? true)
  const [housingOverrideAmt, setHousingOverrideAmt] = useState(formValues?.housingOverrideAmt ?? 0)
  const [frspTenantPortion, setFrspTenantPortion] = useState(formValues?.frspTenantPortion ?? 0)
  const [housingFullCost, setHousingFullCost] = useState(formValues?.housingFullCost ?? 0)

  const [energyCostOverride, setEnergyCostOverride] = useState(formValues?.energyCostOverride ?? true)

  const [electricityCostOverride, setElectricityCostOverride] = useState(formValues?.electricityCostOverride ?? false)
  const [electricityCostOverrideAmt, setElectricityCostOverrideAmt] = useState(formValues?.electricityCostOverrideAmt ?? 0)
  const [waterAndSewerOverride, setWaterAndSewerOverride] = useState(formValues?.waterAndSewerOverride ?? false)
  const [waterAndSewerCostOverrideAmt, setWaterAndSewerCostOverrideAmt] = useState(formValues?.waterAndSewerCostOverrideAmt ?? 0)
  const [cookingFuelOverride, setCookingFuelOverride] = useState(formValues?.cookingFuelOverride ?? false)
  const [cookingFuelCostOverrideAmt, setCookingFuelCostOverrideAmt] = useState(formValues?.cookingFuelCostOverrideAmt ?? 0)
  const [garbageAndTrashOverride, setGarbageAndTrashOverride] = useState(formValues?.garbageAndTrashOverride ?? false)
  const [garbageAndTrashCostOverrideAmt, setGarbageAndTrashCostOverrideAmt] = useState(formValues?.garbageAndTrashCostOverrideAmt ?? 0)

  const [heatInRent, setHeatInRent] = useState(formValues?.heatInRent ?? false)
  const [heatingSource, setHeatingSource] = useState(formValues?.heatingSource ?? '')
  const [heatingCostOverrideAmt, setHeatingCostOverrideAmt] = useState(formValues?.heatingCostOverrideAmt ?? 0)
  const [cep, setCep] = useState(formValues?.cmuntyEligiblityProvision ?? false)

  const [foodOverride, setFoodOverride] = useState(formValues?.foodOverride ?? false)
  const [foodOverrideAmt, setFoodOverrideAmt] = useState(formValues?.foodOverrideAmt ?? 0)

  const [transportOverride, setTransportOverride] = useState(formValues?.transportOverride ?? false)
  const [transportOverrideAmt, setTransportOverrideAmt] = useState(formValues?.transportOverrideAmt ?? {})
  const [publicTransport, setUsePublicTransportation] = useState(formValues?.publicTransport ?? {})

  const [disabilityWorkExpensesM, setDisabilityWorkExpensesM] = useState(formValues?.workRelatedDisability ?? 0)
  const [disabilityOtherExpensesM, setDisabilityOtherExpensesM] = useState(formValues?.disabilityOtherExpensesM ?? 0)
  const [disabilityPersonalExpensesM, setDisabilityPersonalExpensesM] = useState(formValues?.disabilityPersonalExpensesM ?? 0)

  const [phoneOverride, setPhoneOverride] = useState(formValues?.phoneOverride ?? true)
  const [phoneOverrideAmt, setPhoneOverrideAmt] = useState(formValues?.phoneOverrideAmt ?? 0)
  const [numberOfPhonePlans, setNumberOfPhonePlans] = useState(formValues?.numberOfPhonePlans ?? 1)

  const [otherOverride, setOtherOverride] = useState(formValues?.otherOverride ?? true)
  const [otherOverrideAmt, setOtherOverrideAmt] = useState(formValues?.otherOverrideAmt ?? 0)

  const [otherRegularOverride, setOtherRegularOverride] = useState(formValues?.otherRegularOverride ?? false)
  const [outgoingChildSupport, setOutgoingChildSupport] = useState(formValues?.outgoingChildSupport ?? 0)
  const [outgoingAlimony, setOutgoingAlimony] = useState(formValues?.outgoingAlimony ?? 0)
  const [carInsuranceM, setCarInsuranceM] = useState(formValues?.carInsuranceM ?? 0)
  const [carPaymentM, setCarPaymentM] = useState(formValues?.carPaymentM ?? 0)
  const [rentersInsuranceM, setRentersInsuranceM] = useState(formValues?.rentersInsuranceM ?? 0)
  const [studentDebt, setStudentDebt] = useState(formValues?.studentDebt ?? 0)
  const [debtPayment, setDebtPayment] = useState(formValues?.debtPayment ?? 0)
  const [otherPayments, setOtherPayments] = useState(formValues?.otherPayments ?? 0)

  const [educationalExpenses, setEducationExpenses] = useState(formValues?.educationalExpenses ?? {})
  const [isChanged, setIsChanged] = useState(false)
  const [isRequiredFieldsEmpty, setIsRequiredFieldsEmpty] = useState(false)
  const saveUpdatedData = (): void => {
    props.setValues({
      ...formValues,
      adults: { ...adults },
      housingOverride,
      housingOverrideAmt,
      frspTenantPortion,
      housingFullCost,

      energyCostOverride,

      electricityCostOverride,
      electricityCostOverrideAmt,
      waterAndSewerOverride,
      waterAndSewerCostOverrideAmt,
      cookingFuelOverride,
      cookingFuelCostOverrideAmt,
      garbageAndTrashOverride,
      garbageAndTrashCostOverrideAmt,

      heatInRent,
      heatingSource,
      heatingCostOverrideAmt,
      cep,

      phoneOverride,
      phoneOverrideAmt,
      numberOfPhonePlans,

      foodOverride,
      foodOverrideAmt,

      transportOverride,
      transportOverrideAmt,
      publicTransport,

      disabilityWorkExpensesM,
      disabilityOtherExpensesM,
      disabilityPersonalExpensesM,

      otherOverride,
      otherOverrideAmt,

      otherRegularOverride,
      outgoingChildSupport,
      outgoingAlimony,
      carInsuranceM,
      carPaymentM,
      rentersInsuranceM,
      studentDebt,
      debtPayment,
      otherPayments,

      educationalExpenses
    })
  }

  const handleSubmit = (): void => {
    if (numberOfPhonePlans === 0 && phoneOverrideAmt > 0) {
      return
    }

    props.handleNext()
    saveUpdatedData()
  }

  const resetOtherRegularAmounts = (): void => {
    setOutgoingChildSupport(0)
    setOutgoingAlimony(0)
    setCarInsuranceM(0)
    setCarPaymentM(0)
    setRentersInsuranceM(0)
    setStudentDebt(0)
    setDebtPayment(0)
    setOtherPayments(0)
  }

  const resetOtherUtilitiesAmounts = (): void => {
    setElectricityCostOverride(false)
    setElectricityCostOverrideAmt(0)
    setWaterAndSewerOverride(false)
    setWaterAndSewerCostOverrideAmt(0)
    setCookingFuelOverride(false)
    setCookingFuelCostOverrideAmt(0)
    setGarbageAndTrashOverride(false)
    setGarbageAndTrashCostOverrideAmt(0)
    setHeatingCostOverrideAmt(0)
  }

  const renderRentMortgagePayment = (): JSX.Element => (
    <div className='rent-mortgage-payments'>
      <b>Rent or Mortgage Payments</b>

      <RadioGroup
        onChange={() => {
          setHousingOverride(!housingOverride)
          setIsChanged(true)
        }}
        value={String(housingOverride)}
      >
        <div className='amount-input d-flex-column'>
          <div className='housing' >
            <FormControlLabel
              value='true'
              control={<Radio />}
              label={
                <>
                  I want to enter my household rent/mortgage payment. My household pays for rent per month.
                  <br />
                  <i>If your heat is included in your rent, enter the whole amount you pay, including heat.</i>
                </>
              }
            />
            {housingOverride &&
              <InputField
                setValue={setHousingOverrideAmt}
                setIsChanged={setIsChanged}
                label='Amount in $'
                value={housingOverrideAmt}
                type='tel'
              />
            }
          </div>

          {formValues.check_sec8 && housingOverride && <div className='full-rent-question'>
            <p>What is the true cost of your rent or mortgage without subsidies?</p>
            {housingOverride &&
              <InputField
                setValue={setHousingFullCost}
                setIsChanged={setIsChanged}
                label='Amount in $'
                value={housingFullCost}
                type='tel'
              />
            }
          </div>
          }
        </div>
        <FormControlLabel
          value='false'
          control={<Radio />}
          label='I want the calculator to estimate my housing cost based on location.'
          onChange={() => {
            setHousingOverrideAmt(0)
            setHousingFullCost(0)
            resetOtherUtilitiesAmounts()
            setIsChanged(true)
          }}
        />
      </RadioGroup>

      {formValues?.check_frlp &&
        <div className='amount-input'>
          <b>What portion of your adjusted income do you pay in rent when living in FRSP housing? (%)</b>
          <InputField
            setValue={setFrspTenantPortion}
            setIsChanged={setIsChanged}
            label='Amount in $'
            value={frspTenantPortion}
            type='tel'
          />
        </div>
      }
    </div>
  )

  const renderHeatingSource = (): JSX.Element => (
    <div className='rent-mortgage-payments'>
      <b>Heating Costs</b>
      <div>
        <FormControlLabel
          labelPlacement='end'
          onChange={(e: any) => {
            setHeatInRent(e.target.checked)
            setHeatingSource('')
            setHeatingCostOverrideAmt(0)
            setIsChanged(true)
          }}
          label={' Heat is included in my rent amount above.'}
          control={<Checkbox checked={heatInRent} />}
        />

        <div className='amount-input'>
          <p>Heating Source:</p>
          <Select
            isDisabled={heatInRent}
            className='basic-single'
            classNamePrefix='select'
            isSearchable={false}
            placeholder={'Select Source'}
            onChange={(e: any) => {
              setHeatingSource(e.value)
              setIsChanged(true)
            }}
            name='state'
            options={HEATING_SOURCE_OPTIONS}
            value={getSelectdValue(heatingSource, HEATING_SOURCE_OPTIONS)}
          />
        </div>
        {energyCostOverride && <div className='amount-input'>
          <p>Heating costs per month if not included in your rent: </p>
          <InputField
            disabled={heatInRent}
            setValue={setHeatingCostOverrideAmt}
            setIsChanged={setIsChanged}
            label='Amount in $'
            value={heatingCostOverrideAmt}
            type='tel'
          />
        </div>}

      </div>
    </div>
  )

  const renderUtilitiesPayment = (): JSX.Element => (
    <div className='utilities'>
      <b>Utilities</b>
      <RadioGroup
        onChange={() => {
          setEnergyCostOverride(!energyCostOverride)
          setIsChanged(true)
        }}
        value={String(energyCostOverride)}
      >
        <div className='amount-input'>
          <FormControlLabel value='true' control={<Radio />} label='I want to enter how much my household pays out of pocket for utilities like heating, gas, electric, and water.' />
        </div>
        <FormControlLabel
          value='false'
          onChange={() => {
            resetOtherUtilitiesAmounts()
            setIsChanged(true)
          }}
          control={<Radio />}
          label='I want the calculator to estimate my utility costs based on size and location.'
        />
      </RadioGroup>
    </div>
  )

  const otherElectricityUtility = (): JSX.Element => (
    <div className='other-utiltity-box'>
      <FormControlLabel
        labelPlacement='end'
        onChange={(e: any) => {
          setElectricityCostOverride(e.target.checked)
          setElectricityCostOverrideAmt(0)
          setIsChanged(true)
        }}
        label={'Electricity'}
        control={<Checkbox checked={electricityCostOverride} />}
      />

      <div className='amount-input'>
        <InputField
          disabled={!electricityCostOverride}
          setValue={setElectricityCostOverrideAmt}
          setIsChanged={setIsChanged}
          label='Amount in $'
          value={electricityCostOverrideAmt}
          type='tel'
        />
      </div>
    </div>
  )

  const otherWaterAndSewerUtility = (): JSX.Element => (
    <div className='other-utiltity-box'>
      <FormControlLabel
        labelPlacement='end'
        onChange={(e: any) => {
          setWaterAndSewerOverride(e.target.checked)
          setWaterAndSewerCostOverrideAmt(0)
          setIsChanged(true)
        }}
        label={'Water and Sewer'}
        control={<Checkbox checked={waterAndSewerOverride} />}
      />

      <div className='amount-input'>
        <InputField
          disabled={!waterAndSewerOverride}
          setValue={setWaterAndSewerCostOverrideAmt}
          setIsChanged={setIsChanged}
          label='Amount in $'
          value={waterAndSewerCostOverrideAmt}
          type='tel'
        />
      </div>
    </div>
  )

  const otherCookingFuelUtility = (): JSX.Element => (
    <div className='other-utiltity-box'>
      <FormControlLabel
        labelPlacement='end'
        onChange={(e: any) => {
          setCookingFuelOverride(e.target.checked)
          setCookingFuelCostOverrideAmt(0)
          setIsChanged(true)
        }}
        label={'Cooking Fuel if not included in heat bill'}
        control={<Checkbox checked={cookingFuelOverride} />}
      />

      <div className='amount-input'>
        <InputField
          disabled={!cookingFuelOverride}
          setValue={setCookingFuelCostOverrideAmt}
          setIsChanged={setIsChanged}
          label='Amount in $'
          value={cookingFuelCostOverrideAmt}
        />
      </div>
    </div>
  )

  const otherGarbageAndTrashUtility = (): JSX.Element => (
    <div className='other-utiltity-box'>
      <FormControlLabel
        labelPlacement='end'
        onChange={(e: any) => {
          setGarbageAndTrashOverride(e.target.checked)
          setGarbageAndTrashCostOverrideAmt(0)
          setIsChanged(true)
        }}
        label={'Garbage and Trash'}
        control={<Checkbox checked={garbageAndTrashOverride} />}
      />

      <div className='amount-input'>
        <InputField
          disabled={!garbageAndTrashOverride}
          setValue={setGarbageAndTrashCostOverrideAmt}
          setIsChanged={setIsChanged}
          label='Amount in $'
          value={garbageAndTrashCostOverrideAmt}
          type='tel'
        />
      </div>
    </div>
  )

  const renderOtherUtilitiesCosts = (): JSX.Element => (
    <div className='utilities'>
      <b>Other Utilities</b>
      {otherElectricityUtility()}
      {otherWaterAndSewerUtility()}
      {otherCookingFuelUtility()}
      {otherGarbageAndTrashUtility()}
    </div>
  )

  const renderFoodCost = (): JSX.Element => (
    <div className='food'>
      <b>Food</b>
      <RadioGroup
        onChange={() => {
          setFoodOverride(!foodOverride)
          setIsChanged(true)
        }}
        value={String(foodOverride)}
      >
        <div className='amount-input'>
          <FormControlLabel
            value='true'
            control={<Radio />}
            label='I want to enter my household food cost.'
          />

          {foodOverride &&
            <InputField
              setValue={setFoodOverrideAmt}
              setIsChanged={setIsChanged}
              label='Amount in $'
              value={foodOverrideAmt}
              type='tel'
            />
          }
        </div>
        <FormControlLabel
          onChange={() => {
            setFoodOverrideAmt(0)
            setIsChanged(true)
          }}
          value='false'
          control={<Radio />}
          label='I want the calculator to estimate my out-of-pocket food cost.'
        />
      </RadioGroup>

      {
        formValues?.childrenCount > 0 && (formValues?.check_frlp) &&
        formValues?.state !== 'DC' && formValues?.state !== 'PA' &&
        <FormControlLabel
          labelPlacement='start'
          className='school-meal'
          onChange={(e: any) => {
            setCep(e.target.checked)
            setIsChanged(true)
          }}
          label={'Does the school should give free or reduced price meals to all students without asking about income?'}
          control={<Checkbox checked={cep} />}
        />
      }
    </div>
  )

  const resetTransportCost = (): void => {
    setTransportOverrideAmt(0)
    Object.keys(formValues.adults).forEach((key: string | number) => {
      _.set(formValues, ['adults', key, 'transOverrideAmt'], 0)
    })
    setIsChanged(true)
  }

  const renderTransportCost = (): JSX.Element => {
    const handlePublicTransportChange = (key: number) => (event: ChangeEvent<{}>) => {
      const Checkbox = event.target as HTMLInputElement
      setUsePublicTransportation({ ...publicTransport, [key]: Checkbox.checked })
      setAdults({ ...adults, [key]: { ...adults[key], publicTransport: Checkbox.checked } })
      setIsChanged(true)
    }

    return (
      <div className='transportation'>
        <b>Transportation</b>
        <RadioGroup
          onChange={() => {
            setTransportOverride(!transportOverride)
            setIsChanged(true)
            setIsChanged(true)
          }}
          value={String(transportOverride)}
        >
          <div className='amount-input d-flex-column'>
            <FormControlLabel
              value='true'
              control={<Radio />}
              label='I want to enter how much my household spends on transportation
              (gas, car repairs, bus fare, taxi/ride-sharing costs, etc.).'
            />

            {transportOverride &&
              Object.keys(formValues.adults).map((key: string) =>
                <div className='d-flex amount-input' key={key}>
                  <p>{`Please enter the amount that ${key === '0' ? 'you pay' : formValues.adults[key]?.name || `Adult ${key} pays`} for transportation:`}</p>
                  <InputField
                    onChange={(inputValue: number | string) => {
                      setTransportOverrideAmt({ ...transportOverrideAmt, [key]: inputValue })
                      _.set(formValues, ['adults', key, 'transportOverrideAmt'], inputValue)
                      setIsChanged(true)
                    }}
                    label='Amount in $'
                    value={transportOverrideAmt[key]}
                    type='tel'
                  />
                </div>
              )
            }
          </div>

          <div className='public-transport-checkboxes'>
            <div className='public-transport-question'>
              <p>Which members of your household use public transport?</p>

              {formValues.check_com_reduced_fare_passes &&
                <i>If you do not use public transportation, de-select City of Madison Reduced Fare Bus Pass in Step 4.</i>}
            </div>

            {Object.keys(formValues.adults).map((_adult, key) => {
              const adult = formValues.adults[key]
              const name = key < 1 ? 'Myself' : adult?.name || `Adult ${key}`
              _.get(formValues, ['adults', key, 'publicTransport']) === undefined &&
                _.set(formValues, ['adults', key, 'publicTransport'], true)

              return (
                <div className='public-transport-checkboxes' key={key}>
                  <FormControlLabel
                    labelPlacement='bottom'
                    onChange={handlePublicTransportChange(key)}
                    disabled={adult.checkBusPassEligibility}
                    control={<Checkbox checked={adults[key].publicTransport} />}
                    label={name}
                  />
                </div>
              )
            })}
          </div>

          <FormControlLabel
            onChange={resetTransportCost}
            value='false'
            control={<Radio />}
            label='I want the calculator to estimate my households transportation costs based on
             the number of days worked and local transportation costs.'
          />
        </RadioGroup>
      </div>
    )
  }

  const renderDisabilityExpenseInputs = (): JSX.Element => (
    <div className='disability-expenses'>
      <b>Disability-Related Expenses</b>
      <div className='amount-input'>
        <p>How much does your household spend on work-related disability expenses (e.g., wheelchairs needed to get to work)?</p>
        <InputField
          setValue={setDisabilityWorkExpensesM}
          setIsChanged={setIsChanged}
          label='Amount in $'
          value={disabilityWorkExpensesM}
          type='tel'
        />
        <p>per month</p>
      </div>

      <div className='amount-input'>
        <p>How much does your household spend on non-work-related disability expenses (e.g., wheelchairs for people who do not work)?</p>
        <InputField
          setValue={setDisabilityOtherExpensesM}
          setIsChanged={setIsChanged}
          label='Amount in $'
          value={disabilityOtherExpensesM}
          type='tel'
        />
        <p>per month</p>
      </div>

      <div className='amount-input'>
        <p>How much does your household spend on dependent care for people with disabilities (e.g., home aides)?</p>
        <InputField
          setValue={setDisabilityPersonalExpensesM}
          setIsChanged={setIsChanged}
          label='Amount in $'
          value={disabilityPersonalExpensesM}
          type='tel'
        />
        <p>per month</p>
      </div>
    </div>
  )

  const renderOtherNecessities = (): JSX.Element => (
    <div className='phone-bill'>
      <b>Other Necessities</b>
      <RadioGroup
        onChange={() => {
          setOtherOverride(!otherOverride)
          setIsChanged(true)
        }}
        value={String(otherOverride)}
      >
        <div className='amount-input'>
          <FormControlLabel
            value='true'
            control={<Radio />}
            label='I want to enter how much my household  spends per month on other necessary things, like clothes,
             school supplies, and household supplies. My household spends about per month  out of pocket on things like these.'
          />
          {otherOverride &&
            <InputField
              setValue={setOtherOverrideAmt}
              setIsChanged={setIsChanged}
              label='Amount in $'
              value={otherOverrideAmt}
              type='tel'
            />
          }
        </div>
        <FormControlLabel
          onChange={() => {
            setOtherOverrideAmt(0)
            setIsChanged(true)
          }}
          value='false' control={<Radio />}
          label='I want the calculator to estimate  these costs based on national averages'
        />
      </RadioGroup>
    </div>
  )

  const renderOtherRegularPayments = (): JSX.Element => (
    <div className='phone-bill'>
      <b>Other Regular Payments</b>
      <RadioGroup
        onChange={() => {
          setOtherRegularOverride(!otherRegularOverride)
          setIsChanged(true)
        }}
        value={String(otherRegularOverride)}
      >
        <FormControlLabel
          onChange={() => { resetOtherRegularAmounts() }}
          value='false' control={<Radio />}
          label='My household has no other regular payments, or I want to skip entering any for now.'
        />
        <div className='other-regular-inputs-div'>
          <FormControlLabel
            value='true'
            control={<Radio />}
            label='My household has other regular payments. They are:'
          />
          {otherRegularOverride &&
            <>
              <div className='input-div'>
                <p>Child support (that you pay)</p>
                <InputField
                  setValue={setOutgoingChildSupport}
                  setIsChanged={setIsChanged}
                  label='Amount in $'
                  value={outgoingChildSupport}
                  type='tel'
                />
                <p>$ per month</p>
              </div>

              <div className='input-div'>
                <p>Alimony (that you pay)</p>
                <InputField
                  setValue={setOutgoingAlimony}
                  setIsChanged={setIsChanged}
                  label='Amount in $'
                  value={outgoingAlimony}
                  type='tel'
                />
                <p>$ per month</p>
              </div>

              <div className='input-div'>
                <p>Car insurance</p>
                <InputField
                  setValue={setCarInsuranceM}
                  setIsChanged={setIsChanged}
                  label='Amount in $'
                  value={carInsuranceM}
                  type='tel'
                />
                <p>$ per month</p>
              </div>

              <div className='input-div'>
                <p>Car payments (car loans)</p>
                <InputField
                  setValue={setCarPaymentM}
                  setIsChanged={setIsChanged}
                  label='Amount in $'
                  value={carPaymentM}
                  type='tel'
                />
                <p>$ per month</p>
              </div>

              <div className='input-div'>
                <p>Renters&apos; or homeowners&apos; insurance</p>
                <InputField
                  setValue={setRentersInsuranceM}
                  setIsChanged={setIsChanged}
                  label='Amount in $'
                  value={rentersInsuranceM}
                  type='tel'
                />
                <p>$ per month</p>
              </div>

              <div className='input-div'>
                <p>Student loan debt</p>
                <InputField
                  setValue={setStudentDebt}
                  setIsChanged={setIsChanged}
                  label='Amount in $'
                  value={studentDebt}
                  type='tel'
                />
                <p>$ per month</p>
              </div>

              <div className='input-div'>
                <p>Any other debt</p>
                <InputField
                  setValue={setDebtPayment}
                  setIsChanged={setIsChanged}
                  label='Amount in $'
                  value={debtPayment}
                />
                <p>$ per month</p>
              </div>

              <div className='input-div'>
                <p>Any other regular payments</p>
                <InputField
                  setValue={setOtherPayments}
                  setIsChanged={setIsChanged}
                  label='Amount in $'
                  value={otherPayments}
                />
                <p>$ per month</p>
              </div>
            </>
          }
        </div>
      </RadioGroup>
    </div>
  )

  const renderEducationPayments = (adult: Adults, index: number): JSX.Element => (
    <div className='phone-bill flex-column' key={index}>
      <div className='d-flex justify-space-between input-div'>
        <p>{index < 1 ? 'My Educational expenses' : `Educational expenses of ${adult?.name ?? `Adult ${index}`}`} per year</p>
        <InputField
          onChange={(inputValue: number | string) => {
            setEducationExpenses({ ...educationalExpenses, [index]: inputValue })
            _.set(formValues, ['adults', index, 'educationalExpenses'], Number(inputValue))
            setIsChanged(true)
          }}
          label='Amount in $'
          value={educationalExpenses[index] ?? 0}
          type='tel'
        />
      </div>
    </div>
  )

  scrollToTop()

  return (
    <>
      <div className='step-seven'>
        <h3>Other Expenses (per Month)</h3>
        <p>On this page, you can fill in your costs or use the calculator&apos;s estimate.</p>
        <p>If you fill in your costs, include out-of-pocket costs only. For example:</p>
        <p>•  If heating is included in your rent, do not include any cost for heat under “utilities.”</p>
        <p>•  If you receive SNAP benefits or other food assistance, do not include the amount on your EBT card(s). Enter only what you pay out of pocket for food.</p>

        {renderRentMortgagePayment()}

        <Divider />

        {renderUtilitiesPayment()}

        <Divider />

        {renderHeatingSource()}

        <Divider />

        {energyCostOverride && renderOtherUtilitiesCosts()}

        {energyCostOverride && <Divider />}

        <PhoneBillsInputs
          checkLifeline={formValues.check_lifeline}
          enrolledLifeline={formValues.enrolled_lifeline}
          phoneOverride={phoneOverride}
          setPhoneOverride={setPhoneOverride}
          phoneOverrideAmt={phoneOverrideAmt}
          setPhoneOverrideAmt={setPhoneOverrideAmt}
          numberOfPhonePlans={numberOfPhonePlans}
          setNumberOfPhonePlans={setNumberOfPhonePlans}
          setIsChanged={setIsChanged}
          setIsRequiredFieldsEmpty={setIsRequiredFieldsEmpty}
        />

        <Divider />

        {renderFoodCost()}

        <Divider />

        {renderTransportCost()}

        <Divider />

        {anyDisabledAdult(formValues.adults) && renderDisabilityExpenseInputs()}

        {anyDisabledAdult(formValues.adults) && <Divider />}

        {anyAdultStudent(formValues.adults) &&
          <>
            <b>Education Expenses:</b>
            <p>Please enter the yearly education expenses (tuition, fees, pooks, supplies, equipment) for adult student(s)</p>
            {Object.values(formValues.adults).map((adult: any, index: number) =>
              ['less_than_pt_student', 'pt_student', 'ft_student'].includes(adult?.student) && renderEducationPayments(adult, index)
            )}
            <Divider />
          </>
        }
        {renderOtherNecessities()}

        <Divider />

        {renderOtherRegularPayments()}
      </div>
      {isRequiredFieldsEmpty && <ErrorMessage text='Complete required fields' />}
      <NavigationButtons
        handleBack={props.handleBack}
        handleSubmit={handleSubmit}
        step={props.step}
        saveUpdatedData={saveUpdatedData}
        isChanged={isChanged}
      />
    </>
  )
}

export default StepSeven
export type { StepSevenProps }
