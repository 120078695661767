import React, { memo } from 'react'

import { calculateHealthcareAmount } from '../helpers/Benefits'
import { type BenefitProps } from '../../../EmployeeCalculatorInterface'
import BenefitDetails from '../BenefitDetails'

const HealthcareBenefits: React.FC<BenefitProps> = memo(({ formValuesContext, benefitDataCompany, setValues }) => {
  return (
    <BenefitDetails
      title='Healthcare Benefits'
      benefitType='health'
      calculateAmount={calculateHealthcareAmount}
      formValuesContext={formValuesContext}
      benefitDataCompany={benefitDataCompany}
      setValues={setValues}
    />
  )
})

export default HealthcareBenefits
