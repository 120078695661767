import React from 'react'
import { toast, ToastContainer } from 'react-toastify'

import 'react-toastify/dist/ReactToastify.css'

interface NotificationProps {
  message: String,
  success: boolean
}

const Notification: React.FC<NotificationProps> = ({ message, success }) => {
  success ? toast.success(message) : toast.error(message)

  return <ToastContainer
    autoClose={2000}
    closeOnClick
    draggable
    hideProgressBar={false}
    newestOnTop={false}
    pauseOnFocusLoss
    pauseOnHover
    position='top-right'
    rtl={false}
  />
}

export default Notification
export type { NotificationProps }
