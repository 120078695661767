import React, { useState, useEffect } from 'react'
import { currencyFormatter, incrementFromType, valueForIncrement } from '../Helper'
import I18n from '../config/I18n'
import '../component_styles/BenefitsOutcomeCard.sass'

interface BenefitsOutcomeCardProps {
  programName: string
  description: string
  outcomeYearly: number
  outcomeIncrementType: string
  copayValue?: number
  copayIncrement?: string
  copayIncrease?: boolean
}

const BenefitsOutcomeCard: React.FC<BenefitsOutcomeCardProps> = (props) => {
  const { programName, description, outcomeYearly, copayValue, copayIncrement, copayIncrease } = props
  const outcomeValueIsNegative = outcomeYearly < 0
  const [outcomeIncrement, setOutcomeIncrement] = useState('yr')
  const [outcomeValue, setOutcomeValue] = useState(outcomeYearly)
  useEffect(() => {
    setOutcomeIncrement(incrementFromType(props.outcomeIncrementType))
    setOutcomeValue(valueForIncrement(props.outcomeIncrementType, outcomeYearly))
  }, [props.outcomeIncrementType])

  return (
    <div className="benefits-outcome-card">
      <div className="clearfix">
        <h4 className="no-margin-bottom">
          <span className="float-left">{ programName }</span>
          { outcomeValue && outcomeIncrement &&
            <span className={`float-right ${outcomeValueIsNegative ? "red" : ""}`}>{ currencyFormatter.format(outcomeValue) }/{ outcomeIncrement }</span>
          }
          { outcomeValue && !outcomeIncrement &&
            <span className="float-right">{ currencyFormatter.format(outcomeValue) }</span>
          }
          { !outcomeValue &&
            <span className="float-right">{ I18n.t('ineligible') }</span>
          }
        </h4>
      </div>
      { copayValue &&
        <div className="copay">
          { copayIncrease &&
            <i className="fas fa-arrow-circle-up red"></i>
          }
          { !copayIncrease &&
            <i className="fas fa-arrow-circle-down green"></i>
          }
          <span className="copay-value">{ currencyFormatter.format(copayValue) }</span>
          { copayIncrement &&
            <span>/{ copayIncrement }</span>
          }
          <span className="copay-text">copay</span>
        </div>
      }
      { description.length > 300 &&
        <p>{ description.slice(0, 297) }...</p>
      }
      { description.length <= 300 &&
        <p>{ description }</p>
      }
    </div>
  )
}

export default BenefitsOutcomeCard
export type { BenefitsOutcomeCardProps }
