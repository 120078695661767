import React, { useState, useEffect } from 'react'
import '../component_styles/ToggleButton.sass'

interface ToggleButtonProps {
  labels: [string, string],
  selected: string
}

const ToggleButton: React.FC<ToggleButtonProps> = (props) =>
{
  const [selected, setSelected] = useState(props.selected)

  useEffect(() => {
    setSelected(props.selected)
  }, [props.selected])

  function toggleSelection(ev: any) {
    setSelected(ev.target.value)
  }

  return (
    <>
      <input
        id="toggle-on"
        className="toggle toggle-left"
        name="toggle"
        value={props.labels[0]}
        type="radio"
        checked={selected == props.labels[0]}
        onChange={ toggleSelection }
      />
      <label htmlFor="toggle-on" className="toggle-button" >{ props.labels[0] }</label>
      <input
        id="toggle-off"
        className="toggle toggle-right"
        name="toggle"
        value={props.labels[1]}
        type="radio"
        checked={selected == props.labels[1]}
        onChange={ toggleSelection }
      />
      <label htmlFor="toggle-off" className="toggle-button" >{ props.labels[1] }</label>
    </>
  )
}

export default ToggleButton
export type { ToggleButtonProps }

