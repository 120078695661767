import React, { memo, type Context, useState } from 'react'
import { Stepper, Step, StepLabel } from '@material-ui/core/'
import { Button } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'

import { type FormValues } from '../../FormValuesInterfaces'
import FolderManagement from './FolderManagement'
import CaseManagement from './CaseManagement'
import CaseDataExportCsv from './CaseDataExportCsv'
import VisualizationConfigurator from './VisualizationConfiguration'
import CaseOverview from './CaseOverview'
import ScenarioVariables from './ScenarioVariables'
import GenerateVisualization from './GenerateVisualization'
import { ADMIN_DASHBOSRD_STEP_NAMES } from '../../Constants'

import './styles.sass'

interface AdminDasboardStepProps {
  adminValuesContext: Context<FormValues>
}

const DataDashboard: React.FC<AdminDasboardStepProps> = memo(() => {
  const [step, setStep] = useState(0)
  const [formValues, setFormValues] = useState<FormValues>({} as FormValues)
  const AdminValuesContext = React.createContext<FormValues>(formValues)
  const [selectedFolder, setSelectedFolder] = useState({ name: '', id: 0 })
  const [selectedCase, setSelectedCase] = useState({ name: '', id: 0, description: '' })
  const handleNextClick = (): void => {
    setStep(step + 1)
  }

  const handleSelection = (name: string, id: number): void => {
    setSelectedFolder({ name, id })
    handleNextClick()
  }
  const handleCaseSelection = (name: string, id: number, description: string): void => {
    setSelectedCase({ name, id, description })
    handleNextClick()
  }

  const handleBackButton = (): void => {
    setStep(step - 1)
  }
  return (
    <div className='adminDashboardMainContainer'>
      <h2 className='dashboardHeading'>Data DashBoard</h2>
      <Stepper activeStep={step} alternativeLabel className='adminStepper'>
        {ADMIN_DASHBOSRD_STEP_NAMES.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>

      <Button
        onClick={handleBackButton}
        startIcon={<ArrowBackIcon />}
        disabled={ step === 0 }
        className='backButton'
      >
        Back
      </Button>
      <div>
        {(() => {
          switch (step) {
            case 0:
              return (
                <FolderManagement
                  handleItemSelection={handleSelection}
                  adminValuesContext={AdminValuesContext}
                  setValues={setFormValues}
                  handleNextClick={handleNextClick}
                />
              )
            case 1:
              return (
                <CaseManagement
                  adminValuesContext={AdminValuesContext}
                  setValues={setFormValues}
                  selectedItem={selectedFolder}
                  handleItemSelection={handleCaseSelection}
                  handleNextClick={handleNextClick}
                />
              )
            case 2:
              return (
                <CaseOverview
                  adminValuesContext={AdminValuesContext}
                  selectedCase={selectedCase}
                  selectedFolder={selectedFolder}
                  handleNextClick={handleNextClick}
                  setSelectedCase={setSelectedCase}
                />
              )
            case 3:
              return (
                <ScenarioVariables
                  adminValuesContext={AdminValuesContext}
                  selectedCase={selectedCase}
                  selectedFolder={selectedFolder}
                  handleNextClick={handleNextClick}
                />
              )
            case 4:
              return (
                <CaseDataExportCsv
                  adminValuesContext={AdminValuesContext}
                  selectedCase={selectedCase}
                  selectedFolder={selectedFolder}
                  handleNextClick={handleNextClick}
                  setSelectedCase={setSelectedCase}
                />

              )
            case 5:
              return (
                <VisualizationConfigurator
                  selectedItem={selectedCase}
                  selectedFolder={selectedFolder}
                  handleNextClick={handleNextClick}
                />
              )
            case 6:
              return (
                <GenerateVisualization
                  selectedItem={selectedCase}
                  adminValuesContext={AdminValuesContext}
                />
              )
            default:
              return null
          }
        })()}
      </div>
    </div>
  )
})

export default DataDashboard
