import React, { type Dispatch, type SetStateAction } from 'react'
import Modal from '@material-ui/core/Modal'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

import '../component_styles/PrivacyModal.sass'

interface Props {
  modalOpen: boolean
  setModalOpen: Dispatch<SetStateAction<boolean>>
}

const PrivacyPolicyModal: React.FC<Props> = (props) => {
  const { modalOpen, setModalOpen } = props

  const handleModalClose = (): void => {
    setModalOpen(false)
  }
  return (
    <Modal open={modalOpen} onClose={handleModalClose} className='privacy-modal-container'>
      <>
        <IconButton
          aria-label='close'
          className='privacy-modal-close-button'
          onClick={handleModalClose}
        >
          <CloseIcon fontSize='small'/>
        </IconButton>
        <iframe
          src='https://www.iubenda.com/privacy-policy/79928572/full-legal'
          title='Privacy Policy'
          className='privacy-modal-content'
        />
      </>
    </Modal>
  )
}

export default PrivacyPolicyModal
