import { toast } from 'react-toastify'
import { apiClient } from './apiClient'
import I18n from '../config/I18n'

export const getScenarios = (folderId: number, page: number): Promise<any> => {
  return new Promise((resolve, reject) => {
    apiClient
      .get(`/${I18n.locale as string}/admin/folders/${folderId}/scenarios`, {
        params: {
          page: page,
          per_page: 5
        }
      })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        if (error.response && error.response.data && error.response.data.error) {
          toast.error(error.response.data.error)
        } else {
          toast.error('An error occurred while fetching the scenarios.')
        }
        reject(error)
      })
  })
}

export const createScenario = (
  folderId: number,
  name: string,
  file: File,
  description?: string
): Promise<any> => {
  return new Promise((resolve, reject) => {
    const formData = new FormData()
    formData.append('name', name)
    formData.append('file', file)
    if (description) {
      formData.append('description', description)
    }
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }

    apiClient
      .post(`/${I18n.locale as string}/admin/scenarios/${folderId}/create`, formData, config)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        toast.error('An error occurred while creating the scenario.')

        reject(error)
      })
  })
}

export const deleteScenario = (id: number): Promise<any> => {
  return new Promise((resolve, reject) => {
    apiClient
      .delete(`/${I18n.locale as string}/admin/scenarios/${id}`)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        if (error.response && error.response.data && error.response.data.error) {
          toast.error(error.response.data.error)
        } else {
          toast.error('An error occurred while deleting the scenario.')
        }
        reject(error)
      })
  })
}

export const updateScenario = (
  id: number,
  itemKey: string,
  name: string,
  file: File,
  description?: string
): Promise<any> => {
  return new Promise((resolve, reject) => {
    const formData = new FormData()
    formData.append('name', name)
    formData.append('file', file)
    formData.append('item_key', itemKey)
    if (description) {
      formData.append('description', description)
    }
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }

    apiClient
      .patch(`/${I18n.locale as string}/admin/scenarios/${id}`, formData, config)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        if (error.response && error.response.data && error.response.data.error) {
          toast.error(error.response.data.error)
        } else {
          toast.error('An error occurred while updating the scenario.')
        }
        reject(error)
      })
  })
}

export const UpdateDescriptionAdminScenario = (id: number, description: string): Promise<any> => {
  return new Promise((resolve, reject) => {
    apiClient
      .post(`/${I18n.locale as string}/admin/scenarios/${id}/create_or_update_description`, {
        description: description,
      })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        if (error.response && error.response.data && error.response.data.error) {
          toast.error(error.response.data.error)
        } else {
          toast.error('An error occurred while updating the description.')
        }
        reject(error)
      })
  })
}

export const showScenario = (item_id: string, item_key: string): Promise<any> => {
  return new Promise((resolve, reject) => {

    apiClient
      .get(`/${I18n.locale as string}/admin/scenarios/${item_id}?item_key=${item_key}`)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        toast.error('An error occurred while processing the Household CSV.')
        reject(error)
      })
  })
}