import React from 'react'
import I18n from '../config/I18n'
import Button from './Button'
import Link from './Link'
import { SnapshotDetailedCardProps } from './SnapshotDetailedCard'
import { SnapshotSimplifiedCardProps }from './SnapshotSimplifiedCard'
import SnapshotCollection from './SnapshotCollection'
import '../component_styles/UserProfile.sass'

interface UserProfileProps {
  userEmail: string
  createdAt: string
  detailedSnapshot: SnapshotDetailedCardProps
  simpleSnapshots: SnapshotSimplifiedCardProps[]
  userId: number
}
const UserProfile: React.FC<UserProfileProps> = (props) => {

  const snapshotNumber = props.detailedSnapshot ? props.simpleSnapshots.length + 1 : 0
    
  return(
    <div>
      <h2 className="center">{ I18n.t("welcome_back") }</h2>
      <Button text={ I18n.t("snapshot.create_new_snapshot") } />
      <div className="user-profile-greeting">
        <h4>{ props.userEmail }</h4>
        <p>{ I18n.t("snapshot.since_joining", { number:  snapshotNumber, year: props.createdAt}) }</p>
        <Link
         text={ I18n.t("edit_my_info") }
         href={ `/${I18n.locale}/users/${props.userId}/edit` }
        />
      </div>
      { props.detailedSnapshot &&
        <SnapshotCollection
          detailedSnapshot={ props.detailedSnapshot }
          simpleSnapshots={ props.simpleSnapshots }
          className="on-profile-page"
          linkClassName="reverse"
        />
      }
    </div>
  )
}

export default UserProfile
export type { UserProfileProps }
