import React, { memo, useEffect, useState } from 'react'
import Modal from '@mui/material/Modal'
import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'
import Rating from '@mui/material/Rating'
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'

import parsleyB2BLogo from '../../../../assets/images/parsleyB2Blogo.png'
import { postNpsFeedbacks } from '../../../api/npsFeedback'
import PurpleButton from '../PurpleButton'

import './styles.sass'

const StyledRating = styled(Rating)({})

interface NPSModalProps {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  setSurveyCompletionOpen: (isOpen: boolean) => void
  employeeID?: number | string | undefined
  userID?: number
  npsRating: number
  setNpsRating: (npsRating: number) => void
}

const NPSSurvey: React.FC<NPSModalProps> = memo((props) => {
  const { isOpen, setIsOpen, setSurveyCompletionOpen, employeeID, userID, npsRating, setNpsRating } = props
  const [selectedRating, setSelectedRating] = useState<number | null>(null)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [disabled, setDisabled] = useState(true)

  useEffect(() => {
    if (npsRating !== 0) {
      setDisabled(false)
    }
  }, [npsRating])

  const handleChange = (_event: React.SyntheticEvent | null, newNpsRatingValue: number | null): void => {
    if (newNpsRatingValue !== null) {
      setNpsRating(newNpsRatingValue)
      setSelectedRating(newNpsRatingValue)
    }
  }

  const getRatingBoxClassName = (ratingValue: number): string => {
    return selectedRating === ratingValue
      ? 'rating-box selected-rating-box'
      : 'rating-box'
  }

  const handleClose = (): void => { setIsOpen(false) }

  const handleOKButtonClick = (): void => {
    setIsSubmitted(true)
    setSurveyCompletionOpen(true)
  }

  const submitRating = async (): Promise<void> => {
    const NpsFeedbackData = {
      rating: npsRating,
      employee_id: employeeID,
      user_id: userID
    }

    const response = await postNpsFeedbacks(NpsFeedbackData)
    if (response) {
      handleClose()
    }
  }

  useEffect(() => {
    if (isSubmitted) {
      void submitRating()
    }
  }, [isSubmitted])

  return (
    <Modal open={isOpen} className='nps-modal-background'>
      <div className='nps-modal-box'>
        <div className='nps-modal-close-button'>
          <IconButton disableRipple onClick={handleClose}>
            <CloseIcon fontSize='large' />
          </IconButton>
        </div>

        <img src={parsleyB2BLogo} alt='Parsley B2B Logo' className='logo-image' />
        <p>Your feedback is very important to us.</p>

        <div className='nps-modal-option'>
          <b>How likely are you to recommend Parsley to a friend, coworker or family member?</b>
          <div className='customized-rating'>
            <Box className='rating-container'>
              {Array.from({ length: 5 }).map((_, value) => {
                const ratingValue: number = value + 1
                return (
                  <Box
                    key={ratingValue}
                    className={getRatingBoxClassName(ratingValue)}
                    onClick={() => { handleChange(null, ratingValue) }}
                  >
                    {ratingValue}
                  </Box>
                )
              })}
            </Box>
            <StyledRating
              name='customized-color'
              value={npsRating}
              getLabelText={(value: number) => `${value} Heart${value !== 1 ? 's' : ''}`}
              precision={0.5}
              icon={<></>}
              emptyIcon={<></>}
              onChange={(_event, newValue) => { handleChange(null, newValue) }}
            />
          </div>

          <div className='nps-modal-button'>
            <PurpleButton label='Send Feedback' width='' buttonClick={handleOKButtonClick} disabled={disabled} />
          </div>
        </div>
      </div>
    </Modal>
  )
})

export default NPSSurvey
