import React, { memo } from 'react'

import './styles.sass'

const ColorDivider = memo(() => (
    <div className='color-divider'>
      <div className='purple-line'></div>
      <div className='beige-line'></div>
      <div className='red-line'></div>
    </div>
))

export default ColorDivider
