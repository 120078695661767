import React, { memo } from 'react'

import { type FSABenefitProps } from '../../../EmployeeCalculatorInterface'
import BenefitsContributionsDetails from '../BenefitsContributionsDetails'

const TSA403B: React.FC<FSABenefitProps> = memo((props) => {
  return (
    <BenefitsContributionsDetails
      {...props}
      componentName='TSA403b'
      benefitKey='tsa403b'
    />
  )
})

export default TSA403B
