import React, { type Context, useEffect, useState } from 'react'
import { Stepper, Step, StepLabel } from '@material-ui/core/'
import { ToastContainer } from 'react-toastify'

import { STEP_NAMES } from '../../Constants'
import StepOne from './StepOne'
import StepTwo from './StepTwo'
import StepFour from './StepFour'
import StepThree from './StepThree'
import StepFive from './StepFive'
import StepSix from './StepSix'
import StepSeven from './StepSeven'
import StepEight from './StepEight'

import '../../component_styles/BenefitsCliff.sass'
import 'react-toastify/dist/ReactToastify.css'
import LogoAndBackButton from '../LogoAndBackButton'
import { getBenefitData } from '../Employee/helpers/Benefits'
import { type FormValues } from '../../FormValuesInterfaces'
import { type BenefitDataCompany } from '../../EmployeeCalculatorInterface'

interface BenefitsCliffProps {
  usStates: { label: string, value: string }
  wiCounties: string[]
  formValuesContext?: Context<FormValues>
  userId?: number
  values?: FormValues
}

const BenefitsCliff: React.FC<BenefitsCliffProps> = (props) => {
  const { usStates, wiCounties, formValuesContext, values, userId } = props
  const [step, setStep] = useState(0)
  const [formValues, setFormValues] = useState<FormValues>(values ?? {} as FormValues)
  const [benefitDataCompany, setBenefitDataCompany] = useState({})
  const FormValuesContext = formValuesContext ?? React.createContext<FormValues>(formValues)
  const handleBackClick = (): void => { step <= 8 && setStep(step - 1) }
  const handleNextClick = (): void => { step >= 0 && setStep(step + 1) }

  const handleBackButton = (): void => {
    setStep(step - 1)
  }

  const renderLogoAndBackButton = (): React.ReactNode => {
    if (step >= 0) {
      return (
        <LogoAndBackButton
          activeStep={step}
          backButtonCondition={0}
          onBackClick={handleBackButton}
          setStep={setStep}
        />
      )
    }
  }

  const fetchBenefitCompany = async (): Promise<void> => {
    const benefits = await getBenefitData(formValues.b2bData.employee.id)
    setBenefitDataCompany(benefits.benefitData)
  }

  useEffect(() => {
    void fetchBenefitCompany()
  }, [])

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent): void => {
      event.preventDefault()
    }

    window.addEventListener('beforeunload', handleBeforeUnload)

    return () => { window.removeEventListener('beforeunload', handleBeforeUnload) }
  }, [])

  const renderSteps = (benefitDataCompany: BenefitDataCompany): JSX.Element => {
    switch (step) {
      case 0:
        return <StepOne
          usStates={usStates}
          wiCounties={wiCounties}
          formValuesContext={FormValuesContext}
          setValues={setFormValues}
          handleNext={handleNextClick}
          handleBack={handleBackClick}
          step={step}
        />
      case 1:
        return <StepTwo
          formValuesContext={FormValuesContext}
          setValues={setFormValues}
          handleNext={handleNextClick}
          handleBack={handleBackClick}
          step={step}
        />
      case 2:
        return <StepThree
          formValuesContext={FormValuesContext}
          setValues={setFormValues}
          handleNext={handleNextClick}
          handleBack={handleBackClick}
          step={step}
        />
      case 3:
        return <StepFour
          formValuesContext={FormValuesContext}
          setValues={setFormValues}
          handleNext={handleNextClick}
          handleBack={handleBackClick}
          step={step}
        />
      case 4:
        return <StepFive
          formValuesContext={FormValuesContext}
          setValues={setFormValues}
          handleNext={handleNextClick}
          handleBack={handleBackClick}
          step={step}
        />
      case 5:
        return <StepSix
          formValuesContext={FormValuesContext}
          benefitDataCompany={benefitDataCompany}
          setValues={setFormValues}
          handleNext={handleNextClick}
          handleBack={handleBackClick}
          step={step}
        />
      case 6:
        return <StepSeven
          formValuesContext={FormValuesContext}
          setValues={setFormValues}
          handleNext={handleNextClick}
          handleBack={handleBackClick}
          step={step}
        />
      case 7:
        return <StepEight
          formValuesContext={FormValuesContext}
          setValues={setFormValues}
          handleNext={handleNextClick}
          handleBack={handleBackClick}
          step={step}
          userId={userId}
        />

      default:
        return <StepEight
          formValuesContext={FormValuesContext}
          setValues={setFormValues}
          handleNext={handleNextClick}
          handleBack={handleBackClick}
          step={step}
          userId={userId}
        />
    }
  }

  return (
    <FormValuesContext.Provider value={formValues}>
      {renderLogoAndBackButton()}
      <div className='benefits-cliff content'>
        <h2>Benefit Cliffs Calculator</h2>

        <Stepper activeStep={step} alternativeLabel>
          {STEP_NAMES.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>

        {renderSteps(benefitDataCompany as BenefitDataCompany)}

        <ToastContainer
          position='top-right'
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </div>
    </FormValuesContext.Provider>
  )
}

export default BenefitsCliff
export type { BenefitsCliffProps }
