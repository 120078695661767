export const validate = (event: any, field: any) => {
  const errors: any = {}

  switch (field) {
    case 'state':
      if (event?.value === '' || event === null) {
        errors.county = 'County is Required'
      } else {
        errors.county = false
      }
      break
    case 'county':
      if (event?.value === '' || event === null) {
        errors.county = 'County is Required'
      } else {
        errors.county = false
      }
      break

    case 'adultsCount':
      if (event?.value === '' || event === null) {
        errors.adultsCount = 'Adults Count is Required'
      } else {
        errors.adultsCount = false
      }
      break
  }

  return errors
}
