import React, { useCallback, useEffect, useRef, useState } from 'react'

interface Props {
  handleSubmit: () => void
  handleBack: () => void
  step: number
}

const AnchorLinks = (props: Props) => {
  const anchorRefNext = useRef<HTMLAnchorElement>(null)
  const anchorRefPrev = useRef<HTMLAnchorElement>(null)
  const [currentStep, setCurrentStep] = useState(props.step)

  useEffect(() => {
    const handleKeyEvent = (event: KeyboardEvent) => {
      if (event.shiftKey && event.key === 'ArrowRight') {
        anchorRefNext?.current?.click()
      } else if (event.shiftKey && event.key === 'ArrowLeft') {
        anchorRefPrev?.current?.click()
      }
    }

    document.addEventListener('keydown', handleKeyEvent)
    return () => document.removeEventListener('keydown', handleKeyEvent)
  }, [anchorRefNext, anchorRefPrev])

  const handlePrevClick = useCallback(() => {
    if (currentStep > 0) {
      setCurrentStep((prevStep) => prevStep - 1)
      props.handleBack()
    }
  }, [currentStep, props.handleBack])

  const handleNextClick = useCallback(() => {
    if (currentStep < 7) {
      setCurrentStep((prevStep) => prevStep + 1)
      props.handleSubmit()
    }
  }, [currentStep, props.handleSubmit])

  return (
    <>
      <a onClick={handleNextClick} ref={anchorRefNext} className='d-none'></a>
      <a onClick={handlePrevClick} ref={anchorRefPrev} className='d-none'></a>
    </>
  )
}

export default AnchorLinks
