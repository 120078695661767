import React from 'react'
import ColoredBackgroundHeader from './ColoredBackgroundHeader'
import { ExploreCardProps } from './ExploreCard'

interface ExploreCardShowProps {
  card: ExploreCardProps
}

const ExploreCardShow: React.FC<ExploreCardShowProps> = (props) => {
  const { card } = props

  return (
    <div>
      <ColoredBackgroundHeader
        title={ card.title }
        description={ card.description }
        headerBackgroundColor={ card.backgroundColor }
        date={ card.date }
      />
      <div className='content margin-bottom'>
        <div className='primary-image'>
          <img src={ card.imageUrl } title={ card.imageDescription } alt={ card.imageDescription } />
        </div>
        <div
          dangerouslySetInnerHTML={{__html: card.content }}
        />
      </div>
    </div>
  )
}

export default ExploreCardShow
export type { ExploreCardShowProps }
