import React, { Fragment, lazy, memo, Suspense, useState } from 'react'
import { Button } from '@material-ui/core'

import BackButtonModal from './BackButtonModal/index'

const AnchorLinks = lazy(async () => await import('./AnchorLinks'))

interface Props {
  isNextDisabled?: () => boolean | undefined
  handleSubmit: () => void
  handleBack: () => void
  saveUpdatedData: () => void
  isChanged?: boolean
  step: number
}

const NavigationButtons: React.FC<Props> = memo((props) => {
  const { isNextDisabled, handleSubmit, handleBack, saveUpdatedData, isChanged, step } = props
  const [modalOpen, setModalOpen] = useState(false)

  const handleBackWithModal = (): void => {
    isChanged ? setModalOpen(true) : handleBack()
  }

  const handleSaveAndBack = (): void => {
    handleBack()
    saveUpdatedData()
  }

  const nextDisabled = isNextDisabled ? isNextDisabled() : false

  return (
    <>
      <div className='next-back-buttons'>
        <Button
          className={nextDisabled ? 'disabled' : ''}
          variant='contained'
          onClick={handleSubmit}
          disabled={nextDisabled}
        >
          Save & Next
        </Button>

        <Button
          className={step < 1 ? 'disabled' : ''}
          onClick={handleBackWithModal}
          disabled={step < 1}
        >
          Back
        </Button>

        <Suspense fallback={<Fragment>Loading...</Fragment>}>
          <AnchorLinks handleSubmit={handleSubmit} handleBack={handleBackWithModal} step={step} />
        </Suspense>
      </div>

      <BackButtonModal modalOpen={modalOpen} setModalOpen={setModalOpen} handleSaveAndBack={handleSaveAndBack} />
    </>
  )
})

export default NavigationButtons
