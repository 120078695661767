import React, { type ChangeEvent, memo } from 'react'
import { FormControlLabel, FormGroup } from '@material-ui/core'
import Checkbox from '@mui/material/Checkbox'
import SquareOutlinedIcon from '@mui/icons-material/SquareOutlined'
import SquareIcon from '@mui/icons-material/Square'

import './styles.sass'

interface EmployeeBenefits {
  label: string
  value: string
}

interface PurpleCheckboxProps {
  label: string
  value: string
  selectedBenefits: string[]
  setValue: (values: string[]) => void
  benefitsAvailable: string[]
  employeeBenefitsList: EmployeeBenefits[]
}

const PurpleCheckbox: React.FC<PurpleCheckboxProps> = memo(
  ({ label, value, selectedBenefits, setValue, benefitsAvailable, employeeBenefitsList }) => {
    const isChecked = selectedBenefits.includes(value)
    const isDisabled = !benefitsAvailable.includes(value)

    const handleChange = (
      _event: ChangeEvent<{}>,
      isChecked: boolean
    ): void => {
      const updatedBenefits = isChecked
        ? [...selectedBenefits, value]
        : selectedBenefits.filter(benefit => benefit !== value)

      const reorderedBenefits = employeeBenefitsList
        .filter(benefit => updatedBenefits.includes(benefit.value))
        .map(benefit => benefit.value)

      setValue(reorderedBenefits)
    }

    return (
      <div className='purple-checkbox-container'>
        <FormGroup>
          <FormControlLabel
            label={label}
            value={value}
            onChange={handleChange}
            control={
              <Checkbox
                className='checkbox-input'
                icon={<SquareOutlinedIcon color='disabled' fontSize='medium' />}
                checkedIcon={<SquareIcon fontSize='medium' />}
              />
            }
            checked={isChecked}
            disabled={isDisabled}
          />
        </FormGroup>
      </div>
    )
  }
)

export default PurpleCheckbox
