import React, { useState, useContext, useEffect, type Context, type Dispatch } from 'react'
import { Divider, FormControlLabel, RadioGroup, Radio, Checkbox } from '@material-ui/core/'
import Select from 'react-select'

import {
  CHILD_CARE_DURATION_OPTIONS,
  CHILD_CARE_CALCULATED_OPTIONS,
  CHILD_CARE_ATTEND_TYPE,
  MAX_CHILD_CARE_DAYS,
  MAX_CHILD_CARE_HOURS,
  PAYSCALE_DURATION,
  CHILD_CARE_TYPES,
  CHILD_AGED_TWELVE,
  CHILD_AGED_THIRTEEN,
} from '../../Constants'

import { getSelectdValue, haveChildOverTwelve, haveChildUnderThirteen, isChildUnderThirteen, scrollToTop } from '../../Helper'

import '../../component_styles/BenefitsCliff.sass'
import InputField from './InputField'
import NavigationButtons from './NavigationButtons'
import ErrorMessage from '../ErrorMessage'
import { type Children } from '../AdultsInterface'
import { type FormValues } from '../../FormValuesInterfaces'

interface StepFiveProps {
  formValuesContext: Context<FormValues>
  setValues: Dispatch<FormValues>
  handleNext: () => void
  handleBack: () => void
  step: number
  userId?: number
}

const StepFive: React.FC<StepFiveProps> = (props) => {
  let formValues: any = useContext(props.formValuesContext)

  const [children, setChildren] = useState(formValues.children ?? {})
  const [manualChildCare, setManualChildCare] = useState(formValues?.manualChildCare ?? true)
  const [ccdfpayEstimateSource, setCcdfpayEstimateSource] = useState(formValues?.ccdfpayEstimateSource ?? 'amt_ccdf')
  const [hoursPerWeekOrPerDaySummer, setHoursPerWeekOrPerDaySummer] = useState(formValues?.hoursPerWeekOrPerDaySummer ?? {})
  const [isChanged, setIsChanged] = useState(false)
  const [childCareCalculatedOptions, setChildCareCalculatedOptions] = useState(CHILD_CARE_CALCULATED_OPTIONS)
  const [removedChildCareLastOption, setRemovedChildCareLastOption] = useState(false)
  const [isCCDFTypeError, setIsCCDFTypeError] = useState(false)
  const [isRequiredFieldsEmpty, setIsRequiredFieldsEmpty] = useState(false)
  const hasUndefinedCcdfType = Object.values<Children>(children).some((child: Children) => child?.age as number < 13 && (child.ccdfType === undefined || child.ccdfType === 'none'))

  const saveUpdatedData = (): void => {
    let updatedChildren = { ...children }
    let payScale = formValues?.check_ccdf ? 'ccdfPayscale' : 'ccNobenefitPayscale'
    let payAmtM = formValues?.check_ccdf ? 'ccdfpayAmtM' : 'childNobenefitAmtM'

    Object.keys(updatedChildren).forEach((key: string) => {
      let ccdfPayscale = updatedChildren[key]?.ccdfPayscale || updatedChildren[key]?.ccNobenefitPayscale
      let ccdfpayAmtM = updatedChildren[key]?.ccdfpayAmtM || updatedChildren[key]?.childNobenefitAmtM
      delete updatedChildren[key]?.ccdfPayscale
      delete updatedChildren[key]?.ccdfpayAmtM
      delete updatedChildren[key]?.ccNobenefitPayscale
      delete updatedChildren[key]?.childNobenefitAmtM

      if (key in updatedChildren) {
        updatedChildren[key] = {
          ...updatedChildren[key],
          [payScale]: ccdfPayscale,
          [payAmtM]: ccdfpayAmtM
        }
      }
    })

    props.setValues({
      ...formValues,
      children: updatedChildren,
      manualChildCare,
      childCareContinueEstimateSource: manualChildCare ? 'amt' : 'spr',
      childCareNobenefitEstimateSource: manualChildCare ? 'amt' : 'spr',
      ccdfpayEstimateSource,
      hoursPerWeekOrPerDaySummer
    })
  }

  const handleSubmit = (): void => {
    if ((!manualChildCare && hasUndefinedCcdfType) || (formValues.check_ccdf && hasUndefinedCcdfType)) {
      setIsCCDFTypeError(true)
      setIsRequiredFieldsEmpty(true)
      return
    }
    props.handleNext()
    saveUpdatedData()
  }

  const initializeChildren = (): void => {
    const updatedChildren = { ...children }

    Object.keys(updatedChildren).forEach((key: string) => {
      let payScale = updatedChildren[key]?.ccNobenefitPayscale || updatedChildren[key]?.ccdfPayscale || 'none'
      let payAmtM = updatedChildren[key]?.childNobenefitAmtM || updatedChildren[key]?.ccdfpayAmtM || 0

      updatedChildren[key] = {
        ...updatedChildren[key],
        ccdfPayscale: payScale,
        ccdfpayAmtM: payAmtM,
        dayHoursChild: updatedChildren[key]?.dayHoursChild || 0,
        summerdayHoursChild: updatedChildren[key]?.summerdayHoursChild || 0
      }
    })

    setChildren(updatedChildren)
  }

  const handleShowChildCareLastOption = (): void => {
    const shouldRemoveOption = !formValues?.check_ccdf && ccdfpayEstimateSource === 'fullcost_ccdf'
    const registeredOptionIndex = CHILD_CARE_CALCULATED_OPTIONS.findIndex(option => option.value === 'registered')

    if (shouldRemoveOption) {
      if (registeredOptionIndex !== -1) {
        const updatedOptions = CHILD_CARE_CALCULATED_OPTIONS.filter((_option, index) => index !== registeredOptionIndex)
        setChildCareCalculatedOptions(updatedOptions)
      }

      setRemovedChildCareLastOption(true)
    } else if (!shouldRemoveOption && removedChildCareLastOption) {
      const updatedOptions = [...childCareCalculatedOptions, CHILD_CARE_CALCULATED_OPTIONS[4]]
      setChildCareCalculatedOptions(updatedOptions)
      setRemovedChildCareLastOption(false)
    }

    if (formValues?.check_ccdf) {
      const updatedOptions = [...childCareCalculatedOptions]
      updatedOptions[registeredOptionIndex] = { ...updatedOptions[registeredOptionIndex], isDisabled: true }
      setChildCareCalculatedOptions(updatedOptions)
    }
  }

  const renderSummerDayOrWeekOptions = (value: number) => (
    (children[value]?.ccdfPayscale == 'hour' || children[value]?.ccdfPayscale == 'day') ? dailyAndHourlyOptions(value, 'summerdayHoursChild') : weeklyAndMonthlyOptions(value, 'summerweekMonthChild')
  )

  const renderSummerOptions = (value: number) => (
    <div>
      <div><b className='child-care-heading'>During Summer</b></div>
      <FormControlLabel
        control={
          <Checkbox
            className='child-care-hours'
            onChange={() => {
              setHoursPerWeekOrPerDaySummer((prevHours: Array<{ useSameAsSchool: boolean }>) => ({
                ...prevHours,
                [value]: {
                  ...prevHours[value],
                  useSameAsSchool: !prevHours[value]?.useSameAsSchool
                }
              }))

              setChildren((prevDetails: Children[]) => ({
                ...prevDetails,
                [value]: {
                  ...prevDetails[value],
                  summerdayHoursChild: prevDetails[value]?.dayHoursChild,
                  summerweekMonthChild: prevDetails[value]?.weekMonthChild
                }
              }))
            }}
            checked={hoursPerWeekOrPerDaySummer[value]?.useSameAsSchool ?? false}
          />
        }
        label='Use same as during the school year'
      />
      {!hoursPerWeekOrPerDaySummer[value]?.useSameAsSchool && renderSummerDayOrWeekOptions(value)}
    </div>
  )

  const renderDurationOptions = (value: number) => (
    <div className='d-flex justify-space-between child-care-hours'>
      <div>
        <p>How does your provider charge you?</p>
      </div>
      <Select
        className='basic-single mobile-select-width'
        classNamePrefix='select'
        placeholder='Duration'
        onChange={(e) => {
          setChildren({ ...children, [value]: { ...children[value], ccdfPayscale: e.value } })
          resetProviderCharge(value, e.value)
          setIsChanged(true)
        }}
        isSearchable={false}
        options={CHILD_CARE_DURATION_OPTIONS}
        value={children[value]?.ccdfPayscale
          ? getSelectdValue(children[value]?.ccdfPayscale, CHILD_CARE_DURATION_OPTIONS)
          : CHILD_CARE_DURATION_OPTIONS[0]
        }
      />
    </div>
  )

  const weeklyAndMonthlyOptions = (value: number, object: string) => (
    <div className='d-flex justify-space-between child-care-hours'>
      <div>
        <p>Does <strong>{`${children[value]?.name ?? `Child ${value + 1}`}`}</strong> attend:</p>
      </div>
      <Select
        className='basic-single mobile-select-width'
        classNamePrefix='select'
        placeholder='Duration'
        onChange={(e) => {
          setChildren({ ...children, [value]: { ...children[value], [object]: e.value } })
          setDailyWeeklyAndMonthlyHours(value, object, e.value, 'summerweekMonthChild')
        }}
        isSearchable={false}
        options={CHILD_CARE_ATTEND_TYPE}
        value={children[value]?.[object]
          ? getSelectdValue(children[value]?.[object], CHILD_CARE_ATTEND_TYPE)
          : CHILD_CARE_ATTEND_TYPE[0]
        }
      />
    </div>
  )

  const dailyAndHourlyOptions = (value: number, object: string) => (
    <div>
      <div className='render-child-care-inputs child-care-hours' key={value}>
        <p> How many {children[value]?.ccdfPayscale ? (children[value]?.ccdfPayscale == 'hour' ? 'hours' : 'days') : 'hour'} does <strong>{`${children[value]?.name ?? `Child ${value + 1}`}`}</strong> attend per week?</p>
        <div className='inner-div'>
          <InputField
            onChange={(inputValue: number | string) => {
              setChildren({ ...children, [value]: { ...children[value], [object]: inputValue } })
              setDailyWeeklyAndMonthlyHours(value, object, inputValue, 'summerdayHoursChild')
            }}
            className='mobile-input-width'
            label={children[value]?.ccdfPayscale === 'hour' ? 'hours' : 'days'}
            value={children[value]?.[object]}
            maxLimit={children[value]?.ccdfPayscale === 'day' ? MAX_CHILD_CARE_DAYS : MAX_CHILD_CARE_HOURS}
            type='tel'
          />
        </div>
      </div>
    </div>
  )

  const setDailyWeeklyAndMonthlyHours = (value: number, object: string, eventValue: number | string, summerDayHoursOrWeekMonth: string) => {
    if (hoursPerWeekOrPerDaySummer[value]?.useSameAsSchool) setChildren({ ...children, [value]: { ...children[value], [object]: eventValue, [summerDayHoursOrWeekMonth]: eventValue } })
  }

  const renderPriceInput = (value: number) => (
    <div className='d-flex justify-space-between child-care-hours'>
      {formValues?.check_ccdf ? <p>What is the full-price {switchHourlyDailyMonthlyQuote(value)} rate?</p> : <p>What is the {switchHourlyDailyMonthlyQuote(value)} {children[value]?.weekMonthChild && switchFullTimePartTimeQuote(value)} rate?</p>}
      <div className='d-flex'>
        <InputField
          onChange={(inputValue: number | string) => {
            if (children[value]?.ccdfType === undefined) {
              setIsCCDFTypeError(true)
            }

            setChildren({ ...children, [value]: { ...children[value], ccdfpayAmtM: inputValue } })
            setIsRequiredFieldsEmpty(false)
          }}
          className='mobile-input-width'
          label='Amount'
          value={children[value]?.ccdfpayAmtM}
          type='tel'
        />
      </div>
    </div>
  )

  const switchHourlyDailyMonthlyQuote = (value: number) => {
    const hourOrWeekOrMonthQuote = children[value]?.ccdfPayscale != 'none' ? children[value]?.ccdfPayscale : 'hour'
    return PAYSCALE_DURATION[hourOrWeekOrMonthQuote]
  }

  const switchFullTimePartTimeQuote = (value: number) => {
    const fullOrPartTimeQuote = children[value]?.weekMonthChild != 'none' ? children[value]?.weekMonthChild : 'full_time'
    return CHILD_CARE_TYPES[fullOrPartTimeQuote]
  }

  const renderHoursOrDayOption = (value: number, dayHourObject: string, WeekMonthObject: string) => (
    (children[value]?.ccdfPayscale == 'hour' || children[value]?.ccdfPayscale == 'day') ?
      dailyAndHourlyOptions(value, dayHourObject) : weeklyAndMonthlyOptions(value, WeekMonthObject)
  )

  const renderDailyAndHourlyOptions = (value: number, dayHourObject: string, WeekMonthObject: string) => (
    <div className='school-info-container'>
      {renderDurationOptions(value)}
      {children[value]?.ccdfPayscale !== 'none' && (
        formValues?.check_ccdf ? <>
          {renderPriceInput(value)}
          {renderHoursOrDayOption(value, dayHourObject, WeekMonthObject)}
        </> : <>
          {renderHoursOrDayOption(value, dayHourObject, WeekMonthObject)}
          {renderPriceInput(value)}
          <p>Your provider’s rate may change based on whether you use Part-time or Full-time care. Be sure to check if there are new rates if you are increasing or decreasing the amount of care you need.</p>
        </>
      )}
    </div>
  )

  const renderChildCareCostInputs = (value: number) => (
    isChildUnderThirteen(children[value].age) &&
    <div className='child-care-hours margin-top'>
      <div>
        <RadioGroup
          onChange={() => {
            setManualChildCare(!manualChildCare)
            setIsChanged(true)
          }}
          value={String(manualChildCare)}
        >
          <FormControlLabel
            onChange={() => {
              setCcdfpayEstimateSource('amt_ccdf')
              setIsChanged(true)
            }}
            value='true'
            control={<Radio />}
            label={`Enter details for ${children[value]?.name ?? 'Child ' + (value + 1)}:`}
          />
        </RadioGroup>
        <div className='child-care-hours margin-top'>
          <b className='child-care-heading'>During School Year</b>
          {renderDailyAndHourlyOptions(value, 'dayHoursChild', 'weekMonthChild')}
          {children[value]?.ccdfPayscale != 'none' ? renderSummerOptions(value) : null}
        </div>
      </div>
      <Divider />
    </div>
  )

  const renderChildCareTypeOptions = (value: number) => (
    isChildUnderThirteen(children[value].age) &&
    <div className='render-child-care-inputs margin-of-text' key={value}>
      <b>{children[value]?.name ?? `Child ${Number(value) + 1}`}</b>

      <Select
        className='basic-single'
        classNamePrefix='select'
        placeholder={'Select Child Care Type'}
        onChange={(e: any) => {
          setChildren({ ...children, [value]: { ...children[value], ccdfType: e.value } })
          setIsChanged(true)
          setIsRequiredFieldsEmpty(false)
        }}
        isSearchable={false}
        options={childCareCalculatedOptions}
        value={children[value]?.ccdfType
          ? getSelectdValue(children[value]?.ccdfType, childCareCalculatedOptions)
          : childCareCalculatedOptions[0]
        }
      />
    </div>
  )

  const renderChildCareType = (): JSX.Element => (
    <>
      <>
        <b>What type of child care is each child in?</b>
        {Object.keys(children).map((value: string) => renderChildCareTypeOptions(Number(value)))}
      </>
      <Divider />
    </>
  )

  const renderSummerAndSchoolHoursChild = (value: number, object: string) => (
    <div className='d-flex justify-space-between child-care-hours'>
      <p>
        How many hours of care does your child need per week
        {object === 'summerdayHoursChild' ? ' during summer?' : '?'}
      </p>
      <div className='d-flex'>
        <InputField
          onChange={(inputValue: number | string) => {
            setChildren({ ...children, [value]: { ...children[value], [object]: inputValue } })
          }}
          className='mobile-input-width'
          label='Amount'
          value={children[value]?.[object]}
          type='tel'
        />
      </div>
    </div>
  )

  const renderChildCareDaysAndHoursInput = (value: number) => (
    <div className='child-care-hours margin-top'>
      <b className='child-care-heading'>{`Enter details for ${children[value]?.name ?? 'Child ' + (value + 1)}:`}</b>
      {renderSummerAndSchoolHoursChild(value, 'dayHoursChild')}
      {renderSummerAndSchoolHoursChild(value, 'summerdayHoursChild')}
      <Divider />
    </div>
  )

  const renderChildCareCostsCalculator = (): JSX.Element => (
    <>
      <b>Enter your child care costs or use the calculator to estimate.</b>
      <p>If you also use free programs like Head Start or 4-K, only count the hours below for which you pay for child care from a child care provider.</p>
      <p>At this time, Parsley calculates total yearly child care costs and then averages costs evenly across each month. </p>
      <RadioGroup
        onChange={() => {
          setManualChildCare(!manualChildCare)
          setIsChanged(true)
        }}
        value={String(manualChildCare)}
      >
        <div className='amount-input'>
          {Object.keys(children).map((value: string) => renderChildCareCostInputs(Number(value)))}
        </div>
        <div className='child-care-hours'>
          <div>
            {formValues.check_ccdf && manualChildCare && hasUndefinedCcdfType && isCCDFTypeError &&
              <ErrorMessage text='Select type of child care to ensure accurate Shares Subsidy estimate.' />
            }
          </div>
          <FormControlLabel
            disabled={children[0]?.ccdfType === 'registered'}
            onChange={() => {
              setCcdfpayEstimateSource('fullcost_ccdf')
              resetChildCareCostInput()
              setIsChanged(true)
            }}
            value='false'
            control={<Radio />}
            label='I want the calculator to estimate my child care costs based on type of child care:' />
          {!manualChildCare && hasUndefinedCcdfType &&
            <div className='child-care-hours'>
              <ErrorMessage text='Select type of child care to ensure accurate estimate.' />
            </div>
          }
          {ccdfpayEstimateSource == 'fullcost_ccdf' &&
            Object.keys(children).map((value: string) => renderChildCareDaysAndHoursInput(Number(value)))}
        </div>
      </RadioGroup>
    </>
  )

  const renderChildCareTypes = (): JSX.Element => (
    <div className='step-five'>
      {renderChildCareType()}

      {renderChildCareCostsCalculator()}
    </div>
  )

  const resetChildCareCostInput = (): void => {
    const updatedChildren = { ...children }
    const updatedHoursPerWeekOrPerDaySummer = { ...hoursPerWeekOrPerDaySummer }

    Object.keys(updatedChildren).forEach((key: string) => {
      if (key in updatedChildren) {
        delete updatedChildren[key].summerweekMonthChild
        delete updatedChildren[key].weekMonthChild

        updatedChildren[key] = {
          ...updatedChildren[key],
          ccdfPayscale: 'none',
          ccdfpayAmtM: 0,
          dayHoursChild: 0,
          summerdayHoursChild: 0,
        }

        updatedHoursPerWeekOrPerDaySummer[key] = {
          ...updatedHoursPerWeekOrPerDaySummer[key],
          useSameAsSchool: false,
        }
      }
    })

    setChildren(updatedChildren)
    setHoursPerWeekOrPerDaySummer(updatedHoursPerWeekOrPerDaySummer)
  }

  const resetProviderCharge = (key: number, value: string) => {
    const updatedChildren = { ...children }
    const updatedHoursPerWeekOrPerDaySummer = { ...hoursPerWeekOrPerDaySummer }

    if (key in updatedChildren) {
      const childDetailToUpdate = updatedChildren[key]

      childDetailToUpdate.ccdfPayscale = value
      childDetailToUpdate.ccdfpayAmtM = 0
      childDetailToUpdate.dayHoursChild = 0
      childDetailToUpdate.summerdayHoursChild = 0

      if (value === 'hour' || value === 'day') {
        delete childDetailToUpdate.summerweekMonthChild
        delete childDetailToUpdate.weekMonthChild
      } else {
        delete childDetailToUpdate.dayHoursChild
        delete childDetailToUpdate.summerdayHoursChild
        childDetailToUpdate.summerweekMonthChild = 'none'
        childDetailToUpdate.weekMonthChild = 'none'
      }

      updatedHoursPerWeekOrPerDaySummer[key] = {
        ...updatedHoursPerWeekOrPerDaySummer[key],
        useSameAsSchool: false,
      }
    }

    setChildren(updatedChildren)
    setHoursPerWeekOrPerDaySummer(updatedHoursPerWeekOrPerDaySummer)
  }

  const childrenIneligibleForChilCare = () => {
    if (formValues.childrenCount > 1 && haveChildOverTwelve(children)) {
      return <p>Children Over {CHILD_AGED_TWELVE} are not eligible for Care.</p>
    }
  }

  useEffect(() => {
    handleShowChildCareLastOption()
  }, [formValues.ccdf, ccdfpayEstimateSource])

  useEffect(() => {
    initializeChildren()
  }, [])

  scrollToTop()

  return (
    <>
      <div className='step-five'>
        <h3>Child Care Information</h3>
        {childrenIneligibleForChilCare()}
      </div>

      {haveChildUnderThirteen(children)
        ? renderChildCareTypes()
        : <div className='step-five'>
          <p>There are no children in your household under {CHILD_AGED_THIRTEEN}.</p>
        </div>
      }

      {isRequiredFieldsEmpty && <ErrorMessage text='Complete required fields' />}
      <NavigationButtons
        handleBack={props.handleBack}
        handleSubmit={handleSubmit}
        step={props.step}
        saveUpdatedData={saveUpdatedData}
        isChanged={isChanged}
      />
    </>
  )
}

export default StepFive
export type { StepFiveProps }
