import React from 'react'

import { SelectOptions } from './QuickPeekEditor'
import { goToURL } from '../Helper'
import I18n from '../config/I18n'

import '../component_styles/QuickPeekCard.sass'
import { MAX_OUTER_WIDTH } from '../Constants'

interface QuickPeekCardProps {
  backgroundColor: string
  color: string
  content: string
  count: number
  createdAt: string
  description: string
  icon_url: string
  iconDescription: string
  id: number
  showImageDescription: string
  showImageUrl: string
  sub_heading: string
  tag: string
  title: string
  url: string
  category_id: number | string
  image_url: string
  created_at: string
  tier: number
  tags: string[]
  background_color: string
  publish_status: string
  categoryName: string
  image_id: number
  alt_text: string
  image_alt_text: string
  related_topics: SelectOptions[]
  intro: string
  help_from_a_person: string
  cta_heading: string
  cta_text: string
  cta_button_text: string
  cta_link: string
}

const QuickPeekCard: React.FC<QuickPeekCardProps> = (props) => {
  return (
    <div className='quick-peek-card-main' onClick={() => goToURL(`/${I18n.locale}/categories/${props.id}`)}>
      <div className='quick-peek-card'>
        {props.icon_url ?
          <img src={props.icon_url} alt={props.image_alt_text} />
          :
          <div className={`colored-dot ${props.color ?? 'chick-o-stick-yellow'}-background-color`}></div>
        }
      </div>

      <div className='quick-peek-inner-div'>
        <div>
          <span className='d-flex'>
            <h1 className={`${outerWidth < MAX_OUTER_WIDTH ? 'one-liner' : ''}`}>{props.title}</h1>

            {outerWidth > MAX_OUTER_WIDTH && <div className='count-dot'>{props.count ?? 0}</div>}
          </span>
          <div className='one-liner desc-text' dangerouslySetInnerHTML={{ __html: props.sub_heading }} />
        </div>

        {outerWidth < MAX_OUTER_WIDTH && <div className='count-dot'>{props.count ?? 0}</div>}
      </div>
    </div>
  )
}

export default QuickPeekCard
export type { QuickPeekCardProps }
