import React, { memo, useState, useEffect } from 'react'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography,
  Skeleton,
  CircularProgress,
} from '@mui/material'
import { Delete, ModeEdit, CreateNewFolder as CreateNewFolderIcon } from '@mui/icons-material'
import FolderIcon from '@mui/icons-material/Folder'

import { scrollToTop } from '../../../Helper'

import './styles.sass'

interface Item {
  id: number
  name: string
  user_id: number
  created_at: string
  updated_at: string
}

interface FolderItemManagementProps {
  title: string
  itemName: string
  itemNamePlural: string
  items: Item[]
  onCreateItem: (name: string) => Promise<void>
  handleItemSelection?: (name: string, id: number, description: string) => void
  onDeleteItem: (id: number) => Promise<void>
  onUpdateItem: (id: number, name: string) => Promise<void>
}

const FolderItemManagment: React.FC<FolderItemManagementProps> = memo((props) => {
  const { title, items, handleItemSelection, onCreateItem, onDeleteItem, onUpdateItem } = props
  const [item, setItem] = useState('')
  const [open, setOpen] = useState(false)
  const [editIndex, setEditIndex] = useState<number>(-1)
  const [errorMessage, setErrorMessage] = useState('')
  const [loading, setLoading] = useState(false)
  const [deleteLoader, setDeleteLoader] = useState(false)
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const [deleteItemId, setDeleteItemId] = useState<number | null>(null)

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setItem(event.target.value)
    if (errorMessage) setErrorMessage('')
  }

  const handleDelete = (id: number): void => {
    setDeleteItemId(id)
    setDeleteDialogOpen(true)
  }

  const confirmDelete = async (): Promise<void> => {
    if (deleteItemId !== null) {
      try {
        setDeleteLoader(true)
        await onDeleteItem(deleteItemId)
      } finally {
        setDeleteLoader(false)
        setDeleteDialogOpen(false)
        setDeleteItemId(null)
      }
    }
  }

  const handleSubmit = async (): Promise<void> => {
    setLoading(true)

    if (item.trim() === '') {
      setErrorMessage(`${title} name cannot be empty or blank`)
      setLoading(false)
      return
    }

    if (editIndex === -1) {
      await onCreateItem(item)
    } else {
      await onUpdateItem(items[editIndex].id, item)
    }
    setItem('')
    setOpen(false)
    setEditIndex(-1)
    setLoading(false)
  }

  useEffect(() => {
    if (title === 'Case' && item) {
      handleSubmit()
    }
  }, [item, title])

  const handleClose = (): void => {
    setOpen(false)
    setEditIndex(-1)
    setErrorMessage('')
  }

  const handleOpen = (index: number = -1): void => {
    setEditIndex(index)
    setItem(index === -1 ? '' : items[index].name)
    setOpen(true)
  }

  const handleOpenItem = (itemName: string, Itemid: number): void => {
    if (handleItemSelection) {
      handleItemSelection(itemName, Itemid, '')
    }
  }

  const DeleteConfirmationModal = () => {
    return (
      <Dialog open={deleteDialogOpen} onClose={() => setDeleteDialogOpen(false)}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>Are you sure you want to delete this {title.toLowerCase()}?</DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)} color='primary'>
            Cancel
          </Button>
          <Button
            onClick={confirmDelete}
            style={{ backgroundColor: '#4E49B7', color: '#fff' }}
            autoFocus
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  scrollToTop()

  return (
    <div className='dashboardContainer'>
      <div className='dashboardHeader headerWrapper'>
        <Button
          variant='contained'
          className='itemButton'
          onClick={() => {
            handleOpen()
          }}
        >
          <CreateNewFolderIcon className='addItemIcon' /> CREATE NEW {title.toUpperCase()}
        </Button>
      </div>
      <div className='wrapper'>
        {deleteLoader && (
          <div className='circlularLoader'>
            <CircularProgress />
          </div>
        )}
        {items.length > 0 ? (
          <div className='itemListContainer'>
            <TableContainer>
              <Table>
                <TableBody>
                  {items.map((item, index) => (
                    <TableRow key={item.id}>
                      <TableCell>
                        <Typography
                          variant='body1'
                          onClick={() => {
                            handleOpenItem(item.name, item.id)
                          }}
                        >
                          <Box className='itemName'>
                            <FolderIcon />
                            <Box className='itemText'>{item.name}</Box>
                          </Box>
                        </Typography>
                      </TableCell>
                      <TableCell align='right'>
                        <Delete
                          className='deleteIcon'
                          onClick={() => {
                            handleDelete(item.id)
                          }}
                        />
                        <ModeEdit
                          className='editIcon'
                          onClick={() => {
                            handleOpen(index)
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                  {loading && (
                    <TableRow>
                      <TableCell colSpan={2}>
                        <Skeleton animation='wave' />
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        ) : (
          <b className='noItemsMessage'> No folders found </b>
        )}
      </div>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{editIndex === -1 ? `Create New ${title}` : `Edit ${title}`}</DialogTitle>

        <DialogContent className='dialogCustomContainer'>
          <TextField
            required
            label={`${title} Name`}
            fullWidth
            variant='standard'
            value={item}
            onChange={handleChange}
            error={Boolean(errorMessage)}
            helperText={errorMessage}
          />
        </DialogContent>

        <DialogActions>
          <Button
            variant='contained'
            className='popupButton'
            sx={{ background: '#4E49B7 !important' }}
            onClick={handleSubmit}
          >
            {editIndex === -1 ? 'CREATE' : 'UPDATE'}
          </Button>
        </DialogActions>
      </Dialog>
      {DeleteConfirmationModal()}
    </div>
  )
})

export default FolderItemManagment
