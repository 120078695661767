import React, { memo } from 'react'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'

import './styles.sass'

interface ToggleButtonProps {
  type: string
  value: string
  options: Array<{ option: string, value: string }>
  setValue: (newValue: string) => void
  setSelected: (newValue: boolean) => void
}

const BenefitToggleButton: React.FC<ToggleButtonProps> = memo((props) => {
  const { type, value, options, setValue, setSelected } = props

  const handleTypeChange = (_event: React.MouseEvent<HTMLElement>, newType: string): void => {
    setValue(newType)
    setSelected(false)
  }

  return (
    <div className='type-box'>
      <h2>{type}</h2>

      <div className='options-toggle'>
        <ToggleButtonGroup
          color='secondary'
          value={value}
          exclusive
          onChange={handleTypeChange}
          aria-label='Platform'
        >
          {options.map((optionItem, index) => (
            <ToggleButton key={index} value={optionItem.value} className='custom-toggle-group'>{optionItem.option}</ToggleButton>
          ))}
        </ToggleButtonGroup>
      </div>
    </div>
  )
})

export default BenefitToggleButton
