import React from 'react'
import I18n from '../config/I18n'
import SnapshotDetailedCard, { SnapshotDetailedCardProps } from './SnapshotDetailedCard'
import SnapshotSimplifiedCard, { SnapshotSimplifiedCardProps }from './SnapshotSimplifiedCard'
import Link from './Link'
import '../component_styles/SnapshotCollection.sass'

interface SnapshotCollectionProps {
  detailedSnapshot: SnapshotDetailedCardProps
  simpleSnapshots: SnapshotSimplifiedCardProps[]
  className?: string
  linkClassName?: string
}

const SnapshotCollection: React.FC<SnapshotCollectionProps> = (props) => {
  const { detailedSnapshot, simpleSnapshots } = props

  return(
    <div className={`snapshot-index-container ${props.className}`}>
      <div className="detailed-snapshot-container">
        { detailedSnapshot &&
          <SnapshotDetailedCard
            { ...detailedSnapshot }
            linkClassName={ props.linkClassName }
          />
        }
        { !detailedSnapshot &&
          <Link
            text={ I18n.t("snapshot.create_new_snapshot") }
            className="btn"
          />
        }
      </div>
      <div className="simple-snapshot-container">
        <h3>{ I18n.t("snapshot.my_snapshots") }</h3>
        { (simpleSnapshots.length > 0) &&
          simpleSnapshots.map((simpleSnapshot, index) => {
            return (
              <div className="simple-snapshot" key={ `simpleSnapshots-${ index }` }>
                <SnapshotSimplifiedCard
                  { ...simpleSnapshot }
                />
                <hr />
              </div>
            )
          })
        }
        { (simpleSnapshots.length == 0) &&
          <p>{ I18n.t("snapshot.additional_snapshots") }</p>
        }
      </div> 
    </div>
  )
}

export default SnapshotCollection
export type { SnapshotCollectionProps }
