import React, { Context, memo, useContext, useCallback, useState, useEffect } from 'react'
import { Card, CardContent, TextField } from '@mui/material'
import { toast } from 'react-toastify'

import { type FormValues } from '../../../FormValuesInterfaces'
import { type AdminScenariosData } from '../../AdminInterface'
import { scrollToTop } from '../../../Helper'
import { UpdateDescriptionAdminScenario } from '../../../api/scenario'

import './styles.sass'

const _ = require('lodash')

interface BasicCaseDetailsProps {
  selectedCase: { name: string, id: number, description: string }
  selectedFolder: { name: string, id: number }
  baselineScenario?: string
  adminValuesContext?: Context<FormValues>
  setSelectedCase?: React.Dispatch<
    React.SetStateAction<{ name: string, id: number, description: string }>
  >
}

const BasicCaseDetails: React.FC<BasicCaseDetailsProps> = memo((props) => {
  const { selectedCase, selectedFolder, adminValuesContext, setSelectedCase } = props
  const formValues: FormValues | undefined = adminValuesContext
    ? useContext(adminValuesContext)
    : undefined

  const [description, setDescription] = useState(selectedCase.description)

  useEffect(() => {
    setDescription(selectedCase.description)
  }, [selectedCase.description])

  let baselineScenarioDetails: string | undefined

  if (formValues) {
    const scenarioKeys = Object.keys(formValues.adminScenariosData)
    if (scenarioKeys.length > 0) {
      const firstScenarioKey = scenarioKeys[0] 
      const firstScenarioData = formValues.adminScenariosData[firstScenarioKey as keyof typeof formValues.adminScenariosData] as unknown as AdminScenariosData
      const { family_size, wages, child_ages } = firstScenarioData
      baselineScenarioDetails = `Family Size: ${family_size}, Wages: ${wages.join(', ')}, Child Ages: ${child_ages.join(', ')}`
    }
  }

  const updateDescriptionDebounced = useCallback(_.debounce(async (description: string) => {
    try {
      await UpdateDescriptionAdminScenario(selectedCase.id, description);
      if (setSelectedCase) { 
        setSelectedCase(prevState => ({ ...prevState, description }))
      }
      toast.success('Description updated successfully.')
    } catch (error) {
      toast.error('Failed to update description.')
    }
  }, 1000), [selectedCase.id, setSelectedCase])

  const handleDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newDescription = event.target.value
    setDescription(newDescription)
    updateDescriptionDebounced(newDescription)
  }

  scrollToTop()

  return (
    <div className='caseDetailsConatiner'>
      <Card>
        <CardContent>
          <b>Folder: {selectedFolder.name}</b>
          <div className='caseDetailsDescription'>
            <b>Case: {selectedCase.name ?? 'No case selected'}</b>
            <p>
              Description: <TextField value={description} onChange={handleDescriptionChange} />
            </p>
          </div>
          <span className='scenarioDetail'>
            {baselineScenarioDetails && (
              <>
                <b>Baseline Scenario: </b>
                {baselineScenarioDetails}
              </>
            )}
          </span>
        </CardContent>
      </Card>
    </div>
  )
})

export default BasicCaseDetails
