import React, { memo, useContext, useEffect, useState } from 'react'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import Divider from '@mui/material/Divider'

import ColorDivider from '../ColorDivider'
import BenefitToggleButton from '../BenefitToggleButton'
import SelectDeselectButtonGroup from '../useSelectDeselect'
import ContributionHeading from '../ContributionHeading'
import { scrollToTop } from '../../../Helper'
import { getBenefitOptions } from '../helpers/Benefits'
import { type BenefitData, type BenefitDetailsProps } from '../../../EmployeeCalculatorInterface'
import { type Adults } from '../../AdultsInterface'

import './styles.sass'

const BenefitDetails: React.FC<BenefitDetailsProps> = memo(({ title, benefitType, calculateAmount, formValuesContext, benefitDataCompany, setValues }) => {
  const formValues = useContext(formValuesContext)
  const employeeType = formValues?.adults[0]?.employee?.company_name ?? ''
  const [disabled, setDisabled] = useState(false)

  const [providerOptions, setProviderOptions] = useState([''])
  const [typeOptions, setTypeOptions] = useState([{ option: '', value: '' }])
  const [coverageOptions, setCoverageOptions] = useState([{ option: '', value: '' }])
  
  const uniqueProviderOptions = Array.from(new Set(providerOptions.map(item => item)))
  const uniqueTypeOptions = Array.from(new Set(typeOptions.map(item => item)))
  const uniqueCoverageOptions = Array.from(new Set(coverageOptions.map(item => item)))

  const benefitTypeKey = `${benefitType}BenefitData` as keyof Adults

  const extractBenefitType = (): BenefitData | undefined => {
    return formValues?.adults[0]?.[benefitTypeKey] as BenefitData | undefined
  }

  const extractedType = extractBenefitType()
  const type = (extractedType?.type as string) || (uniqueTypeOptions[0]?.value ?? '')

  const initialBenefitData: BenefitData = {
    name: extractedType?.name as string,
    amount: extractedType?.amount as number,
    coverage: extractedType?.coverage as string,
    contribution: extractedType?.contribution as string,
    isSelected: !!(extractedType?.isSelected),
    provider: extractedType?.provider !== ''
      ? extractedType?.provider as string
      : uniqueProviderOptions[0]?.toLowerCase()?.replace(/\s+/g, '_'),
    type
  }

  const [benefitData, setBenefitData] = useState(initialBenefitData)
  const shouldDisable = benefitData.coverage === null || (type !== undefined && benefitData.type === null) || benefitData.provider === null

  useEffect(() => {
    const { providerOptions, typeOptions, coverageOptions } = getBenefitOptions(employeeType, benefitType, benefitDataCompany)

    setProviderOptions(providerOptions)
    setTypeOptions(typeOptions)
    setCoverageOptions(coverageOptions)
  }, [benefitDataCompany])

  useEffect(() => {
    setBenefitData(initialBenefitData)
  }, [initialBenefitData.provider, initialBenefitData.type, initialBenefitData.coverage])

  useEffect(() => {
    setDisabled(shouldDisable)
  }, [shouldDisable])

  useEffect(() => {
    const provider = benefitData?.provider ?? ''
    const coverage = benefitData?.coverage ?? ''
    const type = benefitData.type ?? ''

    const { amount, contribution } = calculateAmount(provider, type, coverage)

    setBenefitData((data) => ({
      ...data,
      amount,
      contribution: contribution.toString()
    }))
  }, [benefitData.provider, benefitData.type, benefitData.coverage])

  scrollToTop()

  return (
    <div className='dental-container'>
      <div className='page-header'>
        <b>{title}</b>
        <p>Choose the plan that best fits your goals and estimated budget. Be sure to reach out to your HR contact with questions.</p>
      </div>

      <ColorDivider />

      <div className='page-body'>
        <b>Providers</b>
        <ToggleButtonGroup
          color='secondary'
          value={benefitData.provider}
          exclusive
          onChange={(_, newProvider) => {
            if (newProvider !== null) {
              setBenefitData((data) => ({ ...data, provider: newProvider }))
            }
          }}
        >
          {uniqueProviderOptions.map((option) => (
            <ToggleButton key={option} value={option.toLowerCase().replace(/\s+/g, '_')} className='provider-option'>
              {option}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>

        <div className='benefit-data'>
          <div className='montly-data'>
            <b>${(benefitData?.amount ?? 0).toFixed(2)}</b>
            <p>PRE-TAX MONTHLY PREMIUM</p>
          </div>

          <Divider variant='middle' />

          <h2>Employer Contribution</h2>
          <ContributionHeading benefitData={benefitData} benefitType='premium' isPension={false} />

          <Divider variant='middle' />

          <div className='benefit-input-container'>
            {uniqueTypeOptions.length !== 0 && (
              <BenefitToggleButton
                type='Type'
                value={benefitData?.type ?? ''}
                options={uniqueTypeOptions.slice(0, 2)}
                setValue={(newValue) => {
                  setBenefitData((data) => ({ ...data, type: newValue }))
                }}
                setSelected={(newValue) => {
                  setBenefitData((data) => ({ ...data, isSelected: newValue }))
                }}
              />
            )}

            <BenefitToggleButton
              type='Coverage'
              value={benefitData?.coverage ?? ''}
              options={uniqueCoverageOptions}
              setValue={(newValue) => {
                setBenefitData((data) => ({ ...data, coverage: newValue }))
              }}
              setSelected={(newValue) => {
                setBenefitData((data) => ({ ...data, isSelected: newValue }))
              }}
            />
          </div>

          <div className='selected-button'>
            <SelectDeselectButtonGroup
              formValuesContext={formValuesContext}
              setValues={setValues}
              benefitData={benefitData}
              setIsSelected={(newValue) => {
                setBenefitData((data) => ({ ...data, isSelected: newValue }))
              }}
              error={disabled}
            />
          </div>
        </div>
      </div>
    </div>
  )
})

export default BenefitDetails
