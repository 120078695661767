import React, { FC, useState } from 'react'
import { Button, FormControlLabel, Checkbox } from '@mui/material'
import { KeyboardArrowUp, KeyboardArrowDown } from '@mui/icons-material'

const _ = require('lodash')

import './styles.sass'

interface MultiSelectInputProps {
  options: string[]
  placeholderProp: string
  value?: { [x: string]: boolean | undefined }
  setValue: Function
  showErrorMsg?: boolean
  errorMsg?: string
}

const MultiSelectInput: FC<MultiSelectInputProps> = ({ options, placeholderProp, value = {}, setValue, showErrorMsg, errorMsg }) => {
  const [arrowUp, setArrowUp] = useState(showErrorMsg)
  const [isOpen, setIsOpen] = useState((Object.keys(value).length > 0 || arrowUp) ? true : false)
  const [values, setValues] = useState(value)
  const [placeholder] = useState(placeholderProp)

  const handleChange = () => {
    setArrowUp(!arrowUp)
    setValue(values)
  }

  const handleValueInput = (e: React.ChangeEvent<HTMLInputElement>, text: string) => {
    !arrowUp && setArrowUp(true)

    if (text == 'None of the above')
      setValues({ [text]: e.target.checked })
    else if (options.includes('None of the above'))
      setValues({ ...values, [text]: e.target.checked, 'None of the above': false })
    else {
      if (e.target.checked)
        setValues({ ...values, [text]: e.target.checked })
      else
        setValues(_.omit(values, text))
    }
  }

  const renderOptions = (text: string) => (
    <FormControlLabel
      control={
        <Checkbox
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleValueInput(e, text)}
          checked={values[text] ?? false}
        />
      }
      label={text}
    />
  )

  return (
    <>
      <div className='multi-select-input d-flex flex-column'>
        <div className='d-flex justify-space-between heading' onClick={() => {
          setArrowUp(!arrowUp)
          setIsOpen(true)
        }}>
          <h4>{placeholder}</h4>
          {arrowUp ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
        </div>
        {
          (arrowUp || isOpen) &&
          <div className={`d-flex flex-column ${showErrorMsg ? 'show-error' : ''}`}>
            {options.map((text: string) => renderOptions(text))}
          </div>
        }

        {arrowUp && <Button variant='outlined' onClick={handleChange}>Continue</Button>}
      </div>
      {showErrorMsg && <p className='error-msg'>{errorMsg}</p>}
    </>
  )
}

export default MultiSelectInput
