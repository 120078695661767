import React, { useState, createContext, type FC, useEffect } from 'react'
import { Stepper, Step, StepLabel } from '@material-ui/core'
import { ToastContainer } from 'react-toastify'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'

import { MAX_OUTER_WIDTH, STEP_NAMES } from '../../Constants'
import Adults from './AdultDetailsInput'
import Children from './ChildDetailsInput'
import Congrats from './Congrats'
import Household from './Household'
import PersonalAndFamily from './PersonalAndFamily'
import SignInUser from '../SignInUser'

import './styles.sass'
import 'react-toastify/dist/ReactToastify.css'

interface QuestionEngineProps {
  wiCounties: String[]
  signedIn: boolean
  userEmail: string
}

const QuestionEngine: FC<QuestionEngineProps> = ({ wiCounties, signedIn, userEmail }) => {
  const getValuesFromStorage = () => localStorage[userEmail] && JSON.parse(localStorage[userEmail])
  const getStepFromStorage = () => localStorage[userEmail] && localStorage?.step && JSON.parse(localStorage?.step)

  const [step, setStep] = useState<any>(getStepFromStorage() ?? 0)
  const [formValues, setFormValues] = useState(getValuesFromStorage() ?? {})
  const FormValuesContext = createContext(formValues)

  const hideHeader = () => !(['CONGRATS', 3, 'ADULTS', 'CHILDREN', 'SIGN_UP_USER_PAGE'].includes(step))

  useEffect(() => {
    if (!signedIn && step == 2)
      setStep(10)
  }, [step])

  const stepNames = [0, 'ADULTS', 'CHILDREN', 'CONGRATS', 1, 2, 3, 4, 5, 6, 7, , 'SIGN_UP_USER_PAGE']

  const renderSteps = () => {
    switch (step) {
      case 0:
        return <Household
          wiCounties={wiCounties}
          setStep={setStep}
          step={step}
          setValues={setFormValues}
          formValuesContext={FormValuesContext}
        />
      case 'ADULTS':
        return <Adults
          setStep={setStep}
          setValues={setFormValues}
          formValuesContext={FormValuesContext}
        />
      case 'CHILDREN':
        return <Children
          setStep={setStep}
          setValues={setFormValues}
          formValuesContext={FormValuesContext}
        />
      case 'CONGRATS':
        return <Congrats
          completedSection='Household Information'
          nextIntro='You finished the first section of your financial profile. Next we’ll ask 16 question about your Personal and Family Background to find benefits that apply to your partner, household children and dependents.'
          upNext='Personal and Family Background'
          goToNext={setStep}
          signedIn={signedIn}
        />
      case 1:
        return <PersonalAndFamily
          setStep={setStep}
          step={step}
          setValues={setFormValues}
          formValuesContext={FormValuesContext}
        />
      case 'SIGN_UP_USER_PAGE':
        return <SignInUser
          setStep={setStep}
          formValuesContext={FormValuesContext}
          step={step}
        />
    }
  }

  const stepBack = () => setStep(stepNames[stepNames.indexOf(step) - 1])

  const showBackButton = () => outerWidth < MAX_OUTER_WIDTH && step != 0

  const stepOnClick = (stepIndex: number) => {
    if (stepNames.indexOf(step) + 1 > stepIndex) setStep(stepIndex)
  }

  return (
    <FormValuesContext.Provider value={formValues}>
      <div className='question-engine'>
        {showBackButton() && <div className='d-flex back-button' onClick={stepBack}>
          <ArrowBackIosNewIcon />
          <p>Back</p>
        </div>}
        {hideHeader() && <>
          <div className='stepper-info-div content'>
            <Stepper activeStep={step}>
              {STEP_NAMES.map((label: string, index: number) => (
                <Step key={label} onClick={() => stepOnClick(index)}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <p className='info-note'>This section will gather {STEP_NAMES[step]} about yourself. Click <b>here</b> for help and <b>FAQ’s</b></p>
          </div>
          <div className='bottom-div' />
        </>}

        {renderSteps()}

        <ToastContainer
          position='top-right'
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </div>
    </FormValuesContext.Provider>
  )
}

export default QuestionEngine
export type { QuestionEngineProps }
