import React, { useEffect, useState } from 'react'

import '../component_styles/Modal.sass'

interface ModalProps {
  isShown: boolean
  hide(): void
  header: string
  description?: string
  children: JSX.Element
}

interface UseModalProps {
  isShown: boolean
  toggle(): void
}

export const useModal = (): UseModalProps => {
  const [isShown, setIsShown] = useState<boolean>(false)
  const toggle = () => setIsShown(!isShown)

  return { isShown, toggle }
}

export const Modal: React.FC<ModalProps> = (props) => {
  let modalDivOverlay: JSX.Element

  useEffect(() => {
    const closeModal = (ev: any) => {
      if (ev.target == modalDivOverlay)
        props.hide()
    }

    if (props.isShown)
      document.addEventListener('click', closeModal, false)
  })

  return (
    <div className={`modal-overlay ${props.isShown ? 'visible' : 'hidden'}`} ref={(el: any) => modalDivOverlay = el}>
      <div className='modal'>
        <div className='modal-header'>
          <div className='modal-title'>
            <h2>{props.header}</h2>
            <div className='modal-close-button' onClick={props.hide}> </div>
          </div>
          {props.description &&
            <p>{props.description}</p>
          }
        </div>
        <div className='modal-content-container'>
          {props.children}
        </div>
      </div>
    </div>
  )
}

export default Modal
export type { ModalProps }
