import React from 'react'
import '../component_styles/QuestionAnswerDisplay.sass'

interface QuestionAnswerDisplayProps {
  question: string
  answerHTML: []
  url: string
  extraClassName?: string
}

const QuestionAnswerDisplay: React.FC<QuestionAnswerDisplayProps> = (props) => {
  const { question, answerHTML, url, extraClassName } = props

  return (
    <div className={ `question-answer-display clearfix ${extraClassName || ""}` }>
      <div className="question-answer float-left">
        <h4>{ question }</h4>
        { answerHTML }
      </div>

      <a href={ url } className="float-right">
        <i className="fas fa-angle-right link-icon"></i>
      </a>
    </div>
  )
}

export default QuestionAnswerDisplay
export type { QuestionAnswerDisplayProps }
