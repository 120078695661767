import React from 'react'
import Link from './Link'

import '../component_styles/CallToAction.sass'

interface CallToActionProps {
  backgroundColor: string
  iconUrl: string
  iconDescription: string
  title: string
  description: string
  buttonText: string
  buttonUrl: string
}

const CallToAction: React.FC<CallToActionProps> = (props) => {
  const {
    iconUrl, iconDescription, title, description,
    buttonText, buttonUrl, backgroundColor
  } = props

  return (
    <div
      className={`call-to-action ${backgroundColor}-background-color`}
     >
      <div
        className="content"
      >
        <div className="icon-container">
          <img src={ iconUrl } alt={ iconDescription } title={ iconDescription } />
        </div>
        <h2>{ title }</h2>
        <div
          dangerouslySetInnerHTML={{__html: description }}
        />
        <Link
          className="btn"
          text={ buttonText }
          href={ buttonUrl }
        />
      </div>
    </div>
  )
}

export default CallToAction
export type { CallToActionProps }
