import React, { memo } from 'react'

import { type FSABenefitProps } from '../../../EmployeeCalculatorInterface'
import BenefitsContributionsDetails from '../BenefitsContributionsDetails'

const PensionBenefits: React.FC<FSABenefitProps> = memo((props) => {
  return (
    <BenefitsContributionsDetails
      {...props}
      componentName='Pension'
      benefitKey='pension'
    />
  )
})

export default PensionBenefits
