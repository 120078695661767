import React, { memo, useState } from 'react'
import { CircularProgress, FormHelperText, Link, OutlinedInput } from '@mui/material'

import { signInEmployer, signUpEmployer } from '../../../api/employer'
import { isValidEmail } from '../../../Helper'
import DividingColorLines from '../../DividingColorLines'
import PrivacyPolicy from '../PrivacyPolicy'
import PurpleButton from '../PurpleButton'

import './styles.sass'

interface SignupProps {
  isRegistration: boolean
}

const SignUpModal: React.FC<SignupProps> = memo((props) => {
  const { isRegistration } = props
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [errors, setErrors] = useState({ email: false, password: false })
  const [errorMessages, setErrorMessages] = useState<string[]>([])
  const [isLoggedIn, setIsLoggedIn] = useState(false)

  const linkText = isRegistration ? 'Already have an account? Login' : 'Create an account? Signup'
  const linkURL = isRegistration ? '/employers/sign_in' : '/employers/sign_up'

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = e.target
    if (name === 'email') {
      setEmail(value)
      setErrors((prevState) => ({ ...prevState, email: false }))
    } else if (name === 'password') {
      setPassword(value)
      setErrors((prevState) => ({ ...prevState, password: false }))
    } else if (name === 'confirmPassword') {
      setConfirmPassword(value)
    }
  }

  const emailBlur = (): void => {
    if (!isValidEmail(email)) {
      setErrors((prevState) => ({ ...prevState, email: true }))
    }
  }

  const passwordBlur = (): void => {
    if (password.length < 6) {
      setErrors((prevState) => ({ ...prevState, password: true }))
    }
  }

  const handleSubmit = async (): Promise<void> => {
    const credentials = { email, password }

    if (isRegistration) {
      const registrationCredentials = {
        ...credentials,
        password_confirmation: confirmPassword
      }

      await signUpEmployer(registrationCredentials, setErrorMessages, setIsLoggedIn)
    } else {
      await signInEmployer(credentials, setErrorMessages, setIsLoggedIn)
    }
  }

  return (
    <div className='pin-modal'>
      {isLoggedIn && (
        <div className='b2b-circular-progress'>
          <CircularProgress />
        </div>
      )}
      <div className='page-content'>
        <b>Welcome to UW Health</b>
        <p>{isRegistration ? 'Create an account to start earning rewards.' : 'Log in to start earning rewards.'}</p>

        <div className='data-modal'>
          <p>{isRegistration ? 'Sign-up with your email' : 'Log-in with your email'}</p>

          <div className='error-messages'>
            {errorMessages?.map((error, index) => (
              <p key={index}>{error}</p>
            ))}
          </div>

          <div className='input-field'>
            <b>Email address</b>
            <OutlinedInput fullWidth type='email' name='email' value={email} onBlur={emailBlur} error={errors.email} onChange={handleInputChange} />

          </div>

          <div className='input-field'>
            <b>Password</b>
            <OutlinedInput fullWidth type='password' name='password' value={password} onBlur={passwordBlur} error={errors.password} onChange={handleInputChange} />

            <FormHelperText>
              6 characters: ABC I abc I 123 I 1@$#% symbols
            </FormHelperText>
          </div>

          {isRegistration &&
            <div className='input-field'>
              <b>Confirm Password</b>
              <OutlinedInput fullWidth type='password' name='confirmPassword' value={confirmPassword} onChange={handleInputChange} />
            </div>
          }

          <div className='landing-button'>
            <PurpleButton
              label={isRegistration ? 'Get Started' : 'Log in'}
              width='280px'
              buttonClick={handleSubmit as VoidFunction}
              disabled={errors.email || errors.password}
            />

            <Link href={linkURL} className='link'>
              {linkText}
            </Link>
          </div>
        </div>
      </div>

      <DividingColorLines />

      <PrivacyPolicy />
    </div>
  )
})

export default SignUpModal
