import React, { ChangeEventHandler, FC, FocusEventHandler } from 'react'
import { TextField } from '@material-ui/core'

interface InputQuestionProps {
  label?: string
  value?: string | number | undefined
  onChangeHandler?: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement> | undefined
  type?: string
  errorMsg?: string
  showErrorMsg?: boolean
  onBlurHandler?: FocusEventHandler<HTMLTextAreaElement | HTMLInputElement>
}

const InputQuestion: FC<InputQuestionProps> = ({ onBlurHandler, label, value, onChangeHandler, type = 'text', showErrorMsg, errorMsg }) => {
  return (
    <>
      <TextField
        label={label}
        margin='dense'
        onChange={onChangeHandler}
        type={type}
        value={value}
        variant='outlined'
        onBlur={onBlurHandler}
      />
      {showErrorMsg && <p className='error-msg'>{errorMsg}</p>}
    </>
  )
}

export default InputQuestion
