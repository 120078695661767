import React, { useState } from 'react'
import { ArrowForwardIos } from '@material-ui/icons'

import { goToURL } from '../Helper'
import I18n from '../config/I18n'
import QuickPeekCard, { QuickPeekCardProps } from './QuickPeekCard'

import '../component_styles/QuickPeekCardCarousel.sass'
import { MAX_OUTER_WIDTH } from '../Constants'

interface QuickPeekCardCarouselProps {
  cards: QuickPeekCardProps[],
  showAllLink: boolean,
  title: string,
  bigCard: boolean,
  quickPeekTags: string[]
}

const QuickPeekCardCarousel: React.FC<QuickPeekCardCarouselProps> = (props) => {
  const { cards, showAllLink, bigCard, quickPeekTags } = props

  const [allCategories, setAllCategories] = useState(cards)
  const [selectedTag, setSelectedTag] = useState('All')

  const handleForwardClick = () => {
    const scroll = document.querySelector('.list')
    scroll && scroll.scrollBy(400, 0)
  }

  const renderTopicCard = (card: QuickPeekCardProps, index: number) => (
    <div
      className='topic-div'
      key={index}
      onClick={() => goToURL(`/${I18n.locale}/categories/${card.id}`)}
    >
      <div className='content'>
        {card.icon_url ?
          <img src={card.icon_url} alt={card.image_alt_text} />
          :
          <div className={`light-colored-div ${card.color}-background-color`}>  </div>
        }
        <p className='one-liner'>{card.title}</p>
      </div>
    </div>
  )

  const renderCategoryLists = (categories: string[], headingText: string) => {
    !categories.includes('All') && categories.splice(0, 0, 'All')

    return (
      <div className='category-list'>
        <h3>{headingText}</h3>
        <div className='d-flex'>
          <div className='list'>
            {categories.map((category: string) => {
              return (
                <div
                  className={`category ${selectedTag == category ? 'selected-tag' : ''}`}
                  onClick={() => filterCategories(category)}
                >
                  <p>{category}</p>
                </div>
              )
            })}
          </div>
          {
            outerWidth > MAX_OUTER_WIDTH &&
            <div className='category forward-icon' onClick={handleForwardClick}>
              <ArrowForwardIos />
            </div>
          }
        </div>
      </div>
    )
  }

  const filterCategories = (tag: string) => {
    setSelectedTag(tag)
    if (tag == 'All') {
      setAllCategories(cards)
    } else {
      setAllCategories(cards.filter((card: QuickPeekCardProps) => card.tags.includes(tag)))
    }
  }

  return (
    <div className='quick-peek-carousel content'>
      {renderCategoryLists(quickPeekTags, 'What can your income do for you?')}
      {!bigCard && <div className='content carousel-content'>
        <div className='carousel-container'>
          <div className='carousel'>
            {allCategories.map((card, index) =>
              <div className='carousel-item' key={`card-${index}`}>
                <QuickPeekCard {...card} />
              </div>
            )}
          </div>
        </div>
      </div>}

      {bigCard &&
        <div className='topics'>
          {allCategories.map((card: QuickPeekCardProps, index: number) =>
            index < (window.outerWidth > MAX_OUTER_WIDTH ? 5 : 4) && renderTopicCard(card, index))
          }
        </div>
      }

      {showAllLink &&
        <div
          className='explore-more-topics'
          onClick={() => goToURL(`/${I18n.locale}/categories`)}
        >
          <p>{I18n.t('quick_peek.explore_more_topics')}</p>
          <ArrowForwardIos className='fa-xs' />
        </div>
      }
    </div>
  )
}

export default QuickPeekCardCarousel
export type { QuickPeekCardCarouselProps }
