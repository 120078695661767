import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en.json'

import { useEffect } from 'react'
import { AVG_WEEKS_PER_MONTH, CHILD_AGED_FIVE, CHILD_AGED_THIRTEEN, CHILD_AGED_TWELVE, CHILD_UNDER_ONE, DROPDOWN_COLOR_OPTIONS, EMAIL_FORMAT, WI_BENEFITS } from './Constants'
import { type CategoryTypeProps } from './components/Category'
import { type QuickPeekCardProps } from './components/QuickPeekCard'
import { type ImageProps, type SelectOptions } from './components/QuickPeekEditor'
import { type Children, type Adults, JobDetails } from './components/AdultsInterface'
const _ = require('lodash')

export const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD'
})

export const incrementFromType = (incrementType: string): 'yr' | 'mo' | 'wk' => {
  if (incrementType == 'monthly') {
    return 'mo'
  } else if (incrementType == 'weekly') {
    return 'wk'
  } else {
    return 'yr'
  }
}

export const valueForIncrement = (incrementType: string, yearlyValue: number): number => {
  if (incrementType == 'monthly') {
    return yearlyValue / 12
  } else if (incrementType == 'weekly') {
    return yearlyValue / 52
  } else {
    return yearlyValue
  }
}

export const timesAgo = (date: string) => {
  TimeAgo.addLocale(en)
  const timeAgo = new TimeAgo('en-US')
  return timeAgo.format(new Date(date), 'round')
}

export const convertCategoriesToOptions = (categories: CategoryTypeProps[]) =>
  categories.map((category: CategoryTypeProps) => ({ value: category.id, label: category.title }))

export const getSelectRelatedTopics = (topics: object[]) => topics && topics.map((topic: any) => topic.value)

export const getSelectedCategory = (categoryId: number | string, categories: CategoryTypeProps[]) => {
  let category = categories.find((category: CategoryTypeProps) => category.id == categoryId)

  if (category) return { value: category?.id, label: category?.title }
}

export const goToURL = (url: string) => window.open(url, '_self')

export const validatFileSize = (input: React.ChangeEvent<HTMLInputElement> | any) => {
  const fileSize = input.target.files[0].size / 1024 / 1024
  if (fileSize > 5) {
    alert('File size exceeds 5 MB')
    return false
  }
  return true
}

export const getRelatedCategory = (id: number | string, categories: CategoryTypeProps[]) =>
  categories.find((category: CategoryTypeProps) => category.id == id) ?? { title: '' }

export const getQuickPeekCardTier = (qpCard: QuickPeekCardProps) =>
  ({ value: qpCard?.tier ?? 1, label: qpCard?.tier ? `Tier ${qpCard?.tier}` : 'Tier 1' })

export const getImage = (images: ImageProps[], id: string | number | undefined) =>
  images && images?.find((image: ImageProps) => image?.id == id)

export const convertToOptions = (options: ImageProps[]) =>
  options?.map((option: ImageProps) => ({ value: option.id, label: option.alt_text, link: option.link }))

export const checkPresenceOfImgNText = (text: string | number | undefined, img: string | number | undefined) => {
  if ((text == '' || text == undefined) && img == undefined) return true
  else if ((text != '' || text != undefined) && img == undefined) return false
  else return true
}

export const getSelectedColor = (color: string) =>
  DROPDOWN_COLOR_OPTIONS.find((clr: SelectOptions) => clr?.value === color)

export const convertObjectToArray = (value: any) => Array.from(Array(parseInt(value)).keys())

export const ordinalSuffixOf = (value: any) => {
  const j = value % 10, k = value % 100
  if (j == 1 && k != 11) return value + 'st'
  else if (j == 2 && k != 12) return value + 'nd'
  else if (j == 3 && k != 13) return value + 'rd'
  return value + 'th'
}

export const isInputPositiveAndInRange = ({ target }: any, max: number) => {
  return Math.max(0, parseInt(target.value)).toString().slice(0, 12) && target.value > -1
    && (target.validity.valid || target.value == '') && target.value < max
}

export const getSelectdValue = (value: any, options: any) => options.filter((option: any) => option.value == value)

export const getMarriedAdultOptions = (adults: any, details: any) =>
  convertObjectToArray(adults).map((_adult: any, index: number) =>
    ({ label: index < 1 ? 'Me' : details[index]?.name ?? `Adult ${index}`, value: `adult${index}` }))

export const sliceMarried = (adults: any, event: any) => adults.filter((adult: any) => adult.value !== event.value)

export const getMoneyMakingObject = (moneyMaking: any, perHours: any, hoursPerWeek: any, adultCount: number) => {
  let resultArray: any = Array(adultCount).fill([]).map((index) => {
    if (moneyMaking[index] !== undefined) {
      return Object.keys(moneyMaking[index]).map((j) => {
        return {
          amount: _.get(moneyMaking, [index, j]) ?? 0,
          duration: _.get(perHours, [index, j]) ?? 'hour',
          hours_per_week: _.get(hoursPerWeek, [index, j]) ?? 0
        }
      })
    } else {
      return []
    }
  })

  return resultArray
}

export const capitalizeFirstLetter = (string: string) => string.charAt(0).toUpperCase() + string.slice(1)

export const getPercentageProgress = (qsNumber: number, totalQuestions: number) =>
  (qsNumber + 1) * 100 / totalQuestions

export const getFilledInput = () => [0, 1, 2, 3].map(() => true)

export const getSelectedValue = (condition: number | string | undefined) => {
  if (condition) return { value: condition, label: condition }
}

export const handleRedirect = (en: string, setIsLoading: Function, url: string) => {
  setTimeout(() => {
    goToURL(`/${en}/${url}`)
    setIsLoading(false)
  }, 2500)
}

export const getNames = (adults: any) => adults && Object.values(adults).map((adult: any) => adult.name)

export const getAgesOfChildren = () => {
  let childAges = Array.from(Array(17), (_, index: any) => ({ label: (index + 1).toString(), value: index + 1 }))
  childAges.unshift({ label: 'Under 1', value: 0 })
  return childAges
}

export const getAgesOfAdults = () => {
  let adultAges = Array.from(Array(53), (_, index: any) => ({ label: (index + 18).toString(), value: index + 18 }))
  adultAges.push({ label: 'Above 70', value: 71 })
  return adultAges
}

export const isValidEmail = (emailAddress: string) => emailAddress.match(EMAIL_FORMAT)

export const anyAdultStudent = (adults: Record<number, Adults>): boolean =>
  Object.values(adults).some((adult: Adults) => adult?.student != 'not-student')

export const haveChildUnderOne = (children: Children[]): boolean =>
  Object.values(children)?.some((child: Children) => child?.age == CHILD_UNDER_ONE)

export const haveChildUnderFive = (children: Record<number, Children>): boolean =>
  Object.values(children).some((child: Children) => child?.age as number < CHILD_AGED_FIVE)

export const haveChildOverTwelve = (children: Record<number, Children>): boolean =>
  Object.values(children).some((child: Children) => child?.age as number > CHILD_AGED_TWELVE)

export const haveChildUnderThirteen = (children: Record<number, Children>): boolean =>
  Object.values(children).some((child: Children) => child?.age as number < CHILD_AGED_THIRTEEN)

export const isChildUnderThirteen = (childAge: number): boolean => childAge < CHILD_AGED_THIRTEEN

export const anyDisabledAdult = (adults: Adults): boolean =>
  Object.values(adults).some((adult: Adults) => adult?.disability)

export const anyBlindAdult = (adults: Record<number, Adults>): boolean =>
  Object.values(adults).some((adult: Adults) => adult?.blindness)

export const anyDisabledChild = (children: Record<number, Children>) =>
  Object.values(children).some((child: Children) => child?.disability)

export const anyBlindChild = (children: Children[]): boolean =>
  Object.values(children).some((child: Children) => child?.blindness)

export const removeMyselfFromAdult = (adultsArray: []): [] => {
  adultsArray.shift()
  return adultsArray
}

export const getBenefitsInitialValues = (): Record<string, boolean> => {
  const benefitsList: Record<string, boolean> = {}

  WI_BENEFITS.forEach((benefit) => {
    const { value } = benefit
    benefitsList[`check_${value}`] = false
    benefitsList[`enrolled_${value}`] = false
  })

  return benefitsList
}

export const scrollToTop = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }, [])
}

export const scrollToBottom = () => {
  useEffect(() => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: 'smooth'
    })
  }, [])
}

export const convertAndRound = (value: number): number => parseFloat(value.toFixed(2))

export const tinymceInitSettings = {
  plugins: 'anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount',
  toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat',
  font_family_formats: 'Poppins=poppins; Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats',
  content_style: `@import url('https://fonts.googleapis.com/css?family=Poppins&display=swap'); body { font-family: Poppins; line-height: 1.5; }`,
  height: '500px',
  images_upload_url: 'postAcceptor.php',
  automatic_uploads: false
}

export const addThousandsSeparator = (number: string): string =>
  number.replace(/\B(?=(\d{3})+(?!\d))/g, ',')

export const splitAndCapitalize = (input: string): string =>
  input.split('_').map(part => part.charAt(0).toUpperCase() + part.slice(1)).join(' ')

export const calculateHoursPerMonth = (jobs: JobDetails[]): number => (
  jobs.reduce((totalHours, jobDetails) => {
    return totalHours + AVG_WEEKS_PER_MONTH * ((jobDetails?.currentWorkweek || 0) + (jobDetails?.overtimeWorkweek || 0))
  }, 0)
)
