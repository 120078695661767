import React from 'react'
import '../component_styles/ButtonMultipleChoiceOption.sass'

interface ButtonMultipleChoiceOptionProps{
  text: string
  value: string
  id: string
  name: string
  icon?: string
  selected?: string
  onInputChange?: (ev: any) => void
}

const ButtonMultipleChoiceOption: React.FC<ButtonMultipleChoiceOptionProps> = (props) =>{
  return (
    <>
      <input
        id={ `${props.name}-${props.id}` }
        className="multiple-choice-option-input"
        name={ props.name }
        value={ props.value }
        type="radio" 
        defaultChecked={ props.selected == props.value }
        onChange={ props.onInputChange }
      />
      <label htmlFor={ `${props.name}-${props.id}` } className="multiple-choice-option-label">
        { props.icon &&
          <span className={ `option-icon ${ props.icon }` } />
        }
        <span className="button-text">{ props.text }</span>
      </label>
    </>
  )
}

export default ButtonMultipleChoiceOption
export type { ButtonMultipleChoiceOptionProps }
