import React, { FC, useContext, useState } from 'react'
import { Button, TextField } from '@material-ui/core'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material'

import { signInGuestUser } from '../../api/benefitCliffs'
import { isValidEmail } from '../../Helper'

interface LoginInputProps {
  email: string | undefined
  formValuesContext: any
  isOpen: boolean
  setIsOpen: Function
}

const LoginInput: FC<LoginInputProps> = ({ email, formValuesContext, isOpen, setIsOpen }) => {
  const [loginEmail, setLoginEmail] = useState<string>(email ?? '')
  const [loginPassword, setLoginPassword] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [error, setError] = useState({
    emailError: false,
    emailMsg: '',
    passwordError: false,
    passwordMsg: ''
  })

  const formValues: any = useContext(formValuesContext)

  const handleLogIn = () => {
    signInGuestUser({ email: loginEmail, password: loginPassword }, setErrorMessage)

    localStorage.setItem(loginEmail, JSON.stringify(formValues))
    localStorage.setItem('step', JSON.stringify(1))
  }

  const closeModal = () => setIsOpen(false)

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLoginEmail(e.target.value)
    setError({ ...error, emailError: false, emailMsg: '' })
    setErrorMessage('')
  }

  const emailBlur = () => {
    !isValidEmail(loginEmail) &&
      setError({ ...error, emailError: true, emailMsg: 'Please enter a valid email address' })
  }

  const passwordBlur = () => {
    loginPassword.length < 10 &&
      setError({ ...error, passwordError: true, passwordMsg: 'Please enter a valid password' })
  }

  const handlePasswordInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLoginPassword(e.target.value)
    setErrorMessage('')
    setError({ ...error, passwordError: false, passwordMsg: '' })
  }

  return (
    <Dialog
      open={isOpen}
      onClose={closeModal}
    >
      <DialogTitle>Sign In</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please provide the password.
        </DialogContentText>
        <div className='d-flex flex-column' style={{ width: '300px' }}>
          <TextField
            error={error.emailError}
            helperText={error.emailMsg}
            label='Email'
            margin='dense'
            onBlur={emailBlur}
            onChange={handleEmailChange}
            value={loginEmail}
            variant='outlined'
          />
          <TextField
            error={error.passwordError}
            helperText={error.passwordMsg}
            label='Password'
            margin='dense'
            onBlur={passwordBlur}
            onChange={handlePasswordInput}
            value={loginPassword}
            variant='outlined'
          />
        </div>
        <p style={{ color: '#f44336', margin: '0', fontSize: '13px' }}>{errorMessage}</p>
      </DialogContent>
      <DialogActions style={{ padding: '0 20px 20px 0' }}>
        <Button
          variant='contained' color='primary'
          disabled={loginPassword.length < 10 || !isValidEmail(loginEmail)}
          onClick={handleLogIn}
        >
          Sign In
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default LoginInput
