import React, { useEffect, useState } from 'react'
import { Modal, useModal } from './Modal'
import Label, { LabelProps } from './Label'
import RadioInputGroup from './RadioInputGroup'

import '../component_styles/Dropdown.sass'

interface DropdownProps {
  placeholder?: string
  className?: string
  header: string
  options?: [string, string | number][] | string[]
  name: string
  selectedId?: string | number
  selectedValue?: string | number
  errors?: string
  labelProps?: LabelProps
  isFullWidth?: boolean
  onChange?: (id: string) => void
}

const Dropdown: React.FC<DropdownProps> = (props) => {
  const { isShown, toggle } = useModal()
  const [options, setOptions] = useState(props.options)
  const [selectedId, setSelectedId] = useState(props.selectedId)
  const [selectedValue, setSelectedValue] = useState(props.selectedValue)

  useEffect(() => {
    setOptions(props.options)
    setSelectedId(props.selectedId)
    setSelectedValue(props.selectedValue)
  }, [props.options, props.selectedId, props.selectedValue])

  function showModal(_ev: any) {
    _ev.preventDefault()
    toggle()
  }

  function handleOptionChange(ev: any) {
    setSelectedId(ev.target.value)

    const selectors = (props.className || '').split(' ')
      .filter(className => className.length > 0)
      .map(className => `.${className}`)
      .join('')

    const labelElement = $(`${selectors} .for-${ev.target.id}`)
    setSelectedValue(labelElement.text())

    if (props.onChange) {
      props.onChange(ev.target.value);
    }
  }

  return (
    <div className={props.className}>
      {props.labelProps &&
        <Label
          hasError={!!props.errors}
          {...props.labelProps}
        />
      }
      <button
        className={`${props.isFullWidth ? '' : 'inline-dropdown-input'} dropdown-input ${props.errors ? 'error' : ''}`}
        onClick={showModal}
        disabled={options && options.length == 0}
      >
        {selectedId ? selectedValue : props.placeholder}
      </button>
      {props.errors &&
        <div className='error-message'>{props.errors}</div>
      }
      <Modal
        isShown={isShown}
        hide={toggle}
        header={props.header}
      >
        <RadioInputGroup
          options={options}
          selectedId={selectedId}
          name={props.name}
          onChange={handleOptionChange}
        />
      </Modal>
    </div>
  )
}

export default Dropdown
export type { DropdownProps }
