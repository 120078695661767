import React, { useState } from 'react'
import Checkbox from '@mui/material/Checkbox'
import { styled } from '@mui/material/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'

export interface EmployeeData {
  id: React.Key
  parsley_identifier: string
  employer_identifier: number
  url: string
}

interface EmployeeListProps {
  employees: EmployeeData[]
}

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover
  },
  '& td, & th': {
    border: 0
  }
}))

const EmployeeList = ({ employees }: EmployeeListProps): JSX.Element => {
  const [selectedRows, setSelectedRows] = useState<React.Key[]>([])

  const handleSelectAllRows = (): void => {
    if (selectedRows.length === employees.length) {
      setSelectedRows([])
    } else {
      const allRowIds = employees.map((employee: EmployeeData) => employee.id)
      setSelectedRows(allRowIds)
    }
  }

  const handleRowCheckboxChange = (id: React.Key): void => {
    if (selectedRows.includes(id)) {
      setSelectedRows(selectedRows.filter(rowId => rowId !== id))
    } else {
      setSelectedRows([...selectedRows, id])
    }
  }

  return (
    <TableContainer>
      <Table aria-label='simple table'>
        <TableHead>
          <TableRow>
            <TableCell>
              <Checkbox
                checked={selectedRows?.length === employees?.length}
                onChange={handleSelectAllRows}
              />
            </TableCell>
            <TableCell>Employer identifier </TableCell>
            <TableCell>Parsley identifier</TableCell>
            <TableCell>URL</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {employees.map((employee: EmployeeData) => (
            <StyledTableRow key={employee.id}>
              <TableCell>
                <Checkbox
                  checked={selectedRows.includes(employee.id)}
                  onChange={() => { handleRowCheckboxChange(employee.id) }}
                  className='custom-checkbox'
                />
              </TableCell>

              <TableCell component='th' scope='row'>
                {employee.employer_identifier}
              </TableCell>

              <TableCell component='th' scope='row'>
                {employee.parsley_identifier}
              </TableCell>

              <TableCell>
                <a href={employee.url} target='_blank' rel='noopener noreferrer'>
                  {employee.url}
                </a>
              </TableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default EmployeeList
