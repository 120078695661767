import React from 'react'
import '../component_styles/FooterLink.sass'

interface FooterLinkProps {
  text: string,
  href?: string,
  iconName: string
}

const FooterLink: React.FC<FooterLinkProps> = (props) => {
  const { text, href, iconName } = props
  return (
    <a href={href} className={`footer-link`}>
      <i className={`fas ${iconName} `} />
      <p>{text}</p>
    </a>
  )
}

export default FooterLink
export type { FooterLinkProps }
