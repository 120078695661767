import { toast } from 'react-toastify'
import axios from 'axios'

import { DURATION_MULTIPLIER, WHITE_SPACE_REGEX } from '../../../Constants'
import I18n from '../../../config/I18n'

const coverageTypes = ['single', 'spouse', 'children', 'double', 'family']

type BenefitAmounts = Record<string, Record<string, Record<string, number>>>

type BenefitContributionAmounts = Record<string, Record<string, Record<string, number>>>

type BenefitData = {
  provider_name: string
  plan_options?: string
  [key: string]: any
}

type TransformedBenefitsData = Record<string, BenefitData[]>

const healthcareAmounts: BenefitAmounts = {}

const healthcareContributionAmounts: BenefitContributionAmounts = {}

const healthcareCoverageOptions: { option: string; value: string }[] = []

const visionAmounts: BenefitAmounts = {}

const visionContributionAmounts: BenefitContributionAmounts = {}

const visionCoverageOptions: { option: string; value: string }[] = []

const dentalAmounts: BenefitAmounts = {}

const dentalContributionAmounts: BenefitContributionAmounts = {}

const dentalCoverageOptions: { option: string; value: string }[] = []

export const getBenefitsData = async (
  employee_id: number,
  setEmployeeBenefitsList: any,
  setBenefitsAnnualContributionLimit?: any
): Promise<void> => {
  try {
    const response = await axios.get(`/${I18n.locale}/employers/${employee_id}/get_benefits`)
    if (response.status !== 200) {
      throw new Error(`Failed to fetch benefits data.`)
    }

    const benefitsData = response.data.benefits

    const transformedBenefitsData: TransformedBenefitsData = {}
    for (const key in benefitsData) {
      if (Object.prototype.hasOwnProperty.call(benefitsData, key)) {
        const transformedKey = key.toLowerCase().replace(/\s+/g, '_')
        transformedBenefitsData[transformedKey] = benefitsData[key]
      }
    }
    const benefitDataFor403b = transformedBenefitsData['403(b)' as keyof typeof transformedBenefitsData]
    processEmployerBenefits(Object.keys(benefitsData), setEmployeeBenefitsList)
    if (setBenefitsAnnualContributionLimit) {
      processAnnualContributionLimitData(transformedBenefitsData, setBenefitsAnnualContributionLimit, benefitDataFor403b)
    }

    if (transformedBenefitsData.health) processHealthcareData(benefitsData.Health)
    if (transformedBenefitsData.vision) processVisionData(benefitsData.Vision)
    if (transformedBenefitsData.dental) processDentalData(benefitsData.Dental)
  } catch (error) {
    toast.error(error)
  }
}

const processAnnualContributionLimitData = (
  transformedBenefitsData: TransformedBenefitsData,
  setBenefitsAnnualContributionLimit: (limits: Record<string, string | number[]>) => void,
  benefitDataFor403b: BenefitData[]
): void => {
  const dependentCareLimit = processDependentCareData(transformedBenefitsData.dependent_care_flexible_spending_account)
  const medicalLimit = processDependentCareData(transformedBenefitsData.health_flexible_spending_account)
  const pensionLimit = processDependentCareData(transformedBenefitsData.pension)
  const tsa403bLimit = processDependentCareData(benefitDataFor403b)

  const contributionLimits = {
    dependentCareLimit,
    medicalLimit,
    pensionLimit,
    tsa403bLimit
  }

  setBenefitsAnnualContributionLimit(contributionLimits)
}

const processDependentCareData = (dependentCareData: BenefitData[]): number[] => {
  return dependentCareData?.map((item) => {
    const limit = item?.annual_contribution_limit
    return limit ? parseFloat(limit) : 0
  }) ?? []
}

const processEmployerBenefits = (
  benefits: string[],
  setBenefitsList: (benefitsList: { label: string; value: string }[]) => void
): void => {
  const desiredOrder = ['Health', 'Dental', 'Vision', 'Dependent Care Flexible Spending Account', 'Health Flexible Spending Account', '403(b)', 'Pension']

  const EMPLOYEE_BENEFITS_LIST = desiredOrder
    .filter(key => benefits.includes(key))
    .map((key) => ({
      label: key,
      value: key.replace(/\s+/g, '_').toLowerCase()
    }))

  setBenefitsList(EMPLOYEE_BENEFITS_LIST)
}

const initializeProvider = (
  providerName: string,
  amounts: BenefitAmounts,
  contributionAmounts: BenefitContributionAmounts,
  planOptionsValue?: string
): void => {
  if (!amounts[providerName]) {
    amounts[providerName] = {}
  }
  if (!contributionAmounts[providerName]) {
    contributionAmounts[providerName] = {}
  }

  if (planOptionsValue && !amounts[providerName][planOptionsValue]) {
    amounts[providerName][planOptionsValue] = {}
  }
  if (planOptionsValue && !contributionAmounts[providerName][planOptionsValue]) {
    contributionAmounts[providerName][planOptionsValue] = {}
  }
}

const processHealthcareData = (healthData: BenefitData[]): void => {
  healthData?.forEach((item) => {
    const providerName = item.provider_name
    const optionsSet = new Set(healthcareCoverageOptions.map((option) => option.option))

    if (item?.plan_options) {
      const planOptionsValue = item.plan_options.replace(/\$/g, '').split(/[\s/]+/).join('_').toLowerCase()
      initializeProvider(providerName, healthcareAmounts, healthcareContributionAmounts, planOptionsValue)
      coverageTypes.forEach((type) => {
        if (item[`additional_coverage_option_${type}`]) {
          healthcareAmounts[providerName][planOptionsValue][type] = item[`additional_coverage_${type}_premium`]
          healthcareContributionAmounts[providerName][planOptionsValue][type] = item[`employer_contribution_${type}_premium`]
          setCoverageOptions(optionsSet, healthcareCoverageOptions, type)
        }
      })
    } else {
      initializeProvider(providerName, healthcareAmounts, healthcareContributionAmounts)

      coverageTypes.forEach((type) => {
        if (item[`additional_coverage_option_${type}`]) {
          healthcareAmounts[providerName][type] = item[`additional_coverage_${type}_premium`]
          healthcareContributionAmounts[providerName][type] = item[`employer_contribution_${type}_premium`]
          setCoverageOptions(optionsSet, healthcareCoverageOptions, type)
        }
      })
    }
  })
}

const processVisionData = (visionData: BenefitData[]): void => {
  visionData?.forEach((item) => {
    const providerName = item.provider_name
    const optionsSet = new Set(visionCoverageOptions.map((option) => option.option))

    if (item?.plan_options) {
      const planOptionsValue = item.plan_options.replace(/\$/g, '').split(/[\s/]+/).join('_').toLowerCase()
      initializeProvider(providerName, visionAmounts, visionContributionAmounts, planOptionsValue)

      coverageTypes.forEach((type) => {
        if (item[`additional_coverage_option_${type}`]) {
          visionAmounts[providerName][planOptionsValue][type] = item[`additional_coverage_${type}_premium`]
          visionContributionAmounts[providerName][planOptionsValue][type] = item[`employer_contribution_${type}_premium`]
          setCoverageOptions(optionsSet, visionCoverageOptions, type)
        }
      })
    } else {
      initializeProvider(providerName, visionAmounts, visionContributionAmounts)

      coverageTypes.forEach((type) => {
        if (item[`additional_coverage_option_${type}`]) {
          visionAmounts[providerName][type] = item[`additional_coverage_${type}_premium`]
          visionContributionAmounts[providerName][type] = item[`employer_contribution_${type}_premium`]
          setCoverageOptions(optionsSet, visionCoverageOptions, type)
        }
      })
    }
  })
}

const processDentalData = (dentalData: BenefitData[]): void => {
  dentalData?.forEach((item) => {
    const providerName = item.provider_name
    const optionsSet = new Set(dentalCoverageOptions.map((option) => option.option))

    if (item?.plan_options) {
      const planOptionsValue = item.plan_options.replace(/\$/g, '').split(/[\s/]+/).join('_').toLowerCase()
      initializeProvider(providerName, dentalAmounts, dentalContributionAmounts, planOptionsValue)

      coverageTypes.forEach((type) => {
        if (item[`additional_coverage_option_${type}`]) {
          dentalAmounts[providerName][planOptionsValue][type] = item[`additional_coverage_${type}_premium`]
          dentalContributionAmounts[providerName][planOptionsValue][type] = item[`employer_contribution_${type}_premium`]
          setCoverageOptions(optionsSet, dentalCoverageOptions, type)
        }
      })
    } else {
      initializeProvider(providerName, dentalAmounts, dentalContributionAmounts)

      coverageTypes.forEach((type) => {
        if (item[`additional_coverage_option_${type}`]) {
          dentalAmounts[providerName][type] = item[`additional_coverage_${type}_premium`]
          dentalContributionAmounts[providerName][type] = item[`employer_contribution_${type}_premium`]
          setCoverageOptions(optionsSet, dentalCoverageOptions, type)
        }
      })
    }
  })
}

const setCoverageOptions = (
  optionsSet: Set<string>,
  coverage: { option: string; value: string }[],
  type: string
): void => {
  if (!optionsSet.has(type)) {
    coverage.push({
      option: type,
      value: type,
    })
    optionsSet.add(type)
  }
}

const benefitsContributionAmounts: Record<string, string> = {
  employee_benefit_corporation: '0',
  best_flex: '0',
  wps: '6.15%',
  mennenga_tax_and_financial: '0',
  lincoln_financial: '0',
  fidelity: 'NA',
  wea: 'NA',
  axa: 'NA'
}

const calculateAmount = (
  provider: string,
  type: string,
  coverage: string,
  data: BenefitAmounts
): number => {
  const providerData = data[provider]
  if (!providerData) return 0

  const getTypeData = (typeData: Record<string, number> | number): number => {
    if (typeof typeData === 'number') {
      return typeData
    } else if (typeof typeData === 'object') {
      const coverageData = typeData[coverage]
      if (typeof coverageData === 'number') {
        return coverageData
      }
    }

    return 0
  }

  if (type !== null && type.length !== 0) {
    const typeData = providerData[type]
    return getTypeData(typeData)
  } else {
    return getTypeData(providerData[coverage])
  }
}

const calculateContribution = (
  provider: string,
  type: string,
  coverage: string,
  data: BenefitContributionAmounts
): number | string => {
  if (provider === 'delta_dental') {
    return '0'
  } else if (provider === 'principal') {
    return '80%'
  }

  const providerData = data[provider]
  if (!providerData) return 0

  const getTypeData = (typeData: Record<string, number | string> | number | string): number | string => {
    if (typeof typeData === 'string') {
      return typeData
    } else if (typeof typeData === 'object') {
      const coverageData = typeData[coverage]
      if (typeof coverageData === 'string') {
        return coverageData
      }
    }

    return 0
  }

  if (type !== null && type.length !== 0) {
    const typeData = providerData[type]
    return getTypeData(typeData)
  } else {
    return getTypeData(providerData[coverage])
  }
}

export const calculateHealthcareAmount = (
  provider: string,
  type: string,
  coverage: string
): { amount: number; contribution: string } => {
  const amount = calculateAmount(provider, type, coverage, healthcareAmounts)
  const contribution = calculateContribution(provider, type, coverage, healthcareContributionAmounts)
  const contributionString = typeof contribution === 'number' ? contribution.toString() : contribution

  return { amount, contribution: contributionString }
}

export const calculateDentalAmount = (
  provider: string,
  type: string,
  coverage: string
): { amount: number; contribution: string } => {
  const amount = calculateAmount(provider, type, coverage, dentalAmounts)
  const contribution = calculateContribution(provider, type, coverage, dentalContributionAmounts)
  const contributionString = typeof contribution === 'number' ? contribution.toString() : contribution

  return { amount, contribution: contributionString }
}

export const calculateVisionAmount = (
  provider: string,
  type: string,
  coverage: string
): { amount: number; contribution: string } => {
  const amount = calculateAmount(provider, type, coverage, visionAmounts)
  const contribution = calculateContribution(provider, type, coverage, visionContributionAmounts)
  const contributionString = typeof contribution === 'number' ? contribution.toString() : contribution

  return { amount, contribution: contributionString }
}

export const calculateFSAAndRetirementContribution = (coverage: string, payfrequency: string): number => {
  const totalWeeks = DURATION_MULTIPLIER[payfrequency]
  const numericCoverage = parseFloat(coverage)

  return isNaN(numericCoverage) ? 0 : parseFloat((numericCoverage / totalWeeks).toFixed(2))
}

export const calculateBenefitsContribution = (provider: string): string => {
  return benefitsContributionAmounts[provider]
}

export const getBenefitData = async (employee_id: number): Promise<any> => {
  try {
    const benefits = await axios.get(`/${I18n.locale}/employers/${employee_id}/get_benefits_company_details`)
    return benefits.data
  } catch (error) {
    toast.error(error)
  }
}

interface BenefitOptions {
  providerOptions: string[]
  typeOptions: { option: string; value: string }[]
  coverageOptions: { option: string; value: string }[]
  annualContributionLimit?: string | undefined
}

export const getBenefitOptions = (
  companyName: string,
  benefitType: string,
  benefitDataCompany?: any
): BenefitOptions => {
  let providerOptions: string[] = []
  let typeOptions: { option: string; value: string }[] = []
  let coverageOptions: { option: string; value: string }[] = []

  if (benefitDataCompany[companyName]?.[benefitType]) {
    providerOptions = benefitDataCompany[companyName][benefitType].providerOptions
    typeOptions = benefitDataCompany[companyName][benefitType].typeOptions
  } else {
    providerOptions = ['Dean', 'GHC']
    typeOptions = [{ option: 'HMO', value: 'hmo' }, { option: 'POS', value: 'pos' }]
  }

  coverageOptions = determineCoverage(benefitType)

  return { providerOptions, typeOptions, coverageOptions }
}

const determineCoverage = (benefitType: string): { option: string; value: string }[] => {
  if (benefitType === 'health') {
    return healthcareCoverageOptions
  } else if (benefitType === 'dental') {
    return dentalCoverageOptions
  } else if (benefitType === 'vision') {
    return visionCoverageOptions
  } else {
    return []
  }
}

export const replaceWhitespaceWithUnderscore = (inputString: string): string =>
  inputString?.toLowerCase()?.replace(WHITE_SPACE_REGEX, '_')
