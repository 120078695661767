import React, { memo } from 'react'

import { type FSABenefitProps } from '../../../EmployeeCalculatorInterface'
import BenefitsContributionsDetails from '../BenefitsContributionsDetails'

const DependentCareFSA: React.FC<FSABenefitProps> = memo((props) => {
  return (
    <BenefitsContributionsDetails
      {...props}
      componentName='Dependent Care Flexible Spending Account'
      benefitKey='dependentCare'
    />
  )
})

export default DependentCareFSA
