import React, { type FC, useState } from 'react'
import { Checkbox, FormControl, FormControlLabel } from '@material-ui/core'

import InputField from '../../InputField'
import './styles.sass'

const _ = require('lodash')

interface TANFProps {
  contextValues: any
  setContextValues: Function
  state: string
}

const TANF: FC<TANFProps> = ({ contextValues, setContextValues, state }) => {
  const [monthReceived, setMonthReceived] = useState<string | number>(contextValues?.tanfMonthsReceived ?? 0)
  const [csp, setCsp] = useState({})

  const handleSave = (): void => {
    setContextValues({
      ...contextValues
    })
  }

  const handleCSPChange = (event: React.ChangeEvent<HTMLInputElement>, index: number | string): void => {
    setCsp({ ...csp, [index]: event.target.checked })
    _.set(contextValues, ['adults', index, 'csp'], event.target.checked)
    handleSave()
  }

  const handleInputChange = (inputValue: number | string): void => {
    setMonthReceived(inputValue)
    _.set(contextValues, 'tanfMonthsReceived', inputValue)
    handleSave()
  }

  const renderCSPInputs = (index: number | string): JSX.Element => {
    _.get(contextValues, ['adults', index, 'csp'], false)

    return (
      <FormControl key={index}>
        <FormControlLabel
          label={`${index == 0 ? 'Do you' : 'Does ' + (_.get(contextValues, ['adults', index, 'name']) || ` Adult ${index}`)} participate in the W-2 Community Service Program?`}
          labelPlacement='start'
          control={
            <Checkbox
              checked={_.get(contextValues, ['adults', index, 'csp'])}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleCSPChange(event, index) }}
            />
          }
        />
      </FormControl>
    )
  }

  const showMonthsInput = (): boolean => !(['PA', 'DC'].includes(state))

  return (
    <div className='tanf-benefit'>
      {showMonthsInput() && <div className='tanf-input'>
        <p className='question-heading'>How many months your household has received TANF from 48-months lifetime limit:</p>
        <InputField
          onChange={(inputValue: number | string) => { handleInputChange(inputValue) }}
          value={monthReceived}
        />
      </div>}

      <h4>Participation in the W-2 Community Service Program</h4>
      {Object.keys(_.get(contextValues, ['adults']) ?? {}).map((key: number | string) => renderCSPInputs(key))}

    </div>
  )
}

export default TANF
