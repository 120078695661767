import React, { useState, useEffect, useRef } from 'react'
import * as d3 from 'd3'

interface BarChartProps {
  chartId: string
  data: DataProps[]
  width?: number
  height?: number
}

interface DataProps {
  age: number
  amount: number
}

const BarChart: React.FC<BarChartProps> = (props) => {
  const [originalWidth] = useState<number>(props.width || 400)
  const [width, setWidth] = useState<number>(originalWidth)
  const [height] = useState<number>(props.height || 600)
  const parentDiv = useRef<HTMLDivElement>(null)

  const margin = { top: 30, right: 50, bottom: 60, left: 50 }
  const graphWidth = width - (margin.left + margin.right)
  const graphHeight = height - (margin.top + margin.bottom)

  const x = d3
    .scaleBand()
    .domain(props.data.map((d: DataProps) => d.age.toString()))
    .range([0, graphWidth])
    .padding(0.1)

  const y = d3
    .scaleLinear()
    .domain([0, d3.max(props.data, (d: DataProps) => d.amount) || 0])
    .nice()
    .range([graphHeight, 0])

  const handleResize = () => {
    if (parentDiv.current) {
      const parentWidth = parentDiv.current.clientWidth
      if (parentWidth < originalWidth) {
        setWidth(parentWidth)
      } else {
        setWidth(originalWidth)
      }
    }
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    handleResize()
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <div>
      <h1>Bar Chart</h1>
      <div ref={parentDiv} className='bar-chart' style={{ height: `${height}px` }}>
        <svg id={props.chartId} width={width} height={height}>
          <g transform={`translate(${margin.left}, ${margin.top})`}>
            {props.data.map((d: DataProps, i: number) => (
              <rect
                key={i}
                x={x(d.age.toString())!}
                y={y(d.amount)}
                width={x.bandwidth()}
                height={graphHeight - y(d.amount)}
                fill='steelblue'
              />
            ))}
            <g
              transform={`translate(0, ${graphHeight})`}
              ref={(node) => d3.select(node!).call(d3.axisBottom(x))}
            />
            <g ref={(node) => d3.select(node!).call(d3.axisLeft(y))} />
          </g>
        </svg>
      </div>
    </div>
  )
}

export default BarChart
export type { BarChartProps }
