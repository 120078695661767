import React, { FC, useState } from 'react'
import { KeyboardArrowUp, KeyboardArrowDown } from '@mui/icons-material'
import { Radio, RadioGroup, FormControlLabel } from '@mui/material'

interface SelectInputProps {
  options: string[]
  placeholderProp: string
  value?: any
  setValue?: any
  showErrorMsg?: boolean
  errorMsg?: string
}

import './styles.sass'

const SelectInput: FC<SelectInputProps> = ({ options, placeholderProp, value, setValue, showErrorMsg, errorMsg }) => {
  const [arrowUp, setArrowUp] = useState(value ?? false)
  const [placeholder, setPlaceholder] = useState(placeholderProp)

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPlaceholder((event.target as HTMLInputElement).value)
    setArrowUp(!arrowUp)
    setValue((event.target as HTMLInputElement).value)
  }

  const renderOptions = (text: string) => <FormControlLabel value={text} control={<Radio />} label={text} />

  return (
    <>
      <div className={`select-input d-flex flex-column ${showErrorMsg ? 'show-error-select-input' : ''}`}>
        <div className='d-flex justify-space-between heading' onClick={() => setArrowUp(!arrowUp)}>
          <h4>{arrowUp ? placeholderProp : placeholder}</h4>
          {arrowUp ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
        </div>
        <div className='d-flex flex-column'>
          <RadioGroup defaultValue='' value={value} onChange={handleChange}>
            {arrowUp && options.map((text: string) => renderOptions(text))}
          </RadioGroup>
        </div>
      </div>
      {showErrorMsg && <p className='error-msg'>{errorMsg}</p>}
    </>
  )
}

export default SelectInput
