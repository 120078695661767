import React, { Context, useContext, useState, useEffect } from 'react'
import { FormControl, InputLabel, Select, MenuItem, SelectChangeEvent } from '@mui/material'

import LineChart from '../../LineChart'
import BarCharts from '../../BarCharts'
import { CHART_HEIGHT, CHART_WIDTH } from '../../../Constants'
import { FormValues } from '../../../FormValuesInterfaces'
import { toast } from 'react-toastify'

interface DataItem {
  age: number
  amount: number
}

interface ChartDashboardProps {
  selectedItem: { name: string, id: number, description: string }
  adminValuesContext: Context<FormValues>
}

const ChartDashboard: React.FC<ChartDashboardProps> = ({ adminValuesContext }) => {
  const formValues = useContext(adminValuesContext)
  const [netIncome, setIncome] = useState<DataItem[]>([])
  const [chartType, setChartType] = useState<string>('bar')
  useEffect(() => {
    if (formValues?.adminScenariosData) {
      const adminScenariosData = formValues.adminScenariosData;
      const allIncome: DataItem[] = [];

      Object.values(adminScenariosData).forEach((scenarioData) => {
        const { income, child_ages } = scenarioData;
        if (income && income.programs_sum !== undefined) {
          if (Array.isArray(child_ages) && child_ages.length > 0) {
            const youngestChildAge = Math.min(...child_ages)
            const scenarioWagesData = {
              age: youngestChildAge,
              amount: Math.round(income.programs_sum),
            }
            allIncome.push(scenarioWagesData)
          } else {
            toast.error("Child ages are empty");
          }
        } else {
          toast.error("Income is not defined.");
        }
      })

      setIncome(allIncome)
    }

  }, [formValues])
  const handleChartTypeChange = (event: SelectChangeEvent<string>) => {
    setChartType(event.target.value)
  }

  return (
    <div className='dashboardContainer'>
      <FormControl fullWidth margin='normal'>
        <InputLabel id='chart-type-label'>Select Chart Type</InputLabel>
        <Select
          labelId='chart-type-label'
          id='chart-type-select'
          value={chartType}
          label='Select Chart Type'
          onChange={handleChartTypeChange}
        >
          <MenuItem value='bar'>Bar Chart</MenuItem>
          <MenuItem value='line'>Line Chart</MenuItem>
        </Select>
      </FormControl>
      {chartType === 'bar' && (
        <BarCharts chartId='bar-chart' width={CHART_WIDTH} height={CHART_HEIGHT} data={netIncome} />
      )}
      {chartType === 'line' && (
        <LineChart
          chartId='line-chart'
          width={CHART_WIDTH}
          height={CHART_HEIGHT}
          rawData={netIncome}
        />
      )}
    </div>
  )
}

export default ChartDashboard
