import React, { useState, useContext, type Dispatch, type SetStateAction, type ChangeEvent, useEffect, type Context } from 'react'
import Select from 'react-select'
import { FormControl, CircularProgress, FormControlLabel, Checkbox } from '@material-ui/core/'

import ErrorMessage from '../ErrorMessage'
import { validate } from '../../formValidations/benefitsCliff/stepOne'
import { convertObjectToArray, getMarriedAdultOptions, getSelectdValue, scrollToTop, sliceMarried } from '../../Helper'
import { DISABLED_BENEFITS, NUMBER_OF_ADULT, NUMBER_OF_CHILDREN, PARSLEY_EMAIL } from '../../Constants'

import '../../component_styles/BenefitsCliff.sass'
import { type FormValues } from '../../FormValuesInterfaces'
import BenefitsCiffCalculatorModal from './BenefitsCliffCalculatorModal'
import NavigationButtons from './NavigationButtons'

interface StepOneProps {
  formValuesContext: Context<FormValues>
  setValues: Dispatch<SetStateAction<FormValues>>
  handleNext: () => void
  handleBack: () => void
  step: number
  usStates: { label: string, value: string }
  wiCounties: string[]
}

const StepOne: React.FC<StepOneProps> = (props) => {
  const states = props.usStates
  const formValues: FormValues = useContext(props.formValuesContext)
  const [state, setState] = useState(formValues?.state ?? { label: 'Wisconsin', value: 'WI' })
  const [isLoading, setIsLoading] = useState(false)
  const [counties] = useState(props.wiCounties)
  const [county, setCounty] = useState(formValues?.county ?? 'Dane')
  const [cityOfMadison, setCityOfMadison] = useState(formValues?.cityOfMadison ?? false)
  const [childrenCount, setChildrenCount] = useState(formValues?.childrenCount ?? 1)
  const [adultsCount, setAdultsCount] = useState(formValues?.adultsCount ?? 2)
  const { children: childrenObj = {} } = formValues
  const [adults, setAdults] = useState(formValues.adults ?? {})
  const [isMarried, setIsMarried] = useState(formValues?.isMarried ?? false)
  const [marriedStatus, setMarriedStatus] = useState(formValues?.marriedStatus ?? {})
  const [marriedAdultOptions, setMarriedAdultOptions] = useState<Array<{ label: string, value: string }>>([])
  const [localMarried, setLocalMarried] = useState([])

  const [errors, setErrors] = useState({
    state: false,
    county: false,
    adultsCount: false,
    maritalStatus: false
  })

  useEffect(() => {
    isMarried &&
      setMarriedAdultOptions(getMarriedAdultOptions(adultsCount, adults))
  }, [isMarried])

  const resetMaritalStatus = (): void => {
    setIsMarried(false)
    const updatedAdultsObj = { ...adults }

    Object.keys(updatedAdultsObj).forEach((_adult, key) => {
      if (updatedAdultsObj[key].married) {
        const { marriedWith, married, ...rest } = updatedAdultsObj[key]
        updatedAdultsObj[key] = rest
      }
    })

    setAdults(updatedAdultsObj)
  }

  useEffect(() => {
    if (formValues?.b2bCalculator && formValues?.adults[0]?.filingStatus === 'married') {
      setIsMarried(true)
      setAdultsCount(2)
    }
  }, [formValues?.b2bCalculator])

  const handleOnChange = (e: any, field: string): void => {
    setErrors({ ...errors, ...validate(e, field) })

    if (field === 'state') setState(e.value)

    if (field === 'county') setCounty(e.value)

    if (field === 'adultsCount') {
      if (e.value === 1) {
        resetMaritalStatus()
      }

      setAdultsCount(e.value)
      setErrors({ ...errors, maritalStatus: false })
    }
  }

  const convertToOptions = (counties: string[]): Array<{ label: string, value: string }> => {
    let convertedCounties: Array<{ label: string, value: string }> = []
    counties.map((county: string) => convertedCounties.push({ value: county, label: county }))
    return convertedCounties
  }

  const saveUpdatedData = (): void => {
    const formValuesUpdated = {
      ...formValues,
      state,
      county,
      cityOfMadison,
      childrenCount,
      adultsCount,
      children: Object.values(childrenObj).slice(0, childrenCount),
      adults: Object.values(adults).slice(0, adultsCount),
      isMarried,
      marriedStatus,
      breastfeeding: formValues.breastfeeding
    }

    childrenCount === 0 && DISABLED_BENEFITS.forEach(benefit => {
      if (formValues?.[benefit]) {
        (formValuesUpdated as FormValues)[benefit] = false
      }
    })

    props.setValues(formValuesUpdated)
  }

  const handleSubmit = (): void => {
    if (isMarried && !Object.values(adults).some(adult => adult?.marriedWith)) {
      setErrors({ ...errors, maritalStatus: true })
      return
    }
    props.handleNext()
    saveUpdatedData()
  }

  const isNextDisabled = (): boolean => state === '' && county === ''

  scrollToTop()

  const renderMartialInput = (index: number): JSX.Element => (
    <>
      <div className='step-one-input' key={index}>
        <p>Martial Status: </p>
        <Select
          className='basic-single'
          classNamePrefix='select'
          placeholder={'Select Martial Status'}
          onChange={(e) => {
            setAdults({ ...adults, [index]: { ...adults[index], marriedWith: e.value, married: true } })
            setLocalMarried(sliceMarried(marriedAdultOptions, e))
            setErrors({ ...errors, maritalStatus: false })
          }}
          isSearchable={false}
          name='state'
          options={marriedAdultOptions}
          value={getSelectdValue(adults[index]?.marriedWith, marriedAdultOptions)}
          required={isMarried && errors.maritalStatus}
        />

        {adults[index]?.married && adultsCount > 1 &&
          <>
            <p className='text-align-center'>married to</p>
            <Select
              className='basic-single'
              classNamePrefix='select'
              placeholder={'Select Martial Mtatus'}
              onChange={(e) => {
                setAdults({ ...adults, [index + 1]: { ...adults[index + 1], marriedWith: e.value, married: true } })
                setErrors({ ...errors, maritalStatus: false })
              }}
              isSearchable={false}
              name='state'
              options={localMarried}
              value={getSelectdValue(adults[index + 1]?.marriedWith, marriedAdultOptions)}
              required={isMarried && errors.maritalStatus}
            />
          </>
        }
      </div>
      {errors.maritalStatus && <p className='error-message'>Please select a value for marital status.</p>}
    </>
  )

  return (
    <>
      {formValues?.b2bCalculator && <BenefitsCiffCalculatorModal />}
      <div className='step-one'>
        <div className='testing-note'>
          <h1 className='testing-note-heading'>Thanks for Using Parsley! This is a tool for estimating net income after expenses with eligibility for public benefits.</h1>
          <p>Parsley is a modeling tool and all results provided are estimates and should not be misconstrued as financial advice.  To confirm eligibility for specific government programs, contact program offices directly.</p>
          <p className='mobile-line-hight'>
            <b className='bold-text'>State of Wisconsin Programs included are:</b> BadgerCare and Medicaid for the Elderly or Disabled, ACA Marketplace Premium Tax Credits, FoodShare (SNAP), WIC, Wisconsin Shares Child Care Tuition Subsidy, HeadStart, Early HeadStart, 4-Year-Old Kindergarten, Lifeline, Reduced Fare Bus Passes for select local communities, State Tax Credits, Earned Income Tax Credit, Child Tax Credit, Child and Dependent Care Tax Credit.&nbsp;
            <b className='bold-text'>Parsley does not estimate eligibility for Supplemental Security Income, Social Security Disability Insurance, Unemployment Insurance, or Medicare Programs.</b>
          </p>
          <p>
            <b className='bold-text'>At this time, Parsley may not provide accurate results for the following populations:</b> those with veteran status, disability status, justice-involvement, foster care youth or youth aging out of foster care, those who are, or have been, recently homeless, those who are not U.S. citizens, or in some cases, adult students or minor parents. We are rapidly adding information to our database, and hope to update these areas soon.
          </p>
          <div className='mobile-line-hight'>
            For more information, email us at <a href={`https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=${PARSLEY_EMAIL}`} target='_blank' className='email_link' rel='noreferrer'>{PARSLEY_EMAIL}</a>.
          </div>
        </div>
        <h3>Location and Family Size</h3>
        <FormControl variant='outlined'>
          <div className='horizontal-input'>
            <p>What is your State?</p>
            <div className='errors-div'>
              <Select
                className='basic-single'
                placeholder={'Select State'}
                onChange={(e) => {
                  setIsLoading(true)
                  handleOnChange(e, 'state')
                }}
                isSearchable={false}
                options={Object.values(states)}
                value={state}
                isDisabled
              />
              <ErrorMessage text={errors.state} />
            </div>
          </div>

          {isLoading && <div className='loader'><CircularProgress /></div>}
          {!isLoading && state && <>
            <div className='horizontal-input'>
              <p>Select your County:</p>
              <div className='errors-div'>
                <Select
                  className='basic-single'
                  placeholder={'Select County'}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => { handleOnChange(e, 'county') }}
                  isSearchable={true}
                  options={convertToOptions(counties)}
                  value={getSelectdValue(county, convertToOptions(counties))}
                />
                <ErrorMessage text={errors.county} />
              </div>
            </div>

            {
              county === 'Dane' &&
              <div className='city-check-box'>
                <FormControlLabel
                  labelPlacement='start'
                  onChange={(e: ChangeEvent<{}>) => {
                    const checkbox = e.target as HTMLInputElement
                    setCityOfMadison(checkbox.checked)
                  }}
                  control={<Checkbox checked={cityOfMadison} />}
                  label='Do you live in the City of Madison?'
                />
              </div>
            }

            {formValues?.b2bCalculator &&
              <p className='dependent-notes'>
                Changing prefilled inputs you’ve already entered in the pay calculator may change your amount of taxes owed or refunds or the the amount withheld for employer-provided benefits.
              </p>
            }

            <div className='horizontal-input'>
              <p> Number of family members over 18: </p>
              <div className='errors-div'>
                <Select
                  className='basic-single'
                  placeholder={'Select Over 18'}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => { handleOnChange(e, 'adultsCount') }}
                  isSearchable={true}
                  options={NUMBER_OF_ADULT}
                  value={getSelectdValue(adultsCount, NUMBER_OF_ADULT)}
                />
                <ErrorMessage text={errors.adultsCount} />
              </div>
            </div>

            <div className='horizontal-input'>
              <p> Number of family members under 18: </p>

              <Select
                className='basic-single'
                placeholder={'Select Under 18'}
                onChange={e => { setChildrenCount(e.value) }}
                isSearchable={true}
                options={NUMBER_OF_CHILDREN}
                value={getSelectdValue(childrenCount, NUMBER_OF_CHILDREN)}
              />
            </div>
            {adultsCount > 1 &&
              <div className='marital-div'>
                <FormControlLabel
                  labelPlacement='start'
                  onChange={(e: ChangeEvent<{}>) => {
                    const CheckBox = e.target as HTMLInputElement
                    if (!CheckBox.checked) {
                      resetMaritalStatus()
                    }
                    setIsMarried(CheckBox.checked)
                    setMarriedStatus({})
                  }}
                  control={<Checkbox checked={isMarried} />}
                  label={'Is anyone married in your household?'}
                />
              </div>
            }

            {isMarried &&
              <div className='marital-status'>
                {convertObjectToArray(adultsCount / 2).map((_child, index: number) => renderMartialInput(index))}
              </div>
            }
          </>}
        </FormControl>
      </div>

      <NavigationButtons
        handleBack={props.handleBack}
        handleSubmit={handleSubmit}
        isNextDisabled={isNextDisabled}
        step={props.step}
        saveUpdatedData={saveUpdatedData}
      />
    </>
  )
}

export default StepOne
export type { StepOneProps }
