import React from 'react'
import '../component_styles/HamburgerMenuItem.sass'

interface HamburgerMenuItemProps {
  text: string
  href: string
  urlMethod?: string
  className?: string
}

const HamburgerMenuItem: React.FC<HamburgerMenuItemProps> = (props) => {
  const { text, href, urlMethod, className } = props

  return (
    <a href={ href } className={`menu-item ${className}`} data-method={ urlMethod ? urlMethod : "get" }>
      { text }
      { className && className.includes('primary-link') &&
        <i className="fas fa-chevron-right icon"></i>
      }
    </a>
  )
}

export default HamburgerMenuItem
export type { HamburgerMenuItemProps }
