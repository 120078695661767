import React from 'react'

import Link, { LinkProps } from './Link'

import '../component_styles/ColoredBackgroundHeader.sass'

interface ColoredBackgroundHeaderProps {
  title?: string
  description?: string
  headerBackgroundColor?: string
  date?: string
  headerLink?: LinkProps
  dot?: boolean
}

const ColoredBackgroundHeader: React.FC<ColoredBackgroundHeaderProps> = (props) => {
  const { headerBackgroundColor, title, dot, description, date, headerLink } = props

  return (
    <div className={`colored-background-header ${headerBackgroundColor}-background-color`}>
      {dot && <div className={`colored-dot`}></div>}
      <div className='content'>
        {headerLink &&
          <div className='clearfix'>
            <Link
              className='btn header-new-link'
              {...headerLink}
            />
            <h1 className='header-title'>{title}</h1>
          </div>
        }
        {!headerLink &&
          <h1 className='header-title'>{title}</h1>
        }
        {description &&
          <div
            className='description'
            dangerouslySetInnerHTML={{ __html: description }}
          />
        }
        {date &&
          <div className='date'>{date}</div>
        }
      </div>
    </div>
  )
}

export default ColoredBackgroundHeader
export type { ColoredBackgroundHeaderProps }
