import * as React from 'react'
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow
} from '@mui/material'
import { Typography } from '@material-ui/core'

import { MAX_NPS_RATING } from '../../Constants'

import './styles.sass'

interface Props {
  visitedUsersCount: number
  npsFeedbackCount: number
  npsFeedbackRatings: Record<string, number>
}

const Statistics: React.FC<Props> = ({ visitedUsersCount, npsFeedbackCount, npsFeedbackRatings }): JSX.Element => {
  const data = [
    { name: 'Number of Users', value: visitedUsersCount },
    { name: 'Number of Survey Responses', value: npsFeedbackCount },
    ...Array.from({ length: MAX_NPS_RATING }, (_, index) => {
      const rating = MAX_NPS_RATING - index
      return {
        name: `${rating} out of ${MAX_NPS_RATING} Responses`,
        value: npsFeedbackRatings[rating]
      }
    })
  ]

  return (
    <Box className='user-activity-display'>
      <Typography paragraph gutterBottom className='user-activity-heading'>User Activity Data Statistics</Typography>
      <TableContainer className='user-data-table'>
        <Table>
          <TableBody>
            {data.map((row, index) => (
              <TableRow key={index} className='user-data-row'>
                <TableCell><b>{row.name}</b></TableCell>
                <TableCell className='data-value-cell'>{row.value}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

export default Statistics
