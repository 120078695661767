import React from 'react'

import '../component_styles/DividingColorLines.sass'

const DividingColorLines: React.FC = () => (
  <>
    <div className='blue-colored-line'></div>
    <div className='orange-colored-line'></div>
    <div className='red-colored-line'></div>
  </>
)

export default DividingColorLines
