import React, { useState } from 'react'

import FileUploader from './FileUploader'
import EmployeeList, { type EmployeeData } from './EmployeeList'

import '../../component_styles/EmployerDashboard.sass'

const EmployerDashboard = ({ employees }: { employees: EmployeeData[] }): JSX.Element => {
  const [userEmployees, setUserEmployees] = useState(employees)

  return (
    <div className='employer-dashboard'>
      <div className='employer-actions'>
        <FileUploader setEmployees={setUserEmployees} />
      </div>
      <EmployeeList employees={userEmployees} />
    </div>
  )
}

export default EmployerDashboard
