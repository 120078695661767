import React, { useEffect, type Dispatch, type SetStateAction } from 'react'
import { Button, Modal } from '@mui/material'

import './styles.sass'

interface Props {
  modalOpen?: boolean
  setModalOpen?: Dispatch<SetStateAction<boolean>>
  handleSaveAndBack: () => void
}

const BackButtonModal: React.FC<Props> = (props) => {
  const { modalOpen, setModalOpen, handleSaveAndBack } = props

  const handleModalClose = (): void => {
    setModalOpen?.(false)
  }

  const handleKeyDown = (event: KeyboardEvent): void => {
    if (event.key === 'Enter') {
      handleSaveAndBack()
    }
  }

  useEffect(() => {
    if (modalOpen) {
      window.addEventListener('keydown', handleKeyDown)
    }

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [modalOpen, handleSaveAndBack])

  return (
    <Modal open={modalOpen ?? false} onClose={handleModalClose}>
      <div className='modal-inner-content' >
        <p>Do you want to save your inputs before going back?</p>
        <div className='buttons-container'>
          <Button size='small' onClick={handleSaveAndBack}>Save & Back</Button>
          <Button size='small' onClick={handleModalClose}>Cancel</Button>
        </div>
      </div>
    </Modal>
  )
}

export default BackButtonModal
