import React, { type Context, type Dispatch, memo, useContext } from 'react'
import PurpleButton from './PurpleButton'
import OutlinedButton from './OutlinedButton'
import { type FormValues } from '../../FormValuesInterfaces'
import { type BenefitData } from '../../EmployeeCalculatorInterface'
interface SelectDeselectButtonGroupProps {
  formValuesContext: Context<FormValues>
  setValues: Dispatch<FormValues>
  benefitData: BenefitData
  setIsSelected: (value: boolean) => void
  error?: boolean
}

const SelectDeselectButtonGroup: React.FC<SelectDeselectButtonGroupProps> = memo((props) => {
  const {
    formValuesContext,
    setValues,
    benefitData,
    setIsSelected,
    error
  } = props
  const formValues: FormValues = useContext(formValuesContext)
  const handleSelect = (): void => {
    const formValuesUpdated = {
      ...formValues,
      adults: {
        ...formValues.adults,
        0: {
          ...formValues.adults[0],
          [benefitData.name as string]: {
            name: benefitData.name,
            amount: benefitData.amount,
            provider: benefitData.provider,
            type: benefitData?.type,
            coverage: benefitData.coverage,
            contribution: benefitData.contribution,
            isSelected: true
          }
        }
      }
    }

    setValues(formValuesUpdated)
    setIsSelected(true)
  }

  const handleDeselect = (): void => {
    const defaultBenefitData: BenefitData = {
      name: benefitData.name,
      provider: '',
      amount: 0,
      type: '',
      coverage: '',
      contribution: '',
      isSelected: false
    }

    const formValuesUpdated = {
      ...formValues,
      adults: {
        ...formValues.adults,
        0: {
          ...formValues.adults[0],
          [benefitData.name as string]: defaultBenefitData
        }
      }
    }

    setValues(formValuesUpdated)
    setIsSelected(false)
  }

  const renderRegularButtons = (): JSX.Element => (
    benefitData.isSelected
      ? (
        <PurpleButton label='Deselect' width='250px' buttonClick={handleDeselect} />
        )
      : (
        <OutlinedButton label='Select' width='250px' btnClick={handleSelect} disabled={error} />
        )
  )

  return (
    renderRegularButtons()
  )
})

export default SelectDeselectButtonGroup
