import React, { useState, useEffect } from 'react'
import { TextField } from '@material-ui/core'
import { ToastContainer } from 'react-toastify'
import CreatableSelect from 'react-select/creatable'
import LoadingButton from '@mui/lab/LoadingButton'
import Select from 'react-select'

import { createNewCategory, updateCategory } from '../api/category'
import { DROPDOWN_COLOR_OPTIONS, DROPDOWN_COLOR_STYLES } from '../Constants'
import { getImage, checkPresenceOfImgNText, getSelectedColor } from '../Helper'
import { ImageProps, SelectOptions } from './QuickPeekEditor'
import { validate } from '../formValidations/category'
import ImageSelector from './ImageSelector'

import '../component_styles/Category.sass'
import 'react-toastify/dist/ReactToastify.css'

const _ = require('lodash')

interface CategoryProps {
  category: CategoryTypeProps
  selectedCategoryTags: string[] | string | undefined
  categoryTags: string[] | string | undefined
  categoriesImages: ImageProps[]
  categoriesIcons: ImageProps[]
}

interface CategoryTypeProps {
  id: number
  title: string
  icon_id: number | string | undefined
  image_id: number | string | undefined
  icon_url: string | undefined
  image_url: string | undefined
  sub_heading: string
  color: string
  tags: string[]
  image_alt_text: string
  icon_alt_text: string
}

const Category: React.FC<CategoryProps> = ({ categoriesIcons, categoriesImages, selectedCategoryTags, categoryTags, category }) => {
  const [categoryTitle, setCategoryTitle] = useState(category.title)
  const [categorySubHeading, setCategorySubHeading] = useState(category.sub_heading)
  const [categoryColor, setCategoryColor] = useState(category.color)
  const [categoryIcon, setCategoryIcon] = useState<number | string>()
  const [categoryImage, setCategoryImage] = useState<number | string>()
  const [destroyTags, setDestroyTags] = useState([])
  const [errors, setErrors] = useState({ category: false, subHeading: false, categoryColor: false, selectedTags: false })
  const [icon, setIcon] = useState()
  const [image, setImage] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const [iconAltText, setIconAltText] = useState<string>()
  const [imageAltText, setImageAltText] = useState<string>()

  const convertOptions = (options: any,) => {
    if (Array.isArray(options) && options.length > 0) {
      return options.map((option: SelectOptions) => ({
        value: option.id,
        label: option.tag
      }))
    } else {
      return []
    }
  }

  const [selectedTags, setSelectedTags] = useState(convertOptions(selectedCategoryTags))
  const [unSelectedTags, setUnSelectedTags] = useState(convertOptions(categoryTags))

  useEffect(() => {
    if (category) {
      categoryIcon ?? setCategoryIcon(getImage(categoriesIcons, category.icon_id)?.link)
      iconAltText ?? setIconAltText(getImage(categoriesIcons, category.icon_id)?.alt_text)
      categoryImage ?? setCategoryImage(getImage(categoriesImages, category.image_id)?.link)
      imageAltText ?? setImageAltText(getImage(categoriesImages, category.image_id)?.alt_text)
      selectedTags ?? setSelectedTags(selectedTags)
    }
  }, [category, categoriesIcons, categoriesImages])

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement> | any, field: string) => {
    setErrors({ ...errors, ...validate(e, field) })

    if (field === 'category') setCategoryTitle(e?.target?.value)
    if (field === 'subHeading') setCategorySubHeading(e?.target?.value)
    if (field === 'selectedTags') {
      setSelectedTags(e)
      setDestroyTags(_.differenceBy(selectedCategoryTags, convertHash(e), 'tag'))
      setUnSelectedTags(convertOptions(categoryTags))
    }
    if (field === 'color') setCategoryColor(e?.value)

    setUnSelectedTags(convertOptions(categoryTags))
  }

  const cantSendRequest = () => {
    if (categoryTitle?.length > 0 && categorySubHeading?.length > 0 && categoryColor?.length > 0 && selectedTags?.length > 0 && checkPresenceOfImgNText(imageAltText, categoryImage) && checkPresenceOfImgNText(iconAltText, categoryIcon)) {
      return false
    }
    return true
  }

  const addDestroyAbleTag = (tags: any) =>
    tags && tags.length > 0 &&
    tags.map((t: SelectOptions) => ({ id: t.id, tag: t.tag, _destroy: true }))

  const convertHash = (tags: any) =>
    tags && tags.length > 0 &&
    tags.map((t: SelectOptions) => t.__isNew__ ? ({ tag: t.label }) : ({ id: t.value, tag: t.label }))

  const handleCreate = () => {
    const categoryData = {
      title: categoryTitle,
      color: categoryColor,
      sub_heading: categorySubHeading,
      category_tags_attributes: [...convertHash(selectedTags), ...addDestroyAbleTag(destroyTags)],
    }
    setIsLoading(true)

    if (category?.title)
      updateCategory(category?.id, categoryData, icon, image, iconAltText, imageAltText, setIsLoading)
    else
      createNewCategory(categoryData, icon, image, iconAltText, imageAltText, setIsLoading)
  }

  return (
    <div className='create-new-category content'>
      <ToastContainer
        position='top-right'
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <h3>{category?.title ? 'Update Category' : 'Create New Category'}</h3>
      <div className='text-fields'>
        <TextField
          name='Category'
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleOnChange(e, 'category')}
          required
          margin='dense'
          error={errors.category}
          label='Category Name'
          variant='outlined'
          value={categoryTitle}
          inputProps={{ maxLength: 100 }}
        />

        <TextField
          name='Sub Heading'
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleOnChange(e, 'subHeading')}
          required
          margin='dense'
          error={errors.subHeading}
          label='Category Sub Heading'
          variant='outlined'
          value={categorySubHeading}
          inputProps={{ maxLength: 100 }}
        />

        <div className='create-select-margin'>
          <CreatableSelect
            classNamePrefix='select'
            isClearable
            isMulti
            onChange={(e: React.ChangeEvent<HTMLSelectElement> | any) => handleOnChange(e, 'selectedTags')}
            options={unSelectedTags}
            placeholder={'Category Tags'}
            value={selectedTags}
          />
          {errors.selectedTags && <p className='error-msg'>{errors.selectedTags}</p>}
        </div>

        <div>
          <Select
            classNamePrefix='select'
            isClearable
            onChange={(e: React.ChangeEvent<HTMLSelectElement> | any) => handleOnChange(e, 'color')}
            options={DROPDOWN_COLOR_OPTIONS}
            placeholder={'Select Color'}
            styles={DROPDOWN_COLOR_STYLES}
            value={getSelectedColor(categoryColor)}
          />
          {errors.categoryColor && <p className='error-msg'>{errors.categoryColor}</p>}
        </div>

        <ImageSelector
          allImages={categoriesIcons}
          displayText={'Category Icon'}
          imageAltText={iconAltText}
          imageId={category?.icon_id}
          image={categoryIcon}
          setAltText={setIconAltText}
          setURLImage={setCategoryIcon}
          setImage={setIcon}
        />

        <ImageSelector
          allImages={categoriesImages}
          displayText={'Category Image'}
          imageAltText={imageAltText}
          imageId={category?.image_id}
          image={categoryImage}
          setAltText={setImageAltText}
          setURLImage={setCategoryImage}
          setImage={setImage}
        />
      </div>

      <div className={`button-div ${cantSendRequest() || isLoading ? 'is-disabled' : ''}`}>
        <LoadingButton
          onClick={handleCreate}
          loadingPosition='center'
          variant='outlined'
          loading={isLoading}
          disabled={cantSendRequest()}
        >
          {category?.title ? 'Update Category' : 'Create Category'}
        </LoadingButton>
      </div>
    </div>
  )
}

export default Category
export type { CategoryProps, CategoryTypeProps }
