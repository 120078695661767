import { AVERAGE_WORK_HOURS_PER_WEEK } from '../Constants'
import { TSA403bData, dentalData, dependentCareData, healthcareData, medicalData, pensionData, visionData, type BenefitData, type employeeValues } from '../EmployeeCalculatorInterface'

export interface JobDetails {
  currentPayscale?: string
  currentWage?: number
  currentWorkweek?: number
  currentAnnualWage?: number
  overtimeWage?: number
  overtimeAvailable?: string
  overtimePayscale?: string
  overtimeWorkweek?: number
  tipsAmount?: number
  tipsAvailable?: string
  tipsPayscale?: string
}

export interface Children {
  age?: number
  ageInMonths?: number
  blindness?: boolean
  ccdfpayAmtM?: number
  ccdfPayscale?: string
  ccdfType?: string
  ccNobenefitPayscale?: string
  parent?: string | number
  dependentClaimant?: string
  childNobenefitAmtM?: number
  dayHoursChild?: []
  disability?: boolean
  gender?: string
  homeless?: boolean
  name?: string
  summerdayHoursChild?: []
  summerWeekHoursChild?: number
  memberOfNativeAmericanTribe?: boolean
  weekHoursChild?: number
  weekMonthChild?: []
}

export interface Adults {
  [x: number]: Array<{ key: string, value: string }>
  age: number
  alimonyPaidM?: number
  blindness?: boolean
  checkBusPassEligibility?: boolean
  childSupportPaidM?: number
  currentJobs?: JobDetails[]
  dependentChildLivesInHome?: string
  dependentChildAge?: number
  disability?: boolean
  gender?: string
  giftIncomeM?: number
  hasAlimony?: boolean
  hasChildSupport?: boolean
  hasGiftIncome?: boolean
  hasOtherIncome?: boolean
  hasSsi?: boolean
  hasUnemploymentIncome?: boolean
  homeless?: boolean
  isChildCareAvailable?: boolean
  isSingleParent?: boolean
  isPartOfRehabilitationProgram?: boolean
  isPrimaryCaretaker?: boolean
  isPrimaryCaretakerForChild?: boolean
  isRecievingUnemploymentCompensation?: boolean
  isUnableToWork?: boolean
  isVetern?: boolean
  jobs?: number
  jobTrainingProgram?: boolean
  married?: boolean
  marriedWith?: string
  name: string
  payFrequency?: string
  pregnant?: boolean
  publicTransport?: boolean
  ssiRecdMnth?: number
  student?: string
  traininghours?: number
  transdaysWeek?: number
  memberOfNativeAmericanTribe?: boolean
  unemploymentIncome?: number
  unemploymentWeeks?: number
  wasInFosterCare?: boolean
  isHighSchoolEnrolled?: boolean
  isHSEDEnrolled?: boolean
  isELLorABEEnrolled?: boolean
  isVocationalTrainingEnrolled?: boolean
  isTechnicalCollegeEnrolled?: boolean
  isOnTheJobTrainingEnrolled?: boolean
  isFourYearCollegeEnrolled?: boolean
  isFederalWorkStudyEnrolled?: boolean
  afterTaxAmount?: number
  annualTakeHomePayAmount?: number
  beforeTaxAmount?: number
  claimDependent?: boolean
  dependencyCount?: number
  employee?: employeeValues
  filingStatus?: string
  hoursPerWeek?: string
  isBenefitsInitialRender?: boolean
  selectAllBenefits?: boolean
  employerBenefits?: Array<{ label: string, value: string }>
  selectedBenefits?: string[]
  takeHomePayAmount?: number
  taxAmount?: string
  taxAppliedOnPay?: number
  dentalBenefitData?: BenefitData
  dependentCareBenefitData?: BenefitData
  healthBenefitData?: BenefitData
  medicalBenefitData?: BenefitData
  TSA403bBenefitData?: BenefitData
  pensionBenefitData?: BenefitData
  visionBenefitData?: BenefitData
}

export const b2bInitialState = {
  afterTaxAmount: 0,
  annualTakeHomePayAmount: 0,
  beforeTaxAmount: 0,
  claimDependent: true,
  dependencyCount: 0,
  employee: {
    id: 0,
    wage_period: 'hour',
    wage: 0,
    benefits_available: [''],
    pay_frequency: '',
    eligible_for_all_benefits: false,
    tips_available: false,
    ot_available: false,
    employee_change: false,
    scheduled_hours: AVERAGE_WORK_HOURS_PER_WEEK,
    hours_range: [0],
    ot_pay_rate: 0,
    ot_hours_range: [0],
    job_title: '',
    company_name: ''
  },
  filingStatus: 'single',
  hoursPerWeek: '',
  isBenefitsInitialRender: true,
  isInitialRender: true,
  payFrequency: '',
  selectAllBenefits: false,
  selectedBenefits: [''],
  takeHomePayAmount: 0,
  taxAmount: '',
  healthBenefitData: healthcareData.healthBenefitData,
  visionBenefitData: visionData.visionBenefitData,
  dentalBenefitData: dentalData.dentalBenefitData,
  dependentCareBenefitData: dependentCareData.dependentCareBenefitData,
  medicalBenefitData: medicalData.medicalBenefitData,
  pensionBenefitData: pensionData.pensionBenefitData,
  TSA403bBenefitData: TSA403bData.TSA403bBenefitData
}

export const jobDetailsInitialState = {
  currentPayscale: '',
  currentWage: 0,
  currentWorkweek: 0,
  currentAnnualWage: 0,
  overtimeWage: 0,
  overtimeAvailable: 'no',
  overtimePayscale: 'week',
  overtimeWorkweek: 0,
  tipsAvailable: 'no',
  tipsAmount: 0,
  tipsPayscale: 'week'
}
