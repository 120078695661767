import React, { useEffect, useState } from 'react'
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField
} from '@material-ui/core'
import { ArrowDropUp, ArrowDropDown, Edit, DeleteOutline } from '@mui/icons-material'
import { styled } from '@mui/material/styles'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  tableCellClasses
} from '@mui/material'
import { ToastContainer } from 'react-toastify'

import { CategoryTypeProps } from '../Category'
import { deleteQuickPeek, togglePublishStatus } from '../../api/quickPeekAdmin'
import { getRelatedCategory, goToURL } from '../../Helper'
import { QuickPeekCardProps } from '../QuickPeekCard'
import CustomTableFooter from '../CustomTableFooter'
import I18n from '../../config/I18n'

import './styles.sass'
import 'react-toastify/dist/ReactToastify.css'

const _ = require('lodash')

interface QuickPeekAdminIndexProps {
  qpCards: QuickPeekCardProps[]
  categories: CategoryTypeProps[]
}

const QuickPeekAdminIndex: React.FC<QuickPeekAdminIndexProps> = ({ qpCards, categories }) => {
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(25)
  const [quickPeekCards, setQuickPeekCards] = useState(qpCards)
  const [cards, setCards] = useState(quickPeekCards)
  const [searchQP, setSearchQP] = useState('')
  const [sortOrder, setSortOrder] = useState<Record<string, boolean>>({
    category: false,
    status: false,
    tier: false,
    title: false
  })
  const [modalOpen, setModalOpen] = useState(false)
  const [cardToDel, setCardToDel] = useState<number>()

  useEffect(() => {
    addCategoryName(qpCards)
    setQuickPeekCards(qpCards)
  }, [quickPeekCards, cards])

  const addCategoryName = (cards: QuickPeekCardProps[]) => {
    cards?.map((card: QuickPeekCardProps) => {
      card.categoryName = getRelatedCategory(card.category_id, categories).title
    })
  }

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }))

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }))

  const pushlishStatus = (status: string) => status == 'published' ? 'Unpublish' : 'Publish'

  const filterQuickPeeks = (cards: QuickPeekCardProps[], event: React.ChangeEvent<HTMLInputElement>) => {
    const basedOnTitle = cards.filter((card: QuickPeekCardProps) =>
      card.title.toLowerCase().includes(event.target.value.toLowerCase()))
    const basedOnContent = cards.filter((card: QuickPeekCardProps) =>
      card.content.toLowerCase().includes(event.target.value.toLowerCase()))
    const basedOnSubHeading = cards.filter((card: QuickPeekCardProps) =>
      card.sub_heading.toLowerCase().includes(event.target.value.toLowerCase()))

    return [...basedOnContent, ...basedOnTitle, ...basedOnSubHeading]
  }

  const tableHeaderWithIcons = (title: string) => (
    <div className='title-container'>
      <b>{title}</b>
      <div className='up-down-icons'>
        <ArrowDropUp />
        <ArrowDropDown />
      </div>
    </div>
  )

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQP(event.target.value)
    if (event.target.value.length > 0)
      setCards(_.uniqBy(filterQuickPeeks(cards, event), 'id'))
    else if (event.target.value.length == 0)
      setCards(quickPeekCards)
  }

  const changeInObject = (column: string) => {
    const changeInObject = sortOrder
    changeInObject[column] = !(changeInObject[column])
    setSortOrder(changeInObject)
  }

  const sortOn = (quickPeek: QuickPeekCardProps, dependent: string) => {
    if (dependent == 'title')
      return quickPeek.title.toLowerCase()
    else if (dependent == 'category')
      return quickPeek.categoryName.toLowerCase()
    else if (dependent == 'status')
      return quickPeek.publish_status
    else if (dependent == 'tier')
      return quickPeek.tier
  }

  const sortQuickPeeks = (quickPeeks: QuickPeekCardProps[], dependent: string) =>
    _.sortBy(quickPeeks, (quickPeekCard: QuickPeekCardProps) => sortOn(quickPeekCard, dependent))

  const handleSort = (column: string) => {
    setCards(sortOrder[column] ? sortQuickPeeks(cards, column) : sortQuickPeeks(cards, column).reverse())
    changeInObject(column)
  }

  const openModal = (card: QuickPeekCardProps) => {
    setModalOpen(true)
    setCardToDel(card.id)
  }

  const closeModal = () => {
    setModalOpen(false)
    setCardToDel(undefined)
  }

  const quickPeekDelete = () => {
    setModalOpen(false)
    cardToDel && deleteQuickPeek(cardToDel, setCards)
  }

  return (
    <div className='quick-peek-admin'>
      <h1>Quick Peek Cards</h1>
      <div className='function-container'>
        <Button
          variant='contained'
          onClick={() => goToURL(`/${I18n.locale}/admin/quick-peek/new`)}
        >
          Create Quick Peek
        </Button>

        <TextField
          label='Search Quick Peek'
          margin='dense'
          onChange={handleSearch}
          placeholder='Search Quick Peek'
          value={searchQP}
          variant='outlined'
        />
      </div>

      <ToastContainer
        position='top-right'
        autoClose={2500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell onClick={() => handleSort('title')}>
                {tableHeaderWithIcons('Title')}
              </StyledTableCell>

              <StyledTableCell align='center' onClick={() => handleSort('category')}>
                {tableHeaderWithIcons('Category')}
              </StyledTableCell>

              <StyledTableCell align='center' onClick={() => handleSort('tier')}>
                {tableHeaderWithIcons('Tier')}
              </StyledTableCell>

              <StyledTableCell align='center'><b>URL</b></StyledTableCell>
              <StyledTableCell align='center'><b>Background Color</b></StyledTableCell>

              <StyledTableCell align='center' onClick={() => handleSort('status')}>
                {tableHeaderWithIcons('Status')}
              </StyledTableCell>
              <StyledTableCell align='center'><b>Actions</b></StyledTableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {(rowsPerPage > 0
              ? cards?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : cards).map((card: QuickPeekCardProps) => (
                <StyledTableRow key={card.id} >
                  <StyledTableCell
                    className='link-button'
                    onClick={() => goToURL(`/${I18n.locale}/quick-peek/${card.id}`)}
                  >
                    <b>{card.title}</b>
                  </StyledTableCell>

                  <StyledTableCell
                    align='center'
                    className='link-button'
                    onClick={() => goToURL(`/${I18n.locale}/categories/${card.category_id}`)}
                  >
                    {getRelatedCategory(card.category_id, categories).title}
                  </StyledTableCell>
                  <StyledTableCell align='center'>{card.tier}</StyledTableCell>
                  <StyledTableCell align='center'>
                    {`${window.location.origin}/${I18n.locale}/quick-peek/${card.id}`}
                  </StyledTableCell>

                  <StyledTableCell align='center'>
                    <div className={`colored-box ${card.background_color}-background-color `}></div>
                  </StyledTableCell>

                  <StyledTableCell
                    align='center'
                    className='link-button'
                    onClick={() => togglePublishStatus(card, setCards)}
                  >
                    {pushlishStatus(card.publish_status)}
                  </StyledTableCell>

                  <StyledTableCell
                    align='center'
                    className='link-button'
                  >
                    <div className='edit-delete'>
                      <div
                        className='edit'
                        onClick={() => goToURL(`/${I18n.locale}/admin/quick-peek/${card.id}/edit`)}
                      >
                        <Edit />
                      </div>

                      <div
                        className='delete'
                        onClick={() => openModal(card)}
                      >
                        <DeleteOutline />
                      </div>
                    </div>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
          </TableBody>
        </Table>
        <CustomTableFooter
          length={cards.length}
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
        />
      </TableContainer>
      <Dialog open={modalOpen} onClose={() => setModalOpen(false)}>
        <DialogTitle>Alert!!!</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete quick peek?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeModal}>Cancel</Button>
          <Button onClick={quickPeekDelete}>Delete</Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default QuickPeekAdminIndex
export type { QuickPeekAdminIndexProps }
