import React, { type FC, useContext, useState } from 'react'
import { Button, Divider } from '@mui/material'

import {
  CHILD_RELATIONSHIPS,
  CONDITION_FOR_CHILDREN,
  GENDERS,
  KINSHIP_CONDITION,
  KINSHIP_PLACEMENT,
  YES_NO
} from '../../../Constants'
import { getNames, removeMyselfFromAdult } from '../../../Helper'
import DividingColorLines from '../../DividingColorLines'
import InputQuestion from '../../InputQuestion'
import MultiSelectInput from '../../MultiSelectInput'
import SelectInput from '../../SelectInput'

import './styles.sass'

interface ChildDetailsProps {
  setValues: Function
  formValuesContext: any
  setStep: Function
}

const ChildDetails: FC<ChildDetailsProps> = ({ formValuesContext, setStep, setValues }) => {
  const { basicInfo }: any = useContext(formValuesContext)
  const [children, setChildren] = useState<any>(basicInfo?.children ?? {})
  const [provideCare, setProvideCare] = useState(basicInfo?.provideCare ?? {})
  const [buyMeals, setBuyMeals] = useState(basicInfo?.buyMeals ?? {})
  const [childrenLivingOutside, setChildrenLivingOutside] = useState(basicInfo?.childrenLivingOutside ?? '')
  const [fosterCare, setFosterCare] = useState(basicInfo?.fosterCare ?? '')
  const [trueForChildren, setTrueForChildren] = useState(basicInfo?.trueForChildren ?? {})
  const [applyToChildren, setApplyToChildren] = useState(basicInfo?.applyToChildren ?? {})
  const [canSubmit, setCanSubmit] = useState(false)
  let totalRequiredInputs = basicInfo?.childrenCount * 4 + 4

  const KINSHIP_CARE = 'This is a relative’s child that I am caring for (Kinship Care)'

  const [childErrors, setChildErrors] = useState<any>({})

  const checkExistenceInObject = (obj: Object) => Object.keys(obj).length > 0 ? 1 : 0

  const checkValueExists = (val: string) => val != '' ? 1 : 0

  const checkKinShipChild = () =>
    Object.keys(children).reduce((curr, next) => children[next]?.isRelativeChild == KINSHIP_CARE ? curr + 1 : curr + 0, 0)

  const checkRequiredFilled = () => {
    totalRequiredInputs += checkKinShipChild()

    let total = Object.keys(children).reduce((curr, next) => curr + Object.values(children[next]).length, 0) +
      checkExistenceInObject(provideCare) + checkExistenceInObject(buyMeals) + checkValueExists(childrenLivingOutside) + checkExistenceInObject(applyToChildren)

    if (childrenLivingOutside == 'Yes') totalRequiredInputs += 1

    if (!(trueForChildren['None of the above'] ?? true)) totalRequiredInputs += 1
    else if (trueForChildren['None of the above']) totalRequiredInputs -= 1

    if (childrenLivingOutside == 'Yes' && fosterCare != '') total += 1

    if (!(trueForChildren['None of the above'] ?? true) && Object.keys(trueForChildren).length != 0) total += 1

    return totalRequiredInputs <= total
  }

  const showBottomQuestion = () => basicInfo?.childrenCount * 4 <= Object.keys(children).reduce((curr, next) => curr + Object.keys(children[next]).length, 0)

  const handleNext = () => {
    setCanSubmit(true)

    if (checkRequiredFilled()) {
      setStep('CONGRATS')
      setValues({
        basicInfo: {
          ...basicInfo,
          children,
          provideCare,
          buyMeals,
          childrenLivingOutside,
          fosterCare,
          trueForChildren,
          applyToChildren
        }
      })
    }
  }

  const setChildNameError = (childNumber: number) => {
    setChildErrors({ ...childErrors, [childNumber]: { ...childErrors[childNumber], name: children[childNumber]?.name == undefined || children[childNumber]?.name == '' } })
  }

  const setChildAgeError = (childNumber: number) => {
    setChildErrors({ ...childErrors, [childNumber]: { ...childErrors[childNumber], age: children[childNumber]?.age == undefined || children[childNumber]?.age == '' || children[childNumber]?.age > 18 || children[childNumber]?.age < 0 } })
  }

  const renderInputs = (childNumber: number, numberOfKids: number) => (
    <div className='content d-flex flex-column questions'>
      <h2>{`Household child information: ${childNumber} of ${numberOfKids ?? 1}`}</h2>
      <InputQuestion
        type='text'
        label='Name of child'
        value={children[childNumber]?.name ?? ''}
        onChangeHandler={(e: React.ChangeEvent<HTMLInputElement>) => { setChildren({ ...children, [childNumber]: { ...children[childNumber], name: e.target.value } }) }}
        showErrorMsg={childErrors[childNumber]?.name || canSubmit && (children[childNumber]?.name == undefined || children[childNumber]?.name == '')}
        errorMsg={'Please provide name'}
        onBlurHandler={() => { setChildNameError(childNumber) }}
      />

      <InputQuestion
        type='number'
        label='Age of child'
        value={children[childNumber]?.age ?? ''}
        onChangeHandler={(e: React.ChangeEvent<HTMLInputElement>) => { setChildren({ ...children, [childNumber]: { ...children[childNumber], age: e.target.value } }) }}
        showErrorMsg={childErrors[childNumber]?.age || canSubmit && (children[childNumber]?.age == undefined || children[childNumber]?.age == '' || children[childNumber]?.age > 18 || children[childNumber]?.age < 0)}
        errorMsg={'Age is required & should be over 0 and less than 18'}
        onBlurHandler={() => { setChildAgeError(childNumber) }}
      />

      <SelectInput
        options={GENDERS}
        placeholderProp={'Gender'}
        value={children[childNumber]?.gender}
        setValue={(input: string) => { setChildren({ ...children, [childNumber]: { ...children[childNumber], gender: input } }) }}
        showErrorMsg={canSubmit && children[childNumber]?.gender == undefined}
        errorMsg={'Select a gender'}
      />

      <SelectInput
        options={CHILD_RELATIONSHIPS}
        placeholderProp={'Relationship'}
        value={children[childNumber]?.isRelativeChild}
        setValue={(input: string) => { setChildren({ ...children, [childNumber]: { ...children[childNumber], isRelativeChild: input } }) }}
        showErrorMsg={canSubmit && children[childNumber]?.isRelativeChild == undefined}
        errorMsg={'Select a relationship'}
      />

      {children[childNumber]?.isRelativeChild == KINSHIP_CONDITION &&
        <MultiSelectInput
          options={KINSHIP_PLACEMENT}
          placeholderProp={'Are any of the following true of your Kinship placement?'}
          value={children[childNumber]?.kinshipCondition}
          setValue={(input: string) => { setChildren({ ...children, [childNumber]: { ...children[childNumber], kinshipCondition: input } }) }}
          showErrorMsg={canSubmit && children[childNumber]?.kinshipCondition == undefined}
          errorMsg={'Select a condition'}
        />}
    </div>
  )

  return (
    <div className='child-details-input'>
      <div className='d-flex flex-column header-child-input'>
        <h2 className='child-household'>Children in household</h2>
        <p className='child-paragraphh'>Enter information about the children in your household</p>
      </div>
      <DividingColorLines />
      {Array(basicInfo?.childrenCount).fill(0).map((_adult: number, index: number) => renderInputs(index, basicInfo?.childrenCount))}
      <div className='content'>
        {basicInfo?.childrenCount > 0 && showBottomQuestion() && <>
          <Divider />
          <MultiSelectInput
            options={getNames(children) ?? []}
            placeholderProp={'Do you provide care for any of these children?'}
            value={provideCare}
            setValue={setProvideCare}
            showErrorMsg={canSubmit && Object.keys(provideCare).length == 0}
            errorMsg={'Select an option'}
          />
          <MultiSelectInput
            options={getNames(children).concat(removeMyselfFromAdult(getNames(basicInfo.adults ?? [])))}
            placeholderProp={'Who do you buy or eat meals with in your household?'}
            value={buyMeals}
            setValue={setBuyMeals}
            showErrorMsg={canSubmit && Object.keys(buyMeals).length == 0}
            errorMsg={'Select an option'}
          />
          <SelectInput
            options={YES_NO}
            placeholderProp={'Do you have children under 18 who don’t live with you?'}
            value={childrenLivingOutside}
            setValue={setChildrenLivingOutside}
            showErrorMsg={canSubmit && Object.keys(childrenLivingOutside).length == 0}
            errorMsg={'Select an option'}
          />
          {childrenLivingOutside == 'Yes' && <SelectInput
            options={YES_NO}
            placeholderProp={'Are they in foster care outside your home or living with a relative?'}
            value={fosterCare}
            setValue={setFosterCare}
            showErrorMsg={canSubmit && Object.keys(fosterCare).length == 0}
            errorMsg={'Select an option'}
          />}
          <MultiSelectInput
            options={CONDITION_FOR_CHILDREN}
            placeholderProp={'Are any of the following true of any of your children?'}
            value={trueForChildren}
            setValue={setTrueForChildren}
            showErrorMsg={canSubmit && Object.keys(trueForChildren).length == 0}
            errorMsg={'Select an option'}
          />
          {!(trueForChildren['None of the above'] ?? true) && <MultiSelectInput
            options={getNames(children) ?? []}
            placeholderProp={'Which children do these apply to?'}
            value={applyToChildren}
            setValue={setApplyToChildren}
            showErrorMsg={canSubmit && Object.keys(applyToChildren).length == 0}
            errorMsg={'Select an option'}
          />}
        </>}
        {basicInfo?.childrenCount == 0 && <p>You don't have any children in your household, please continue</p>}
        <Button variant='outlined' onClick={handleNext}>Continue</Button>
      </div>
    </div>
  )
}

export default ChildDetails
