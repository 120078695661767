import React, { useState } from 'react'
import { Link } from '@mui/material'

import PrivacyPolicyModal from '../../PrivacyPolicyModal'
import './styles.sass'

const PrivacyPolicy = (): JSX.Element => {
  const [modalOpen, setModalOpen] = useState(false)

  const openModal = (): void => {
    setModalOpen(true)
  }

  return (
    <div className='privacy-modal'>
      <p>Your privacy is our absolute first priority.</p>
      <Link onClick={openModal}>Review our privacy policy</Link>

      <PrivacyPolicyModal modalOpen={modalOpen} setModalOpen={setModalOpen} />
    </div>
  )
}
export default PrivacyPolicy
