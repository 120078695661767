import React, { useState } from 'react'
import { Button, Select, MenuItem, FormControl } from '@mui/material'
import { SelectChangeEvent } from '@mui/material/Select'

import './styles.sass'

interface InputSelectProps {
  blur: { [x: number]: boolean }
  handleNext: Function
  heading: String
  number: number
  options: String[] | { value: String | number, label: String | number }[]
  setValue: Function
  value: string | undefined
  qsNumber: number
  setQSNumber: Function
}

const InputSelect: React.FC<InputSelectProps> = (props) => {
  const { blur, options, heading, number, handleNext, value, setValue, qsNumber } = props
  const [showError] = useState(false)
  
  return (
    <>
      <div className={`input-select ${blur[number] ? '' : 'is-blur no-pointer-event'}`}    >
        <p className='question-heading'>{heading}</p>
        <FormControl sx={{ width: '100%' }} size='small'>
          <Select
            id='demo-simple-select'
            labelId='demo-simple-select-label'
            value={value}
            displayEmpty
            onChange={(event: SelectChangeEvent) => setValue(event.target.value)}
            MenuProps={{ PaperProps: { sx: { maxHeight: 420 } } }}
          >
            {options.map((option: any) =>
              <MenuItem value={option?.value ?? option}>
                {option?.label ?? option}
              </MenuItem>
            )}
          </Select>
        </FormControl>
        {qsNumber == number &&
          <Button variant='outlined' fullWidth onClick={() => handleNext(number)}>
            Continue
          </Button>
        }
      </div >
      {showError && <p className='error-paragraph'>Please make a selection</p>}
    </>
  )
}

export default InputSelect
export type { InputSelectProps }
