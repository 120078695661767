import React, { useState, useEffect, useRef } from 'react'
import * as d3 from 'd3'
import Axis from './Axis'
import Grid from './Grid'
import '../component_styles/LineChart.sass'

interface LineChartProps {
  width?: number
  height?: number
  chartId: string
  rawData: DataProps[]
}

interface DataProps {
  age: number
  amount: number
}

const LineChart: React.FC<LineChartProps> = (props) => {
  const [originalWidth, setOriginalWidth] = useState(props.width || 800)
  const [width, setWidth] = useState(originalWidth)
  const [height, setHeight] = useState(props.height || 500)
  const [line, setLine] = useState<string>()
  const [data, setData] = useState<DataProps[]>([])
  const parentDiv = useRef<HTMLInputElement>(null)

  const margin = { top: 30, right: 50, bottom: 80, left: 60 }
  const graphWidth = width - (margin.left + margin.right)
  const graphHeight = height - (margin.top + margin.bottom)

  const xExtent: any = d3.extent(data, function (d: any) {
    return d.age
  })
  const x: any = d3.scaleLinear().domain(xExtent).range([0, graphWidth])

  const y: any = d3
    .scaleLinear()
    .domain([
      0,
      d3.max(data, function (d: any) {
        return d.amount
      }),
    ])
    .range([graphHeight, 0])

  const rawLine: any = d3
    .line()
    .x(function (d: any) {
      return x(d.age)
    })
    .y(function (d: any) {
      return y(d.amount)
    })
    .curve(d3.curveCardinal)

  const xAxis = d3.axisBottom(x)
  const yAxis = d3.axisLeft(y)
  const yGrid = d3
    .axisLeft(y)
    .ticks(5)
    .tickSize(-graphWidth)
    .tickFormat(() => {
      return ''
    })
  const transform = 'translate(' + margin.left + ',' + margin.top + ')'

  function handleResize() {
    if (parentDiv.current) {
      const parentWidth = parentDiv.current.clientWidth
      if (parentWidth < originalWidth) {
        setWidth(parentWidth)
      } else {
        setWidth(originalWidth)
      }
    }
  }

  useEffect(() => {
    setData(props.rawData)
  }, [props.rawData])

  useEffect(() => {
    setLine(rawLine(data))
  }, [data])

  useEffect(() => {
    if (props.height) {
      setHeight(props.height)
    }
  }, [props.height])

  useEffect(() => {
    if (props.width) {
      setOriginalWidth(props.width)
      setWidth(props.width)
    }
  }, [props.width])

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    handleResize()
  }, [])

  return (
    <div>
      <h1>Line Chart</h1>
      <div ref={parentDiv} className='line-chart' style={{ height: `${height}` }}>
        <svg id={props.chartId} width={width} height={height}>
          <g transform={transform}>
            <Grid gridHeight={graphHeight} grid={yGrid} gridType='y' />
            <Axis axisHeight={graphHeight} axis={xAxis} axisType='x' />
            <Axis axisHeight={graphHeight} axis={yAxis} axisType='y' />
            {data.map((d, i) => (
              <rect
                key={i}
                x={x(d.age) - 5}
                y={y(d.amount) - 5}
                width={10}
                height={10}
                fill='steelblue'
              />
            ))}
            {data.map((d, i) => (
              <text key={i} x={x(d.age)} y={y(d.amount) - 10} textAnchor='middle' fill='#333'>
                {d.amount}
              </text>
            ))}
            <text
              transform={`translate(${graphWidth / 2}, ${graphHeight + margin.bottom - 20})`}
              textAnchor='middle'
            >
              Age
            </text>
            <text
              transform={`translate(${-margin.left + 20}, ${graphHeight / 2}) rotate(-90)`}
              textAnchor='middle'
            >
              Amount
            </text>
            <path className='line shadow' d={line} strokeLinecap='round' />
          </g>
        </svg>
      </div>
    </div>
  )
}

export default LineChart
export type { LineChartProps }
