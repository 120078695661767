import React from 'react'
import { NavigateNext, NavigateBefore } from '@mui/icons-material'

import { goToURL } from '../Helper'
import { QuickPeekCardProps } from './QuickPeekCard'
import I18n from '../config/I18n'

import '../component_styles/ExploreRelatedTopics.sass'
import { MAX_OUTER_WIDTH } from '../Constants'

interface ExploreRelatedTopicsProps {
  cards: QuickPeekCardProps[]
}

const renderTopicCard = (card: QuickPeekCardProps, index: number) => (
  <div
    className='topic-div'
    key={index}
    onClick={() => goToURL(`/${I18n.locale}/quick-peek/${card.id}`)}
  >
    <div className='content'>
      {card.image_url && <img src={card.image_url} alt={card.image_alt_text} />}

      {!card.image_url &&
        <div className='light-colored-div'>
          <div className='dark-colored-div'></div>
        </div>
      }
      <p className='two-lines'>{card.title}</p>
    </div>
  </div>
)

const ExploreRelatedTopics: React.FC<ExploreRelatedTopicsProps> = ({ cards }) => {
  const scrollHorizontally = (direction: string) => {
    const scroll = document.querySelector('.topics')
    scroll && scroll.scrollBy(direction == 'left' ? -400 : 400, 0)
  }

  return (
    <div className='explore-related-topics'>
      <div className='d-flex justify-space-between'>
        <h2>Explore Related Topics</h2>
        {outerWidth > MAX_OUTER_WIDTH && cards.length > 6 &&
          <div className='d-flex justify-space-between navigation-div'>
            <div className='navigate' onClick={() => scrollHorizontally('left')}><NavigateBefore /></div>
            <div className='navigate' onClick={() => scrollHorizontally('right')}><NavigateNext /></div>
          </div>
        }
      </div>
      <div className='topics'>
        {cards.map((card: QuickPeekCardProps, index: number) => renderTopicCard(card, index))}
      </div>
    </div>
  )
}

export default ExploreRelatedTopics
export type { ExploreRelatedTopicsProps }
