import React from 'react'
import ButtonMultipleChoiceOption, {ButtonMultipleChoiceOptionProps} from './ButtonMultipleChoiceOption'
import Label from './Label'
import '../component_styles/ButtonMultipleChoiceInput.sass'

interface ButtonMultipleChoiceInputProps{
  options: ButtonMultipleChoiceOptionProps[]
  name: string
  textOnly?: boolean
  isRequired?: boolean
  isEncouraged?: boolean
  label: string
  errors?: string
  disabled: boolean
  selected?: string
  onInputChange?: (ev: any) => void
}

const ButtonMultipleChoiceInput: React.FC<ButtonMultipleChoiceInputProps> = (props) =>{
  return (
    <>
      < Label
        label={ props.label }
        hasError={ !!props.errors }
        isRequired={ props.isRequired }
        isEncouraged={ props.isEncouraged }
        className={ props.disabled ? 'disabled' : '' }
      />
      <div className={ `multiple-choice-option-container ${props.textOnly ? "with-text-options" : "" }` }>
        { props.options.map((option, index) => {
            return (
              <ButtonMultipleChoiceOption
                key={ `${props.name}-${index}` }
                selected={ props.selected }
                { ...option }
                onInputChange={ props.onInputChange }
              />
            )
          })
        }
      </div>
    </>
  )
}

export default ButtonMultipleChoiceInput
export type { ButtonMultipleChoiceInputProps }
