import { toast } from 'react-toastify'

import { apiClient } from './apiClient'
import { QuickPeekCardProps } from '../components/QuickPeekCard'
import I18n from '../config/I18n'

export const togglePublishStatus = (card: QuickPeekCardProps, setCards: Function) =>
  apiClient
    .post(`/${I18n.locale as string}/admin/quick-peek/${card.id}/toggle_publish_status`, 
      {
        id: card.id,
        publish_status: (card.publish_status === 'published') && 'unpublished' || 'published'
      })
    .then((response) => {
      setCards(response?.data?.cards)
      toast.success(response?.data?.message)
    })
    .catch(({ response }) => toast.error(response?.data?.message))

export const deleteQuickPeek = (cardId: number, setCards: Function) =>
  apiClient
    .delete(`/${I18n.locale as string}/admin/quick-peek/${cardId}`)
    .then((response) => {
      setCards(response?.data?.cards)
      toast.success(response?.data?.message)
    })
    .catch(({ response }) => toast.error(response?.data?.message))
