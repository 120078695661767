import React, { memo, useMemo } from 'react'
import { Paper, Table, TableBody, TableCell, TableContainer, TableRow } from '@material-ui/core'

import {
  type GetYearlyOrMonthlyAmount,
  type MonthYearToggleButton,
  type RenderDefaultRow,
  type RenderRow,
  type RenderTableBody,
  type SectionVisibilityType
} from '../../../interfaces'
import { type Income, type Program, type Result } from '../../../OutputInterfaces'
import { type FormValues } from '../../../FormValuesInterfaces'

interface Props {
  formValues: FormValues
  result: Result
  monthYearToggleButton: MonthYearToggleButton
  tableVisibility: SectionVisibilityType
  renderRow: RenderRow
  renderDefaultRow: RenderDefaultRow
  toggleTableBody: RenderTableBody
  getMonthlyOrYearlyAmount: GetYearlyOrMonthlyAmount
}

const CurrentFinances: React.FC<Props> = memo((props) => {
  const {
    result,
    monthYearToggleButton,
    tableVisibility,
    renderRow,
    renderDefaultRow,
    toggleTableBody,
    getMonthlyOrYearlyAmount
  } = props

  const renderIcomeSectionsTakeHomePayRows = useMemo(() => (incomeProgram: Income, programIndex: number) => {
    if (incomeProgram.taxDeduction) {
      return (
        tableVisibility.takeHomePay &&
        <>
          {renderRow(
            'totalDeductionFromPay',
            'currentFinanceCalculations',
            'Total Deductions from Paycheck',
            incomeProgram.taxDeduction.programs,
            programIndex,
            '',
            incomeProgram.taxDeduction.programs_sum,
            '80px',
            '50px'
          )}
        </>
      )
    } else {
      return (
        tableVisibility.takeHomePay &&
        renderDefaultRow('currentFinanceCalculations', incomeProgram as Program, programIndex, '50px')
      )
    }
  }, [tableVisibility.takeHomePay, renderRow, renderDefaultRow])

  const renderIcomeSection = useMemo(() => (incomeProgram: Income, index: number) => {
    if (incomeProgram?.takeHomePay) {
      return incomeProgram?.takeHomePay?.programs?.map((takeHomePayProgram: Program, index: number) =>
        renderIcomeSectionsTakeHomePayRows(takeHomePayProgram as Income, index)
      )
    } else {
      return renderDefaultRow('currentFinanceCalculations', incomeProgram as Program, index)
    }
  }, [renderIcomeSectionsTakeHomePayRows, renderDefaultRow])

  const renderCurrentFinancesTable = useMemo(() => (
    <Table>
      <TableRow>
        <TableCell className='cursor-pointer' onClick={() => { toggleTableBody('takeHomePay') }}>
          <b>
            {tableVisibility?.takeHomePay ? <b>-</b> : <b>+</b>} Take Home Pay After Taxes and Deductions
          </b>
        </TableCell>

        <TableCell align='right'>
          <b>
            ${getMonthlyOrYearlyAmount(result?.output?.income?.programs[0]?.takeHomePay?.programs_sum, 'currentFinanceCalculations')}
          </b>
        </TableCell>
      </TableRow>

      <TableBody>
        {result?.output?.income?.programs?.map((incomePrograms: Income, index: number) =>
          renderIcomeSection(incomePrograms, index)
        )}
      </TableBody>
    </Table>
  ), [
    result?.output?.income?.programs,
    tableVisibility.takeHomePay,
    toggleTableBody,
    getMonthlyOrYearlyAmount,
    renderIcomeSection
  ])

  return (
    <>
      <div className='d-flex align-items-end'>
        <h3>Income</h3>

        <div className='d-flex justify-content-end flex-column'>
          {monthYearToggleButton('currentFinanceCalculations')}
          <h3>
            ${getMonthlyOrYearlyAmount(result?.output?.income?.programs_sum, 'currentFinanceCalculations')}
          </h3>
        </div>
      </div>

      <TableContainer component={Paper}>
        {renderCurrentFinancesTable}
      </TableContainer>
    </>
  )
})

export default CurrentFinances
