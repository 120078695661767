import React, { useState } from 'react'
import Label from './Label'
import '../component_styles/CurrencyField.sass'

interface CurrencyFieldProps {
  name: string
  value: string
  placeholder: string
  label: string
  errors?: string
  isRequired?: boolean
  disabled?: boolean
}

const CurrencyField: React.FC<CurrencyFieldProps> = (props) => {
  const { label, isRequired, errors, ...options } = props
  const [value, setValue] = useState(props.value)

  function handleValueChange(e: any) {
    setValue(e.target.value)
  }

  return (
    <div className="input currency">
      <Label
        label={ label }
        hasError={ !!errors }
        isRequired={ isRequired }
        className={ props.disabled ? 'disabled' : '' }
      />
      <div className="currency-symbol clearfix">$</div>
      <input
        { ...options }
        value={ value }
        type="number"
        min="0"
        step="any"
        onChange={ handleValueChange }
        className={ errors ? "error" : "" }
      />
      { props.errors &&
        <div className="error-message">{ props.errors }</div>
      }
    </div>
  )
}

export default CurrencyField
export type { CurrencyFieldProps }
