import I18n from 'i18n-js'

declare global {
  interface Window {
    ocConfig: {
      locale: string
    }
  }
}

I18n.locale = window.ocConfig.locale

export default I18n
