import React from 'react'

import { type BenefitData } from '../../EmployeeCalculatorInterface'
import { PENSION_PLAN_YEARS_LIMIT } from '../../Constants'

interface ContributionHeadingProps {
  benefitData: BenefitData
  benefitType: string
  isPension: boolean
}

const ContributionHeading: React.FC<ContributionHeadingProps> = ({ benefitData, benefitType, isPension }) => {
  if (benefitData.contribution !== '0' && benefitData.coverage !== null && benefitData.contribution !== 'NA') {
    if (benefitType === 'premium') {
      return <p>Your employer contributes {benefitData.contribution} of the remaining cost of your premium.</p>
    } else {
      const matchText = isPension ? ` You must participate in the plan for ${PENSION_PLAN_YEARS_LIMIT} years to receive the match investment.` : ''
      return <p>Your employer matches up to {benefitData.contribution} of your contribution.{matchText}</p>
    }
  } else if (benefitData.coverage === null || benefitData.type === null || benefitData.provider === null) {
    return <p>Select plan details to see employer contribution.</p>
  } else {
    const benefitTypeText = benefitType === 'premium' ? 'the cost of this benefit' : 'this benefit'
    return <p>Your employer does not contribute to {benefitTypeText}.</p>
  }
}

export default ContributionHeading
