import React from 'react'
import I18n from '../config/I18n'
import '../component_styles/Label.sass'

interface LabelProps {
  label: string
  hasError?: boolean
  isRequired?: boolean
  isEncouraged?: boolean
  children?: JSX.Element
  className?: string
  labelClassName?: string
}

const Label: React.FC<LabelProps> = (props) => {
  function determineLabelClassNames() {
    const classes = [props.className]

    if (props.hasError) {
      classes.push("error")
    }

    if (props.isRequired) {
      classes.push("required")
    }

    if (props.isEncouraged) {
      classes.push("encouraged")
    }

    return classes.join(" ")
  }

  return (
    <label
      className={ determineLabelClassNames() }
    >
      { props.children }
      <span className={ props.labelClassName }> { props.label } </span>
      { props.isRequired &&
          <span className="required">{ I18n.t("forms.is_required") }</span>
      }
      { props.isEncouraged &&
          <span className="encouraged">{ I18n.t("forms.is_encouraged") }</span>
      }
    </label>
  )
}

export default Label
export type { LabelProps }
