import React, { useState } from 'react'

import CategorySlider from './CategorySlider'
import ColoredBackgroundHeader from './ColoredBackgroundHeader'
import I18n from '../config/I18n'
import QuickPeekCard, { QuickPeekCardProps } from './QuickPeekCard'

import '../component_styles/QuickPeekCard.sass'
import '../component_styles/QuickPeekCardIndex.sass'

interface QuickPeekCardIndexProps {
  withHeader: boolean
  cards: QuickPeekCardProps[]
  counts: number[],
  categoryTags: string[]
}

const QuickPeekCardIndex: React.FC<QuickPeekCardIndexProps> = (props) => {
  const { withHeader, cards, counts, categoryTags } = props

  const [allCategories, setAllCategories] = useState(cards)

  return (
    <div className='quick-peek-card-index'>
      {withHeader &&
        <ColoredBackgroundHeader
          title={I18n.t('quick_peek.title')}
          description={I18n.t('quick_peek.description')}
          headerBackgroundColor='slighty-white-grey'
          dot={false}
        />
      }
      <div className='colored-div'></div>

      {categoryTags && <div className='content'>
        <CategorySlider categories={categoryTags} cards={cards} setAllCategories={setAllCategories} />
      </div>}

      <div className='content quick-peeks-responsiveness'>
        {allCategories.map((card, index) => {
          return (
            <QuickPeekCard
              key={`card-${index}`}
              {...card}
              count={counts[card.id]}
            />
          )
        })
        }
      </div>
    </div>
  )
}

export default QuickPeekCardIndex
export type { QuickPeekCardIndexProps }
