import React, { useState, useEffect } from 'react'
import '../component_styles/ProgressButton.sass'

interface ProgressButtonProps {
  text: string
  completedSteps: number
  totalSteps: number
  disabled?: boolean
  className?: string
}

const ProgressButton: React.FC<ProgressButtonProps> = (props) =>
{
  const [completedSteps, setCompletedSteps] = useState(props.completedSteps)
  const [totalSteps, setTotalSteps] = useState(props.totalSteps)

  useEffect(() => {
    setCompletedSteps(props.completedSteps)
    setTotalSteps(props.totalSteps)
  }, [props.completedSteps, props.totalSteps])

  function calculateRoundedProgress(completedSteps:number, totalSteps:number) {
    const percent = (completedSteps / totalSteps)*100;
    if (percent > 100) {
      return 100;
    } else {
      return Math.ceil(percent / 10) * 10;
    }
  }

  return (
      <button className={`progress-bar btn ${props.disabled ? "disabled" : ""} ${props.className}`}>
        <div className={`progress-bar-fill progress-${calculateRoundedProgress(completedSteps, totalSteps)}`} />
        <div className="progress-bar-text">
          { props.text }
        </div>
      </button>
  )
}

export default ProgressButton
