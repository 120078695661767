import React, { FC, useContext, useEffect, useState } from 'react'
import { ENROLLMENT_STATUS, YES_NO, EDUCATION_TYPES } from '../../../Constants'

import SelectInput from '../../SelectInput'

const _ = require('lodash')

interface EducationProps {
  formValuesContext?: any
  student: string
  setStudentEducation: Function
  studentEducation: any
  canSubmit: boolean
}

const Education: FC<EducationProps> = ({ student, formValuesContext, setStudentEducation, canSubmit, studentEducation }) => {
  const { basicInfo }: any = useContext(formValuesContext)
  const [adultStudentInfo, setAdultStudentInfo] = useState(basicInfo?.adults)
  const [adultNumber, setAdultNumber] = useState('')

  useEffect(() => {
    let indexOfAdult = Object.values(basicInfo?.adults).map((adult: any, index: number) => {
      if (student == adult?.name) {
        return index
      }
    })

    setAdultNumber(_.compact(indexOfAdult)[0])
  }, [student])

  useEffect(() => {
    setStudentEducation(adultStudentInfo)
  }, [adultStudentInfo])

  return (
    <>
      <h4>{`Enter details about ${student}'s education:`}</h4>
      <SelectInput
        options={EDUCATION_TYPES}
        placeholderProp={'What type of education are they enrolled in?'}
        value={adultStudentInfo[adultNumber]?.educationType ?? ''}
        setValue={(input: React.ChangeEvent<HTMLInputElement>) => setAdultStudentInfo({ ...adultStudentInfo, [adultNumber]: { ...adultStudentInfo[adultNumber], educationType: input } })}
        showErrorMsg={canSubmit && (studentEducation[adultNumber]?.educationType == undefined || studentEducation[adultNumber]?.educationType == '')}
        errorMsg={'Select an option'}
      />
      <SelectInput
        options={ENROLLMENT_STATUS}
        placeholderProp={'Enrollment Status'}
        value={adultStudentInfo[adultNumber]?.enrollmentStatus ?? ''}
        setValue={(input: React.ChangeEvent<HTMLInputElement>) => setAdultStudentInfo({ ...adultStudentInfo, [adultNumber]: { ...adultStudentInfo[adultNumber], enrollmentStatus: input } })}
        showErrorMsg={canSubmit && (studentEducation[adultNumber]?.enrollmentStatus == undefined || Object.keys(studentEducation[adultNumber]?.enrollmentStatus).length == 0)}
        errorMsg={'Select an option'}
      />
      <SelectInput
        options={YES_NO}
        placeholderProp={'Is working at least 20 hours per month?'}
        value={adultStudentInfo[adultNumber]?.working20Hours ?? ''}
        setValue={(input: React.ChangeEvent<HTMLInputElement>) => setAdultStudentInfo({ ...adultStudentInfo, [adultNumber]: { ...adultStudentInfo[adultNumber], working20Hours: input } })}
        showErrorMsg={canSubmit && (studentEducation[adultNumber]?.working20Hours == undefined || studentEducation[adultNumber]?.working20Hours == '')}
        errorMsg={'Select an option'}
      />
      <SelectInput
        options={YES_NO}
        placeholderProp={'Is caring for a child under 6?'}
        value={adultStudentInfo[adultNumber]?.caringChildUnderSix ?? ''}
        setValue={(input: React.ChangeEvent<HTMLInputElement>) => setAdultStudentInfo({ ...adultStudentInfo, [adultNumber]: { ...adultStudentInfo[adultNumber], caringChildUnderSix: input } })}
        showErrorMsg={canSubmit && (studentEducation[adultNumber]?.caringChildUnderSix == undefined || studentEducation[adultNumber]?.caringChildUnderSix == '')}
        errorMsg={'Select an option'}
      />
      <SelectInput
        options={YES_NO}
        placeholderProp={'Is caring for a child between ages 6-9 without adequate child care?'}
        value={adultStudentInfo[adultNumber]?.caringWOChildCare ?? ''}
        setValue={(input: React.ChangeEvent<HTMLInputElement>) => setAdultStudentInfo({ ...adultStudentInfo, [adultNumber]: { ...adultStudentInfo[adultNumber], caringWOChildCare: input } })}
        showErrorMsg={canSubmit && (studentEducation[adultNumber]?.caringWOChildCare == undefined || studentEducation[adultNumber]?.caringWOChildCare == '')}
        errorMsg={'Select an option'}
      />
      <SelectInput
        options={YES_NO}
        placeholderProp={'Is enrolled in an education program that will be completed in lessthan 2 years and will lead to employment?'}
        value={adultStudentInfo[adultNumber]?.enrolledLeadingToJob ?? ''}
        setValue={(input: React.ChangeEvent<HTMLInputElement>) => setAdultStudentInfo({ ...adultStudentInfo, [adultNumber]: { ...adultStudentInfo[adultNumber], enrolledLeadingToJob: input } })}
        showErrorMsg={canSubmit && (studentEducation[adultNumber]?.enrolledLeadingToJob == undefined || studentEducation[adultNumber]?.enrolledLeadingToJob == '')}
        errorMsg={'Select an option'}
      />
      <SelectInput
        options={YES_NO}
        placeholderProp={'Are they participating in a federally or state-funded work study program?'}
        value={adultStudentInfo[adultNumber]?.fundedStudyProgram ?? ''}
        setValue={(input: React.ChangeEvent<HTMLInputElement>) => setAdultStudentInfo({ ...adultStudentInfo, [adultNumber]: { ...adultStudentInfo[adultNumber], fundedStudyProgram: input } })}
        showErrorMsg={canSubmit && (studentEducation[adultNumber]?.fundedStudyProgram == undefined || studentEducation[adultNumber]?.fundedStudyProgram == '')}
        errorMsg={'Select an option'}
      />
      <SelectInput
        options={YES_NO}
        placeholderProp={'Is attending school because of a placement through a WIOA, W-2 or FSET program?'}
        value={adultStudentInfo[adultNumber]?.attendingThroughProgram ?? ''}
        showErrorMsg={canSubmit && (studentEducation[adultNumber]?.attendingThroughProgram == undefined || studentEducation[adultNumber]?.attendingThroughProgram == '')}
        errorMsg={'Select an option'}
        setValue={(input: React.ChangeEvent<HTMLInputElement>) => {
          setAdultStudentInfo({ ...adultStudentInfo, [adultNumber]: { ...adultStudentInfo[adultNumber], attendingThroughProgram: input } })
        }}
      />
    </>
  )
}

export default Education
