import React, { useEffect } from 'react'
import I18n from '../config/I18n'

import { useGlobalState } from '../GlobalState'
import HamburgerMenuButton from './HamburgerMenuButton'
import Link from './Link'

import '../component_styles/Header.sass'

interface HeaderProps {
  includeMenu: boolean
  userId: number
  signedIn: boolean
  userIsAdmin: boolean
  userIsGuest: boolean
  sitemapDate: string
  userEmail?: string
  progressPercent?: number
  backUrl?: string
  editUrl?: string
}

const Header: React.FC<HeaderProps> = (props) => {
  const [headerState, setHeaderState] = useGlobalState(window.OC.globals.headerState)

  useEffect(() => {
    setHeaderState({ backUrl: props.backUrl, editUrl: props.editUrl, progressPercent: props.progressPercent, ...window.OC.globals.headerState })
  }, [props.backUrl, props.editUrl, props.progressPercent])

  let greeting = null
  if (props.signedIn) {
    if (!props.userIsGuest) {
      greeting = (
        <span className='greeting'>
          <a href={`/${I18n.locale}/users/${props.userId}/`}>
            {I18n.t('greeting_user', { email: props.userEmail })}
          </a>
        </span>
      )
    } else {
      greeting = (
        <span className='greeting'>
          {I18n.t('greeting_guest')}
          <Link
            text={I18n.t('claim_account')}
            href='/users/sign_up'
            className='nudge-right-10'
          />
        </span>
      )
    }
  } else {
    greeting = (
      <Link
        text={I18n.t('sign_in_sign_up')}
        href='/users/sign_up'
      />
    )
  }

  return (
    <div className='header'>
      {headerState.backUrl &&
        <a href={headerState.backUrl} className='display-inline-block vertical-centered' ></a>
      }
      <div className='sign-in-link display-inline-block'>
        {headerState.editUrl &&
          <a href={headerState.editUrl} className='green caps'>
            {I18n.t('edit')}
          </a>
        }
        {!headerState.editUrl && greeting}
        <HamburgerMenuButton
          className={props.includeMenu ? 'visible' : 'hidden'}
          userIsAdmin={props.userIsAdmin}
          userIsGuest={props.userIsGuest}
          userId={props.userId}
          sitemapDate={props.sitemapDate}
        >
        </HamburgerMenuButton>
      </div>
      {headerState.progressPercent &&
        <div className='header-progress-bar-container'>
          <div className='header-progress-bar' style={{ left: `${headerState.progressPercent}%` }}></div>
        </div>
      }
    </div>
  )
}

export default Header
export type { HeaderProps }
